<template>
	<v-timeline dense clipped class="pt-4">
		<v-timeline-item fill-dot class="mb-4" color="info" large>
			<template v-slot:icon>
				<span class="white--text">{{ generateBulletText(user) }}</span>
			</template>

			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="message"
					:label="`${$t('$shipment.leave_message')}...`"
					@keyup.enter="commentSaveAction"
					:rules="rules.message"
					counter="250"
				>
					<template v-slot:append>
						<v-btn
							color="primary"
							class="mb-2"
							depressed
							@click="commentSaveAction"
							:disabled="message.trim().length === 0"
							:loading="savingMessage"
						>
							{{ $t("save") }}
						</v-btn>
					</template>
				</v-text-field>
			</v-form>
		</v-timeline-item>

		<v-timeline-item
			v-for="item in list"
			:key="item.id"
			class="pb-4"
			small
			fill-dot
			:color="getBulletColor(item)"
		>
			<template v-slot:icon>
				<span class="text-caption white--text">{{ item.iconText }}</span>
			</template>
			<v-row justify="space-between">
				<v-col xs="12" sm="9">
					{{ item.text }}
					<v-card v-if="item.note" elevation="3">
						<v-card-text class="pa-2 mt-2">
							{{ item.note }}
						</v-card-text>
					</v-card>
				</v-col>
				<v-col class="d-none d-sm-block text-right text-caption" cols="3">
					{{ item.date }}
				</v-col>
				<v-col class="d-sm-none text-caption pt-0" cols="12">
					{{ item.date }}
				</v-col>
			</v-row>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
import { mapState } from "vuex";
import remote from "../../../../data/remote";
import colors from "vuetify/lib/util/colors";

export default {
	props: { value: Object },
	data() {
		return {
			colors,
			list: null,
			message: "",
			savingMessage: false,
			valid: true,
			rules: {
				message: [
					(v) => {
						if (v && v.length > 250)
							return this.$t("$validation.max_length", {
								item: this.$t("message"),
								length: "250",
							});
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapState("auth", ["user"]),
	},
	watch: {
		value(val) {
			this.handleProps();
		},
	},
	methods: {
		handleProps() {
			this.list = [];
			this.value.actions.reverse().forEach((action) => {
				const item = {
					text: null,
					note: null,
					date: action.date.format(this.$t("$format.date_time_without_second")),
					iconText: this.generateBulletText(action.createdBy),
					type: action.type,
					id: action.id,
					createdBy: action.createdBy,
				};
				if (action.type == "comment")
					item.text = `${action.createdBy.firstName} ${action.createdBy.lastName}`;
				else if (this.value.method == "land" && this.isContainerAction(action)) {
					item.text = this.$t(`$shipment.$actionDescription.$land.${action.type}`, {
						name: `${action.createdBy.firstName} ${action.createdBy.lastName}`,
					});
				} else {
					item.text = this.$t(`$shipment.$actionDescription.${action.type}`, {
						name: `${action.createdBy.firstName} ${action.createdBy.lastName}`,
					});
				}
				item.note = action.referenceText;
				this.list.push(item);
			});
		},
		generateBulletText(item) {
			return `${item.firstName[0].toUpperCase()}${item.lastName[0].toUpperCase()}`;
		},
		getBulletColor(action) {
			if (
				action.createdBy.firstName == this.user.firstName &&
				action.createdBy.lastName == this.user.lastName
			)
				return "info";
			else return colors.grey.darken1;
		},
		commentSaveAction() {
			if (this.valid) {
				if (this.message.trim().length > 0) {
					this.savingMessage = true;
					remote.shipments.comment(this.value, this.message, {
						onSuccess: (shipment) => {
							this.$emit("input", shipment);
							this.savingMessage = false;
							this.message = "";
						},
						onFail: () => (savingMessage = false),
					});
				}
			} else this.$refs.form.validate();
		},
		isContainerAction(action) {
			return ["create_container", "update_container", "delete_container"].includes(action.type);
		},
		// isAttachmentAction(action) {
		// 	return [
		// 		"create_attachment",
		// 		"update_attachment",
		// 		"delete_attachment",
		// 	].includes(action.type);
		// },
		// isComment(action) {
		// 	return action.type == "comment";
		// },
		// findContainer(action) {
		// 	return action.containers.find((c) => c.id == action.referenceId);
		// },
		// findAttachemtn(action) {
		// 	return action.attachments.find((c) => c.id == action.referenceId);
		// },
	},
	created() {
		this.handleProps();
	},
};
</script>

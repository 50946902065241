import { mapActions } from "vuex";

export default {
	methods: {
		...mapActions("ui", [
			"showProgressBar",
			"hideProgressBar",
			"showProgressDialog",
			"hideProgressDialog",
			"showAlertDialog",
			"showConfirmDialog",
			"showTextInputDialog",
			"showSnackBar",
		]),
	},
};

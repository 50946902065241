import { mapGetters, mapActions } from "vuex";

export default {
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
	},
	methods: {
		...mapActions("auth", [
			"checkForRefresh",
			"signIn",
			"signUp",
			"signOut",
			"stopRefreshTimer",
		]),
	},
};

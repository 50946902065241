<template v-slot:extension>
	<v-container>
		<!-- Employee Data Table -->
		<v-data-table
			:headers="headers"
			:items="employees"
			:items-per-page="-1"
			hide-default-footer
			class="ma-4"
			style="cursor: pointer"
			disable-sort
		>
			<template v-slot:item.type="{ item }">
				<v-select
					v-model="item.type"
					:items="typeOptions"
					item-value="value"
					item-text="text"
					:disabled="item.user.id === user.id"
					@change="typeChangeAction(item)"
				/>
			</template>
			<template v-slot:item.buttons="{ item }">
				<v-tooltip bottom open-delay="500">
					<template v-slot:activator="{ on }">
						<v-btn
							icon
							@click="deleteEmployee(item)"
							v-on="on"
							:disabled="item.user.id === user.id"
						>
							<v-icon small>mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("delete") }}</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<!-- Invitations Data Table -->
		<v-data-table
			:headers="invitationHeaders"
			:items="invitations"
			:options.sync="tableOptions"
			:server-items-length="pagination.totalItemCount"
			:footer-props="tableFooterProps"
			:loading="loading"
			class="ma-4"
			style="cursor: pointer"
			@click:row="dialog.show"
			disable-sort
		>
			<template v-slot:item.type="{ item }">
				{{ $t(`$invitationType.${item.type}`) }}
			</template>
		</v-data-table>

		<!-- New Invitation Button -->
		<v-btn color="accent" elevation="0" fixed bottom right fab @click="dialog.show(null)">
			<v-icon>mdi-account-plus</v-icon>
		</v-btn>

		<!-- Invitation Dialog -->
		<invitation-dialog
			v-model="dialog.visibility"
			:item="dialog.item"
			:onlyCompany="false"
			@saved="dialog.saved"
			@emailSent="dialog.emailSent"
			@deleted="dialog.deleted"
		/>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import InvitationDialog from "./dialogs/InvitationDialog";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import { invitationTypes } from "../../../utils/enum";

export default {
	mixins: [uiMixin, paginationMixin],
	components: { InvitationDialog },
	data() {
		return {
			employees: [],
			invitations: [],
			loading: false,
			tableOptions: {
				page: 1,
				itemsPerPage: 10,
				sortBy: [],
				sortDesc: false,
			},
			pagination: {
				totalItemCount: 0,
			},
			typeOptionsForCompany: [
				{ value: "admin", text: this.$t("$employeeType.admin") },
				{ value: "default", text: this.$t("$employeeType.default") },
			],
			typeOptionsForCustomsAgency: [
				{ value: "admin", text: this.$t("$employeeType.admin") },
				{
					value: "default",
					text: `${this.$t("$employeeType.exporter")} - ${this.$t("$employeeType.importer")}`,
				},
				{ value: "exporter", text: this.$t("$employeeType.exporter") },
				{ value: "importer", text: this.$t("$employeeType.importer") },
			],
			headers: [
				{ text: this.$t("first_name"), value: "user.firstName" },
				{ text: this.$t("last_name"), value: "user.lastName" },
				{ text: this.$t("email"), value: "user.email" },
				{ text: this.$t("type"), value: "type", width: 148 },
				{ text: "", value: "buttons", width: 70, align: "right" },
			],
			invitationHeaders: [
				{ text: this.$t("type"), value: "type" },
				{ text: this.$t("email"), value: "email" },
				{ text: this.$t("code"), value: "code" },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (invitation) => {
					this.dialog.item = invitation;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadInvitations();
				},
				emailSent: () => {
					this.dialog.hide();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadInvitations();
				},
			},
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState("auth", ["user"]),
		typeOptions() {
			if (this.company) return this.typeOptionsForCompany;
			else if (this.customsAgency) return this.typeOptionsForCustomsAgency;
		},
	},
	methods: {
		loadCompanyEmployees() {
			remote.employees.getMyCompanyEmployees({
				onSuccess: (result) => {
					this.employees = result.items.sort((a, b) => (a.name < b.name ? -1 : 1));
					this.hideProgressBar();
				},
			});
		},
		typeChangeAction(employee) {
			this.showProgressBar();
			remote.employees.updateType(employee.id, employee.type, {
				onSuccess: (_) => this.loadCompanyEmployees(),
				onFail: (_) => this.loadCompanyEmployees(),
			});
		},
		deleteEmployee(employee) {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressBar();
					remote.employees.delete(employee.id, {
						onSuccess: () => {
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("employee"),
								}),
							});
							this.loadCompanyEmployees();
						},
					});
				},
			});
		},
		loadInvitations() {
			this.loading = true;
			remote.invitations.filter(
				{ status: "active", types: invitationTypes.Employee },
				{
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.invitations = result.items;
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: (error) => {
						if (error) console.error(error);
						this.loading = false;
					},
				}
			);
		},
	},
	created() {
		this.loadCompanyEmployees();
		this.loadInvitations();
	},
};
</script>

export default {
	$accessibility: {
		present: "En curso",
		archived: "Archivado",
	},
	$accessModifier: {
		$description: {
			private: "Privado",
			hidden_from_receiver: "Oculto del receptor",
			hidden_from_sender: "Oculto del remitente",
			shared_with_everyone: "Compartido con todos",
			shared_with_custom: "Compartido con personalizado",
			shared_with_receiver: "Compartido con el receptor",
			shared_with_sender: "Compartido con el remitente",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andorra",
		united_arab_emirates: "Emiratos Árabes Unidos",
		afghanistan: "Afganistán",
		antigua_and_barbuda: "Antigua y Barbuda",
		anguilla: "Anguila",
		albania: "Albania",
		armenia: "Armenia",
		angola: "Angola",
		antarctica: "Antártida",
		argentina: "Argentina",
		american_samoa: "Samoa Americana",
		austria: "Austria",
		australia: "Australia",
		aruba: "Aruba",
		aland: "Islas Åland",
		azerbaijan: "Azerbaiyán",
		bosnia_and_herzegovina: "Bosnia y Herzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Bélgica",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaria",
		bahrain: "Baréin",
		burundi: "Burundi",
		benin: "Benín",
		saint_barthelemy: "San Bartolomé",
		bermuda: "Bermudas",
		brunei: "Brunéi",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, San Eustaquio y Saba",
		brazil: "Brasil",
		bahamas: "Bahamas",
		bhutan: "Bután",
		bouvet_island: "Isla Bouvet",
		botswana: "Botsuana",
		belarus: "Bielorrusia",
		belize: "Belice",
		canada: "Canadá",
		cocos_islands: "Islas Cocos",
		dr_congo: "República Democrática del Congo",
		central_african_republic: "República Centroafricana",
		congo_republic: "Congo",
		switzerland: "Suiza",
		ivory_coast: "Costa de Marfil",
		cook_islands: "Islas Cook",
		chile: "Chile",
		cameroon: "Camerún",
		china: "China",
		colombia: "Colombia",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Cabo Verde",
		curacao: "Curazao",
		christmas_island: "Isla de Navidad",
		cyprus: "Chipre",
		czechia: "Chequia",
		germany: "Alemania",
		djibouti: "Yibuti",
		denmark: "Dinamarca",
		dominica: "Dominica",
		dominican_republic: "República Dominicana",
		algeria: "Argelia",
		ecuador: "Ecuador",
		estonia: "Estonia",
		egypt: "Egipto",
		western_sahara: "Sahara Occidental",
		eritrea: "Eritrea",
		spain: "España",
		ethiopia: "Etiopía",
		finland: "Finlandia",
		fiji: "Fiyi",
		falkland_islands: "Islas Malvinas",
		micronesia: "Micronesia",
		faroe_islands: "Islas Feroe",
		france: "Francia",
		gabon: "Gabón",
		united_kingdom: "Reino Unido",
		grenada: "Granada",
		georgia: "Georgia",
		french_guiana: "Guayana Francesa",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Groenlandia",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadalupe",
		equatorial_guinea: "Guinea Ecuatorial",
		greece: "Grecia",
		south_georgia_and_south_sandwich_islands:
			"Islas Georgias del Sur y Sandwich del Sur",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bisáu",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Isla Heard e Islas McDonald",
		honduras: "Honduras",
		croatia: "Croacia",
		haiti: "Haití",
		hungary: "Hungría",
		indonesia: "Indonesia",
		ireland: "Irlanda",
		israel: "Israel",
		isle_of_man: "Isla de Man",
		india: "India",
		british_indian_ocean_territory: "Territorio Británico del Océano Índico",
		iraq: "Irak",
		iran: "Irán",
		iceland: "Islandia",
		italy: "Italia",
		jersey: "Jersey",
		jamaica: "Jamaica",
		jordan: "Jordania",
		japan: "Japón",
		kenya: "Kenia",
		kyrgyzstan: "Kirguistán",
		cambodia: "Camboya",
		kiribati: "Kiribati",
		comoros: "Comoras",
		st_kitts_and_nevis: "San Cristóbal y Nieves",
		north_korea: "Corea del Norte",
		south_korea: "Corea del Sur",
		kuwait: "Kuwait",
		cayman_islands: "Islas Caimán",
		kazakhstan: "Kazajistán",
		laos: "Laos",
		lebanon: "Líbano",
		saint_lucia: "Santa Lucía",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesoto",
		lithuania: "Lituania",
		luxembourg: "Luxemburgo",
		latvia: "Letonia",
		libya: "Libia",
		morocco: "Marruecos",
		monaco: "Mónaco",
		moldova: "Moldavia",
		montenegro: "Montenegro",
		saint_martin: "San Martín",
		madagascar: "Madagascar",
		marshall_islands: "Islas Marshall",
		north_macedonia: "Macedonia del Norte",
		mali: "Malí",
		myanmar: "Myanmar",
		mongolia: "Mongolia",
		macao: "Macao",
		northern_mariana_islands: "Islas Marianas del Norte",
		martinique: "Martinica",
		mauritania: "Mauritania",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauricio",
		maldives: "Maldivas",
		malawi: "Malaui",
		mexico: "México",
		malaysia: "Malasia",
		mozambique: "Mozambique",
		namibia: "Namibia",
		new_caledonia: "Nueva Caledonia",
		niger: "Níger",
		norfolk_island: "Isla Norfolk",
		nigeria: "Nigeria",
		nicaragua: "Nicaragua",
		netherlands: "Países Bajos",
		norway: "Noruega",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Nueva Zelanda",
		oman: "Omán",
		panama: "Panamá",
		peru: "Perú",
		french_polynesia: "Polinesia Francesa",
		papua_new_guinea: "Papúa Nueva Guinea",
		philippines: "Filipinas",
		pakistan: "Pakistán",
		poland: "Polonia",
		saint_pierre_and_miquelon: "San Pedro y Miquelón",
		pitcairn_islands: "Islas Pitcairn",
		puerto_rico: "Puerto Rico",
		palestine: "Palestina",
		portugal: "Portugal",
		palau: "Palaos",
		paraguay: "Paraguay",
		qatar: "Catar",
		reunion: "Reunión",
		romania: "Rumanía",
		serbia: "Serbia",
		russia: "Rusia",
		rwanda: "Ruanda",
		saudi_arabia: "Arabia Saudita",
		solomon_islands: "Islas Salomón",
		seychelles: "Seychelles",
		sudan: "Sudán",
		sweden: "Suecia",
		singapore: "Singapur",
		saint_helena: "Santa Elena",
		slovenia: "Eslovenia",
		svalbard_and_jan_mayen: "Svalbard y Jan Mayen",
		slovakia: "Eslovaquia",
		sierra_leone: "Sierra Leona",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Surinam",
		south_sudan: "Sudán del Sur",
		sao_tome_and_principe: "Santo Tomé y Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Siria",
		eswatini: "Esuatini",
		turks_and_caicos_islands: "Islas Turcas y Caicos",
		chad: "Chad",
		french_southern_territories: "Territorios Franceses del Sur",
		togo: "Togo",
		thailand: "Tailandia",
		tajikistan: "Tayikistán",
		tokelau: "Tokelau",
		timor_leste: "Timor Oriental",
		turkmenistan: "Turkmenistán",
		tunisia: "Túnez",
		tonga: "Tonga",
		turkiye: "Turquía",
		trinidad_and_tobago: "Trinidad y Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwán",
		tanzania: "Tanzania",
		ukraine: "Ucrania",
		uganda: "Uganda",
		us_minor_outlying_islands: "Islas menores alejadas de los Estados Unidos",
		united_states: "Estados Unidos",
		uruguay: "Uruguay",
		uzbekistan: "Uzbekistán",
		vatican_city: "Ciudad del Vaticano",
		st_vincent_and_grenadines: "San Vicente y las Granadinas",
		venezuela: "Venezuela",
		british_virgin_islands: "Islas Vírgenes Británicas",
		us_virgin_islands: "Islas Vírgenes de los Estados Unidos",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis y Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yemen",
		mayotte: "Mayotte",
		south_africa: "Sudáfrica",
		zambia: "Zambia",
		zimbabwe: "Zimbabue",
	},
	$currency: {
		us_dollar: "Dólar estadounidense", //CHECK:
		euro: "Euro",
		turkish_lira: "Lira turca",
	},
	$employeeType: {
		admin: "Administrador",
		default: "Normal",
		exporter: "Exportador",
		importer: "Importador",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:SS",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Bienvenido a Antrego",
			description:
				"¡Controla tus procesos de importación-exportación con Antrego!",
			sign_in_title: "¿Ya tienes una cuenta?",
			sign_in_subtitle: "Inicia sesión aquí",
			sign_up_title: "¿Quieres crear una cuenta?",
			sign_up_subtitle: "Regístrate aquí",
		},
		$noCompany: {
			title: "Bienvenido, {name}",
			description:
				"Has iniciado sesión exitosamente. Ahora debes unirte a una empresa o crear la tuya propia.",
			join_company_title: "¿Quieres unirte a una empresa existente?",
			join_company_subtitle:
				"Ingresa tu código de invitación aquí y únete a la empresa.",
			create_company_title: "¿Quieres crear tu propia empresa?",
			create_company_subtitle: "Crea una nueva empresa aquí.",
		},
	},
	$invitationType: {
		employee: "Invitación de Empleado",
		company_partner: "Invitación de Colaboración Comercial",
		customs_partner: "Invitación de Agente Aduanal",
	},
	$landing: {
		$question: {
			$title: {
				0: "¿Qué es Antrego?",
				1: "¿Cómo beneficiará Antrego a mi empresa en el proceso de envío?",
				2: "¿Qué tipos de logística soporta Antrego?",
				3: "¿Por qué debería usar Antrego?",
				4: "¿Antrego es una aplicación de pago?",
				5: "¿Los clientes deben pagar por Antrego?",
			},
			$text: {
				0: "<p>Un programa de seguimiento de envíos que ayuda a las empresas de envío y a sus clientes a realizar un seguimiento conjunto, evitando daños causados por retrasos en la etapa de seguimiento.</p>",
				1: `<p>- Comparte todas tus actualizaciones en tiempo real con tus clientes. Mejora la calidad de tu servicio.<br>
					- Ve todos tus documentos de envío en un solo sistema y compártelos con tus clientes.<br>
					- Realiza un seguimiento de todos tus envíos desde un solo lugar. Controla tus finanzas con informes dinámicos.</p>`,
				2: `<p>- Marítimo<br>
					- Aéreo<br>
					- Terrestre<br>
					- Ferroviario</p>`,
				3: `<p>Facilita el proceso de envío con los servicios que ofrece, siempre al alcance de tu mano.<br>
					<strong>1. Fácil Acceso</strong><br>
					Accesible desde cualquier dispositivo y lugar a través del navegador web.<br>
					<strong>2. Seguridad de Datos</strong><br>
					Todos los datos se almacenan con la tecnología de cifrado SHA-256.<br>
					<strong>3. Seguimiento Compartido</strong><br>
					Ofrece la posibilidad de seguimiento a todas las partes interesadas en el proceso comercial.</p>`,
				4: "<p>Sí, es una aplicación de pago. Queremos facilitar tus negocios llevando todos los procesos de envío a lo digital y seguir haciendo mejoras útiles para ti. Para mantener estas mejoras y el espacio que utilizas en el servidor, te pedimos una tarifa mensual.</p>",
				5: "<p>No, tus clientes no tienen que pagar ninguna tarifa mientras rastrean tus envíos.</p>",
			},
		},
		$lifecycle: {
			headline: "TODOS LOS PROCESOS A TU ALCANCE",
			$title: {
				0: "Regístrate",
				1: "Envía una Factura Proforma",
				2: "Crea un Envío",
				3: "Sigue las Actividades Financieras",
			},
			$text: {
				0: "Ingresa la información de tu empresa y tus productos, y crea a tus clientes.",
				1: "Crea una factura proforma y compártela con tus clientes para su aprobación.",
				2: "Ingresa la información de tu envío y adjunta los documentos necesarios. Gestiona todos los procesos de tu envío con tus clientes desde un solo canal.",
				3: "Realiza un seguimiento en tiempo real de todas las actividades financieras relacionadas con importación y exportación.",
			},
		},
		$content: {
			headline: "TIPOS DE LOGÍSTICA SOPORTADOS",
			$title: {
				0: "Marítimo",
				1: "Aéreo",
				2: "Terrestre",
				3: "Ferroviario",
			},
		},
		$pricing: {
			0: {
				title: "GRATIS",
				subtitle: "Individual",
				features: ["1 Usuario", "3 Colaboradores", "10 Envíos", "10 Facturas"],
			},
			1: {
				title: "ANUAL",
				subtitle: "Empresas",
				features: [
					"20 Usuarios",
					"100 Colaboradores",
					"1000 Envíos",
					"1000 Facturas",
				],
			},
			2: {
				title: "MENSUAL",
				subtitle: "Pequeñas Empresas",
				features: [
					"10 Usuarios",
					"30 Colaboradores",
					"250 Envíos",
					"250 Facturas",
				],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "¿Estás seguro de que quieres {function}?",
		created: "{item} creado.",
		deleted: "{item} eliminado.",
		file_oversize: "El tamaño del archivo debe ser como máximo {size}.",
		invalid: "{item} no válido.",
		sent_email: "Correo electrónico enviado. ({value})",
		sign_in_failed: "Correo electrónico o contraseña incorrectos.",
		updated: "{item} actualizado.",
		migrated: "{item} migrado. ({value})",
	},
	$productGroup: {
		add_child: "Agregar Subgrupo",
		add_to_root: "Agregar a la Raíz",
		has_children_fail_message:
			"No se puede eliminar un grupo de productos que tiene subgrupos.",
		initial_message:
			"Presiona el botón {0} para agregar tu primer grupo de productos.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} creado por {name}.",
			update: "{name} actualizado por {name}.",
			send: "{name} envió la factura proforma al destinatario.",
			revoke: "{name} revocó la factura proforma.",
			approve: "{name} aprobó la factura proforma.",
			demand_revise: "{name} solicitó una revisión de la factura proforma.",
			reject: "{name} rechazó la factura proforma.",
			mark_as_processed: "{name} marcó la factura proforma como procesada.",
			create_attachment: "{name} creó un archivo adjunto.",
			update_attachment: "{name} actualizó un archivo adjunto.",
			delete_attachment: "{name} eliminó un archivo adjunto.",
		},
		$actionDescription2: {
			send: '{name} marcado como "Enviado".',
			revoke: '{name} marcado como "Revocado".',
			approve: '{name} marcado como "Aprobado".',
			demand_revise: '{name} marcado como "Revisión Pendiente".',
			reject: "{name} rechazado.",
			mark_as_processed: '{name} marcado como "Procesado".',
		},
		$actions: {
			approve: "Aprobar",
			demand_revise: "Solicitar Revisión",
			mark_as_approved: "Aprobado",
			mark_as_processed: "Procesado",
			mark_as_rejected: "Rechazado",
			mark_as_revise_demanded: "Revisión Pendiente",
			mark_as_revoked: "Revocado",
			mark_as_sent: "Enviado",
			reject: "Rechazar",
			revoke: "Revocar",
			send: "Enviar",
		},
		$status: {
			approved: "Aprobado",
			pending_approval: "Pendiente de Aprobación",
			pending_revise: "Pendiente de Revisión",
			processed: "Procesado",
			rejected: "Rechazado",
			revoked: "Revocado",
			template: "Plantilla",
		},
		send_confirm_message:
			"¿Estás seguro de que quieres enviar la factura proforma al destinatario?",
		send_success_message: "La factura proforma se ha enviado al destinatario.",
		approve_confirm_message:
			"¿Estás seguro de que quieres aprobar la factura proforma?",
		approve_success_message: "La factura proforma ha sido aprobada.",
		demand_revise_success_message:
			"La factura proforma ha sido enviada para su revisión.",
		reject_confirm_message:
			"¿Estás seguro de que quieres rechazar la factura proforma?",
		reject_success_message: "La factura proforma ha sido rechazada.",
		mark_as_processed_confirm_message:
			"¿Estás seguro de que quieres marcar la factura proforma como procesada?",
		mark_as_processed_success_message:
			"La factura proforma ha sido marcada como procesada.",
		revoke_confirm_message:
			"¿Estás seguro de que quieres revocar la factura proforma?",
		revoke_success_message: "La factura proforma ha sido revocada.",
		demand_revise_description_message:
			"Por favor, explique qué revisión está solicitando:",
		no_attachment_text: "No se ha cargado ningún archivo adjunto aún.",
	},
	$serviceMessage: {
		already_subs_module: "Este módulo ya tiene una suscripción.",
		bad_request: "Solicitud incorrecta",
		code_already_used: "El código ya ha sido utilizado.",
		container_status_not_available:
			"El estado del contenedor no está disponible.",
		existing_email_warning: "El correo electrónico ya existe.",
		existing_employee_error: "Este usuario ya ha sido agregado a su empresa.",
		existing_employee_for_email_warning:
			"El código de invitación ya ha sido enviado a este correo electrónico.",
		existing_invitation_warning:
			"El código de invitación ya ha sido enviado a este correo electrónico.",
		existing_token_already_have_time:
			"El token existente ya tiene suficiente tiempo para ser utilizado.",
		existing_user: "El usuario ya existe.",
		five_min_reset_password_mail:
			"Después de solicitar un correo electrónico de restablecimiento de contraseña, debe esperar al menos 5 minutos.",
		forbidden: "Prohibido",
		internal_server_error: "Error interno del servidor.",
		instance_can_no_delete_existing_booking_code:
			"El elemento ya no se puede eliminar debido al código de reserva existente.",
		instance_cannot_delete: "El elemento ya no se puede eliminar.",
		instance_not_found: "Elemento no encontrado.",
		invitation_accepting_is_due_to_the_admin:
			"La aceptación de la invitación depende del administrador.",
		invitation_already_discarded: "La invitación ya ha sido descartada.",
		invitation_already_used: "La invitación ya ha sido utilizada.",
		invitation_has_been_used: "La invitación ha sido utilizada.",
		invitation_not_exist_email:
			"La dirección de correo electrónico de la invitación no existe.",
		invitation_not_for_custom_agency:
			"Esta invitación no es para una agencia de aduanas.",
		invitation_not_for_company: "Esta invitación no es para una empresa.",
		invitation_not_found: "Invitación no encontrada.",
		invitation_not_valid: "La invitación ya no es válida.",
		invalid_current_password: "Su contraseña actual es incorrecta.",
		invalid_identifier_or_password: "Identificador o contraseña no válidos",
		invalid_invitation_code: "Código de invitación no válido.",
		invalid_moving_request: "Solicitud de traslado no válida.",
		invalid_refresh_token: "Token de actualización no válido.",
		invalid_operation: "Operación no válida.",
		invalid_unit: "Unidad no válida.",
		have_no_corporation: "No tienes una empresa.",
		have_no_custom_agency: "No tienes una agencia de aduanas.",
		have_no_employee: "No tienes empleados.",
		link_not_be_used_anymore: "Este enlace ya no puede ser utilizado.",
		link_has_been_expired: "El enlace ha caducado.",
		missing_required_fields: "Faltan campos obligatorios.",
		no_employee_found: "No se encontraron empleados.",
		no_proper_partner_found: "No se encontró un socio adecuado.",
		not_acceptable: "No aceptable.",
		not_allowed_action: "No tienes permiso para realizar esta acción.",
		not_allowed_use_api: "No tienes permiso para utilizar esta API.",
		not_found: "No encontrado.",
		not_permitted_use_api: "No tienes permiso para utilizar esta API",
		own_invitation_cannot_accept: "No puedes enviar una invitación a ti mismo.",
		parent_group_not_found: "Grupo de productos principal no encontrado.",
		partnerId_required: "Se requiere el ID del socio.",
		partnership_already_exists: "La asociación ya ha sido agregada.",
		password_cant_involve_space_char:
			"La contraseña no puede contener caracteres de espacio.",
		password_min_chars_least: "La contraseña debe tener al menos 6 caracteres.",
		ports_are_required: "Se requieren puertos.",
		product_group_tree_not_found: "Árbol de grupos de productos no encontrado.",
		proforma_invoice_action_not_available:
			"La acción de la factura proforma no está disponible para este estado.",
		proforma_invoice_template_update:
			"La factura proforma debe ser una plantilla para ser actualizada.",
		shipment_method_is_required: "Se requiere el método de envío.",
		shipping_term_is_required: "Se requiere el término de envío.",
		shipment_email_not_exist:
			"Por favor, introduce un correo electrónico válido",
		someone_else_uses_this_email:
			"Otra persona ya está usando este correo electrónico.",
		someone_else_uses_this_username:
			"Otra persona ya está usando este nombre de usuario.",
		something_went_wrong: "Algo salió mal",
		type_can_be_abstract_company:
			'El "tipo" solo puede ser "abstract_company".',
		unauthorized: "No autorizado",
		undefined_result: "Resultado indefinido.",
		unexpected_error_occurred: "Ocurrió un error inesperado.",
		unit_already_added: "La unidad ya ha sido añadida.",
		username_min_chars_error:
			'"Nombre de usuario" debe tener al menos 6 caracteres.',
		user_already_employee: "El usuario ya es un empleado.",
		user_already_exists: "El usuario ya es un empleado de una empresa.",
		user_does_not_exist: "El usuario no existe.",
		user_not_found: "Usuario no encontrado.",
	},
	$shipment: {
		$actionDescription: {
			create: "Creado por {name}.",
			update: "Actualizado por {name}.",
			create_container: "{name} agregó un contenedor.",
			update_container: "{name} actualizó un contenedor.",
			delete_container: "{name} eliminó un contenedor.",
			create_attachment: "{name} creó un archivo adjunto.",
			update_attachment: "{name} actualizó un archivo adjunto.",
			delete_attachment: "{name} eliminó un archivo adjunto.",
			$land: {
				create_container: "{name} agregó un vehículo.",
				update_container: "{name} actualizó un vehículo.",
				delete_container: "{name} eliminó un vehículo.",
			},
		},
		$containerStatus: {
			reserved: "Reservado",
			on_load: "Cargado",
			on_way: "En camino",
			arrived: "Llegó",
			off_load: "Descargado",
		},
		$method: {
			sea: "Mar",
			aerial: "Aéreo",
			land: "Terrestre",
			railway: "Ferrocarril",
		},
		$vehicle: {
			license_number: "Número de matrícula",
		},
		archiving_success_message: "El envío se archivó.",
		leave_message: "Deje su mensaje o comentario",
		no_booking_info:
			"La información de reserva aún no está definida. Presione el botón {0} para agregar.",
		no_transportation_info:
			"Los detalles del envío aún no están definidos. Presione el botón {0} para definirlos.",
		un_archiving_success_message: "El envío se desarchivó.",
	},
	$shortening: {
		number: "Nº",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Milímetro",
		meter: "Metro",
		kilometer: "Kilómetro",
		milligram: "Miligramo",
		gram: "Gramo",
		kilogram: "Kilogramo",
		square_meter: "Metro cuadrado",
		hectare: "Hectárea",
		square_kilometer: "Kilómetro cuadrado",
		milliliter: "Mililitro",
		liter: "Litro",
		cubic_meter: "Metro cúbico",
		acres: "Acres",
		feet: "Pies",
		fluid_ounces: "Onza líquida",
		gallons: "Galones",
		inches: "Pulgadas",
		miles: "Millas",
		ounces: "Onzas",
		pounds: "Libras",
		quarts: "Cuarto de galón",
		square_feet: "Pies cuadrados",
		square_miles: "Millas cuadradas",
		square_yards: "Yardas cuadradas",
		square_inches: "Pulgadas cuadradas",
		yards: "Yardas",
		pieces: "Piezas",
		boxes: "Cajas",
		pallets: "Palets",
		dozen: "Docena",
		ton: "Tonelada",
	},
	$validation: {
		required: "{item} no puede estar vacío",
		required_for_default_language:
			"La información de {item} debe estar definida para el idioma predeterminado de la empresa.",
		invalid: "{item} no es válido",
		email: "Por favor, ingrese un correo electrónico válido",
		min_length: "{item} debe tener al menos {length} caracteres",
		max_length: "{item} no debe tener más de {length} caracteres",
		password_repeat_warning:
			"La repetición de la contraseña debe ser igual a la nueva contraseña.",
	},

	accept_invitation_success_message: "Has utilizado la invitación con éxito.",
	accessibility: "Accesibilidad",
	actions: "Acciones",
	active: "Activo",
	add: "Añadir",
	add_item: "Añadir {item}",
	address: "Dirección",
	again: "Otra vez",
	alert: "Alerta",
	already_have_employee_message: "Ya eres empleado de una empresa.",
	amount: "Cantidad",
	archive: "Archivar",
	attachment: "Adjunto",
	attachments: "Adjuntos",

	booking: "Reserva",
	booking_code: "Código de Reserva",
	business_partner: "Socio Comercial",
	business_partners: "Socios Comerciales",

	cancel: "Cancelar",
	change_password: "Cambiar Contraseña",
	checking_invitation_code_message: "Comprobando el código de invitación",
	choose_image: "Elegir Imagen",
	clear: "Limpiar",
	close: "Cerrar",
	code: "Código",
	companies: "Empresas",
	company: "Empresa",
	company_profile: "Perfil de la Empresa",
	commercial: "Comercial",
	commercial_partners: "Socios Comerciales",
	content: "Contenido",
	configuration: "Configuración",
	confirm: "Confirmar",
	contact: "Contacto",
	contact_info: "Información de Contacto",
	contact_to_us: "Contáctanos",
	container: "Contenedor",
	containers: "Contenedores",
	content: "Contenido",
	countries: "Países",
	country: "País",
	create: "Crear",
	create_company: "Crear Empresa",
	created_at: "Creado en",
	currencies: "Monedas",
	currency: "Moneda",
	current_password: "Contraseña Actual",
	customs_agency: "Agencia Aduanal",
	customs_agencies: "Agencias Aduanales",

	date: "Fecha",
	dashboard: "Tablero",
	default: "Predeterminado",
	default_language: "Idioma Predeterminado",
	delete: "Eliminar",
	delete_confirm_message: "¿Estás seguro de que quieres eliminar esto?",
	description: "Descripción",
	detail: "Detalle",
	discard: "Descartar",
	document_number: "Número de Documento",
	download: "Descargar",

	edit: "Editar",
	edit_item: "Editar {item}",
	email: "Correo Electrónico",
	employee: "Empleado",
	employee_type: "Tipo de Empleado",
	employees: "Empleados",
	estimated_arrival_day: "Día de Llegada Estimado",
	expense: "Gasto",
	expenses: "Gastos",
	exported_containers: "Contenedores Exportados",

	faq: "Preguntas Frecuentes",
	file: "Archivo",
	filter: "Filtrar",
	first_name: "Nombre",
	forget_password: "Olvidé mi Contraseña",
	forget_password_message: "¿Olvidaste tu contraseña?",
	from: "Desde",

	hide: "Ocultar",
	home_page: "Página de Inicio",

	imported_containers: "Contenedores Importados",
	incoming: "Entrante",
	incoming_shipments: "Envíos Entrantes",
	initial: "Inicial",
	invitation: "Invitación",
	invitation_code: "Código de Invitación",
	invitation_mail_sent: "Correo de invitación enviado",
	invitations: "Invitaciones",

	join: "Unirse",

	language_options: "Opciones de Idioma",
	last_name: "Apellido",
	last_one_year: "Último Año",
	latitude: "Latitud",
	loading_country: "País de Carga",
	loading_point: "Punto de Carga",
	loading_port: "Puerto de Carga",
	logo: "Logotipo",
	longitude: "Longitud",

	go_to_panel: "Ir al Panel",

	mail_sent: "Correo Enviado",
	migrate: "Migrar",
	migrate_partner: "Migrar Socio",
	message: "Mensaje",
	message_company_create: "Empresa creada.",
	message_company_join: "Únete a la empresa",
	message_company_joined: "Te has unido a la empresa.",
	message_employee_delete: "Empleado eliminado.",
	message_employee_save: "Empleado guardado.",
	message_join_company:
		"Ingresa el código de invitación para unirte a una empresa.",
	method: "Método",
	mobile_number: "Número de Teléfono Móvil",
	my_role: "Mi Rol",

	name: "Nombre",
	next: "Siguiente",
	new: "Nuevo",
	new_password: "Nueva Contraseña",
	no: "No",
	no_content: "Sin Contenido",
	no_text: "Sin Texto",
	not_found_page_message: "Lo siento, no se puede encontrar esta página :(",
	note: "Nota",

	ok: "Aceptar",
	outgoing: "Saliente",
	outgoing_shipments: "Envíos Salientes",

	parent_group: "Grupo Principal",
	partner: "Socio",
	partners: "Socios",
	passive: "Pasivo",
	password: "Contraseña",
	phone_number: "Número de Teléfono",
	previous: "Anterior",
	pricing: "Precios",
	product: "Producto",
	product_group: "Grupo de Productos",
	product_groups: "Grupos de Productos",
	products: "Productos",
	profile: "Perfil",
	proforma_invoice: "Factura Proforma",
	proforma_invoices: "Facturas Proforma",
	proforma_number: "Número de Factura Proforma",
	purchase: "Compra",

	quantity: "Cantidad",

	receiver: "Receptor",
	receiver_company: "Empresa Receptora",
	receiver_customs_agency: "Agencia Aduanal Receptora",
	redirection_to_home_link: "haz clic aquí",
	redirection_to_home_message: "Para volver a la página de inicio, ",
	redirection_to_sign_in_message: "¿Ya tienes una cuenta?",
	redirection_to_sign_up_message: "¿Todavía no tienes una cuenta?",
	reload: "Recargar",
	reset_password: "Restablecer Contraseña",

	sales: "Ventas",
	save: "Guardar",
	search: "Buscar",
	select_file: "Seleccionar Archivo",
	select_file_place_holder: "Por favor, selecciona una imagen",
	selected_partner: "Socio Seleccionado",
	send: "Enviar",
	send_again: "Enviar de Nuevo",
	send_email: "Enviar Correo Electrónico",
	send_invitation: "Enviar Invitación",
	sender: "Remitente",
	sender_company: "Empresa Remitente",
	sender_customs_agency: "Agencia Aduanal Remitente",
	show: "Mostrar",
	shipment: "Envío",
	shipment_method: "Método de Envío",
	shipments: "Envíos",
	shipments_filter: "Filtro de Envíos",
	shipping_term: "Término de Envío",
	shipping_terms: "Términos de Envío",
	sign_in: "Iniciar Sesión",
	sign_out: "Cerrar Sesión",
	sign_out_confirm_message: "¿Estás seguro de que quieres cerrar sesión?",
	sign_out_success_message: "Adiós.",
	sign_up: "Registrarse",
	social_media: "Redes Sociales",
	social_media_account: "Cuenta de Redes Sociales",
	status: "Estado",
	subscribe: "Suscribirse",
	super: "Super",
	switch_language: "Cambiar Idioma",
	switch_theme: "Cambiar Tema",

	target: "Objetivo",
	target_partner: "Socio Objetivo",
	theme: "Tema",
	this_month: "Este Mes",
	this_year: "Este Año",
	title: "Título",
	to: "A",
	toDelete: "Eliminar",
	total: "Total",
	total_amount: "Cantidad Total",
	type: "Tipo",
	types: "Tipos",

	un_archive: "Desarchivar",
	unit: "Unidad",
	units: "Unidades",
	unloading_country: "País de Descarga",
	unloading_point: "Punto de Descarga",
	unloading_port: "Puerto de Descarga",
	use_code: "Usar Código",
	use_invitation_code: "Usar Código de Invitación",
	use_invitation_code_description:
		"Ingresa el código de invitación que se envió a tu correo.",
	use_invitation_code_success_message:
		"Código de invitación utilizado con éxito.",
	user_profile: "Perfil de Usuario",

	vehicle: "Vehículo",
	vehicles: "Vehículos",
	visibility: "Visibilidad",

	warehouse: "Almacén",
	warehouses: "Almacenes",
	website: "Sitio Web",
	welcome: "Bienvenido",

	yes: "Sí",
};

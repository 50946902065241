import Model from "../_model";

export default class CompanyModel extends Model {
	constructor(provider) {
		super(provider, "companies");
	}

	_parse(object) {
		if (object.expiresAt) object.expiresAt = this.parseDateTime(object.expiresAt);
		return super._parse(object);
	}

	filter({ pager, sorter }, { onSuccess, onFail }) {
		return super.filter({ params: {}, pager, sorter, onSuccess, onFail });
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(company, { onSuccess, onFail }) {
		const body = {
			name: company.name,
			countryId: company.countryId,
			address: company.address,
			phoneNumber: company.phoneNumber,
			taxNumber: company.taxNumber,
			email: company.email,
			website: company.website,
			logo: company.logo
				? {
						key: company.logo.key,
						type: company.logo.type,
				  }
				: null,
			settings: company.settings || "",
		};
		return super.create(body, { onSuccess, onFail });
	}

	update(company, { onSuccess, onFail }) {
		const body = {
			name: company.name,
			countryId: company.countryId,
			address: company.address,
			phoneNumber: company.phoneNumber,
			taxNumber: company.taxNumber,
			email: company.email,
			website: company.website,
			settings: company.settings,
		};
		if (company.logo)
			body.logo = {
				key: company.logo.key,
				type: company.logo.type,
			};
		else if (company.logo === null) body.logo = null;
		return super.update(body, { id: company.id, onSuccess, onFail });
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}

	myCompany({ onSuccess, onFail }) {
		return super.filter({ url: "company", onSuccess, onFail });
	}

	getMySubscription({ onSuccess, onFail }) {
		return super.filter({ url: "mySubscription", onSuccess, onFail });
	}

	getUnsubscribe({ onSuccess, onFail }) {
		return super.update({}, { url: "unsubscribe", onSuccess, onFail });
	}

	getMyPaymentHistory({ pager, sorter }, { onSuccess, onFail }) {
		return super.filter({
			url: "myPaymentHistory",
			params: {},
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}
}

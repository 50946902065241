<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">{{ title }}</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="currentItem.name"
						:label="$t('name')"
						:rules="rules.name"
						prepend-icon="mdi-signature-text"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<country-select
						v-model="currentItem.countryId"
						:options="countries"
						:label="$t('country')"
						:rules="rules.country"
						prepend-icon="mdi-earth-box"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<v-textarea
						v-model="currentItem.address"
						:label="$t('address')"
						:rules="rules.address"
						counter
						prepend-icon="mdi-office-building-marker-outline"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<v-text-field
						v-model="currentItem.phoneNumber"
						:label="$t('phone_number')"
						:rules="rules.phoneNumber"
						type="number"
						hide-spin-buttons
						prepend-icon="mdi-phone"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<v-text-field
						v-model="currentItem.taxNumber"
						:label="this.$t('tax_number')"
						type="number"
						hide-spin-buttons
						:rules="rules.taxNumber"
						prepend-icon="mdi-receipt-text-outline"
						counter="11"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<v-text-field
						v-model="currentItem.email"
						:label="$t('email')"
						:rules="rules.email"
						prepend-icon="mdi-email-variant"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
					<v-text-field
						v-model="currentItem.website"
						:label="$t('website')"
						prepend-icon="mdi-web"
						:disabled="currentItem.type != partnerTypes.AbstractCompany"
					/>
				</v-form>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-btn
					color="error"
					v-if="currentItem.id && currentItem.type == partnerTypes.AbstractCompany"
					:disabled="deleting || saving"
					:loading="deleting"
					@click="deleteAction"
				>
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="deleting || saving" @click="closeAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					v-if="currentItem.type == partnerTypes.AbstractCompany"
					color="accent"
					:disabled="deleting || saving"
					:loading="saving"
					@click="saveAction"
				>
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import CountrySelect from "../../../controls/CountrySelect";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import { mapGetters, mapState } from "vuex";
import { partnerTypes } from "../../../../utils/enum";

export default {
	mixins: [uiMixin],
	props: ["value", "item", "itemId"],
	components: { CountrySelect, LocalizedTextField },
	data() {
		return {
			currentItem: null,
			valid: null,
			saving: false,
			deleting: false,
			itemTemplate: {
				name: "",
				countryId: null,
				address: "",
				phoneNumber: "",
				taxNumber: "",
				email: "",
				website: "",
				type: partnerTypes.AbstractCompany,
			},
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						else if (v && v.length < 3)
							return this.$t("$validation.min_length", {
								item: this.$t("name"),
								length: 3,
							});
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: 100,
							});
						return true;
					},
				],
				country: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("country"),
							});
						return true;
					},
				],
				address: [
					(v) => {
						if (v && v.length > 140)
							return this.$t("$validation.max_length", {
								item: this.$t("address"),
								length: 140,
							});
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: 20,
							});
						return true;
					},
				],
				taxNumber: [
					(v) => {
						if (!v) {
							return this.$t("$validation.required", {
								item: this.$t("tax_number"),
							});
						} else if (v.length < 10) {
							return this.$t("$validation.min_length", {
								item: this.$t("tax_number"),
								length: "10",
							});
						} else if (v.length > 11) {
							return this.$t("$validation.max_length", {
								item: this.$t("tax_number"),
								length: "11",
							});
						}
						return true;
					},
				],
				email: [
					(v) =>
						!v ||
						!!/.+@.+\..+/.test(v) ||
						this.$t("$validation.invalid", { item: this.$t("email") }),
				],
			},
		};
	},
	computed: {
		...mapGetters(["defaultLanguage"]),
		...mapState(["corporation", "countries"]),
		partnerTypes: () => partnerTypes,
		title() {
			return (this.currentItem.id ? "" : `${this.$t("new")} `) + this.$t("business_partner");
		},
	},
	watch: {
		value(val) {
			if (val) {
				if (this.itemId) this.loadItem(this.itemId, this.item.ownerCompany.companyId);
				else this.currentItem = Object.assign({}, this.item || this.itemTemplate);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
			}
		},
	},
	methods: {
		loadItem(id, ownerCompanyId) {
			remote.corporations.partners.findCorporationForCustomsAgency(
				this.corporation.id,
				id,
				ownerCompanyId,
				{
					onSuccess: (result) => (result ? (this.currentItem = result) : null),
				}
			);
		},
		saveAction() {
			if (this.valid) {
				this.saving = true;
				if (this.currentItem.id) {
					remote.corporations.partners.update(this.corporation.id, this.currentItem, {
						onSuccess: (result) => {
							this.saving = false;
							this.showSnackBar({
								message: this.$t("$message.updated", {
									item: this.$t("business_partner"),
								}),
							});
							this.$editEvent("partner");
							this.$emit("saved", result);
						},
						onFail: () => this.hideProgressDialog(),
					});
				} else {
					remote.corporations.partners.create(this.corporation.id, this.currentItem, {
						onSuccess: (result) => {
							this.saving = false;
							this.showSnackBar({
								message: this.$t("$message.created", {
									item: this.$t("business_partner"),
								}),
							});
							this.$addEvent("partner");
							this.$emit("saved", result);
						},
						onFail: () => this.hideProgressDialog(),
					});
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.corporations.partners.delete(this.corporation.id, this.currentItem.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("business_partner"),
								}),
							});
							this.$emit("deleted", result);
						},
					});
				},
			});
		},
		closeAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>

<template>
	<div class="fill-height">
		<v-app-bar app color="primary" elevation="0" dark v-if="appBarVisibility">
			<v-app-bar-nav-icon
				v-if="corporation"
				@click.stop="
					$store.state.ui.navigationDrawer.state =
						!$store.state.ui.navigationDrawer.state
				"
			/>
			<v-toolbar-title><slot name="title" /> </v-toolbar-title>​ <v-spacer />​
			<slot name="buttons" />
			<template v-slot:extension v-if="hasAppBarExtension">
				<slot name="appBarExtension" />
			</template>
		</v-app-bar>
		<slot name="content" />
	</div>
</template>
<!-- USAGE -->
<!--
  <page-template>
    <template slot="title">page title slot</template>
    <template slot="buttons">menu buttons slot</template>
    <template slot="content">
      content slot
    </template>
  </page-template>
-->
​
<script>
import { mapState } from "vuex";

export default {
	props: { hideAppBar: Boolean | null, hasAppBarExtension: Boolean },
	computed: {
		...mapState(["corporation"]),
		appBarVisibility() {
			return this.hideAppBar == null ? true : !this.hideAppBar;
		},
	},
};
</script>

<template>
	<v-dialog v-model="value" persistent max-width="360">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("shipments_filter") }}
			</v-card-title>
			<v-card-text>
				<v-select v-model="currentItem.role" item-text="text" item-value="value" :label="$t(`my_role`)" :items="roleOptions" clearable />
				<v-select
					v-model="currentItem.accessibilities"
					item-text="text"
					item-value="value"
					:label="$t('status')"
					:items="accessibilityOptions"
					multiple
					chips
					small-chips
					deletable-chips
					clearable
				/>
				<v-text-field v-model="currentItem.bookingCode" :label="$t('booking_code')" clearable />
				<shipment-method-select v-model="currentItem.method" :prepend-icon="null" />
			</v-card-text>
			<v-card-actions>
				<v-btn color="warning" @click="clearButtonAction">
					{{ $t("clear") }}
				</v-btn>
				<v-spacer />
				<v-btn @click="cancelButtonAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn color="accent" @click="okButtonAction">
					{{ $t("ok") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect";

export default {
	props: { value: Boolean, item: Object },
	components: { ShipmentMethodSelect },
	data() {
		return {
			currentItem: {
				role: null, // sender, receiver
				accessibilities: [], // present, archived
				bookingCode: "",
				method: null, // Aerial, Land, Railway, Sea
			},
			roleOptions: [
				{
					text: this.$t("sender"),
					value: "sender",
				},
				{
					text: this.$t("receiver"),
					value: "receiver",
				},
			],
			accessibilityOptions: [
				{
					text: this.$t("$accessibility.present"),
					value: "present",
				},
				{
					text: this.$t("$accessibility.archived"),
					value: "archived",
				},
			],
		};
	},
	watch: {
		value(val) {
			if (val)
				this.currentItem = Object.assign(
					{},
					{
						role: this.item.role,
						accessibilities: this.item.accessibilities,
						bookingCode: this.item.bookingCode,
						method: this.item.method,
					}
				);
			else this.currentItem = null;
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				accessibilities: this.currentItem.accessibilities,
				bookingCode: this.currentItem.bookingCode,
				method: this.currentItem.method,
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
		clearButtonAction() {
			this.$emit("done", {
				role: null,
				accessibilities: [],
				bookingCode: "",
				method: null,
			});
		},
	},
};
</script>

<template>
	<v-dialog v-model="value" persistent max-width="680">
		<v-card>
			<v-card-title class="headline">{{ $t("select_language") }}</v-card-title>
			<v-card-text class="mt-2">
				<v-row dense>
					<v-col v-for="(lang, index) in languages" :key="index" cols="3">
						<v-btn
							@click="selectLanguage(lang.code)"
							:class="{ 'white--text accent': lang.code === selectedLanguage }"
							color="black"
							text
						>
							{{ lang.name }}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="closeAction">{{ $t("close") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { getSetting, setSetting, Setting } from "../../../data/local/ls";

export default {
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selectedLanguage: "",
			languages: [
				{ code: "en", name: "English" },
				{ code: "tr", name: "Turkish" },
				/* { code: "ar", name: "Arabic" },
				{ code: "de", name: "German" }, 
			   { code: "es", name: "Spanish" },
				{ code: "fr", name: "French" }, 
				{ code: "hi", name: "Hindi" },
				{ code: "ind", name: "Indonesian" },
				{ code: "it", name: "Italian" },
				{ code: "ja", name: "Japanese" },
				{ code: "ko", name: "Korean" },
				{ code: "pt", name: "Portuguese" },
				{ code: "ru", name: "Russian" }, 
	      { code: "vi", name: "Vietnamese" },
				{ code: "zn", name: "Chinese" },  */
			],
		};
	},
	watch: {
		value(val) {
			if (!val) {
				this.$emit("closed");
			}
		},
	},
	methods: {
		selectLanguage(code) {
			this.selectedLanguage = code;
			const newLocale = this.selectedLanguage;
			setSetting(Setting.locale, newLocale);
			location.reload();
		},
		closeAction() {
			this.$emit("closed");
		},
	},
	created() {
		this.selectedLanguage = getSetting(Setting.locale);
	},
};
</script>

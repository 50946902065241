export default {
	$accessibility: {
		present: "Berlangsung",
		archived: "Diarsipkan",
	},
	$accessModifier: {
		$description: {
			private: "Pribadi",
			hidden_from_receiver: "Tersembunyi dari Penerima",
			hidden_from_sender: "Tersembunyi dari Pengirim",
			shared_with_everyone: "Dibagikan dengan Semua Orang",
			shared_with_custom: "Dibagikan dengan Pengaturan Khusus",
			shared_with_receiver: "Dibagikan dengan Penerima",
			shared_with_sender: "Dibagikan dengan Pengirim",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andora",
		united_arab_emirates: "Uni Emirat Arab",
		afghanistan: "Afganistan",
		antigua_and_barbuda: "Antigua dan Barbuda",
		anguilla: "Anguilla",
		albania: "Albania",
		armenia: "Armenia",
		angola: "Angola",
		antarctica: "Antartika",
		argentina: "Argentina",
		american_samoa: "Samoa Amerika",
		austria: "Austria",
		australia: "Australia",
		aruba: "Aruba",
		aland: "Kepulauan Aland",
		azerbaijan: "Azerbaijan",
		bosnia_and_herzegovina: "Bosnia dan Herzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Belgia",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaria",
		bahrain: "Bahrain",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthelemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius dan Saba",
		brazil: "Brasil",
		bahamas: "Bahama",
		bhutan: "Bhutan",
		bouvet_island: "Pulau Bouvet",
		botswana: "Botswana",
		belarus: "Belarus",
		belize: "Belize",
		canada: "Kanada",
		cocos_islands: "Kepulauan Cocos",
		dr_congo: "Kongo",
		central_african_republic: "Republik Afrika Tengah",
		congo_republic: "Republik Kongo",
		switzerland: "Swiss",
		ivory_coast: "Pantai Gading",
		cook_islands: "Kepulauan Cook",
		chile: "Cile",
		cameroon: "Kamerun",
		china: "Cina",
		colombia: "Kolombia",
		costa_rica: "Kosta Rika",
		cuba: "Kuba",
		cabo_verde: "Cabo Verde",
		curacao: "Curacao",
		christmas_island: "Pulau Christmas",
		cyprus: "Siprus",
		czechia: "Ceko",
		germany: "Jerman",
		djibouti: "Djibouti",
		denmark: "Denmark",
		dominica: "Dominika",
		dominican_republic: "Republik Dominika",
		algeria: "Aljazair",
		ecuador: "Ekuador",
		estonia: "Estonia",
		egypt: "Mesir",
		western_sahara: "Sahara Barat",
		eritrea: "Eritrea",
		spain: "Spanyol",
		ethiopia: "Ethiopia",
		finland: "Finlandia",
		fiji: "Fiji",
		falkland_islands: "Kepulauan Falkland",
		micronesia: "Mikronesia",
		faroe_islands: "Kepulauan Faroe",
		france: "Perancis",
		gabon: "Gabon",
		united_kingdom: "Inggris",
		grenada: "Grenada",
		georgia: "Georgia",
		french_guiana: "Guyana Perancis",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Greenland",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadeloupe",
		equatorial_guinea: "Guinea Khatulistiwa",
		greece: "Yunani",
		south_georgia_and_south_sandwich_islands:
			"Kepulauan Georgia Selatan dan Sandwich Selatan",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Kepulauan Heard dan McDonald",
		honduras: "Honduras",
		croatia: "Kroasia",
		haiti: "Haiti",
		hungary: "Hungaria",
		indonesia: "Indonesia",
		ireland: "Irlandia",
		israel: "Israel",
		isle_of_man: "Pulau Man",
		india: "India",
		british_indian_ocean_territory: "Wilayah Samudera Hindia Britania",
		iraq: "Irak",
		iran: "Iran",
		iceland: "Islandia",
		italy: "Italia",
		jersey: "Jersey",
		jamaica: "Jamaika",
		jordan: "Yordania",
		japan: "Jepang",
		kenya: "Kenya",
		kyrgyzstan: "Kirgistan",
		cambodia: "Kamboja",
		kiribati: "Kiribati",
		comoros: "Komoro",
		st_kitts_and_nevis: "Saint Kitts dan Nevis",
		north_korea: "Korea Utara",
		south_korea: "Korea Selatan",
		kuwait: "Kuwait",
		cayman_islands: "Kepulauan Cayman",
		kazakhstan: "Kazakhstan",
		laos: "Laos",
		lebanon: "Lebanon",
		saint_lucia: "Saint Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesotho",
		lithuania: "Lituania",
		luxembourg: "Luksemburg",
		latvia: "Latvia",
		libya: "Libya",
		morocco: "Maroko",
		monaco: "Monako",
		moldova: "Moldova",
		montenegro: "Montenegro",
		saint_martin: "Saint Martin",
		madagascar: "Madagaskar",
		marshall_islands: "Kepulauan Marshall",
		north_macedonia: "Makedonia Utara",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mongolia",
		macao: "Makau",
		northern_mariana_islands: "Kepulauan Mariana Utara",
		martinique: "Martinik",
		mauritania: "Mauritania",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Maladewa",
		malawi: "Malawi",
		mexico: "Meksiko",
		malaysia: "Malaysia",
		mozambique: "Mozambik",
		namibia: "Namibia",
		new_caledonia: "Kaledonia Baru",
		niger: "Niger",
		norfolk_island: "Pulau Norfolk",
		nigeria: "Nigeria",
		nicaragua: "Nikaragua",
		netherlands: "Belanda",
		norway: "Norwegia",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Selandia Baru",
		oman: "Oman",
		panama: "Panama",
		peru: "Peru",
		french_polynesia: "Polinesia Prancis",
		papua_new_guinea: "Papua Nugini",
		philippines: "Filipina",
		pakistan: "Pakistan",
		poland: "Polandia",
		saint_pierre_and_miquelon: "Saint Pierre dan Miquelon",
		pitcairn_islands: "Kepulauan Pitcairn",
		puerto_rico: "Puerto Riko",
		palestine: "Palestina",
		portugal: "Portugal",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Rumania",
		serbia: "Serbia",
		russia: "Rusia",
		rwanda: "Rwanda",
		saudi_arabia: "Arab Saudi",
		solomon_islands: "Kepulauan Solomon",
		seychelles: "Seychelles",
		sudan: "Sudan",
		sweden: "Swedia",
		singapore: "Singapura",
		saint_helena: "Saint Helena",
		slovenia: "Slovenia",
		svalbard_and_jan_mayen: "Svalbard dan Jan Mayen",
		slovakia: "Slovakia",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Suriname",
		south_sudan: "Sudan Selatan",
		sao_tome_and_principe: "São Tomé dan Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Suriah",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Kepulauan Turks dan Caicos",
		chad: "Chad",
		french_southern_territories: "Wilayah Selatan Prancis",
		togo: "Togo",
		thailand: "Thailand",
		tajikistan: "Tajikistan",
		tokelau: "Tokelau",
		timor_leste: "Timor Leste",
		turkmenistan: "Turkmenistan",
		tunisia: "Tunisia",
		tonga: "Tonga",
		turkiye: "Turki",
		trinidad_and_tobago: "Trinidad dan Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwan",
		tanzania: "Tanzania",
		ukraine: "Ukraina",
		uganda: "Uganda",
		us_minor_outlying_islands: "Kepulauan Terluar Kecil AS",
		united_states: "Amerika Serikat",
		uruguay: "Uruguay",
		uzbekistan: "Uzbekistan",
		vatican_city: "Kota Vatikan",
		st_vincent_and_grenadines: "Saint Vincent dan Grenadines",
		venezuela: "Venezuela",
		british_virgin_islands: "Kepulauan Virgin Britania",
		us_virgin_islands: "Kepulauan Virgin AS",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis dan Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yaman",
		mayotte: "Mayotte",
		south_africa: "Afrika Selatan",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		us_dollar: "Dolar Amerika Serikat",
		euro: "Euro",
		turkish_lira: "Lira Turki",
	},
	$employeeType: {
		admin: "Administrator",
		default: "Default",
		exporter: "Eksportir",
		importer: "Importir",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Selamat datang di Antrego",
			description: "Selamat datang di Antrego",
			sign_in_title: "Sudah memiliki akun?",
			sign_in_subtitle: "Di sini masuk",
			sign_up_title: "Ingin membuat akun?",
			sign_up_subtitle: "Di sini daftar",
		},
		$noCompany: {
			title: "Selamat datang, {name}",
			description:
				"Anda telah berhasil masuk. Sekarang Anda perlu bergabung dengan perusahaan atau membuat perusahaan Anda sendiri.",
			join_company_title: "Ingin bergabung dengan perusahaan yang sudah ada?",
			join_company_subtitle:
				"Di sini masukkan kode undangan dan bergabung dengan perusahaan.",
			create_company_title: "Ingin membuat perusahaan Anda sendiri?",
			create_company_subtitle: "Di sini buat perusahaan baru.",
		},
	},
	$invitationType: {
		employee: "Undangan Karyawan",
		company_partner: "Undangan Mitra Bisnis",
		customs_partner: "Undangan Bea Cukai",
	},
	$landing: {
		$question: {
			$title: {
				0: "Apa itu Antrego?",
				1: "Apa manfaat Antrego dalam proses pengiriman bagi perusahaan saya?",
				2: "Jenis logistik apa yang didukung oleh Antrego?",
				3: "Mengapa saya harus menggunakan Antrego?",
				4: "Apakah Antrego aplikasi berbayar?",
				5: "Apakah pelanggan kami juga perlu membayar untuk Antrego?",
			},
			$text: {
				0: "<p>Membantu perusahaan pengirim dan pelanggan mereka dalam melacak bersama selama proses pengiriman, sebagai program pelacakan pengiriman yang mencegah kerugian akibat keterlambatan dalam pelacakan.</p>",
				1: `<p>- Bagikan semua pembaruan langsung dengan pelanggan Anda. Tingkatkan kualitas layanan Anda.<br>
            - Lihat semua dokumen pengiriman Anda dalam satu sistem dan bagikan dengan pelanggan Anda.<br>
            - Lacak semua pengiriman dari satu tempat. Kendalikan keuangan Anda dengan laporan dinamis.</p>`,
				2: `<p>- Laut<br>
            - Udara<br>
            - Darat<br>
            - Kereta</p>`,
				3: `<p>Memudahkan proses pengiriman dengan layanan yang ditawarkan selalu siap digunakan.<br>
            <strong>1. Akses Mudah</strong><br>
            Dapat diakses melalui browser web dari semua perangkat dan di mana saja.<br>
            <strong>2. Keamanan Data</strong><br>
            Semua data disimpan dengan teknologi enkripsi SHA-256.<br>
            <strong>3. Pelacakan Bersama</strong><br>
            Menyediakan pelacakan untuk semua pemangku kepentingan dalam proses bisnis.</strong>`,
				4: "<p>Iya, ini adalah aplikasi berbayar. Kami ingin memudahkan bisnis Anda dengan membawa semua proses pengiriman ke ranah digital, dan kami ingin terus membuat perbaikan yang bermanfaat bagi Anda. Untuk memungkinkan terusnya pengembangan dan penyediaan ruang server yang Anda gunakan, kami meminta biaya bulanan dari Anda.</p>",
				5: "<p>Tidak. Pelanggan Anda tidak perlu membayar untuk melacak pengiriman yang Anda lakukan.</p>",
			},
		},
		$lifecycle: {
			headline: "SEMUA PROSES ADA DI UJUNG JARI ANDA",
			$title: {
				0: "Bergabung",
				1: "Kirim Proforma Faktur",
				2: "Buat Pengiriman",
				3: "Pantau Aktivitas Keuangan",
			},
			$text: {
				0: "Masukkan informasi perusahaan dan produk, buat pelanggan Anda.",
				1: "Buat proforma faktur dan bagikan kepada pelanggan untuk persetujuan.",
				2: "Masukkan informasi pengiriman dan lampirkan dokumen. Kelola semua proses pengiriman dengan satu saluran bersama pelanggan Anda.",
				3: "Pantau keuangan yang berkaitan dengan impor dan ekspor secara real-time.",
			},
		},
		$content: {
			headline: "JENIS LOGISTIK YANG DIDUKUNG",
			$title: {
				0: "Laut",
				1: "Udara",
				2: "Darat",
				3: "Kereta",
			},
		},
		$pricing: {
			0: {
				title: "GRATIS",
				subtitle: "Individu",
				features: ["1 Pengguna", "3 Mitra", "10 Pengiriman", "10 Faktur"],
			},
			1: {
				title: "TAHUNAN",
				subtitle: "Perusahaan",
				features: [
					"20 Pengguna",
					"100 Mitra",
					"1000 Pengiriman",
					"1000 Faktur",
				],
			},
			2: {
				title: "BULANAN",
				subtitle: "Usaha Kecil",
				features: ["10 Pengguna", "30 Mitra", "250 Pengiriman", "250 Faktur"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function} yakin ingin melakukannya?",
		created: "{item} dibuat.",
		deleted: "{item} dihapus.",
		file_oversize: "Ukuran berkas maksimum {size} harus.",
		invalid: "{item} tidak valid.",
		sent_email: "Email dikirim. ({value})",
		sign_in_failed: "Email atau kata sandi salah",
		updated: "{item} diperbarui.",
		migrated: "{item} dipindahkan. ({value})",
	},
	$productGroup: {
		add_child: "Tambahkan Sub-Group",
		add_to_root: "Tambahkan ke Tingkat Pertama",
		has_children_fail_message:
			"Grup produk dengan sub-grup tidak dapat dihapus",
		initial_message:
			"Tekan tombol {0} untuk menambahkan grup produk pertama Anda.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "Dibuat oleh {name}.",
			update: "Diperbarui oleh {name}.",
			send: "{name} mengirimkan untuk persetujuan penerima.",
			revoke: "{name} mencabut.",
			approve: "{name} menyetujui.",
			demand_revise: "{name} meminta revisi.",
			reject: "{name} menolak.",
			mark_as_processed: "{name} ditandai sebagai diproses.",
			create_attachment: "{name} membuat lampiran.",
			update_attachment: "{name} memperbarui lampiran.",
			delete_attachment: "{name} menghapus lampiran.",
		},
		$actionDescription2: {
			send: '{name} ditandai sebagai "Dikirim".',
			revoke: '{name} ditandai sebagai "Ditarik".',
			approve: '{name} ditandai sebagai "Disetujui".',
			demand_revise: '{name} ditandai sebagai "Revisi Ditunggu".',
			reject: "{name} ditolak.",
			mark_as_processed: `{name} ditandai sebagai "Diproses".`,
		},
		$actions: {
			approve: "Setujui",
			demand_revise: "Minta Revisi",
			mark_as_approved: "Disetujui",
			mark_as_processed: "Diproses",
			mark_as_rejected: "Ditolak",
			mark_as_revise_demanded: "Revisi Diminta",
			mark_as_revoked: "Ditarik",
			mark_as_sent: "Dikirim",
			reject: "Tolak",
			revoke: "Tarik Kembali",
			send: "Kirim",
		},
		$status: {
			approved: "Disetujui",
			pending_approval: "Menunggu Persetujuan",
			pending_revise: "Menunggu Revisi",
			processed: "Diproses",
			rejected: "Ditolak",
			revoked: "Ditarik Kembali",
			template: "Template",
		},
		send_confirm_message:
			"Apakah Anda yakin ingin mengirimkan faktur proforma ke penerima?",
		send_success_message: "Faktur proforma berhasil dikirimkan ke penerima.",
		approve_confirm_message:
			"Apakah Anda yakin ingin menyetujui faktur proforma?",
		approve_success_message: "Faktur proforma telah disetujui.",
		demand_revise_success_message:
			"Faktur proforma telah dikembalikan untuk direvisi.",
		reject_confirm_message: "Apakah Anda yakin ingin menolak faktur proforma?",
		reject_success_message: "Faktur proforma telah ditolak.",
		mark_as_processed_confirm_message:
			"Apakah Anda yakin ingin menandai faktur proforma sebagai diproses?",
		mark_as_processed_success_message:
			"Faktur proforma berhasil ditandai sebagai diproses.",
		revoke_confirm_message:
			"Apakah Anda yakin ingin menarik kembali faktur proforma?",
		revoke_success_message: "Faktur proforma berhasil ditarik kembali.",
		demand_revise_description_message:
			"Harap jelaskan jenis revisi apa yang Anda harapkan:",
		no_attachment_text: "Belum ada lampiran yang diunggah.",
	},
	$serviceMessage: {
		already_subs_module: "Sudah ada langganan untuk modul ini.",
		bad_request: "Permintaan buruk",
		code_already_used: "Kode sudah digunakan.",
		container_status_not_available: "Status kontainer tidak tersedia.",
		existing_email_warning: "Email sudah ada.",
		existing_employee_error:
			"Pengguna ini sudah ditambahkan ke perusahaan Anda.",
		existing_employee_for_email_warning:
			"Kode undangan sudah dikirimkan ke email ini.",
		existing_invitation_warning: "Kode undangan sudah dikirimkan ke email ini.",
		existing_token_already_have_time:
			"Token yang ada sudah memiliki waktu yang cukup.",
		existing_user: "Pengguna sudah ada.",
		five_min_reset_password_mail:
			"Setelah meminta email reset password, tunggu setidaknya 5 menit.",
		forbidden: "Dilarang",
		internal_server_error: "Kesalahan server internal.",
		instance_can_no_delete_existing_booking_code:
			"Item tidak dapat dihapus karena kode pemesanan yang sudah ada.",
		instance_cannot_delete: "Item tidak dapat dihapus lagi.",
		instance_not_found: "Item tidak ditemukan.",
		invitation_accepting_is_due_to_the_admin:
			"Penerimaan undangan tergantung pada admin.",
		invitation_already_discarded: "Undangan sudah dibatalkan.",
		invitation_already_used: "Undangan sudah digunakan.",
		invitation_has_been_used: "Undangan sudah digunakan.",
		invitation_not_exist_email: "Undangan dengan alamat email ini tidak ada.",
		invitation_not_for_custom_agency:
			"Undangan ini bukan untuk agen bea cukai.",
		invitation_not_for_company: "Undangan ini bukan untuk perusahaan.",
		invitation_not_found: "Undangan tidak ditemukan.",
		invitation_not_valid: "Undangan tidak lagi valid.",
		invalid_current_password: "Kata sandi saat ini salah.",
		invalid_identifier_or_password: "Email atau kata sandi tidak valid",
		invalid_invitation_code: "Kode undangan tidak valid.",
		invalid_moving_request: "Permintaan pemindahan tidak valid.",
		invalid_refresh_token: "Token pembaruan tidak valid.",
		invalid_operation: "Operasi tidak valid.",
		invalid_unit: "Unit tidak valid.",
		have_no_corporation: "Perusahaan Anda tidak ditemukan.",
		have_no_custom_agency: "Agen bea cukai Anda tidak ditemukan.",
		have_no_employee: "Karyawan Anda tidak ditemukan.",
		link_not_be_used_anymore: "Tautan ini mungkin tidak lagi digunakan.",
		link_has_been_expired: "Waktu tautan telah berakhir.",
		missing_required_fields: "Harap isi semua kolom yang diperlukan.",
		no_employee_found: "Tidak ada karyawan yang ditemukan.",
		no_proper_partner_found: "Tidak ada mitra yang cocok ditemukan.",
		not_acceptable: "Tidak dapat diterima.",
		not_allowed_action: "Anda tidak diizinkan untuk melakukan tindakan ini.",
		not_allowed_use_api: "Anda tidak diizinkan menggunakan API ini.",
		not_found: "Tidak ditemukan.",
		not_permitted_use_api: "Anda tidak diizinkan menggunakan API ini",
		own_invitation_cannot_accept:
			"Anda tidak dapat mengirimkan undangan kepada diri sendiri.",
		parent_group_not_found: "Grup produk tidak ditemukan.",
		partnerId_required: "ID Mitra diperlukan.",
		partnership_already_exists: "Kemitraan sudah ada.",
		password_cant_involve_space_char:
			"Kata sandi tidak boleh mengandung karakter spasi.",
		password_min_chars_least: "Kata sandi harus minimal 6 karakter.",
		ports_are_required: "Port diperlukan.",
		product_group_tree_not_found: "Pohon grup produk tidak ditemukan.",
		proforma_invoice_action_not_available:
			"Tindakan tidak tersedia untuk faktur proforma ini.",
		proforma_invoice_template_update:
			"Faktur proforma harus dalam bentuk template untuk diupdate.",
		shipment_method_is_required: "Metode pengiriman diperlukan.",
		shipping_term_is_required: "Syarat pengiriman diperlukan.",
		shipment_email_not_exist: "Harap masukkan alamat email yang valid",
		someone_else_uses_this_email:
			"Seseorang sudah menggunakan alamat email ini.",
		someone_else_uses_this_username:
			"Seseorang sudah menggunakan nama pengguna ini.",
		something_went_wrong: "Terjadi kesalahan.",
		type_can_be_abstract_company:
			'"type" hanya dapat berupa "abstract_company".',
		unauthorized: "Tidak diizinkan",
		undefined_result: "Hasil tidak terdefinisi.",
		unexpected_error_occurred: "Terjadi kesalahan yang tidak terduga.",
		unit_already_added: "Unit sudah ditambahkan.",
		username_min_chars_error:
			'"Nama Pengguna" harus memiliki minimal 6 karakter.',
		user_already_employee: "Pengguna sudah menjadi karyawan.",
		user_already_exists: "Pengguna sudah menjadi karyawan perusahaan.",
		user_does_not_exist: "Pengguna tidak ditemukan.",
		user_not_found: "Pengguna tidak ditemukan.",
	},
	$shipment: {
		$actionDescription: {
			create: "Dibuat oleh {name}.",
			update: "Diperbarui oleh {name}.",
			create_container: "{name} menambahkan kontainer.",
			update_container: "{name} memperbarui kontainer.",
			delete_container: "{name} menghapus kontainer.",
			create_attachment: "{name} membuat lampiran.",
			update_attachment: "{name} memperbarui lampiran.",
			delete_attachment: "{name} menghapus lampiran.",
			$land: {
				create_container: "{name} menambahkan kendaraan darat.",
				update_container: "{name} memperbarui kendaraan darat.",
				delete_container: "{name} menghapus kendaraan darat.",
			},
		},
		$containerStatus: {
			reserved: "Dipesan",
			on_load: "Dimuat",
			on_way: "Sedang Dikirim",
			arrived: "Tiba",
			off_load: "Diturunkan",
		},
		$method: {
			sea: "Laut",
			aerial: "Udara",
			land: "Darat",
			railway: "Kereta Api",
		},
		$vehicle: {
			license_number: "Nomor Plat",
		},
		archiving_success_message: "Pengiriman diarsipkan.",
		leave_message: "Tinggalkan pesan atau komentar Anda",
		no_booking_info:
			"Info pemesanan belum ditentukan. Tekan tombol {0} untuk menambahkannya.",
		no_transportation_info:
			"Detail pengiriman pemesanan belum ditentukan. Tekan tombol {0} untuk menentukannya.",
		un_archiving_success_message: "Pengiriman dihapus dari arsip.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Milimeter",
		meter: "Meter",
		kilometer: "Kilometer",
		milligram: "Miligram",
		gram: "Gram",
		kilogram: "Kilogram",
		square_meter: "Meter Persegi",
		hectare: "Hektar",
		square_kilometer: "Kilometer Persegi",
		milliliter: "Mililiter",
		liter: "Liter",
		cubic_meter: "Meter Kubik",
		acres: "Acre",
		feet: "Kaki",
		fluid_ounces: "Ons Cair",
		gallons: "Galon",
		inches: "Inci",
		miles: "Mil",
		ounces: "Ons",
		pounds: "Pound",
		quarts: "Kuart",
		square_feet: "Kaki Persegi",
		square_miles: "Mil Persegi",
		square_yards: "Yard Persegi",
		square_inches: "Inci Persegi",
		yards: "Yard",
		pieces: "Potongan",
		boxes: "Kotak",
		pallets: "Pallet",
		dozen: "Dusin",
		ton: "Ton",
	},
	$validation: {
		required: "{item} tidak boleh kosong",
		required_for_default_language:
			"{item} harus ditentukan untuk bahasa default perusahaan.",
		invalid: "{item} tidak valid",
		email: "Masukkan alamat email yang valid",
		min_length: "{item} harus setidaknya {length} karakter",
		max_length: "{item} tidak boleh lebih dari {length} karakter",
		password_repeat_warning:
			"Ulangi kata sandi harus sama dengan kata sandi baru.",
	},

	accept_invitation_success_message: "Undangan berhasil diterima.",
	accessibility: "Aksesibilitas",
	actions: "Tindakan",
	active: "Aktif",
	add: "Tambah",
	add_item: "Tambahkan {item}",
	address: "Alamat",
	again: "Lagi",
	alert: "Peringatan",
	already_have_employee_message: "Anda sudah menjadi karyawan perusahaan.",
	amount: "Jumlah",
	archive: "Arsip",
	attachment: "Lampiran",
	attachments: "Lampiran",

	booking: "Pemesanan",
	booking_code: "Kode Pemesanan",
	business_partner: "Mitra Bisnis",
	business_partners: "Mitra Bisnis",

	cancel: "Batal",
	change_password: "Ubah Kata Sandi",
	checking_invitation_code_message: "Memeriksa kode undangan",
	choose_image: "Pilih Gambar",
	clear: "Bersihkan",
	close: "Tutup",
	code: "Kode",
	companies: "Perusahaan",
	company: "Perusahaan",
	company_profile: "Profil Perusahaan",
	commercial: "Komersial",
	commercial_partners: "Mitra Komersial",
	content: "Konten",
	configuration: "Konfigurasi",
	confirm: "Konfirmasi",
	contact: "Kontak",
	contact_info: "Informasi Kontak",
	contact_to_us: "Hubungi Kami",
	container: "Kontainer",
	containers: "Kontainer",
	content: "Konten",
	countries: "Negara",
	country: "Negara",
	create: "Buat",
	create_company: "Buat Perusahaan",
	created_at: "Dibuat pada",
	currencies: "Mata Uang",
	currency: "Mata Uang",
	current_password: "Kata Sandi Saat Ini",
	customs_agency: "Badan Bea Cukai",
	customs_agencies: "Badan Bea Cukai",

	date: "Tanggal",
	dashboard: "Dasbor",
	default: "Default",
	default_language: "Bahasa Default",
	delete: "Hapus",
	delete_confirm_message: "Anda yakin ingin menghapus ini?",
	description: "Deskripsi",
	detail: "Detail",
	discard: "Buang",
	document_number: "Nomor Dokumen",
	download: "Unduh",

	edit: "Edit",
	edit_item: "Edit {item}",
	email: "Email",
	employee: "Karyawan",
	employee_type: "Jenis Karyawan",
	employees: "Karyawan",
	estimated_arrival_day: "Hari Kedatangan Estimasi",
	expense: "Biaya",
	expenses: "Biaya",
	exported_containers: "Kontainer diekspor",

	faq: "FAQ",
	file: "Berkas",
	filter: "Filter",
	first_name: "Nama Depan",
	forget_password: "Lupa Kata Sandi",
	forget_password_message: "Lupa kata sandi?",
	from: "Dari",

	hide: "Sembunyikan",
	home_page: "Halaman Utama",

	imported_containers: "Kontainer yang Diimpor",
	incoming: "Masuk",
	incoming_shipments: "Pengiriman Masuk",
	initial: "Awal",
	invitation: "Undangan",
	invitation_code: "Kode Undangan",
	invitation_mail_sent: "Email undangan telah dikirim",
	invitations: "Undangan",

	join: "Bergabung",

	language_options: "Opsi Bahasa",
	last_name: "Nama Belakang",
	last_one_year: "Satu Tahun Terakhir",
	latitude: "Garis Lintang",
	loading_country: "Negara Pemuatan",
	loading_point: "Titik Pemuatan",
	loading_port: "Pelabuhan Pemuatan",
	logo: "Logo",
	longitude: "Garis Bujur",

	go_to_panel: "Buka Dasbor",

	mail_sent: "Email terkirim",
	migrate: "Migrasi",
	migrate_partner: "Migrasi Mitra",
	message: "Pesan",
	message_company_create: "Perusahaan berhasil dibuat.",
	message_company_join: "Bergabung dengan perusahaan",
	message_company_joined: "Anda telah bergabung dengan perusahaan",
	message_employee_delete: "Karyawan dihapus.",
	message_employee_save: "Karyawan disimpan.",
	message_join_company:
		"Masukkan kode undangan untuk bergabung dengan perusahaan.",
	method: "Metode",
	mobile_number: "Nomor Ponsel",
	my_role: "Peran Saya",

	name: "Nama",
	next: "Selanjutnya",
	new: "Baru",
	new_password: "Kata Sandi Baru",
	no: "Tidak",
	no_content: "Tidak ada konten",
	no_text: "Tidak ada teks",
	not_found_page_message: "Maaf, halaman tidak ditemukan :(",
	note: "Catatan",

	ok: "OK",
	outgoing: "Keluar",
	outgoing_shipments: "Pengiriman Keluar",

	parent_group: "Grup Induk",
	partner: "Mitra",
	partners: "Mitra",
	passive: "Pasif",
	password: "Kata Sandi",
	phone_number: "Nomor Telepon",
	previous: "Sebelumnya",
	pricing: "Harga",
	product: "Produk",
	product_group: "Grup Produk",
	product_groups: "Grup Produk",
	products: "Produk",
	profile: "Profil",
	proforma_invoice: "Faktur Proforma",
	proforma_invoices: "Faktur Proforma",
	proforma_number: "Nomor Faktur Proforma",
	purchase: "Pembelian",

	quantity: "Jumlah",

	receiver: "Penerima",
	receiver_company: "Perusahaan Penerima",
	receiver_customs_agency: "Badan Bea Cukai Penerima",
	redirection_to_home_link: "klik di sini",
	redirection_to_home_message: "Untuk kembali ke halaman utama, ",
	redirection_to_sign_in_message: "Sudah punya akun?",
	redirection_to_sign_up_message: "Belum memiliki akun?",
	reload: "Muat Ulang",
	reset_password: "Reset Kata Sandi",

	sales: "Penjualan",
	save: "Simpan",
	search: "Cari",
	select_file: "Pilih Berkas",
	select_file_place_holder: "Silakan pilih gambar",
	selected_partner: "Mitra yang Dipilih",
	send: "Kirim",
	send_again: "Kirim Ulang",
	send_email: "Kirim Email",
	send_invitation: "Kirim Undangan",
	sender: "Pengirim",
	sender_company: "Perusahaan Pengirim",
	sender_customs_agency: "Badan Bea Cukai Pengirim",
	show: "Tampilkan",
	shipment: "Pengiriman",
	shipment_method: "Metode Pengiriman",
	shipments: "Pengiriman",
	shipments_filter: "Filter Pengiriman",
	shipping_term: "Syarat Pengiriman",
	shipping_terms: "Syarat Pengiriman",
	sign_in: "Masuk",
	sign_out: "Keluar",
	sign_out_confirm_message: "Anda yakin ingin keluar?",
	sign_out_success_message: "Selamat tinggal.",
	sign_up: "Daftar",
	social_media: "Media Sosial",
	social_media_account: "Akun Media Sosial",
	status: "Status",
	subscribe: "Berlangganan",
	super: "Super",
	switch_language: "Ganti Bahasa",
	switch_theme: "Ganti Tema",

	target: "Tujuan",
	target_partner: "Mitra Tujuan",
	theme: "Tema",
	this_month: "Bulan Ini",
	this_year: "Tahun Ini",
	title: "Judul",
	to: "Ke",
	toDelete: "Hapus",
	total: "Total",
	total_amount: "Total Jumlah",
	type: "Jenis",
	types: "Jenis",

	un_archive: "Buka Arsip",
	unit: "Unit",
	units: "Unit",
	unloading_country: "Negara Bongkar",
	unloading_point: "Titik Bongkar",
	unloading_port: "Pelabuhan Bongkar",
	use_code: "Gunakan Kode",
	use_invitation_code: "Gunakan Kode Undangan",
	use_invitation_code_description:
		"Masukkan kode undangan yang telah dikirim ke kotak masuk email Anda.",
	use_invitation_code_success_message: "Kode undangan berhasil digunakan.",
	user_profile: "Profil Pengguna",

	vehicle: "Kendaraan",
	vehicles: "Kendaraan",
	visibility: "Visibilitas",

	warehouse: "Gudang",
	warehouses: "Gudang",
	website: "Situs Web",
	welcome: "Selamat datang",

	yes: "Ya",
};

<template>
	<page-template>
		<template slot="title">{{ $t("user_profile") }}</template>
		<template slot="buttons">
			<v-tooltip top open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="changeLanguageDialog.show" v-on="on">
						<v-icon>mdi-translate</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("switch_language") }}</span>
			</v-tooltip>
			<v-divider vertical inset class="mx-2" />
			<span>V {{ version }}</span>
			<v-btn v-if="!company" icon @click="$router.push('/')">
				<v-icon>mdi-home</v-icon>
			</v-btn>
		</template>
		<template slot="content">
			<v-container v-if="user" style="max-width: 640px">
				<v-card elevation="1">
					<v-card-text>
						<v-form ref="form" v-model="valid">
							<v-text-field
								v-if="editMode"
								v-model="editItem.firstName"
								:label="$t('first_name')"
								prepend-icon="mdi-signature-text"
								:rules="rules.firstName"
							/>
							<title-value-list-item
								v-else
								icon="mdi-signature-text"
								:title="$t('first_name')"
								:value="user.firstName"
							/>

							<v-text-field
								v-if="editMode"
								v-model="editItem.lastName"
								:label="$t('last_name')"
								prepend-icon="mdi-signature-text"
								:rules="rules.last_name"
							/>
							<title-value-list-item
								v-else
								icon="mdi-signature-text"
								:title="$t('last_name')"
								:value="user.lastName"
							/>

							<v-text-field
								v-if="editMode"
								v-model="editItem.email"
								:label="$t('email')"
								prepend-icon="mdi-at"
								:rules="rules.email"
							/>
							<title-value-list-item
								v-else
								icon="mdi-at"
								:title="$t('email')"
								:value="user.email"
							/>
						</v-form>
					</v-card-text>

					<v-card-actions v-if="editMode">
						<v-btn @click="cancelButtonAction()">
							{{ $t("cancel") }}
						</v-btn>
						<v-spacer />
						<v-btn @click="updateUser(editItem)" color="primary">
							{{ $t("save") }}
						</v-btn>
					</v-card-actions>
					<v-card-actions v-else>
						<v-btn @click="editButtonAction()">
							{{ $t("edit") }}
						</v-btn>
						<v-spacer />
						<v-btn @click="dialog.show()">
							{{ $t("change_password") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-container>

			<v-dialog v-model="dialog.visibility" max-width="480">
				<v-card>
					<v-card-title class="headline">
						{{ $t("change_password") }}
					</v-card-title>
					<v-card-text>
						<v-form ref="dialogForm">
							<v-text-field
								v-model="dialog.item.oldPassword"
								:label="$t('current_password')"
								:rules="dialog.rules.oldPassword"
								type="password"
								@keydown.enter="dialog.run()"
							/>
							<v-text-field
								v-model="dialog.item.newPassword"
								:label="$t('new_password')"
								:rules="dialog.rules.newPassword"
								type="password"
								@keydown.enter="dialog.run()"
							/>
							<v-text-field
								v-model="dialog.item.newPasswordAgain"
								:label="`${$t('new_password')} (${$t('again')})`"
								:rules="dialog.rules.newPasswordAgain"
								type="password"
								@keydown.enter="dialog.run()"
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn @click="dialog.hide()" :disabled="dialog.running">
							{{ $t("cancel") }}
						</v-btn>
						<v-btn color="accent" @click="dialog.run()" :loading="dialog.running">
							{{ $t("save") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<change-language-dialog
				v-model="changeLanguageDialog.visibility"
				@closed="changeLanguageDialog.hide"
			/>
		</template>
	</page-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import PageTemplate from "../templates/PageTemplate";
import TitleValueListItem from "../partials/TitleValueListItem";
import remote from "../../data/remote";
import ChangeLanguageDialog from "../partials/dialogs/ChangeLanguageDialog.vue";

let newPass;
let newPassValid = true;

export default {
	mixins: [uiMixin],

	components: { PageTemplate, TitleValueListItem, ChangeLanguageDialog },

	data() {
		return {
			user: null,
			editItem: null,
			editMode: false,
			version: process.env.PACKAGE_VERSION || "0.0.0",
			valid: null,
			rules: {
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("email"),
								length: "50",
							});
						return true;
					},
				],
				firstName: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("first_name"),
							});
						if (v && v.length > 25)
							return this.$t("$validation.max_length", {
								item: this.$t("first_name"),
								length: "25",
							});
						return true;
					},
				],
				last_name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("last_name"),
							});
						if (v && v.length > 25)
							return this.$t("$validation.max_length", {
								item: this.$t("last_name"),
								length: "25",
							});
						return true;
					},
				],
			},

			dialog: {
				visibility: false,
				showErrors: false,
				running: false,
				item: {
					oldPassword: "",
					newPassword: "",
					newPasswordRepeat: "",
				},
				rules: {
					oldPassword: [
						(v) =>
							!this.dialog.showErrors ||
							Boolean(v) ||
							this.$t("$validation.required", {
								item: this.$t("current_password"),
							}),
					],
					newPassword: [
						(v) => {
							newPass = v;
							newPassValid = false;
							if (this.dialog.showErrors) {
								if (!v)
									return this.$t("$validation.required", {
										item: this.$t("new_password"),
									});
								else if (v && v.length < 6)
									return this.$t("$validation.min_length", {
										item: this.$t("new_password"),
										length: "6",
									});
							}
							newPassValid = true;
							return true;
						},
					],
					newPasswordAgain: [
						(v) => {
							return (
								!this.dialog.showErrors ||
								!newPassValid ||
								v === newPass ||
								this.$t("$validation.password_repeat_warning")
							);
						},
					],
				},
				show: () => {
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.visibility = false;
					this.dialog.reset();
				},
				reset: () => {
					this.dialog.item.oldPassword = "";
					this.dialog.item.newPassword = "";
					this.dialog.item.newPasswordRepeat = "";
					this.dialog.showErrors = false;
					this.dialog.running = false;
				},
				run: async () => {
					this.dialog.showErrors = true;
					if (await this.$refs.dialogForm.validate()) {
						remote.auth.changePassword(this.dialog.item, {
							onSuccess: () => {
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("password"),
									}),
								});
								this.dialog.hide();
							},
							onFail: (error) => {
								if (error) console.error(error);
							},
						});
					}
				},
			},
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},

	computed: {
		...mapGetters(["company"]),
	},

	methods: {
		...mapActions("auth", ["signOut"]),
		cancelButtonAction() {
			this.editMode = !this.editMode;
			this.editItem = null;
		},
		editButtonAction() {
			this.editItem = Object.assign({}, this.user);
			this.editMode = !this.editMode;
		},
		loadUser() {
			this.showProgressBar();
			remote.auth.getUserProfile({
				onSuccess: (result) => {
					this.user = result;
					this.hideProgressBar();
				},
				onFail: (_) => this.hideProgressBar(),
			});
		},
		updateUser(item) {
			this.showProgressBar();
			if (this.valid) {
				if (this.company) {
					remote.employees.updateMyEmployee(
						{ user: item },
						{
							onSuccess: (employee) => {
								this.user = employee.user;
								this.hideProgressBar();
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("profile"),
									}),
								});
								this.editMode = false;
							},
							onFail: (_) => this.hideProgressBar(),
						}
					);
				} else {
					remote.auth.updateUserProfile(item, {
						onSuccess: (user) => {
							this.user = user;
							this.hideProgressBar();
							this.showSnackBar({
								message: this.$t("$message.updated", {
									item: this.$t("profile"),
								}),
							});
							this.$editEvent("user_profile");
							this.editMode = false;
						},
						onFail: (_) => this.hideProgressBar(),
					});
				}
			} else this.$refs.form.validate();
		},
	},

	created() {
		this.$trackPageView(this.$route.path);
		this.loadUser();
	},
};
</script>

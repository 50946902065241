export const partnerTypes = {
	AbstractCompany: "abstract_company",
	Company: "company",
	CustomsAgency: "customs_agency",
};

export const corporationTypes = {
	Company: "Company",
	CustomsAgency: "CustomsAgency",
};

export const employeeTypes = {
	Default: "default",
	Admin: "admin",
	Exporter: "exporter",
	Importer: "importer",
};

export const invitationTypes = {
	Employee: "employee",
	CompanyPartner: "company_partner",
	CustomsPartner: "customs_partner",
};

export const shipmentMethod = {
	Sea: "sea",
	Aerial: "aerial",
	Land: "land",
	Railway: "railway",
};

export const containerStatus = {
	Reserved: "reserved",
	OnLoad: "on_load",
	OnWay: "on_way",
	Arrived: "arrived",
	OffLoad: "off_load",
};

export const paymentSource = {
	AutomaticRenew: "automatic_renew",
	UserPayment: "user_payment",
	GiftCard: "gift_card",
};

export const paymentStatus = {
	Repaid: -9,
	Failed: -1,
	InProgress: 0,
	Success: 1,
};

export const paymentTypes = {
	TL: {
		symbol:"₺",
		code: "TL"
	},
	USD: {
		symbol: "$",
		code: "USD",
	},
};

export const antModule = {
	Partner: "partner",
	// ProformaInvoice : "proforma_invoice",
	// Product : "product",
	Shipment: "shipment",
	Storage: "storage",
	User: "user",
};

export const antProductCategoryTypes = {
	Free: "free",
	Premium: "premium",
	PremiumPlus: "premium_plus",
};

export const subscriptionStatus = {
	Passive: 0,
	Active: 1,
};

export const contractPath = {
	CancellationAndReturnPolicy: "documents/cancellation_and_return_policy.pdf",
	DistanceSalesAgreement: "documents/distance_sales_agreement.pdf",
	PrivacyAndSecurityPolicy: "documents/privacy_and_security_policy.pdf",
};

<template>
	<page-template>
		<template slot="title">{{ $t("shipments") }}</template>
		<template slot="buttons">
			<v-btn icon @click="filterDialog.show">
				<v-icon>{{ filterIcon }}</v-icon>
			</v-btn>
		</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="shipments"
				:options.sync="tableOptions"
				:server-items-length="pagination.totalItemCount"
				:footer-props="tableFooterProps"
				:loading="loading"
				style="cursor: pointer; margin-bottom: 84px"
				@click:row="shipmentAction"
				disable-sort
			>
				<template v-slot:item.method="{ item }">
					<v-tooltip bottom open-delay="500">
						<template v-slot:activator="{ on }">
							<v-icon v-on="on">
								{{ getShipmentMethodIcon(item.method) }}
							</v-icon>
						</template>
						<span>{{ $t(`$shipment.$method.${item.method}`) }}</span>
					</v-tooltip>
				</template>
				<template v-slot:item.from="{ item }">
					{{ item.loadingPort ? item.loadingPort.name : item.loadingPoint }}
				</template>
				<template v-slot:item.to="{ item }">
					{{ item.unloadingPort ? item.unloadingPort.name : item.unloadingPoint }}
				</template>
			</v-data-table>

			<v-speed-dial
				v-if="!customsAgency"
				fixed
				bottom
				right
				direction="top"
				transition="slide-y-reverse-transition"
			>
				<template v-slot:activator>
					<v-btn color="accent" fab class="d-flex d-sm-none" elevation="0">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
					<v-btn color="accent" rounded large class="d-none d-sm-flex">
						{{ $t("new") }}
						<v-icon right>mdi-plus-circle</v-icon>
					</v-btn>
				</template>
				<v-tooltip left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							fab
							small
							color="info"
							elevation="0"
							@click="newDialog.show('receiver')"
						>
							<v-icon>mdi-arrow-bottom-left</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("incoming") }}</span>
				</v-tooltip>
				<v-tooltip left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							fab
							small
							color="success"
							elevation="0"
							@click="newDialog.show('sender')"
						>
							<v-icon>mdi-arrow-top-right</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("outgoing") }}</span>
				</v-tooltip>
			</v-speed-dial>

			<shipment-filter-dialog
				v-model="filterDialog.visibility"
				:item="filterDialog.item"
				@done="filterDialog.done"
			/>

			<new-shipment-dialog
				v-model="newDialog.visibility"
				:my-role="newDialog.myRole"
				@saved="newDialog.saved"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import {
	pagination as paginationMixin,
	iconHelper as iconHelperMixin,
} from "../../mixins/utils.mixin";
import PageTemplate from "../../templates/PageTemplate";
import remote from "../../../data/remote/index";
import NewShipmentDialog from "./dialog/NewShipmentDialog";
import ShipmentFilterDialog from "./dialog/ShipmentFilterDialog.vue";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin, paginationMixin, iconHelperMixin],
	components: {
		PageTemplate,
		NewShipmentDialog,
		ShipmentFilterDialog,
	},
	data() {
		return {
			shipments: [],
			loading: false,
			filterDialog: {
				visibility: false,
				item: {
					role: null, // sender, receiver
					accessibilities: [], // present, archived
					bookingCode: "",
					method: null, // Aerial, Land, Railway, Sea
				},
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (item) => {
					this.filterDialog.item = item;
					this.tableOptions.page = 1;
					this.$router.push({
						query: {
							role: this.filterDialog.item.role || undefined,
							accessibilities: this.filterDialog.item.accessibilities.length
								? this.filterDialog.item.accessibilities.join(",")
								: undefined,
							bookingCode: this.filterDialog.item.bookingCode
								? this.filterDialog.item.bookingCode.trim()
								: undefined,
							method: this.filterDialog.item.method,
						},
					});
					this.filterDialog.hide();
					this.loadItems();
				},
			},
			headers: [
				{ value: "method", width: 1 },
				{ text: this.$t("sender"), value: "sender.company.name" },
				{ text: this.$t("receiver"), value: "receiver.company.name" },
				{ text: this.$t("from"), value: "from" },
				{ text: this.$t("to"), value: "to" },
			],
			newDialog: {
				visibility: false,
				myRole: null,
				show: (role) => {
					this.newDialog.myRole = role;
					this.newDialog.visibility = true;
				},
				hide: () => {
					this.newDialog.myRole = null;
					this.newDialog.visibility = false;
				},
				saved: (item) => {
					this.newDialog.hide();
					this.shipmentAction(item);
				},
			},
		};
	},
	computed: {
		...mapGetters(["customsAgency"]),
		filterIcon() {
			return this.filterDialog.item.role || this.filterDialog.item.accessibilities.length
				? "mdi-filter"
				: "mdi-filter-outline";
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.shipments.filter({
				filter: {
					role: this.filterDialog.item.role,
					accessibilities: this.filterDialog.item.accessibilities,
					bookingCode: this.filterDialog.item.bookingCode,
					method: this.filterDialog.item.method,
				},
				pager: {
					number: this.tableOptions.page,
					size: this.tableOptions.itemsPerPage,
				},
				sorter: this.tableOptions.sortBy.length
					? {
							property: this.tableOptions.sortBy,
							method: this.tableOptions.sortDesc ? "desc" : "asc",
					  }
					: undefined,
				onSuccess: (result) => {
					this.pagination = result.pagination;
					this.shipments = result.items.sort((a, b) => {
						if (a.createdAt && b.createdAt) return a.createdAt < b.createdAt ? 1 : -1;
						else return !a.createdAt ? 1 : -1;
					});
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		shipmentAction(item) {
			this.$router.push({ name: "shipment", params: { id: item.id } });
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.filterDialog.item.role = this.$route.query.role;
		const accessibilities = this.$route.query.accessibilities;
		if (accessibilities) this.filterDialog.item.accessibilities = accessibilities.split(",");
		else this.filterDialog.item.accessibilities = ["present"];
		this.filterDialog.item.bookingCode = this.$route.query.bookingCode;
		this.filterDialog.item.method = this.$route.query.method;
		this.loadItems();
	},
};
</script>

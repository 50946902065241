export default {
	$accessibility: {
		present: "Em Andamento",
		archived: "Arquivado",
	},
	$accessModifier: {
		$description: {
			private: "Privado",
			hidden_from_receiver: "Oculto do Destinatário",
			hidden_from_sender: "Oculto do Remetente",
			shared_with_everyone: "Compartilhado com Todos",
			shared_with_custom: "Compartilhado com Personalização",
			shared_with_receiver: "Compartilhado com o Destinatário",
			shared_with_sender: "Compartilhado com o Remetente",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andorra",
		united_arab_emirates: "Emirados Árabes Unidos",
		afghanistan: "Afeganistão",
		antigua_and_barbuda: "Antígua e Barbuda",
		anguilla: "Anguilla",
		albania: "Albânia",
		armenia: "Armênia",
		angola: "Angola",
		antarctica: "Antártida",
		argentina: "Argentina",
		american_samoa: "Samoa Americana",
		austria: "Áustria",
		australia: "Austrália",
		aruba: "Aruba",
		aland: "Ilhas Aland",
		azerbaijan: "Azerbaijão",
		bosnia_and_herzegovina: "Bósnia e Herzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Bélgica",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgária",
		bahrain: "Bahrein",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthelemy",
		bermuda: "Bermudas",
		brunei: "Brunei",
		bolivia: "Bolívia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Santo Eustáquio e Saba",
		brazil: "Brasil",
		bahamas: "Bahamas",
		bhutan: "Butão",
		bouvet_island: "Ilha Bouvet",
		botswana: "Botsuana",
		belarus: "Bielorrússia",
		belize: "Belize",
		canada: "Canadá",
		cocos_islands: "Ilhas Cocos",
		dr_congo: "República Democrática do Congo",
		central_african_republic: "República Centro-Africana",
		congo_republic: "Congo - Brazzaville",
		switzerland: "Suíça",
		ivory_coast: "Costa do Marfim",
		cook_islands: "Ilhas Cook",
		chile: "Chile",
		cameroon: "Camarões",
		china: "China",
		colombia: "Colômbia",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Cabo Verde",
		curacao: "Curaçao",
		christmas_island: "Ilha Christmas",
		cyprus: "Chipre",
		czechia: "Chéquia",
		germany: "Alemanha",
		djibouti: "Djibuti",
		denmark: "Dinamarca",
		dominica: "Dominica",
		dominican_republic: "República Dominicana",
		algeria: "Argélia",
		ecuador: "Equador",
		estonia: "Estônia",
		egypt: "Egito",
		western_sahara: "Saara Ocidental",
		eritrea: "Eritreia",
		spain: "Espanha",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		fiji: "Fiji",
		falkland_islands: "Ilhas Malvinas",
		micronesia: "Micronésia",
		faroe_islands: "Ilhas Faroé",
		france: "França",
		gabon: "Gabão",
		united_kingdom: "Reino Unido",
		grenada: "Granada",
		georgia: "Geórgia",
		french_guiana: "Guiana Francesa",
		guernsey: "Guernsey",
		ghana: "Gana",
		gibraltar: "Gibraltar",
		greenland: "Groenlândia",
		gambia: "Gâmbia",
		guinea: "Guiné",
		guadeloupe: "Guadalupe",
		equatorial_guinea: "Guiné Equatorial",
		greece: "Grécia",
		south_georgia_and_south_sandwich_islands:
			"Geórgia do Sul e Ilhas Sandwich do Sul",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guiné-Bissau",
		guyana: "Guiana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Ilha Heard e Ilhas McDonald",
		honduras: "Honduras",
		croatia: "Croácia",
		haiti: "Haiti",
		hungary: "Hungria",
		indonesia: "Indonésia",
		ireland: "Irlanda",
		israel: "Israel",
		isle_of_man: "Ilha de Man",
		india: "Índia",
		british_indian_ocean_territory: "Território Britânico do Oceano Índico",
		iraq: "Iraque",
		iran: "Irã",
		iceland: "Islândia",
		italy: "Itália",
		jersey: "Jersey",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		japan: "Japão",
		kenya: "Quênia",
		kyrgyzstan: "Quirguistão",
		cambodia: "Camboja",
		kiribati: "Kiribati",
		comoros: "Comores",
		st_kitts_and_nevis: "São Cristóvão e Nevis",
		north_korea: "Coreia do Norte",
		south_korea: "Coreia do Sul",
		kuwait: "Kuwait",
		cayman_islands: "Ilhas Cayman",
		kazakhstan: "Cazaquistão",
		laos: "Laos",
		lebanon: "Líbano",
		saint_lucia: "Santa Lúcia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Libéria",
		lesotho: "Lesoto",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		latvia: "Letônia",
		libya: "Líbia",
		morocco: "Marrocos",
		monaco: "Mônaco",
		moldova: "Moldávia",
		montenegro: "Montenegro",
		saint_martin: "São Martinho",
		madagascar: "Madagáscar",
		marshall_islands: "Ilhas Marshall",
		north_macedonia: "Macedônia do Norte",
		mali: "Mali",
		myanmar: "Mianmar",
		mongolia: "Mongólia",
		macao: "Macau",
		northern_mariana_islands: "Ilhas Marianas do Norte",
		martinique: "Martinica",
		mauritania: "Mauritânia",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Maurício",
		maldives: "Maldivas",
		malawi: "Malawi",
		mexico: "México",
		malaysia: "Malásia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		new_caledonia: "Nova Caledônia",
		niger: "Níger",
		norfolk_island: "Ilha Norfolk",
		nigeria: "Nigéria",
		nicaragua: "Nicarágua",
		netherlands: "Países Baixos",
		norway: "Noruega",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Nova Zelândia",
		oman: "Omã",
		panama: "Panamá",
		peru: "Peru",
		french_polynesia: "Polinésia Francesa",
		papua_new_guinea: "Papua-Nova Guiné",
		philippines: "Filipinas",
		pakistan: "Paquistão",
		poland: "Polônia",
		saint_pierre_and_miquelon: "Saint Pierre e Miquelon",
		pitcairn_islands: "Ilhas Pitcairn",
		puerto_rico: "Porto Rico",
		palestine: "Palestina",
		portugal: "Portugal",
		palau: "Palau",
		paraguay: "Paraguai",
		qatar: "Catar",
		reunion: "Reunião",
		romania: "Romênia",
		serbia: "Sérvia",
		russia: "Rússia",
		rwanda: "Ruanda",
		saudi_arabia: "Arábia Saudita",
		solomon_islands: "Ilhas Salomão",
		seychelles: "Seychelles",
		sudan: "Sudão",
		sweden: "Suécia",
		singapore: "Cingapura",
		saint_helena: "Santa Helena",
		slovenia: "Eslovênia",
		svalbard_and_jan_mayen: "Svalbard e Jan Mayen",
		slovakia: "Eslováquia",
		sierra_leone: "Serra Leoa",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somália",
		suriname: "Suriname",
		south_sudan: "Sudão do Sul",
		sao_tome_and_principe: "São Tomé e Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Síria",
		eswatini: "Essuatíni",
		turks_and_caicos_islands: "Ilhas Turks e Caicos",
		chad: "Chade",
		french_southern_territories: "Territórios Franceses do Sul",
		togo: "Togo",
		thailand: "Tailândia",
		tajikistan: "Tajiquistão",
		tokelau: "Tokelau",
		timor_leste: "Timor-Leste",
		turkmenistan: "Turcomenistão",
		tunisia: "Tunísia",
		tonga: "Tonga",
		turkiye: "Turquia",
		trinidad_and_tobago: "Trinidad e Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwan",
		tanzania: "Tanzânia",
		ukraine: "Ucrânia",
		uganda: "Uganda",
		us_minor_outlying_islands: "Ilhas Menores Distantes dos Estados Unidos",
		united_states: "Estados Unidos",
		uruguay: "Uruguai",
		uzbekistan: "Uzbequistão",
		vatican_city: "Cidade do Vaticano",
		st_vincent_and_grenadines: "São Vicente e Granadinas",
		venezuela: "Venezuela",
		british_virgin_islands: "Ilhas Virgens Britânicas",
		us_virgin_islands: "Ilhas Virgens Americanas",
		vietnam: "Vietnã",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis e Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Iêmen",
		mayotte: "Mayotte",
		south_africa: "África do Sul",
		zambia: "Zâmbia",
		zimbabwe: "Zimbábue",
	},
	$currency: {
		us_dollar: "Dólar Americano",
		euro: "Euro",
		turkish_lira: "Lira Turca",
	},
	$employeeType: {
		admin: "Administrador",
		default: "Padrão",
		exporter: "Exportador",
		importer: "Importador",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Bem-vindo à Antrego",
			description:
				"Controle seus processos de importação e exportação com a Antrego!",
			sign_in_title: "Já tem uma conta?",
			sign_in_subtitle: "Faça login aqui",
			sign_up_title: "Quer criar uma conta?",
			sign_up_subtitle: "Registre-se aqui",
		},
		$noCompany: {
			title: "Bem-vindo, {name}",
			description:
				"Você fez login com sucesso. Agora, você precisa se juntar a uma empresa existente ou criar a sua própria.",
			join_company_title: "Quer se juntar a uma empresa existente?",
			join_company_subtitle:
				"Digite seu código de convite aqui e junte-se à empresa.",
			create_company_title: "Quer criar sua própria empresa?",
			create_company_subtitle: "Crie uma nova empresa aqui.",
		},
	},
	$invitationType: {
		employee: "Convite de Empregado",
		company_partner: "Convite de Parceria Comercial",
		customs_partner: "Convite de Despachante Aduaneiro",
	},
	$landing: {
		$question: {
			$title: {
				0: "O que é a Antrego?",
				1: "Como a Antrego contribuirá para o meu negócio no processo de remessa?",
				2: "Quais tipos de logística a Antrego suporta?",
				3: "Por que devo usar a Antrego?",
				4: "A Antrego é um aplicativo pago?",
				5: "Os clientes da Antrego também precisam pagar?",
			},
			$text: {
				0: "<p>Um programa de rastreamento de remessas que ajuda empresas de remessas e seus clientes a fazerem um acompanhamento conjunto, prevenindo danos causados por atrasos na fase de acompanhamento.</p>",
				1: `<p>- Compartilhe todas as atualizações em tempo real com seus clientes para melhorar a qualidade do serviço.<br>
				- Veja todos os documentos de remessa em um único sistema e compartilhe com seus clientes.<br>
				- Acompanhe todas as suas remessas de um só lugar. Mantenha o controle financeiro com relatórios dinâmicos.</p>`,
				2: `<p>- Marítimo<br>
				- Aéreo<br>
				- Terrestre<br>
				- Ferroviário</p>`,
				3: `<p>Facilita o processo de remessa com os serviços oferecidos a qualquer momento.<br>
				<strong>1. Acesso Fácil</strong><br>
				Pode ser acessado de qualquer lugar e dispositivo via navegador web.<br>
				<strong>2. Segurança de Dados</strong><br>
				Todos os dados são armazenados com a tecnologia de criptografia SHA-256.<br>
				<strong>3. Acompanhamento Compartilhado</strong><br>
				Oferece a capacidade de rastreamento a todas as partes interessadas no processo de negócios.</p>`,
				4: "<p>Sim, é um aplicativo pago. Queremos continuar facilitando seus negócios, digitalizando todos os processos de suas remessas e continuando a melhorar para fornecer benefícios. Para continuar com essas melhorias e manter o espaço que você usa nos servidores, solicitamos uma taxa mensal.</p>",
				5: "<p>Não, seus clientes não precisam pagar para rastrear as remessas que você faz.</p>",
			},
		},
		$lifecycle: {
			headline: "TODOS OS PROCESSOS AO SEU ALCANCE",
			$title: {
				0: "Junte-se",
				1: "Envie Fatura Proforma",
				2: "Crie Remessa",
				3: "Acompanhe Atividades Financeiras",
			},
			$text: {
				0: "Insira informações da empresa e produtos, e crie seus clientes.",
				1: "Crie e compartilhe faturas proforma para aprovação dos seus clientes.",
				2: "Insira informações sobre sua remessa e documentos relacionados. Gerencie todas as fases da remessa com seus clientes em um único canal.",
				3: "Acompanhe em tempo real todas as atividades financeiras relacionadas a importação e exportação.",
			},
		},
		$content: {
			headline: "TIPOS DE LOGÍSTICA SUPORTADOS",
			$title: {
				0: "Marítimo",
				1: "Aéreo",
				2: "Terrestre",
				3: "Ferroviário",
			},
		},
		$pricing: {
			0: {
				title: "GRÁTIS",
				subtitle: "Individual",
				features: ["1 Usuário", "3 Parceiros", "10 Remessas", "10 Faturas"],
			},
			1: {
				title: "ANUAL",
				subtitle: "Empresas",
				features: [
					"20 Usuários",
					"100 Parceiros",
					"1000 Remessas",
					"1000 Faturas",
				],
			},
			2: {
				title: "MENSAL",
				subtitle: "Pequenas Empresas",
				features: [
					"10 Usuários",
					"30 Parceiros",
					"250 Remessas",
					"250 Faturas",
				],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Tem certeza de que deseja {function}?",
		created: "{item} criado.",
		deleted: "{item} excluído.",
		file_oversize: "O tamanho do arquivo deve ser no máximo {size}.",
		invalid: "{item} inválido.",
		sent_email: "E-mail enviado. ({value})",
		sign_in_failed: "E-mail ou senha incorretos.",
		updated: "{item} atualizado.",
		migrated: "{item} migrado. ({value})",
	},
	$productGroup: {
		add_child: "Adicionar Subgrupo",
		add_to_root: "Adicionar à Raiz",
		has_children_fail_message:
			"O grupo de produtos com subgrupos não pode ser excluído",
		initial_message:
			"Pressione o botão {0} para adicionar seu primeiro grupo de produtos.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} criou.",
			update: "{name} atualizou.",
			send: "{name} enviou para aprovação ao destinatário.",
			revoke: "{name} revogou.",
			approve: "{name} aprovou.",
			demand_revise: "{name} solicitou revisão.",
			reject: "{name} rejeitou.",
			mark_as_processed: "{name} marcou como processado.",
			create_attachment: "{name} criou um anexo.",
			update_attachment: "{name} atualizou um anexo.",
			delete_attachment: "{name} excluiu um anexo.",
		},
		$actionDescription2: {
			send: '{name} marcado como "Enviado".',
			revoke: '{name} marcado como "Revogado".',
			approve: '{name} marcado como "Aprovado".',
			demand_revise: '{name} marcado como "Revisão Solicitada".',
			reject: "{name} rejeitado.",
			mark_as_processed: `{name} marcado como "Processado".`,
		},
		$actions: {
			approve: "Aprovar",
			demand_revise: "Solicitar Revisão",
			mark_as_approved: "Aprovado",
			mark_as_processed: "Processado",
			mark_as_rejected: "Rejeitado",
			mark_as_revise_demanded: "Revisão Solicitada",
			mark_as_revoked: "Revogado",
			mark_as_sent: "Enviado",
			reject: "Rejeitar",
			revoke: "Revogar",
			send: "Enviar",
		},
		$status: {
			approved: "Aprovado",
			pending_approval: "Aguardando Aprovação",
			pending_revise: "Aguardando Revisão",
			processed: "Processado",
			rejected: "Rejeitado",
			revoked: "Revogado",
			template: "Modelo",
		},
		send_confirm_message:
			"Tem certeza de que deseja enviar a fatura pró-forma para o destinatário?",
		send_success_message: "A fatura pró-forma foi enviada para o destinatário.",
		approve_confirm_message:
			"Tem certeza de que deseja aprovar a fatura pró-forma?",
		approve_success_message: "A fatura pró-forma foi aprovada.",
		demand_revise_success_message:
			"A fatura pró-forma foi devolvida para revisão.",
		reject_confirm_message:
			"Tem certeza de que deseja rejeitar a fatura pró-forma?",
		reject_success_message: "A fatura pró-forma foi rejeitada.",
		mark_as_processed_confirm_message:
			"Tem certeza de que deseja marcar a fatura pró-forma como processada?",
		mark_as_processed_success_message:
			"A fatura pró-forma foi marcada como processada.",
		revoke_confirm_message:
			"Tem certeza de que deseja revogar a fatura pró-forma?",
		revoke_success_message: "A fatura pró-forma foi revogada.",
		demand_revise_description_message:
			"Por favor, explique que revisão você está esperando:",
		no_attachment_text: "Ainda não foi anexado nenhum documento.",
	},
	$serviceMessage: {
		already_subs_module: "Já existe uma assinatura para este módulo.",
		bad_request: "Requisição inválida",
		code_already_used: "O código já foi utilizado.",
		container_status_not_available:
			"O status do contêiner não está disponível.",
		existing_email_warning: "E-mail já existente.",
		existing_employee_error: "Este usuário já foi adicionado à sua empresa.",
		existing_employee_for_email_warning:
			"Este e-mail já recebeu um código de convite.",
		existing_invitation_warning: "Este e-mail já recebeu um código de convite.",
		existing_token_already_have_time:
			"O token atual já possui tempo suficiente para ser utilizado.",
		existing_user: "Usuário já existe.",
		five_min_reset_password_mail:
			"Você deve esperar pelo menos 5 minutos após solicitar um e-mail de redefinição de senha.",
		forbidden: "Proibido",
		internal_server_error: "Erro interno do servidor.",
		instance_can_no_delete_existing_booking_code:
			"O item não pode ser excluído devido ao código de reserva existente.",
		instance_cannot_delete: "O item não pode mais ser excluído.",
		instance_not_found: "Item não encontrado.",
		invitation_accepting_is_due_to_the_admin:
			"A aceitação do convite está sujeita à aprovação do administrador.",
		invitation_already_discarded: "O convite já foi descartado.",
		invitation_already_used: "O convite já foi utilizado.",
		invitation_has_been_used: "O convite já foi utilizado.",
		invitation_not_exist_email: "O e-mail do convite não existe.",
		invitation_not_for_custom_agency:
			"Este convite não é para uma agência alfandegária.",
		invitation_not_for_company: "Este convite não é para uma empresa.",
		invitation_not_found: "Convite não encontrado.",
		invitation_not_valid: "O convite não é mais válido.",
		invalid_current_password: "Sua senha atual é inválida.",
		invalid_identifier_or_password: "Identificador de e-mail ou senha inválido",
		invalid_invitation_code: "Código de convite inválido.",
		invalid_moving_request: "Solicitação de movimentação inválida.",
		invalid_refresh_token: "Token de atualização inválido.",
		invalid_operation: "Operação inválida.",
		invalid_unit: "Unidade inválida.",
		have_no_corporation: "Você não possui uma empresa.",
		have_no_custom_agency: "Você não possui uma agência alfandegária.",
		have_no_employee: "Você não possui funcionários.",
		link_not_be_used_anymore: "Este link não pode mais ser utilizado.",
		link_has_been_expired: "O prazo deste link expirou.",
		missing_required_fields: "Campos obrigatórios devem ser preenchidos.",
		no_employee_found: "Nenhum funcionário encontrado.",
		no_proper_partner_found: "Nenhum parceiro adequado encontrado.",
		not_acceptable: "Não aceitável.",
		not_allowed_action: "Você não tem permissão para executar esta ação.",
		not_allowed_use_api: "Você não tem permissão para usar esta API.",
		not_found: "Não encontrado.",
		not_permitted_use_api: "Você não tem permissão para usar esta API.",
		own_invitation_cannot_accept:
			"Você não pode enviar um convite para si mesmo.",
		parent_group_not_found: "Grupo de produtos não encontrado.",
		partnerId_required: "O campo Partner ID é obrigatório.",
		partnership_already_exists: "A parceria já foi adicionada.",
		password_cant_involve_space_char: "A senha não pode conter espaços.",
		password_min_chars_least: "A senha deve ter pelo menos 6 caracteres.",
		ports_are_required: "As portas são obrigatórias.",
		product_group_tree_not_found:
			"Árvore de grupos de produtos não encontrada.",
		proforma_invoice_action_not_available:
			"A ação não está disponível para o estado atual da fatura pró-forma.",
		proforma_invoice_template_update:
			"A fatura pró-forma deve ser um modelo para ser atualizada.",
		shipment_method_is_required: "O método de envio é obrigatório.",
		shipping_term_is_required: "O termo de envio é obrigatório.",
		shipment_email_not_exist: "Por favor, insira um e-mail válido.",
		someone_else_uses_this_email: "Outra pessoa já está usando este e-mail.",
		someone_else_uses_this_username:
			"Outra pessoa já está usando este nome de usuário.",
		something_went_wrong: "Algo deu errado",
		type_can_be_abstract_company:
			'O "type" pode ser apenas "abstract_company".',
		unauthorized: "Não autorizado",
		undefined_result: "Resultado indefinido.",
		unexpected_error_occurred: "Ocorreu um erro inesperado.",
		unit_already_added: "A unidade já foi adicionada.",
		username_min_chars_error:
			'O "Nome de Usuário" deve ter pelo menos 6 caracteres.',
		user_already_employee: "O usuário já é um funcionário.",
		user_already_exists: "O usuário já faz parte dos funcionários da empresa.",
		user_does_not_exist: "O usuário não existe.",
		user_not_found: "Usuário não encontrado.",
	},
	$shipment: {
		$actionDescription: {
			create: "{name} criou.",
			update: "{name} atualizou.",
			create_container: "{name} adicionou um contêiner.",
			update_container: "{name} atualizou um contêiner.",
			delete_container: "{name} excluiu um contêiner.",
			create_attachment: "{name} criou um anexo.",
			update_attachment: "{name} atualizou um anexo.",
			delete_attachment: "{name} excluiu um anexo.",
			$land: {
				create_container: "{name} adicionou um veículo.",
				update_container: "{name} atualizou um veículo.",
				delete_container: "{name} excluiu um veículo.",
			},
		},
		$containerStatus: {
			reserved: "Reservado",
			on_load: "Carregado",
			on_way: "Em Trânsito",
			arrived: "Chegou",
			off_load: "Descarregado",
		},
		$method: {
			sea: "Marítimo",
			aerial: "Aéreo",
			land: "Terrestre",
			railway: "Ferroviário",
		},
		$vehicle: {
			license_number: "Número da Placa",
		},
		archiving_success_message: "O envio foi arquivado.",
		leave_message: "Deixe sua mensagem ou comentário",
		no_booking_info:
			"Ainda não há informações de reserva definidas. Pressione {0} para adicionar.",
		no_transportation_info:
			"Os detalhes do envio ainda não foram definidos. Pressione {0} para definir.",
		un_archiving_success_message: "O envio foi desarquivado.",
	},
	$shortening: {
		number: "Nº",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Milímetro",
		meter: "Metro",
		kilometer: "Quilômetro",
		milligram: "Miligrama",
		gram: "Grama",
		kilogram: "Quilograma",
		square_meter: "Metro Quadrado",
		hectare: "Hectare",
		square_kilometer: "Quilômetro Quadrado",
		milliliter: "Mililitro",
		liter: "Litro",
		cubic_meter: "Metro Cúbico",
		acres: "Acres",
		feet: "Pés",
		fluid_ounces: "Onças Líquidas",
		gallons: "Galões",
		inches: "Polegadas",
		miles: "Milhas",
		ounces: "Onças",
		pounds: "Libras",
		quarts: "Quartos",
		square_feet: "Pés Quadrados",
		square_miles: "Milhas Quadradas",
		square_yards: "Jardas Quadradas",
		square_inches: "Polegadas Quadradas",
		yards: "Jardas",
		pieces: "Peças",
		boxes: "Caixas",
		pallets: "Paletes",
		dozen: "Dúzia",
		ton: "Tonelada",
	},
	$validation: {
		required: "{item} não pode ficar em branco",
		required_for_default_language:
			"{item} deve ser definido para o idioma padrão da empresa.",
		invalid: "{item} inválido",
		email: "Por favor, insira um e-mail válido",
		min_length: "{item} deve ter pelo menos {length} caracteres",
		max_length: "{item} deve ter no máximo {length} caracteres",
		password_repeat_warning:
			"A repetição da senha deve ser igual à nova senha.",
	},

	accept_invitation_success_message: "Você aceitou o convite com sucesso.",
	accessibility: "Acessibilidade",
	actions: "Ações",
	active: "Ativo",
	add: "Adicionar",
	add_item: "Adicionar {item}",
	address: "Endereço",
	again: "Novamente",
	alert: "Alerta",
	already_have_employee_message: "Você já é um funcionário da empresa.",
	amount: "Valor",
	archive: "Arquivar",
	attachment: "Anexo",
	attachments: "Anexos",

	booking: "Reserva",
	booking_code: "Código de Reserva",
	business_partner: "Parceiro de Negócios",
	business_partners: "Parceiros de Negócios",

	cancel: "Cancelar",
	change_password: "Alterar Senha",
	checking_invitation_code_message: "Verificando código de convite",
	choose_image: "Escolher Imagem",
	clear: "Limpar",
	close: "Fechar",
	code: "Código",
	companies: "Empresas",
	company: "Empresa",
	company_profile: "Perfil da Empresa",
	commercial: "Comercial",
	commercial_partners: "Parceiros Comerciais",
	content: "Conteúdo",
	configuration: "Configuração",
	confirm: "Confirmar",
	contact: "Contato",
	contact_info: "Informações de Contato",
	contact_to_us: "Contate-nos",
	container: "Contêiner",
	containers: "Contêineres",
	content: "Conteúdo",
	countries: "Países",
	country: "País",
	create: "Criar",
	create_company: "Criar Empresa",
	created_at: "Criado em",
	currencies: "Moedas",
	currency: "Moeda",
	current_password: "Senha Atual",
	customs_agency: "Agência Aduaneira",
	customs_agencies: "Agências Aduaneiras",

	date: "Data",
	dashboard: "Painel",
	default: "Padrão",
	default_language: "Idioma Padrão",
	delete: "Excluir",
	delete_confirm_message: "Tem certeza de que deseja excluir?",
	description: "Descrição",
	detail: "Detalhe",
	discard: "Descartar",
	document_number: "Número do Documento",
	download: "Baixar",

	edit: "Editar",
	edit_item: "Editar {item}",
	email: "E-mail",
	employee: "Funcionário",
	employee_type: "Tipo de Funcionário",
	employees: "Funcionários",
	estimated_arrival_day: "Dia Estimado de Chegada",
	expense: "Despesa",
	expenses: "Despesas",
	exported_containers: "Contêineres Exportados",

	faq: "Perguntas Frequentes",
	file: "Arquivo",
	filter: "Filtro",
	first_name: "Nome",
	forget_password: "Esqueci a Senha",
	forget_password_message: "Esqueceu sua senha?",
	from: "De",

	hide: "Ocultar",
	home_page: "Página Inicial",

	imported_containers: "Contêineres Importados",
	incoming: "Entrada",
	incoming_shipments: "Remessas de Entrada",
	initial: "Inicial",
	invitation: "Convite",
	invitation_code: "Código do Convite",
	invitation_mail_sent: "E-mail de convite enviado",
	invitations: "Convites",

	join: "Participar",

	language_options: "Opções de Idioma",
	last_name: "Sobrenome",
	last_one_year: "Último Ano",
	latitude: "Latitude",
	loading_country: "País de Carregamento",
	loading_point: "Ponto de Carregamento",
	loading_port: "Porto de Carregamento",
	logo: "Logotipo",
	longitude: "Longitude",

	go_to_panel: "Ir para o Painel",

	mail_sent: "E-mail enviado",
	migrate: "Migrar",
	migrate_partner: "Migrar Parceiro",
	message: "Mensagem",
	message_company_create: "Empresa criada.",
	message_company_join: "Junte-se à empresa",
	message_company_joined: "Você se juntou à empresa.",
	message_employee_delete: "Funcionário excluído.",
	message_employee_save: "Funcionário salvo.",
	message_join_company:
		"Digite o código de convite para se inscrever em uma empresa.",
	method: "Método",
	mobile_number: "Número de Celular",
	my_role: "Meu Cargo",

	name: "Nome",
	next: "Próximo",
	new: "Novo",
	new_password: "Nova Senha",
	no: "Não",
	no_content: "Sem conteúdo",
	no_text: "Sem texto",
	not_found_page_message: "Desculpe, não consigo encontrar esta página :(",
	note: "Nota",

	ok: "Ok",
	outgoing: "Saída",
	outgoing_shipments: "Remessas de Saída",

	parent_group: "Grupo Pai",
	partner: "Parceiro",
	partners: "Parceiros",
	passive: "Passivo",
	password: "Senha",
	phone_number: "Número de Telefone",
	previous: "Anterior",
	pricing: "Preços",
	product: "Produto",
	product_group: "Grupo de Produto",
	product_groups: "Grupos de Produto",
	products: "Produtos",
	profile: "Perfil",
	proforma_invoice: "Fatura Proforma",
	proforma_invoices: "Faturas Proforma",
	proforma_number: "Número Proforma",
	purchase: "Compra",

	quantity: "Quantidade",

	receiver: "Destinatário",
	receiver_company: "Empresa Destinatária",
	receiver_customs_agency: "Agência Aduaneira Destinatária",
	redirection_to_home_link: "clique aqui",
	redirection_to_home_message: "Para voltar à página inicial, ",
	redirection_to_sign_in_message: "Já tem uma conta?",
	redirection_to_sign_up_message: "Ainda não tem uma conta?",
	reload: "Recarregar",
	reset_password: "Redefinir Senha",

	sales: "Vendas",
	save: "Salvar",
	search: "Buscar",
	select_file: "Selecionar Arquivo",
	select_file_place_holder: "Por favor, escolha uma imagem",
	selected_partner: "Parceiro Selecionado",
	send: "Enviar",
	send_again: "Enviar Novamente",
	send_email: "Enviar E-mail",
	send_invitation: "Enviar Convite",
	sender: "Remetente",
	sender_company: "Empresa Remetente",
	sender_customs_agency: "Agência Aduaneira Remetente",
	show: "Mostrar",
	shipment: "Remessa",
	shipment_method: "Método de Remessa",
	shipments: "Remessas",
	shipments_filter: "Filtro de Remessas",
	shipping_term: "Termo de Remessa",
	shipping_terms: "Termos de Remessa",
	sign_in: "Entrar",
	sign_out: "Sair",
	sign_out_confirm_message: "Tem certeza de que deseja sair?",
	sign_out_success_message: "Até logo.",
	sign_up: "Registrar",
	social_media: "Mídias Sociais",
	social_media_account: "Conta de Mídia Social",
	status: "Status",
	subscribe: "Assinar",
	super: "Super",
	switch_language: "Trocar Idioma",
	switch_theme: "Trocar Tema",

	target: "Alvo",
	target_partner: "Parceiro Alvo",
	theme: "Tema",
	this_month: "Este Mês",
	this_year: "Este Ano",
	title: "Título",
	to: "Para",
	toDelete: "Excluir",
	total: "Total",
	total_amount: "Valor Total",
	type: "Tipo",
	types: "Tipos",

	un_archive: "Desarquivar",
	unit: "Unidade",
	units: "Unidades",
	unloading_country: "País de Descarregamento",
	unloading_point: "Ponto de Descarregamento",
	unloading_port: "Porto de Descarregamento",
	use_code: "Usar Código",
	use_invitation_code: "Usar Código de Convite",
	use_invitation_code_description:
		"Digite o código de convite enviado para sua caixa de entrada de e-mail.",
	use_invitation_code_success_message: "Código de convite usado com sucesso.",
	user_profile: "Perfil do Usuário",

	vehicle: "Veículo",
	vehicles: "Veículos",
	visibility: "Visibilidade",

	warehouse: "Armazém",
	warehouses: "Armazéns",
	website: "Site",
	welcome: "Bem-vindo",

	yes: "Sim",
};

export default {
	$accessibility: {
		present: "جاري",
		archived: "تم الأرشفة",
	},
	$accessModifier: {
		$description: {
			private: "خاص",
			hidden_from_receiver: "مخفي عن المستلم",
			hidden_from_sender: "مخفي عن الراسل",
			shared_with_everyone: "مشترك مع الجميع",
			shared_with_custom: "مشترك مع مخصص",
			shared_with_receiver: "مشترك مع المستلم",
			shared_with_sender: "مشترك مع الراسل",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "أندورا",
		united_arab_emirates: "الإمارات العربية المتحدة",
		afghanistan: "أفغانستان",
		antigua_and_barbuda: "أنتيغوا وبربودا",
		anguilla: "أنغويلا",
		albania: "ألبانيا",
		armenia: "أرمينيا",
		angola: "أنغولا",
		antarctica: "القارة القطبية الجنوبية",
		argentina: "الأرجنتين",
		american_samoa: "ساموا الأمريكية",
		austria: "النمسا",
		australia: "أستراليا",
		aruba: "أروبا",
		aland: "جزر آلاند",
		azerbaijan: "أذربيجان",
		bosnia_and_herzegovina: "البوسنة والهرسك",
		barbados: "باربادوس",
		bangladesh: "بنغلاديش",
		belgium: "بلجيكا",
		burkina_faso: "بوركينا فاسو",
		bulgaria: "بلغاريا",
		bahrain: "البحرين",
		burundi: "بوروندي",
		benin: "بنين",
		saint_barthelemy: "سانت بارتيليمي",
		bermuda: "برمودا",
		brunei: "بروناي",
		bolivia: "بوليفيا",
		bonaire_sint_eustatius_and_saba: "بونير، سينت أيوستاتيوس وسابا",
		brazil: "البرازيل",
		bahamas: "الباهاما",
		bhutan: "بوتان",
		bouvet_island: "جزيرة بوفيه",
		botswana: "بتسوانا",
		belarus: "روسيا البيضاء",
		belize: "بليز",
		canada: "كندا",
		cocos_islands: "جزر كوكوس",
		dr_congo: "جمهورية الكونغو الديمقراطية",
		central_african_republic: "جمهورية أفريقيا الوسطى",
		congo_republic: "الكونغو - برازافيل",
		switzerland: "سويسرا",
		ivory_coast: "ساحل العاج",
		cook_islands: "جزر كوك",
		chile: "تشيلي",
		cameroon: "الكاميرون",
		china: "الصين",
		colombia: "كولومبيا",
		costa_rica: "كوستاريكا",
		cuba: "كوبا",
		cabo_verde: "الرأس الأخضر",
		curacao: "كوراساو",
		christmas_island: "جزيرة الكريسماس",
		cyprus: "قبرص",
		czechia: "التشيك",
		germany: "ألمانيا",
		djibouti: "جيبوتي",
		denmark: "الدنمارك",
		dominica: "دومينيكا",
		dominican_republic: "الجمهورية الدومينيكية",
		algeria: "الجزائر",
		ecuador: "الإكوادور",
		estonia: "إستونيا",
		egypt: "مصر",
		western_sahara: "الصحراء الغربية",
		eritrea: "إريتريا",
		spain: "إسبانيا",
		ethiopia: "إثيوبيا",
		finland: "فنلندا",
		fiji: "فيجي",
		falkland_islands: "جزر فوكلاند",
		micronesia: "ولايات ميكرونيسيا المتحدة",
		faroe_islands: "جزر فارو",
		france: "فرنسا",
		gabon: "الغابون",
		united_kingdom: "المملكة المتحدة",
		grenada: "غرينادا",
		georgia: "جورجيا",
		french_guiana: "غويانا الفرنسية",
		guernsey: "جيرنزي",
		ghana: "غانا",
		gibraltar: "جبل طارق",
		greenland: "غرينلاند",
		gambia: "غامبيا",
		guinea: "غينيا",
		guadeloupe: "جوادلوب",
		equatorial_guinea: "غينيا الاستوائية",
		greece: "اليونان",
		south_georgia_and_south_sandwich_islands:
			"جورجيا الجنوبية وجزر ساندويتش الجنوبية",
		guatemala: "غواتيمالا",
		guam: "غوام",
		guinea_bissau: "غينيا بيساو",
		guyana: "غيانا",
		hong_kong: "هونج كونج",
		heard_island_and_mcdonald_islands: "جزيرة هيرد وجزر ماكدونالد",
		honduras: "هندوراس",
		croatia: "كرواتيا",
		haiti: "هايتي",
		hungary: "هنغاريا",
		indonesia: "إندونيسيا",
		ireland: "أيرلندا",
		israel: "إسرائيل",
		isle_of_man: "جزيرة مان",
		india: "الهند",
		british_indian_ocean_territory: "إقليم المحيط الهندي البريطاني",
		iraq: "العراق",
		iran: "إيران",
		iceland: "آيسلندا",
		italy: "إيطاليا",
		jersey: "جيرسي",
		jamaica: "جامايكا",
		jordan: "الأردن",
		japan: "اليابان",
		kenya: "كينيا",
		kyrgyzstan: "قيرغيزستان",
		cambodia: "كمبوديا",
		kiribati: "كيريباتي",
		comoros: "جزر القمر",
		st_kitts_and_nevis: "سانت كيتس ونيفيس",
		north_korea: "كوريا الشمالية",
		south_korea: "كوريا الجنوبية",
		kuwait: "الكويت",
		cayman_islands: "جزر الكايمان",
		kazakhstan: "كازاخستان",
		laos: "لاوس",
		lebanon: "لبنان",
		saint_lucia: "سانت لوسيا",
		liechtenstein: "ليختنشتاين",
		sri_lanka: "سريلانكا",
		liberia: "ليبيريا",
		lesotho: "ليسوتو",
		lithuania: "ليتوانيا",
		luxembourg: "لوكسمبورغ",
		latvia: "لاتفيا",
		libya: "ليبيا",
		morocco: "المغرب",
		monaco: "موناكو",
		moldova: "مولدوفا",
		montenegro: "الجبل الأسود",
		saint_martin: "سانت مارتن",
		madagascar: "مدغشقر",
		marshall_islands: "جزر مارشال",
		north_macedonia: "شمال مقدونيا",
		mali: "مالي",
		myanmar: "ميانمار",
		mongolia: "منغوليا",
		macao: "ماكاو",
		northern_mariana_islands: "جزر ماريانا الشمالية",
		martinique: "مارتينيك",
		mauritania: "موريتانيا",
		montserrat: "مونتسرات",
		malta: "مالطا",
		mauritius: "موريشيوس",
		maldives: "المالديف",
		malawi: "ملاوي",
		mexico: "المكسيك",
		malaysia: "ماليزيا",
		mozambique: "موزمبيق",
		namibia: "ناميبيا",
		new_caledonia: "كاليدونيا الجديدة",
		niger: "النيجر",
		norfolk_island: "جزيرة نورفولك",
		nigeria: "نيجيريا",
		nicaragua: "نيكاراغوا",
		netherlands: "هولندا",
		norway: "النرويج",
		nepal: "نيبال",
		nauru: "ناورو",
		niue: "نييو",
		new_zealand: "نيوزيلندا",
		oman: "عمان",
		panama: "بنما",
		peru: "بيرو",
		french_polynesia: "بولينزيا الفرنسية",
		papua_new_guinea: "بابوا غينيا الجديدة",
		philippines: "الفلبين",
		pakistan: "باكستان",
		poland: "بولندا",
		saint_pierre_and_miquelon: "سان بيير وميكلون",
		pitcairn_islands: "جزر بيتكيرن",
		puerto_rico: "بورتوريكو",
		palestine: "فلسطين",
		portugal: "البرتغال",
		palau: "بالاو",
		paraguay: "باراغواي",
		qatar: "قطر",
		reunion: "لا ريونيون",
		romania: "رومانيا",
		serbia: "صربيا",
		russia: "روسيا",
		rwanda: "رواندا",
		saudi_arabia: "المملكة العربية السعودية",
		solomon_islands: "جزر سليمان",
		seychelles: "سيشل",
		sudan: "السودان",
		sweden: "السويد",
		singapore: "سنغافورة",
		saint_helena: "سانت هيلينا",
		slovenia: "سلوفينيا",
		svalbard_and_jan_mayen: "سفالبارد ويان ماين",
		slovakia: "سلوفاكيا",
		sierra_leone: "سيراليون",
		san_marino: "سان مارينو",
		senegal: "السنغال",
		somalia: "الصومال",
		suriname: "سورينام",
		south_sudan: "جنوب السودان",
		sao_tome_and_principe: "ساو تومي وبرينسيب",
		el_salvador: "السلفادور",
		sint_maarten: "سانت مارتن",
		syria: "سوريا",
		eswatini: "إسواتيني",
		turks_and_caicos_islands: "جزر تركس وكايكوس",
		chad: "تشاد",
		french_southern_territories: "المقاطعات الجنوبية الفرنسية",
		togo: "توغو",
		thailand: "تايلاند",
		tajikistan: "طاجيكستان",
		tokelau: "توكيلاو",
		timor_leste: "تيمور الشرقية",
		turkmenistan: "تركمانستان",
		tunisia: "تونس",
		tonga: "تونغا",
		turkiye: "تركيا",
		trinidad_and_tobago: "ترينيداد وتوباغو",
		tuvalu: "توفالو",
		taiwan: "جمهورية الصين",
		tanzania: "تنزانيا",
		ukraine: "أوكرانيا",
		uganda: "أوغندا",
		us_minor_outlying_islands: "جزر الولايات المتحدة الصغيرة النائية",
		united_states: "الولايات المتحدة الأمريكية",
		uruguay: "الأوروغواي",
		uzbekistan: "أوزبكستان",
		vatican_city: "الفاتيكان",
		st_vincent_and_grenadines: "سانت فنسنت وجزر غرينادين",
		venezuela: "فنزويلا",
		british_virgin_islands: "جزر العذراء البريطانية",
		us_virgin_islands: "جزر العذراء الأمريكية",
		vietnam: "فيتنام",
		vanuatu: "فانواتو",
		wallis_and_futuna: "والس وفوتونا",
		samoa: "ساموا",
		kosovo: "كوسوفو",
		yemen: "اليمن",
		mayotte: "مايوت",
		south_africa: "جنوب أفريقيا",
		zambia: "زامبيا",
		zimbabwe: "زيمبابوي",
	},
	$currency: {
		us_dollar: "الدولار الأمريكي",
		euro: "اليورو",
		turkish_lira: "الليرة التركية",
	},
	$employeeType: {
		admin: "المدير",
		default: "عادي",
		exporter: "مصدّر",
		importer: "مستورد",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "مرحبًا بك في أنتريغو",
			description: "تحكم في عمليات الاستيراد والتصدير الخاصة بك مع أنتريغو!",
			sign_in_title: "هل لديك حساب؟",
			sign_in_subtitle: "قم بتسجيل الدخول هنا",
			sign_up_title: "هل ترغب في إنشاء حساب؟",
			sign_up_subtitle: "قم بالتسجيل هنا",
		},
		$noCompany: {
			title: "مرحبًا {name}",
			description:
				"لقد قمت بتسجيل الدخول بنجاح. يجب عليك الآن الانضمام إلى شركة أو إنشاء شركتك الخاصة.",
			join_company_title: "هل ترغب في الانضمام إلى شركة موجودة؟",
			join_company_subtitle: "أدخل رمز الدعوة الخاص بك هنا وانضم إلى الشركة.",
			create_company_title: "هل ترغب في إنشاء شركتك؟",
			create_company_subtitle: "أنشئ شركة جديدة من هنا.",
		},
	},
	$invitationType: {
		employee: "دعوة الموظف",
		company_partner: "دعوة للتعاون التجاري",
		customs_partner: "دعوة الجمارك",
	},
	$landing: {
		$question: {
			$title: {
				0: "ما هو أنتريغو؟",
				1: "كيف يمكن لأنتريغو أن تساعد شركتي في عملية الشحن؟",
				2: "ما هي أنواع اللوجستيات التي يدعمها أنتريغو؟",
				3: "لماذا يجب علي استخدام أنتريغو؟",
				4: "هل أنتريغو تطبيق مدفوع؟",
				5: "هل يجب على عملائنا دفع رسوم لاستخدام أنتريغو؟",
			},
			$text: {
				0: "<p>برنامج تتبع الشحن الذي يساعد شركات الشحن وعملائها على تتبع المراحل المشتركة أثناء مرحلة التتبع، ويمنع الخسائر التي قد تحدث بسبب التأخير في هذه المرحلة.</p>",
				1: `<p>- شارك جميع التحديثات الفورية مع عميلك. زيادة جودة الخدمة.<br>
            - راجع وشارك جميع المستندات المتعلقة بالشحن في نظام واحد مركزي.<br>
            - تتبع جميع عمليات الشحن من مكان واحد. احفظ على نظام رصد مالي ديناميكي.</p>`,
				2: `<p>- بحر<br>
            - جو<br>
            - بر<br>
            - قطار</p>`,
				3: `<p>يسهل الوصول إلى جميع الخدمات المقدمة لتبسيط عمليات الشحن.<br>
            <strong>1. سهولة الوصول</strong><br>
            يمكن الوصول إليها عبر المتصفح من جميع الأجهزة وفي أي مكان.<br>
            <strong>2. أمان البيانات</strong><br>
            يتم تخزين جميع البيانات باستخدام تقنية التشفير SHA-256.<br>
            <strong>3. تتبع مشترك</strong><br>
            يوفر إمكانية تتبع لجميع الأطراف المعنية في العملية التجارية.</p>`,
				4: "<p>نعم، إنه تطبيق مدفوع. نحن نرغب في تبسيط عملياتك من خلال نقل جميع عمليات الشحن الخاصة بك إلى الرقمي وتقديم تحسينات مفيدة لك. لضمان استمرار تحسيناتنا وتوفير المساحة على الخادم التي تستخدمها، نطلب منك دفع رسوم شهرية.</p>",
				5: "<p>لا، لا يتعين على عملائك دفع أي رسوم أثناء تتبعهم لشحناتك.</p>",
			},
		},
		$lifecycle: {
			headline: "جميع العمليات في متناول يدك",
			$title: {
				0: "سجل",
				1: "أرسل الفاتورة التمهيدية",
				2: "إنشاء شحنة",
				3: "تتبع الأنشطة المالية",
			},
			$text: {
				0: "أدخل معلومات الشركة والمنتجات وقم بإنشاء عملائك.",
				1: "أنشئ فاتورة تمهيدية وشاركها مع عملائك للموافقة.",
				2: "أدخل معلومات شحنتك وأرفق المستندات. قم بإدارة جميع عمليات شحنك مع عملائك من خلال قناة واحدة.",
				3: "تتبع جميع الأنشطة المالية المتعلقة بالاستيراد والتصدير بشكل فوري.",
			},
		},
		$content: {
			headline: "أنواع اللوجستيات المدعومة",
			$title: {
				0: "بحر",
				1: "جو",
				2: "بر",
				3: "قطار",
			},
		},
		$pricing: {
			0: {
				title: "مجاني",
				subtitle: "فردي",
				features: ["1 مستخدم", "3 شركاء", "10 شحنات", "10 فواتير"],
			},
			1: {
				title: "سنوي",
				subtitle: "الشركات",
				features: ["20 مستخدم", "100 شريك", "1000 شحنة", "1000 فاتورة"],
			},
			2: {
				title: "شهري",
				subtitle: "الأعمال الصغيرة",
				features: ["10 مستخدمين", "30 شريك", "250 شحنة", "250 فاتورة"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "هل أنت متأكد أنك تريد {function}؟",
		created: "{item} تم إنشاؤه.",
		deleted: "{item} تم حذفه.",
		file_oversize: "يجب أن يكون حجم الملف أقل من {size}.",
		invalid: "{item} غير صالح",
		sent_email: "تم إرسال البريد الإلكتروني. ({value})",
		sign_in_failed: "البريد الإلكتروني أو كلمة المرور غير صحيحة",
		updated: "{item} تم تحديثه.",
		migrated: "{item} تم نقله. ({value})",
	},
	$productGroup: {
		add_child: "إضافة فرع",
		add_to_root: "إضافة إلى الجذر",
		has_children_fail_message:
			"لا يمكن حذف مجموعة المنتجات التي تحتوي على أقسام فرعية",
		initial_message: "اضغط على {0} لإضافة أول مجموعة منتجات لديك.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "تم إنشاءها بواسطة {name}.",
			update: "تم تحديثها بواسطة {name}.",
			send: "أرسلها {name} للموافقة.",
			revoke: "قام {name} بالسحب.",
			approve: "وافق {name}.",
			demand_revise: "طلب {name} تعديل.",
			reject: "رفضها {name}.",
			mark_as_processed: "وضع علامة {name} كمعالجة.",
			create_attachment: "قام {name} بإنشاء مرفق.",
			update_attachment: "قام {name} بتحديث المرفق.",
			delete_attachment: "قام {name} بحذف المرفق.",
		},
		$actionDescription2: {
			send: 'وُضعت علامة {name} بأنها "تم الإرسال".',
			revoke: 'وُضعت علامة {name} بأنها "تم السحب".',
			approve: 'وُضعت علامة {name} بأنها "تمت الموافقة".',
			demand_revise: 'وُضعت علامة {name} بأنها "تحتاج لتعديل".',
			reject: "تم رفض {name}.",
			mark_as_processed: 'وُضعت علامة {name} بأنها "تمت المعالجة".',
		},
		$actions: {
			approve: "الموافقة",
			demand_revise: "طلب تعديل",
			mark_as_approved: "تمت الموافقة",
			mark_as_processed: "تمت المعالجة",
			mark_as_rejected: "تم الرفض",
			mark_as_revise_demanded: "تم طلب التعديل",
			mark_as_revoked: "تم السحب",
			mark_as_sent: "تم الإرسال",
			reject: "الرفض",
			revoke: "السحب",
			send: "الإرسال",
		},
		$status: {
			approved: "تمت الموافقة عليها",
			pending_approval: "في انتظار الموافقة",
			pending_revise: "في انتظار التعديل",
			processed: "تمت المعالجة",
			rejected: "تم الرفض",
			revoked: "تم السحب",
			template: "قالب",
		},
		send_confirm_message: "هل ترغب حقًا في إرسال فاتورة البضائع إلى المستلم؟",
		send_success_message: "تم إرسال فاتورة البضائع بنجاح إلى المستلم.",
		approve_confirm_message:
			"هل أنت متأكد أنك تريد الموافقة على فاتورة البضائع؟",
		approve_success_message: "تمت الموافقة على فاتورة البضائع بنجاح.",
		demand_revise_success_message: "تم إرجاع فاتورة البضائع بنجاح للتعديل.",
		reject_confirm_message: "هل أنت متأكد أنك تريد رفض فاتورة البضائع؟",
		reject_success_message: "تم رفض فاتورة البضائع بنجاح.",
		mark_as_processed_confirm_message:
			"هل ترغب حقًا في وضع علامة على فاتورة البضائع كمعالجة؟",
		mark_as_processed_success_message:
			"تم وضع علامة على فاتورة البضائع كمعالجة بنجاح.",
		revoke_confirm_message: "هل أنت متأكد أنك تريد سحب فاتورة البضائع؟",
		revoke_success_message: "تم سحب فاتورة البضائع بنجاح.",
		demand_revise_description_message: "يرجى شرح التعديل المطلوب:",
		no_attachment_text: "لم يتم تحميل أي مرفق حتى الآن.",
	},
	$serviceMessage: {
		already_subs_module: "هناك بالفعل اشتراك لهذه الوحدة.",
		bad_request: "طلب غير صالح",
		code_already_used: "الرمز مستخدم بالفعل.",
		container_status_not_available: "حالة الحاوية غير متاحة.",
		existing_email_warning: "البريد الإلكتروني موجود بالفعل.",
		existing_employee_error: "تمت إضافة هذا المستخدم إلى شركتك بالفعل.",
		existing_employee_for_email_warning:
			"تم بالفعل إرسال رمز الدعوة إلى هذا البريد الإلكتروني.",
		existing_invitation_warning:
			"تم بالفعل إرسال رمز الدعوة إلى هذا البريد الإلكتروني.",
		existing_token_already_have_time:
			"الرمز الحالي لديه بالفعل وقت كافٍ للاستخدام.",
		existing_user: "المستخدم موجود بالفعل.",
		five_min_reset_password_mail:
			"يجب الانتظار لمدة 5 دقائق على الأقل بعد طلب بريد إعادة تعيين كلمة المرور مرة واحدة.",
		forbidden: "ممنوع",
		internal_server_error: "خطأ في الخادم الداخلي.",
		instance_can_no_delete_existing_booking_code:
			"لا يمكن حذف العنصر بسبب وجود رمز حجز حالي.",
		instance_cannot_delete: "لا يمكن حذف العنصر بعد الآن.",
		instance_not_found: "العنصر غير موجود.",
		invitation_accepting_is_due_to_the_admin: "قبول الدعوة يعتمد على المسؤول.",
		invitation_already_discarded: "تم رفض الدعوة بالفعل.",
		invitation_already_used: "تم استخدام الدعوة بالفعل.",
		invitation_has_been_used: "تم استخدام الدعوة.",
		invitation_not_exist_email: "الدعوة غير موجودة لهذا البريد الإلكتروني.",
		invitation_not_for_custom_agency: "هذه الدعوة ليست لوكالة الجمارك.",
		invitation_not_for_company: "هذه الدعوة ليست للشركة.",
		invitation_not_found: "الدعوة غير موجودة.",
		invitation_not_valid: "الدعوة لم تعد صالحة.",
		invalid_current_password: "كلمة المرور الحالية غير صحيحة.",
		invalid_identifier_or_password:
			"البريد الإلكتروني أو كلمة المرور غير صالحة",
		invalid_invitation_code: "رمز الدعوة غير صالح.",
		invalid_moving_request: "طلب نقل غير صالح.",
		invalid_refresh_token: "رمز التحديث غير صالح.",
		invalid_operation: "عملية غير صالحة.",
		invalid_unit: "وحدة غير صالحة.",
		have_no_corporation: "ليس لديك شركة.",
		have_no_custom_agency: "ليس لديك وكالة جمارك.",
		have_no_employee: "ليس لديك موظف.",
		link_not_be_used_anymore: "لا يمكن استخدام هذا الرابط بعد الآن.",
		link_has_been_expired: "انتهت صلاحية الرابط.",
		missing_required_fields: "يجب ملء الحقول المطلوبة.",
		no_employee_found: "لم يتم العثور على الموظف.",
		no_proper_partner_found: "لم يتم العثور على شريك مناسب.",
		not_acceptable: "غير مقبول.",
		not_allowed_action: "لا يُسمح بهذا الإجراء.",
		not_allowed_use_api: "لا يُسمح باستخدام هذا الواجهة البرمجية.",
		not_found: "غير موجود.",
		not_permitted_use_api: "لا يُسمح باستخدام هذا الواجهة البرمجية",
		own_invitation_cannot_accept: "لا يمكنك إرسال دعوة لنفسك.",
		parent_group_not_found: "لم يتم العثور على مجموعة المنتجات الرئيسية.",
		partnerId_required: "مطلوب معرف الشريك.",
		partnership_already_exists: "الشراكة تمت إضافتها بالفعل.",
		password_cant_involve_space_char:
			"كلمة المرور لا يمكن أن تحتوي على مسافات.",
		password_min_chars_least: "يجب أن تحتوي كلمة المرور على الأقل على 6 أحرف.",
		ports_are_required: "المنافذ مطلوبة.",
		product_group_tree_not_found: "لم يتم العثور على شجرة مجموعة المنتجات.",
		proforma_invoice_action_not_available:
			"الحالة الحالية لفاتورة البضائع غير متاحة لهذا الإجراء.",
		proforma_invoice_template_update:
			"يجب أن تكون فاتورة البضائع قالبًا لتحديثها.",
		shipment_method_is_required: "طريقة الشحن مطلوبة.",
		shipping_term_is_required: "شروط الشحن مطلوبة.",
		shipment_email_not_exist: "الرجاء إدخال عنوان بريد إلكتروني صحيح",
		someone_else_uses_this_email:
			"هذا البريد الإلكتروني يُستخدم بالفعل من قبل شخص آخر.",
		someone_else_uses_this_username:
			"هذا اسم المستخدم يُستخدم بالفعل من قبل شخص آخر.",
		something_went_wrong: "حدث خطأ ما",
		type_can_be_abstract_company: 'يمكن أن يكون "النوع" فقط "شركة مجردة".',
		unauthorized: "غير مصرح به",
		undefined_result: "نتيجة غير معرفة.",
		unexpected_error_occurred: "حدث خطأ غير متوقع.",
		unit_already_added: "تمت إضافة الوحدة بالفعل.",
		username_min_chars_error: 'يجب أن يكون "اسم المستخدم" على الأقل 6 أحرف.',
		user_already_employee: "المستخدم بالفعل موظف.",
		user_already_exists: "المستخدم بالفعل موظف في إحدى الشركات.",
		user_does_not_exist: "المستخدم غير موجود.",
		user_not_found: "المستخدم غير موجود.",
	},
	$shipment: {
		$actionDescription: {
			create: "تم إنشاؤها بواسطة {name}.",
			update: "تم تحديثها بواسطة {name}.",
			create_container: "أضاف {name} حاوية.",
			update_container: "قام {name} بتحديث الحاوية.",
			delete_container: "قام {name} بحذف الحاوية.",
			create_attachment: "أضاف {name} مرفقًا.",
			update_attachment: "قام {name} بتحديث المرفق.",
			delete_attachment: "قام {name} بحذف المرفق.",
			$land: {
				create_container: "أضاف {name} مركبة.",
				update_container: "قام {name} بتحديث المركبة.",
				delete_container: "قام {name} بحذف المركبة.",
			},
		},
		$containerStatus: {
			reserved: "محجوز",
			on_load: "تم التحميل",
			on_way: "في الطريق",
			arrived: "وصل",
			off_load: "تم التفريغ",
		},
		$method: {
			sea: "بحري",
			aerial: "جوي",
			land: "بري",
			railway: "سكك حديدية",
		},
		$vehicle: {
			license_number: "رقم اللوحة",
		},
		archiving_success_message: "تم أرشفة الشحنة بنجاح.",
		leave_message: "اترك رسالتك أو تعليقك",
		no_booking_info: "لم يتم تحديد معلومات الحجز بعد. انقر على {0} للإضافة.",
		no_transportation_info:
			"لم تتم تحديد تفاصيل الشحن لحجز الحمولة بعد. انقر على {0} للتحديد.",
		un_archiving_success_message: "تمت إزالة الشحنة من الأرشيف بنجاح.",
	},
	$shortening: {
		number: "لا",
	},
	$socialMedia: {
		facebook: "فيسبوك",
		instagram: "إنستجرام",
		linked_in: "لينكد إن",
		twitter: "تويتر",
	},
	$unit: {
		millimeter: "مليمتر",
		meter: "متر",
		kilometer: "كيلومتر",
		milligram: "مليجرام",
		gram: "جرام",
		kilogram: "كيلوجرام",
		square_meter: "متر مربع",
		hectare: "هكتار",
		square_kilometer: "كيلومتر مربع",
		milliliter: "مليلتر",
		liter: "لتر",
		cubic_meter: "متر مكعب",
		acres: "فدان",
		feet: "قدم",
		fluid_ounces: "أونصة سائلة",
		gallons: "غالون",
		inches: "بوصة",
		miles: "ميل",
		ounces: "أونصة",
		pounds: "جنيه",
		quarts: "كوارت",
		square_feet: "قدم مربع",
		square_miles: "ميل مربع",
		square_yards: "ياردة مربعة",
		square_inches: "بوصة مربعة",
		yards: "ياردة",
		pieces: "قطعة",
		boxes: "صناديق",
		pallets: "بالتات",
		dozen: "دزينة",
		ton: "طن",
	},
	$validation: {
		required: "{item} لا يمكن تركه فارغًا",
		required_for_default_language:
			"يجب أن تكون معلومات {item} معرفة للغة الافتراضية للشركة.",
		invalid: "{item} غير صالح",
		email: "الرجاء إدخال عنوان بريد إلكتروني صالح",
		min_length: "{item} يجب أن يكون على الأقل {length} حرفًا",
		max_length: "{item} يجب أن لا يتجاوز {length} حرفًا",
		password_repeat_warning:
			"يجب أن تتطابق تكرار كلمة المرور مع الكلمة الجديدة.",
	},

	accept_invitation_success_message: "تم قبول الدعوة بنجاح.",
	accessibility: "إمكانية الوصول",
	actions: "الإجراءات",
	active: "نشط",
	add: "إضافة",
	add_item: "إضافة {item}",
	address: "عنوان",
	again: "مرة أخرى",
	alert: "تنبيه",
	already_have_employee_message: "أنت بالفعل موظف في شركة.",
	amount: "المبلغ",
	archive: "أرشفة",
	attachment: "مرفق",
	attachments: "مرفقات",

	booking: "الحجز",
	booking_code: "رمز الحجز",
	business_partner: "شريك الأعمال",
	business_partners: "شركاء الأعمال",

	cancel: "إلغاء",
	change_password: "تغيير كلمة المرور",
	checking_invitation_code_message: "جاري التحقق من رمز الدعوة",
	choose_image: "اختر صورة",
	clear: "مسح",
	close: "إغلاق",
	code: "الكود",
	companies: "الشركات",
	company: "الشركة",
	company_profile: "ملف الشركة",
	commercial: "تجاري",
	commercial_partners: "شركاء تجاريون",
	content: "المحتوى",
	configuration: "التكوين",
	confirm: "تأكيد",
	contact: "اتصال",
	contact_info: "معلومات الاتصال",
	contact_to_us: "تواصل معنا",
	container: "حاوية",
	containers: "حاويات",
	content: "المحتوى",
	countries: "الدول",
	country: "البلد",
	create: "إنشاء",
	create_company: "إنشاء شركة",
	created_at: "تاريخ الإنشاء",
	currencies: "العملات",
	currency: "العملة",
	current_password: "كلمة المرور الحالية",
	customs_agency: "وكالة الجمارك",
	customs_agencies: "وكالات الجمارك",

	date: "التاريخ",
	dashboard: "لوحة التحكم",
	default: "افتراضي",
	default_language: "اللغة الافتراضية",
	delete: "حذف",
	delete_confirm_message: "هل تريد تأكيد عملية الحذف؟",
	description: "الوصف",
	detail: "التفاصيل",
	discard: "تجاهل",
	document_number: "رقم المستند",
	download: "تنزيل",

	edit: "تعديل",
	edit_item: "تعديل {item}",
	email: "البريد الإلكتروني",
	employee: "الموظف",
	employee_type: "نوع الموظف",
	employees: "الموظفون",
	estimated_arrival_day: "اليوم المتوقع للوصول",
	expense: "التكلفة",
	expenses: "التكاليف",
	exported_containers: "حاويات الصادر",

	faq: "الأسئلة الشائعة",
	file: "ملف",
	filter: "تصفية",
	first_name: "الاسم الأول",
	forget_password: "نسيت كلمة المرور",
	forget_password_message: "هل نسيت كلمة المرور؟",
	from: "من",

	hide: "إخفاء",
	home_page: "الصفحة الرئيسية",

	imported_containers: "حاويات الوارد",
	incoming: "الوارد",
	incoming_shipments: "الشحنات الواردة",
	initial: "ابتدائي",
	invitation: "دعوة",
	invitation_code: "رمز الدعوة",
	invitation_mail_sent: "تم إرسال رسالة الدعوة",
	invitations: "الدعوات",

	join: "الانضمام",

	language_options: "خيارات اللغة",
	last_name: "الاسم الأخير",
	last_one_year: "السنة الأخيرة",
	latitude: "خط العرض",
	loading_country: "بلد التحميل",
	loading_point: "نقطة التحميل",
	loading_port: "ميناء التحميل",
	logo: "شعار",
	longitude: "خط الطول",

	go_to_panel: "الانتقال إلى اللوحة",

	mail_sent: "تم إرسال البريد",
	migrate: "نقل",
	migrate_partner: "نقل الشريك",
	message: "رسالة",
	message_company_create: "تم إنشاء الشركة.",
	message_company_join: "الانضمام إلى الشركة",
	message_company_joined: "لقد انضممت إلى الشركة.",
	message_employee_delete: "تم حذف الموظف.",
	message_employee_save: "تم حفظ الموظف.",
	message_join_company: "يرجى إدخال رمز الدعوة للانضمام إلى الشركة.",
	method: "الطريقة",
	mobile_number: "رقم الجوال",
	my_role: "دوري",

	name: "الاسم",
	next: "التالي",
	new: "جديد",
	new_password: "كلمة المرور الجديدة",
	no: "لا",
	no_content: "لا يوجد محتوى",
	no_text: "لا يوجد نص",
	not_found_page_message: "عذرًا، لا يمكن العثور على هذه الصفحة :(",
	note: "ملاحظة",

	ok: "موافق",
	outgoing: "الصادر",
	outgoing_shipments: "الشحنات الصادرة",

	parent_group: "المجموعة الأصلية",
	partner: "الشريك",
	partners: "الشركاء",
	passive: "غير نشط",
	password: "كلمة المرور",
	phone_number: "رقم الهاتف",
	previous: "السابق",
	pricing: "التسعير",
	product: "المنتج",
	product_group: "مجموعة المنتج",
	product_groups: "مجموعات المنتجات",
	products: "المنتجات",
	profile: "الملف الشخصي",
	proforma_invoice: "فاتورة أولية",
	proforma_invoices: "فواتير أولية",
	proforma_number: "رقم الفاتورة الأولية",
	purchase: "الشراء",

	quantity: "الكمية",

	receiver: "المستلم",
	receiver_company: "شركة المستلم",
	receiver_customs_agency: "وكالة الجمارك للمستلم",
	redirection_to_home_link: "انقر هنا",
	redirection_to_home_message: "للعودة إلى الصفحة الرئيسية، انقر ",
	redirection_to_sign_in_message: "هل لديك حساب؟",
	redirection_to_sign_up_message: "ليس لديك حساب؟",
	reload: "إعادة تحميل",
	reset_password: "إعادة تعيين كلمة المرور",

	sales: "المبيعات",
	save: "حفظ",
	search: "بحث",
	select_file: "اختر ملفًا",
	select_file_place_holder: "يرجى اختيار صورة",
	selected_partner: "الشريك المحدد",
	send: "إرسال",
	send_again: "إعادة الإرسال",
	send_email: "إرسال بريد إلكتروني",
	send_invitation: "إرسال دعوة",
	sender: "المرسل",
	sender_company: "شركة المرسل",
	sender_customs_agency: "وكالة الجمارك للمرسل",
	show: "عرض",
	shipment: "الشحن",
	shipment_method: "طريقة الشحن",
	shipments: "الشحنات",
	shipments_filter: "تصفية الشحنات",
	shipping_term: "شروط الشحن",
	shipping_terms: "شروط الشحن",
	sign_in: "تسجيل الدخول",
	sign_out: "تسجيل الخروج",
	sign_out_confirm_message: "هل ترغب في تأكيد تسجيل الخروج؟",
	sign_out_success_message: "وداعًا.",
	sign_up: "التسجيل",
	social_media: "وسائل التواصل الاجتماعي",
	social_media_account: "حساب وسائل التواصل الاجتماعي",
	status: "الحالة",
	subscribe: "الاشتراك",
	super: "سوبر",
	switch_language: "تغيير اللغة",
	switch_theme: "تغيير السمة",

	target: "الهدف",
	target_partner: "الشريك المستهدف",
	theme: "السمة",
	this_month: "هذا الشهر",
	this_year: "هذا العام",
	title: "العنوان",
	to: "إلى",
	toDelete: "حذف",
	total: "الإجمالي",
	total_amount: "المبلغ الإجمالي",
	type: "النوع",
	types: "الأنواع",

	un_archive: "إلغاء الأرشفة",
	unit: "الوحدة",
	units: "الوحدات",
	unloading_country: "بلد التفريغ",
	unloading_point: "نقطة التفريغ",
	unloading_port: "ميناء التفريغ",
	use_code: "استخدم الرمز",
	use_invitation_code: "استخدام رمز الدعوة",
	use_invitation_code_description:
		"أدخل رمز الدعوة الذي تلقيته في بريدك الإلكتروني.",
	use_invitation_code_success_message: "تم استخدام رمز الدعوة بنجاح.",
	user_profile: "الملف الشخصي",

	vehicle: "المركبة",
	vehicles: "المركبات",
	visibility: "الرؤية",

	warehouse: "المستودع",
	warehouses: "المستودعات",
	website: "الموقع الإلكتروني",
	welcome: "مرحبًا",

	yes: "نعم",
};

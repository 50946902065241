<template>
	<page-template hasAppBarExtension>
		<template slot="title">{{ $t("proforma_invoices") }}</template>
		<template slot="buttons">
			<v-btn icon @click="filterDialog.show">
				<v-icon>{{ filterIcon }}</v-icon>
			</v-btn>
		</template>
		<template slot="appBarExtension">
			<v-tabs v-model="tab" grow :color="$color.anchor" @change="tabChangeAction">
				<v-tab>{{ $t("outgoing") }}</v-tab>
				<v-tab>{{ $t("incoming") }}</v-tab>
			</v-tabs>
		</template>
		<template slot="content">
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<proforma-invoices-table
						role="sender"
						:filterData="filterDialog.dataForSender"
						:initialPage="senderInitialPage"
						:atFront="Boolean(tab == 0)"
						:listChanged="listChanged"
						@rowAction="rowAction"
						style="margin-bottom: 84px"
					/>
				</v-tab-item>

				<v-tab-item>
					<proforma-invoices-table
						role="receiver"
						:filterData="filterDialog.dataForReceiver"
						:initialPage="receiverInitialPage"
						:atFront="Boolean(tab == 1)"
						:listChanged="listChanged"
						@rowAction="rowAction"
					/>
				</v-tab-item>
			</v-tabs-items>

			<v-btn
				v-if="!tab"
				color="accent"
				fixed
				bottom
				right
				fab
				@click="detailDialog.show(null)"
				class="d-flex d-sm-none"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>

			<v-btn
				v-if="!tab"
				color="accent"
				fixed
				bottom
				right
				rounded
				large
				elevation="0"
				class="d-none d-sm-flex"
				@click="detailDialog.show(null)"
			>
				{{ $t("new") }}
				<v-icon right> mdi-plus-circle</v-icon>
			</v-btn>

			<proforma-invoice-filter-dialog
				v-model="filterDialog.visibility"
				:data="filterDialogData"
				@done="filterDialog.done"
			/>

			<proforma-invoice-dialog
				v-model="detailDialog.visibility"
				:item="detailDialog.item"
				@saved="detailDialog.saved"
				@deleted="detailDialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import PageTemplate from "../../templates/PageTemplate";
import ProformaInvoiceDialog from "./dialogs/ProformaInvoiceDialog";
import ProformaInvoiceFilterDialog from "./dialogs/ProformaInvoiceFilterDialog";
import ProformaInvoicesTable from "./partial/ProformaInvoicesTable.vue";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		ProformaInvoicesTable,
		ProformaInvoiceDialog,
		ProformaInvoiceFilterDialog,
	},
	data() {
		return {
			tab: null,
			senderInitialPage: undefined,
			receiverInitialPage: undefined,
			listChanged: false,
			filterDialog: {
				visibility: false,
				dataForSender: { role: "sender", referenceId: null, statuses: [] },
				dataForReceiver: { role: "receiver", referenceId: null, statuses: [] },
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (data) => {
					if (this.tab) this.filterDialog.dataForReceiver = data;
					else this.filterDialog.dataForSender = data;
					this.filterDialog.hide();
				},
			},
			detailDialog: {
				visibility: false,
				item: null,
				show(item) {
					this.item = item;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: (_) => {
					this.listChanged = !this.listChanged;
				},
				deleted: (_) => {
					this.detailDialog.hide();
					this.listChanged = !this.listChanged;
				},
			},
		};
	},
	computed: {
		filterIcon() {
			return this.filterDialogData.referenceId || this.filterDialogData.statuses.length
				? "mdi-filter"
				: "mdi-filter-outline";
		},
		filterDialogData() {
			return this.tab ? this.filterDialog.dataForReceiver : this.filterDialog.dataForSender;
		},
	},
	methods: {
		tabChangeAction(val) {
			let query = Object.assign({}, this.$route.query);
			query.tab = val;
			this.$router.replace({ query });
		},
		rowAction(item) {
			this.detailDialog.show(item);
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.tab = Number(this.$route.query.tab);
		const page = this.$route.query.page ? Number(this.$route.query.page) : undefined;
		if (this.tab) {
			this.receiverInitialPage = page;
			this.filterDialog.dataForReceiver.referenceId = this.$route.query.referenceId;
			if (this.$route.query.statuses)
				this.filterDialog.dataForReceiver.statuses = this.$route.query.statuses.split(",");
		} else {
			this.senderInitialPage = page;
			this.filterDialog.dataForSender.referenceId = this.$route.query.referenceId;
			if (this.$route.query.statuses)
				this.filterDialog.dataForSender.statuses = this.$route.query.statuses.split(",");
		}
	},
};
</script>

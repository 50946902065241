<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("social_media") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-select v-model="currentItem.type" :items="typeOptions" item-text="title" item-value="value" :rules="rules.type" :label="$t('type')" />
					<v-text-field v-model="currentItem.value" :label="$t('name')" :rules="rules.value" />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn v-if="currentItem.id" :disabled="deleting || saving" :loading="deleting" color="error" text @click="deleteAction">
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn text :disabled="deleting || saving" @click="cancelAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn :disabled="deleting || saving" :loading="saving" color="accent" text @click="saveAction">
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	props: ["value", "item"],
	components: { LocalizedTextField },
	data() {
		return {
			currentItem: null,
			valid: null,
			saving: false,
			deleting: false,
			typeOptions: [
				{
					title: this.$t("$socialMedia.facebook"),
					value: "facebook",
				},
				{
					title: this.$t("$socialMedia.linked_in"),
					value: "linked_in",
				},
				{
					title: this.$t("$socialMedia.instagram"),
					value: "instagram",
				},
				{
					title: this.$t("$socialMedia.twitter"),
					value: "twitter",
				},
			],
			rules: {
				type: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("type") })],
				value: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("name") })],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) this.currentItem = Object.assign({}, this.item);
			else
				this.currentItem = {
					type: null,
					value: "",
				};
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
		},
		saveAction() {
			if (this.valid) {
				const onSuccess = (result) => {
					this.saving = false;
					this.$emit("saved", result);
					this.showSnackBar({
						message: this.$t(this.currentItem.id ? "$message.updated" : "$message.created", {
							item: this.$t("social_media_account"),
						}),
					});
				};
				const onFail = () => (this.saving = false);
				const account = {
					id: this.currentItem.id,
					type: this.currentItem.type,
					value: this.currentItem.value,
				};
				this.saving = true;
				if (this.currentItem.id) remote.socialMedia.update(this.company.id, account, { onSuccess, onFail });
				else remote.socialMedia.create(this.company.id, account, { onSuccess, onFail });
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.socialMedia.delete(this.company.id, this.currentItem.id, {
						onSuccess: (result) => {
							this.deleting = false;
							this.$emit("deleted", result);
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("social_media_account"),
								}),
							});
						},
						onFail: () => (this.deleting = false),
					});
				},
			});
		},
		cancelAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>

<template>
	<v-dialog v-bind:value="value" persistent max-width="480px" eager>
		<v-card v-if="currentItem">
			<v-card-text class="pt-6">
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="currentItem.name" :label="$t('title')" :rules="rules.name" />
					<v-text-field
						v-model="currentItem.quantity"
						:label="$t('quantity')"
						:rules="rules.quantity"
						type="number"
						min="0"
					/>
					<v-text-field
						v-model="currentItem.amount"
						:label="$t('amount')"
						:rules="rules.amount"
						type="number"
						min="0"
						:suffix="shipment.currency.symbol"
					/>
				</v-form>
			</v-card-text>
			<v-divider />
			<v-card-actions>
				<v-btn v-if="this.currentItem.id" color="error" text @click="deleteAction(currentItem)">
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn @click="closeAction">{{ $t("close") }}</v-btn>
				<v-btn color="accent" @click="saveAction">{{ $t("save") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";

export default {
	props: { value: Boolean, item: Object, shipment: Object, container: Object },
	data() {
		return {
			valid: null,
			rules: {
				name: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("title"),
							});
						} else if (v.length > 240) {
							return this.$t("$validation.max_length", {
								item: this.$t("title"),
								length: "240",
							});
						}
						return true;
					},
				],

				quantity: [
					(v) =>
						(!isNaN(v) && Number(v) > 0) ||
						this.$t("$validation.required", { item: this.$t("quantity") }),
				],
				amount: [
					(v) => !Number.isNaN(v) || this.$t("$validation.required", { item: this.$t("amount") }),
				],
			},
			currentItem: null,
			saving: false,
			deleting: false,
		};
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) this.currentItem = cloneDeep(this.item);
			else this.useItemTemplate();
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
		},
		useItemTemplate() {
			this.currentItem = {
				name: "",
				quantity: null,
				amount: null,
			};
		},
		saveAction() {
			if (!this.saving && !this.deleting) {
				if (this.valid) {
					const onSuccess = (result) => this.$emit("saved", result);
					if (this.currentItem.amount == null || this.currentItem.amount == "")
						this.currentItem.amount = 0;
					if (this.currentItem.id) {
						remote.shipments.containers.expenses.update(
							this.shipment.id,
							this.container.id,
							this.currentItem,
							{ onSuccess }
						);
					} else {
						remote.shipments.containers.expenses.create(
							this.shipment.id,
							this.container.id,
							this.currentItem,
							{ onSuccess }
						);
					}
				} else this.$refs.form.validate();
			}
		},
		deleteAction(item) {
			if (!this.saving && !this.deleting) {
				remote.shipments.containers.expenses.delete(this.shipment.id, this.container.id, item.id, {
					onSuccess: (result) => this.$emit("deleted", result),
				});
			}
		},
		closeAction() {
			if (!this.saving && !this.deleting) this.$emit("input", false);
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>

export default {
	$accessibility: {
		present: "Đang diễn ra",
		archived: "Đã lưu trữ",
	},
	$accessModifier: {
		$description: {
			private: "Riêng tư",
			hidden_from_receiver: "Ẩn khỏi người nhận",
			hidden_from_sender: "Ẩn khỏi người gửi",
			shared_with_everyone: "Chia sẻ với mọi người",
			shared_with_custom: "Chia sẻ tùy chỉnh",
			shared_with_receiver: "Chia sẻ với người nhận",
			shared_with_sender: "Chia sẻ với người gửi",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andora",
		united_arab_emirates: "Các Tiểu vương quốc Ả Rập Thống nhất",
		afghanistan: "Afghanistan",
		antigua_and_barbuda: "Antigua và Barbuda",
		anguilla: "Anguilla",
		albania: "Albania",
		armenia: "Armenia",
		angola: "Angola",
		antarctica: "Châu Nam Cực",
		argentina: "Argentina",
		american_samoa: "Samoa Mỹ",
		austria: "Áo",
		australia: "Úc",
		aruba: "Aruba",
		aland: "Quần đảo Åland",
		azerbaijan: "Azerbaijan",
		bosnia_and_herzegovina: "Bosnia và Herzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Bỉ",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaria",
		bahrain: "Bahrain",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthelemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius và Saba",
		brazil: "Brasil",
		bahamas: "Bahamas",
		bhutan: "Bhutan",
		bouvet_island: "Đảo Bouvet",
		botswana: "Botswana",
		belarus: "Belarus",
		belize: "Belize",
		canada: "Canada",
		cocos_islands: "Quần đảo Cocos (Keeling)",
		dr_congo: "Cộng hòa Dân chủ Congo",
		central_african_republic: "Cộng hòa Trung Phi",
		congo_republic: "Congo - Brazzaville",
		switzerland: "Thụy Sĩ",
		ivory_coast: "Bờ Biển Ngà",
		cook_islands: "Quần đảo Cook",
		chile: "Chile",
		cameroon: "Cameroon",
		china: "Trung Quốc",
		colombia: "Colombia",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Cabo Verde",
		curacao: "Curaçao",
		christmas_island: "Đảo Christmas",
		cyprus: "Síp",
		czechia: "Cộng hòa Séc",
		germany: "Đức",
		djibouti: "Djibouti",
		denmark: "Đan Mạch",
		dominica: "Dominica",
		dominican_republic: "Cộng hòa Dominica",
		algeria: "Algeria",
		ecuador: "Ecuador",
		estonia: "Estonia",
		egypt: "Ai Cập",
		western_sahara: "Tây Sahara",
		eritrea: "Eritrea",
		spain: "Tây Ban Nha",
		ethiopia: "Ethiopia",
		finland: "Phần Lan",
		fiji: "Fiji",
		falkland_islands: "Quần đảo Falkland",
		micronesia: "Quốc liên bang Micronesia",
		faroe_islands: "Quần đảo Faroe",
		france: "Pháp",
		gabon: "Gabon",
		united_kingdom: "Vương quốc Anh",
		grenada: "Grenada",
		georgia: "Georgia",
		french_guiana: "Guiana thuộc Pháp",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Greenland",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadeloupe",
		equatorial_guinea: "Guinea Xích đạo",
		greece: "Hy Lạp",
		south_georgia_and_south_sandwich_islands:
			"Quần đảo Nam Georgia và Nam Sandwich",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		hong_kong: "Hồng Kông",
		heard_island_and_mcdonald_islands: "Đảo Heard và Quần đảo McDonald",
		honduras: "Honduras",
		croatia: "Croatia",
		haiti: "Haiti",
		hungary: "Hungary",
		indonesia: "Indonesia",
		ireland: "Ireland",
		israel: "Israel",
		isle_of_man: "Đảo Man",
		india: "Ấn Độ",
		british_indian_ocean_territory: "Lãnh thổ Ấn Độ Dương thuộc Anh",
		iraq: "Iraq",
		iran: "Iran",
		iceland: "Iceland",
		italy: "Ý",
		jersey: "Jersey",
		jamaica: "Jamaica",
		jordan: "Jordan",
		japan: "Nhật Bản",
		kenya: "Kenya",
		kyrgyzstan: "Kyrgyzstan",
		cambodia: "Campuchia",
		kiribati: "Kiribati",
		comoros: "Comoros",
		st_kitts_and_nevis: "Saint Kitts và Nevis",
		north_korea: "Bắc Triều Tiên",
		south_korea: "Hàn Quốc",
		kuwait: "Kuwait",
		cayman_islands: "Quần đảo Cayman",
		kazakhstan: "Kazakhstan",
		laos: "Lào",
		lebanon: "Liban",
		saint_lucia: "Saint Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesotho",
		lithuania: "Litva",
		luxembourg: "Luxembourg",
		latvia: "Latvia",
		libya: "Libya",
		morocco: "Morocco",
		monaco: "Monaco",
		moldova: "Moldovya",
		montenegro: "Montenegro",
		saint_martin: "Saint Martin",
		madagascar: "Madagascar",
		marshall_islands: "Quần đảo Marshall",
		north_macedonia: "Bắc Macedonia",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mông Cổ",
		macao: "Macao",
		northern_mariana_islands: "Quần đảo Bắc Mariana",
		martinique: "Martinique",
		mauritania: "Mauritania",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Maldives",
		malawi: "Malawi",
		mexico: "Mexico",
		malaysia: "Malaysia",
		mozambique: "Mozambique",
		namibia: "Namibia",
		new_caledonia: "New Caledonia",
		niger: "Niger",
		norfolk_island: "Đảo Norfolk",
		nigeria: "Nigeria",
		nicaragua: "Nicaragua",
		netherlands: "Hà Lan",
		norway: "Na Uy",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "New Zealand",
		oman: "Oman",
		panama: "Panama",
		peru: "Peru",
		french_polynesia: "Polynésie thuộc Pháp",
		papua_new_guinea: "Papua New Guinea",
		philippines: "Philippines",
		pakistan: "Pakistan",
		poland: "Ba Lan",
		saint_pierre_and_miquelon: "Saint Pierre và Miquelon",
		pitcairn_islands: "Quần đảo Pitcairn",
		puerto_rico: "Puerto Rico",
		palestine: "Lãnh thổ Palestine",
		portugal: "Bồ Đào Nha",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Romania",
		serbia: "Serbia",
		russia: "Nga",
		rwanda: "Rwanda",
		saudi_arabia: "Ả Rập Saudi",
		solomon_islands: "Quần đảo Solomon",
		seychelles: "Seychelles",
		sudan: "Sudan",
		sweden: "Thụy Điển",
		singapore: "Singapore",
		saint_helena: "Saint Helena",
		slovenia: "Slovenia",
		svalbard_and_jan_mayen: "Svalbard và Jan Mayen",
		slovakia: "Slovakia",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Suriname",
		south_sudan: "Nam Sudan",
		sao_tome_and_principe: "São Tomé và Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Syria",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Quần đảo Turks và Caicos",
		chad: "Chad",
		french_southern_territories: "Lãnh thổ Nam Cực thuộc Pháp",
		togo: "Togo",
		thailand: "Thái Lan",
		tajikistan: "Tajikistan",
		tokelau: "Tokelau",
		timor_leste: "Đông Timor",
		turkmenistan: "Turkmenistan",
		tunisia: "Tunisia",
		tonga: "Tonga",
		turkiye: "Thổ Nhĩ Kỳ",
		trinidad_and_tobago: "Trinidad và Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Đài Loan",
		tanzania: "Tanzania",
		ukraine: "Ukraine",
		uganda: "Uganda",
		us_minor_outlying_islands: "Các đảo nhỏ thuộc Hoa Kỳ",
		united_states: "Hoa Kỳ",
		uruguay: "Uruguay",
		uzbekistan: "Uzbekistan",
		vatican_city: "Thành phố Vatican",
		st_vincent_and_grenadines: "Saint Vincent và Grenadines",
		venezuela: "Venezuela",
		british_virgin_islands: "Quần đảo Virgin thuộc Anh",
		us_virgin_islands: "Quần đảo Virgin thuộc Hoa Kỳ",
		vietnam: "Việt Nam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis và Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yemen",
		mayotte: "Mayotte",
		south_africa: "Nam Phi",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		us_dollar: "Đô la Mỹ",
		euro: "Euro",
		turkish_lira: "Lira Thổ Nhĩ Kỳ",
	},
	$employeeType: {
		admin: "Quản trị viên",
		default: "Bình thường",
		exporter: "Người xuất khẩu",
		importer: "Người nhập khẩu",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Chào mừng bạn đến với Antrego",
			description:
				"Kiểm soát quy trình nhập khẩu và xuất khẩu của bạn với Antrego!",
			sign_in_title: "Bạn đã có tài khoản chưa?",
			sign_in_subtitle: "Đăng nhập tại đây",
			sign_up_title: "Bạn muốn tạo tài khoản?",
			sign_up_subtitle: "Đăng ký tại đây",
		},
		$noCompany: {
			title: "Chào mừng {name}",
			description:
				"Bạn đã đăng nhập thành công. Bây giờ bạn cần tham gia một công ty hiện tại hoặc tạo công ty mới của mình.",
			join_company_title: "Bạn muốn tham gia một công ty hiện tại?",
			join_company_subtitle: "Nhập mã mời của bạn ở đây và tham gia công ty.",
			create_company_title: "Bạn muốn tạo công ty của mình?",
			create_company_subtitle: "Tạo một công ty mới từ đây.",
		},
	},
	$invitationType: {
		employee: "Lời mời nhân viên",
		company_partner: "Lời mời Hợp tác Kinh doanh",
		customs_partner: "Lời mời Đối tác Hải quan",
	},
	$landing: {
		$question: {
			$title: {
				0: "Antrego là gì?",
				1: "Antrego sẽ đóng góp gì cho doanh nghiệp trong quy trình vận chuyển?",
				2: "Loại hình logistics nào được Antrego hỗ trợ?",
				3: "Tại sao nên sử dụng Antrego?",
				4: "Antrego có phải là ứng dụng trả phí không?",
				5: "Khách hàng của tôi có phải trả phí khi sử dụng Antrego không?",
			},
			$text: {
				0: "<p>Là một chương trình theo dõi vận chuyển giúp các công ty và khách hàng của họ theo dõi chung trong quá trình theo dõi, ngăn chặn thiệt hại có thể xảy ra do trễ.</p>",
				1: `<p>- Chia sẻ tất cả các cập nhật ngay lập tức với khách hàng của bạn. Tăng chất lượng dịch vụ của bạn.<br>
				- Xem tất cả các tài liệu vận chuyển trong một hệ thống, sau đó chia sẻ chúng với khách hàng của bạn.<br>
				- Theo dõi tất cả các lô hàng của bạn từ cùng một nơi. Giữ tài chính của bạn được kiểm soát với báo cáo động.</p>`,
				2: `<p>- Đường biển<br>
				- Đường hàng không<br>
				- Đường bộ<br>
				- Đường sắt</p>`,
				3: `<p>Giúp dễ dàng quy trình vận chuyển với các dịch vụ mà nó cung cấp.<br>
				<strong>1. Tiện Lợi Truy Cập</strong><br>
				Có thể truy cập từ mọi thiết bị và mọi nơi thông qua trình duyệt web.<br>
				<strong>2. Bảo mật Dữ liệu</strong><br>
				Tất cả dữ liệu được lưu trữ bằng công nghệ mã hóa SHA-256.<br>
				<strong>3. Theo Dõi Chung</strong><br>
				Cung cấp khả năng theo dõi cho tất cả các bên liên quan trong quy trình kinh doanh.</strong>`,
				4: "<p>Đúng, đó là ứng dụng trả phí. Chúng tôi muốn đơn giản hóa công việc của bạn bằng cách chuyển toàn bộ quy trình vận chuyển của bạn sang dạng số, đồng thời tiếp tục cải thiện và phát triển. Để tiếp tục các cải tiến này và duy trì không gian máy chủ bạn sử dụng, chúng tôi sẽ thu phí từ bạn hàng tháng.</p>",
				5: "<p>Không, khách hàng của bạn không cần phải trả bất kỳ chi phí nào khi theo dõi vận chuyển của bạn.</p>",
			},
		},
		$lifecycle: {
			headline: "TẤT CẢ QUY TRÌNH NGAY TẠI BÀN TAY",
			$title: {
				0: "Đăng Ký",
				1: "Gửi Hóa Đơn Proforma",
				2: "Tạo Vận Chuyển",
				3: "Theo Dõi Hoạt Động Tài Chính",
			},
			$text: {
				0: "Nhập thông tin công ty và sản phẩm, tạo khách hàng của bạn.",
				1: "Tạo hóa đơn Proforma và chia sẻ cho khách hàng để xác nhận.",
				2: "Nhập thông tin vận chuyển và đính kèm các tài liệu. Quản lý tất cả quy trình vận chuyển của bạn với khách hàng thông qua một kênh duy nhất.",
				3: "Theo dõi tất cả tình hình tài chính liên quan đến nhập khẩu và xuất khẩu.",
			},
		},
		$content: {
			headline: "LOẠI HÌNH LOGISTICS ĐƯỢC HỖ TRỢ",
			$title: {
				0: "Đường Biển",
				1: "Đường Hàng Không",
				2: "Đường Bộ",
				3: "Đường Sắt",
			},
		},
		$pricing: {
			0: {
				title: "MIỄN PHÍ",
				subtitle: "Cá nhân",
				features: ["1 Người Dùng", "3 Đối tác", "10 Lô Hàng", "10 Hóa Đơn"],
			},
			1: {
				title: "HÀNG NĂM",
				subtitle: "Công Ty",
				features: [
					"20 Người Dùng",
					"100 Đối tác",
					"1000 Lô Hàng",
					"1000 Hóa Đơn",
				],
			},
			2: {
				title: "HÀNG THÁNG",
				subtitle: "Doanh Nghiệp Nhỏ",
				features: ["10 Người Dùng", "30 Đối tác", "250 Lô Hàng", "250 Hóa Đơn"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Bạn có chắc muốn {function} không?",
		created: "{item} đã được tạo.",
		deleted: "{item} đã bị xóa.",
		file_oversize: "Kích thước tệp phải nhỏ hơn hoặc bằng {size}.",
		invalid: "{item} không hợp lệ.",
		sent_email: "Email đã được gửi. ({value})",
		sign_in_failed: "Email hoặc mật khẩu không đúng.",
		updated: "{item} đã được cập nhật.",
		migrated: "{item} đã được chuyển. ({value})",
	},
	$productGroup: {
		add_child: "Thêm Nhóm Con",
		add_to_root: "Thêm vào Gốc",
		has_children_fail_message: "Không thể xóa nhóm sản phẩm có nhóm con.",
		initial_message: "Nhấn vào {0} để thêm nhóm sản phẩm đầu tiên của bạn.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "Đã được tạo bởi {name}.",
			update: "Đã được cập nhật bởi {name}.",
			send: "{name} đã gửi cho người nhận để xác nhận.",
			revoke: "{name} đã thu hồi.",
			approve: "{name} đã duyệt.",
			demand_revise: "{name} đã yêu cầu sửa đổi.",
			reject: "{name} đã từ chối.",
			mark_as_processed: "{name} đã đánh dấu là đã xử lý.",
			create_attachment: "{name} đã tạo một tệp đính kèm.",
			update_attachment: "{name} đã cập nhật một tệp đính kèm.",
			delete_attachment: "{name} đã xóa một tệp đính kèm.",
		},
		$actionDescription2: {
			send: '{name} đã được đánh dấu là "Đã Gửi".',
			revoke: '{name} đã được đánh dấu là "Đã Thu Hồi".',
			approve: '{name} đã được đánh dấu là "Đã Duyệt".',
			demand_revise: '{name} đã được đánh dấu là "Sửa Đổi Đang Chờ".',
			reject: "{name} đã bị từ chối.",
			mark_as_processed: `{name} đã được đánh dấu là "Đã Xử Lý".`,
		},
		$actions: {
			approve: "Duyệt",
			demand_revise: "Yêu Cầu Sửa Đổi",
			mark_as_approved: "Đã Duyệt",
			mark_as_processed: "Đã Xử Lý",
			mark_as_rejected: "Đã Từ Chối",
			mark_as_revise_demanded: "Đã Yêu Cầu Sửa Đổi",
			mark_as_revoked: "Đã Thu Hồi",
			mark_as_sent: "Đã Gửi",
			reject: "Từ Chối",
			revoke: "Thu Hồi",
			send: "Gửi",
		},
		$status: {
			approved: "Đã Duyệt",
			pending_approval: "Chờ Duyệt",
			pending_revise: "Chờ Sửa Đổi",
			processed: "Đã Xử Lý",
			rejected: "Đã Từ Chối",
			revoked: "Đã Thu Hồi",
			template: "Mẫu",
		},
		send_confirm_message:
			"Bạn có chắc muốn gửi hóa đơn Proforma cho người nhận không?",
		send_success_message: "Hóa đơn Proforma đã được gửi đến người nhận.",
		approve_confirm_message: "Bạn có chắc muốn duyệt hóa đơn Proforma không?",
		approve_success_message: "Hóa đơn Proforma đã được duyệt.",
		demand_revise_success_message:
			"Hóa đơn Proforma đã được gửi lại để sửa đổi.",
		reject_confirm_message: "Bạn có chắc muốn từ chối hóa đơn Proforma không?",
		reject_success_message: "Hóa đơn Proforma đã bị từ chối.",
		mark_as_processed_confirm_message:
			"Bạn có chắc muốn đánh dấu hóa đơn Proforma là đã xử lý không?",
		mark_as_processed_success_message:
			"Hóa đơn Proforma đã được đánh dấu là đã xử lý.",
		revoke_confirm_message: "Bạn có chắc muốn thu hồi hóa đơn Proforma không?",
		revoke_success_message: "Hóa đơn Proforma đã được thu hồi.",
		demand_revise_description_message:
			"Vui lòng mô tả sửa đổi mà bạn đang chờ:",
		no_attachment_text: "Chưa có tệp đính kèm nào.",
	},
	$serviceMessage: {
		already_subs_module: "Đã có một đăng ký cho module này.",
		bad_request: "Yêu cầu không hợp lệ",
		code_already_used: "Mã đã được sử dụng.",
		container_status_not_available: "Trạng thái container không khả dụng.",
		existing_email_warning: "Email đã tồn tại.",
		existing_employee_error: "Người dùng này đã được thêm vào công ty của bạn.",
		existing_employee_for_email_warning:
			"Mã mời đã được gửi đến email này trước đó.",
		existing_invitation_warning: "Mã mời đã được gửi đến email này trước đó.",
		existing_token_already_have_time: "Jeton hiện tại đã đủ thời gian sử dụng.",
		existing_user: "Người dùng đã tồn tại.",
		five_min_reset_password_mail:
			"Sau khi yêu cầu email đặt lại mật khẩu, bạn cần đợi ít nhất 5 phút trước khi yêu cầu lại.",
		forbidden: "Cấm",
		internal_server_error: "Lỗi máy chủ nội bộ.",
		instance_can_no_delete_existing_booking_code:
			"Không thể xóa mục vì đã có mã đặt chỗ hiện tại.",
		instance_cannot_delete: "Không thể xóa mục nữa.",
		instance_not_found: "Không tìm thấy mục.",
		invitation_accepting_is_due_to_the_admin:
			"Việc chấp nhận lời mời phụ thuộc vào quản trị viên.",
		invitation_already_discarded: "Lời mời đã được hủy bỏ.",
		invitation_already_used: "Lời mời đã được sử dụng.",
		invitation_has_been_used: "Lời mời đã được sử dụng.",
		invitation_not_exist_email: "Lời mời không tồn tại cho địa chỉ email này.",
		invitation_not_for_custom_agency:
			"Lời mời này không dành cho một đại lý hải quan.",
		invitation_not_for_company: "Lời mời này không dành cho một công ty.",
		invitation_not_found: "Không tìm thấy lời mời.",
		invitation_not_valid: "Lời mời không còn hợp lệ.",
		invalid_current_password: "Mật khẩu hiện tại của bạn không đúng.",
		invalid_identifier_or_password: "Email hoặc mật khẩu không hợp lệ",
		invalid_invitation_code: "Mã mời không hợp lệ.",
		invalid_moving_request: "Yêu cầu di chuyển không hợp lệ.",
		invalid_refresh_token: "Mã làm mới không hợp lệ.",
		invalid_operation: "Thao tác không hợp lệ.",
		invalid_unit: "Đơn vị không hợp lệ.",
		have_no_corporation: "Bạn chưa có công ty.",
		have_no_custom_agency: "Bạn chưa có đại lý hải quan.",
		have_no_employee: "Bạn chưa có nhân viên.",
		link_not_be_used_anymore: "Liên kết này không thể sử dụng nữa.",
		link_has_been_expired: "Liên kết đã hết hạn.",
		missing_required_fields: "Các trường bắt buộc phải được điền.",
		no_employee_found: "Không tìm thấy nhân viên.",
		no_proper_partner_found: "Không tìm thấy đối tác phù hợp.",
		not_acceptable: "Không chấp nhận được.",
		not_allowed_action: "Bạn không được phép thực hiện hành động này.",
		not_allowed_use_api: "Bạn không có quyền sử dụng API này.",
		not_found: "Không tìm thấy.",
		not_permitted_use_api: "Bạn không có quyền sử dụng API này.",
		own_invitation_cannot_accept: "Bạn không thể gửi mời cho chính mình.",
		parent_group_not_found: "Không tìm thấy nhóm sản phẩm cha.",
		partnerId_required: "Yêu cầu Partner ID.",
		partnership_already_exists: "Đối tác đã được thêm vào.",
		password_cant_involve_space_char: "Mật khẩu không thể chứa ký tự trắng.",
		password_min_chars_least: "Mật khẩu phải chứa ít nhất 6 ký tự.",
		ports_are_required: "Cần phải có cổng.",
		product_group_tree_not_found: "Không tìm thấy cây nhóm sản phẩm.",
		proforma_invoice_action_not_available:
			"Hành động không khả dụng cho hóa đơn Proforma này.",
		proforma_invoice_template_update: "Cần cập nhật mẫu cho hóa đơn Proforma.",
		shipment_method_is_required: "Phương thức vận chuyển là bắt buộc.",
		shipping_term_is_required: "Điều khoản vận chuyển là bắt buộc.",
		shipment_email_not_exist: "Vui lòng nhập một địa chỉ email hợp lệ.",
		someone_else_uses_this_email: "Người khác đã sử dụng địa chỉ email này.",
		someone_else_uses_this_username:
			"Người khác đã sử dụng tên người dùng này.",
		something_went_wrong: "Đã xảy ra lỗi.",
		type_can_be_abstract_company: '"type" chỉ có thể là "abstract_company".',
		unauthorized: "Không có quyền",
		undefined_result: "Kết quả không xác định.",
		unexpected_error_occurred: "Đã xảy ra lỗi không mong muốn.",
		unit_already_added: "Đã thêm đơn vị này rồi.",
		username_min_chars_error: '"Tên người dùng" phải có ít nhất 6 ký tự.',
		user_already_employee: "Người dùng đã là một nhân viên.",
		user_already_exists: "Người dùng đã làm việc cho công ty của bạn.",
		user_does_not_exist: "Người dùng không tồn tại.",
		user_not_found: "Không tìm thấy người dùng.",
	},
	$shipment: {
		$actionDescription: {
			create: "Được tạo bởi {name}.",
			update: "Được cập nhật bởi {name}.",
			create_container: "{name} đã thêm một container.",
			update_container: "{name} đã cập nhật một container.",
			delete_container: "{name} đã xóa một container.",
			create_attachment: "{name} đã tạo một tệp đính kèm.",
			update_attachment: "{name} đã cập nhật một tệp đính kèm.",
			delete_attachment: "{name} đã xóa một tệp đính kèm.",
			$land: {
				create_container: "{name} đã thêm một phương tiện đường bộ.",
				update_container: "{name} đã cập nhật một phương tiện đường bộ.",
				delete_container: "{name} đã xóa một phương tiện đường bộ.",
			},
		},
		$containerStatus: {
			reserved: "Đã Đặt Chỗ",
			on_load: "Đã Tải",
			on_way: "Đang Trên Đường",
			arrived: "Đã Đến",
			off_load: "Đã Dỡ Hàng",
		},
		$method: {
			sea: "Biển",
			aerial: "Hàng Không",
			land: "Đường Bộ",
			railway: "Đường Sắt",
		},
		$vehicle: {
			license_number: "Biển Số Xe",
		},
		archiving_success_message: "Lô hàng đã được lưu trữ.",
		leave_message: "Hãy để lại tin nhắn hoặc ý kiến của bạn",
		no_booking_info: "Chưa có thông tin đặt chỗ. Nhấn vào {0} để thêm.",
		no_transportation_info:
			"Chưa có chi tiết vận chuyển lô hàng. Nhấn vào {0} để thêm.",
		un_archiving_success_message: "Lô hàng đã được khôi phục từ lưu trữ.",
	},
	$shortening: {
		number: "Số",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Milimét",
		meter: "Mét",
		kilometer: "Kilômét",
		milligram: "Miligam",
		gram: "Gam",
		kilogram: "Kilogam",
		square_meter: "Mét vuông",
		hectare: "Hecta",
		square_kilometer: "Kilômét vuông",
		milliliter: "Mililit",
		liter: "Lít",
		cubic_meter: "Mét khối",
		acres: "Mẫu Anh",
		feet: "Feet",
		fluid_ounces: "Ổn chảy ounces",
		gallons: "Gallon",
		inches: "Inch",
		miles: "Dặm",
		ounces: "Ounces",
		pounds: "Pao",
		quarts: "Quart",
		square_feet: "Feet vuông",
		square_miles: "Dặm vuông",
		square_yards: "Yards vuông",
		square_inches: "Inch vuông",
		yards: "Yards",
		pieces: "Cái",
		boxes: "Hộp",
		pallets: "Palet",
		dozen: "Tá",
		ton: "Tấn",
	},
	$validation: {
		required: "{item} không thể để trống",
		required_for_default_language:
			"{item} phải được định nghĩa cho ngôn ngữ mặc định của công ty.",
		invalid: "{item} không hợp lệ",
		email: "Vui lòng nhập một địa chỉ email hợp lệ",
		min_length: "{item} phải có ít nhất {length} ký tự",
		max_length: "{item} không thể quá {length} ký tự",
		password_repeat_warning: "Mật khẩu lặp lại phải giống với mật khẩu mới.",
	},

	accept_invitation_success_message: "Bạn đã chấp nhận lời mời thành công.",
	accessibility: "Khả năng tiếp cận",
	actions: "Hành động",
	active: "Hoạt động",
	add: "Thêm",
	add_item: "Thêm {item}",
	address: "Địa chỉ",
	again: "Làm lại",
	alert: "Cảnh báo",
	already_have_employee_message: "Bạn đã là nhân viên của một công ty.",
	amount: "Số tiền",
	archive: "Lưu trữ",
	attachment: "Tệp đính kèm",
	attachments: "Tệp đính kèm",

	booking: "Đặt chỗ",
	booking_code: "Mã đặt chỗ",
	business_partner: "Đối tác kinh doanh",
	business_partners: "Đối tác kinh doanh",

	cancel: "Hủy",
	change_password: "Đổi mật khẩu",
	checking_invitation_code_message: "Kiểm tra mã mời",
	choose_image: "Chọn hình ảnh",
	clear: "Xóa",
	close: "Đóng",
	code: "Mã",
	companies: "Công ty",
	company: "Công ty",
	company_profile: "Hồ sơ công ty",
	commercial: "Thương mại",
	commercial_partners: "Đối tác thương mại",
	content: "Nội dung",
	configuration: "Cấu hình",
	confirm: "Xác nhận",
	contact: "Liên hệ",
	contact_info: "Thông tin liên hệ",
	contact_to_us: "Liên hệ với chúng tôi",
	container: "Container",
	containers: "Các container",
	content: "Nội dung",
	countries: "Quốc gia",
	country: "Quốc gia",
	create: "Tạo mới",
	create_company: "Tạo công ty",
	created_at: "Được tạo lúc",
	currencies: "Tiền tệ",
	currency: "Tiền tệ",
	current_password: "Mật khẩu hiện tại",
	customs_agency: "Công ty hải quan",
	customs_agencies: "Các công ty hải quan",

	date: "Ngày",
	dashboard: "Bảng điều khiển",
	default: "Mặc định",
	default_language: "Ngôn ngữ mặc định",
	delete: "Xóa",
	delete_confirm_message: "Bạn có chắc chắn muốn xóa không?",
	description: "Mô tả",
	detail: "Chi tiết",
	discard: "Loại bỏ",
	document_number: "Số tài liệu",
	download: "Tải xuống",

	edit: "Chỉnh sửa",
	edit_item: "Chỉnh sửa {item}",
	email: "Email",
	employee: "Nhân viên",
	employee_type: "Loại nhân viên",
	employees: "Nhân viên",
	estimated_arrival_day: "Ngày dự kiến đến",
	expense: "Chi phí",
	expenses: "Chi phí",
	exported_containers: "Container xuất khẩu",

	faq: "Câu hỏi thường gặp",
	file: "Tệp",
	filter: "Lọc",
	first_name: "Tên",
	forget_password: "Quên mật khẩu",
	forget_password_message: "Quên mật khẩu?",
	from: "Từ",

	hide: "Ẩn",
	home_page: "Trang chủ",

	imported_containers: "Container nhập khẩu",
	incoming: "Đang đến",
	incoming_shipments: "Lô hàng đang đến",
	initial: "Ban đầu",
	invitation: "Lời mời",
	invitation_code: "Mã mời",
	invitation_mail_sent: "Thư mời đã được gửi",
	invitations: "Lời mời",

	join: "Tham gia",

	language_options: "Tùy chọn ngôn ngữ",
	last_name: "Họ",
	last_one_year: "12 tháng qua",
	latitude: "Vĩ độ",
	loading_country: "Quốc gia xếp hàng",
	loading_point: "Điểm xếp hàng",
	loading_port: "Cảng xếp hàng",
	logo: "Logo",
	longitude: "Kinh độ",

	go_to_panel: "Đi đến bảng điều khiển",

	mail_sent: "Thư đã được gửi",
	migrate: "Di chuyển",
	migrate_partner: "Di chuyển đối tác",
	message: "Tin nhắn",
	message_company_create: "Công ty đã được tạo.",
	message_company_join: "Tham gia công ty",
	message_company_joined: "Bạn đã tham gia công ty",
	message_employee_delete: "Nhân viên đã bị xóa.",
	message_employee_save: "Nhân viên đã được lưu.",
	message_join_company: "Vui lòng nhập mã mời để đăng ký vào một công ty.",
	method: "Phương pháp",
	mobile_number: "Số di động",
	my_role: "Vai trò của tôi",

	name: "Tên",
	next: "Tiếp",
	new: "Mới",
	new_password: "Mật khẩu mới",
	no: "Không",
	no_content: "Không có nội dung",
	no_text: "Không có văn bản",
	not_found_page_message: "Rất tiếc, không thể tìm thấy trang này :(",
	note: "Ghi chú",

	ok: "OK",
	outgoing: "Đang đi",
	outgoing_shipments: "Lô hàng đi",

	parent_group: "Nhóm cha",
	partner: "Đối tác",
	partners: "Đối tác",
	passive: "Bị động",
	password: "Mật khẩu",
	phone_number: "Số điện thoại",
	previous: "Trước",
	pricing: "Giá",
	product: "Sản phẩm",
	product_group: "Nhóm sản phẩm",
	product_groups: "Nhóm sản phẩm",
	products: "Sản phẩm",
	profile: "Hồ sơ",
	proforma_invoice: "Hóa đơn Proforma",
	proforma_invoices: "Hóa đơn Proforma",
	proforma_number: "Số Proforma",
	purchase: "Mua",

	quantity: "Số lượng",

	receiver: "Người nhận",
	receiver_company: "Công ty người nhận",
	receiver_customs_agency: "Công ty hải quan người nhận",
	redirection_to_home_link: "bấm vào đây",
	redirection_to_home_message: "Để quay lại trang chủ, ",
	redirection_to_sign_in_message: "Bạn đã có tài khoản?",
	redirection_to_sign_up_message: "Bạn chưa có tài khoản?",
	reload: "Tải lại",
	reset_password: "Đặt lại mật khẩu",

	sales: "Bán hàng",
	save: "Lưu",
	search: "Tìm kiếm",
	select_file: "Chọn tệp",
	select_file_place_holder: "Vui lòng chọn một hình ảnh",
	selected_partner: "Đối tác đã chọn",
	send: "Gửi",
	send_again: "Gửi lại",
	send_email: "Gửi email",
	send_invitation: "Gửi lời mời",
	sender: "Người gửi",
	sender_company: "Công ty người gửi",
	sender_customs_agency: "Công ty hải quan người gửi",
	show: "Hiển thị",
	shipment: "Lô hàng",
	shipment_method: "Phương thức vận chuyển",
	shipments: "Lô hàng",
	shipments_filter: "Bộ lọc Lô hàng",
	shipping_term: "Điều khoản vận chuyển",
	shipping_terms: "Điều khoản vận chuyển",
	sign_in: "Đăng nhập",
	sign_out: "Đăng xuất",
	sign_out_confirm_message: "Bạn có chắc chắn muốn đăng xuất không?",
	sign_out_success_message: "Tạm biệt.",
	sign_up: "Đăng ký",
	social_media: "Mạng xã hội",
	social_media_account: "Tài khoản mạng xã hội",
	status: "Trạng thái",
	subscribe: "Đăng ký",

	target: "Mục tiêu",
	target_partner: "Đối tác mục tiêu",
	theme: "Giao diện",
	this_month: "Tháng này",
	this_year: "Năm nay",
	title: "Tiêu đề",
	to: "Đến",
	toDelete: "Xóa",
	total: "Tổng",
	total_amount: "Tổng số tiền",
	type: "Loại",
	types: "Loại",

	un_archive: "Bỏ lưu trữ",
	unit: "Đơn vị",
	units: "Đơn vị",
	unloading_country: "Quốc gia dỡ hàng",
	unloading_point: "Điểm dỡ hàng",
	unloading_port: "Cảng dỡ hàng",
	use_code: "Sử dụng mã",
	use_invitation_code: "Sử dụng mã mời",
	use_invitation_code_description: "Nhập mã mời được gửi đến hộp thư của bạn.",
	use_invitation_code_success_message: "Mã mời đã được sử dụng thành công.",
	user_profile: "Hồ sơ của tôi",

	vehicle: "Phương tiện",
	vehicles: "Phương tiện",
	visibility: "Tính hiển thị",

	warehouse: "Kho",
	warehouses: "Kho",
	website: "Trang web",
	welcome: "Chào mừng",

	yes: "Có",
};

export default {
	$accessibility: {
		present: "चल रहा है",
		archived: "संग्रहीत",
	},
	$accessModifier: {
		$description: {
			private: "निजी",
			hidden_from_receiver: "प्राप्तकर्ता से छिपा हुआ",
			hidden_from_sender: "भेजने वाले से छिपा हुआ",
			shared_with_everyone: "सभी के साथ साझा",
			shared_with_custom: "कस्टम के साथ साझा",
			shared_with_receiver: "प्राप्तकर्ता के साथ साझा",
			shared_with_sender: "भेजने वाले के साथ साझा",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "अंडोरा",
		united_arab_emirates: "संयुक्त अरब अमीरात",
		afghanistan: "अफगानिस्तान",
		antigua_and_barbuda: "एंटीगुआ और बारबुडा",
		anguilla: "एंगुइला",
		albania: "अल्बेनिया",
		armenia: "आर्मेनिया",
		angola: "अंगोला",
		antarctica: "अंटार्कटिका",
		argentina: "अर्जेंटीना",
		american_samoa: "अमेरिकन समोआ",
		austria: "ऑस्ट्रिया",
		australia: "ऑस्ट्रेलिया",
		aruba: "अरूबा",
		aland: "एलैंड द्वीपसमूह",
		azerbaijan: "अज़रबैजान",
		bosnia_and_herzegovina: "बॉस्निया और हर्जगोविना",
		barbados: "बारबाडोस",
		bangladesh: "बांग्लादेश",
		belgium: "बेल्जियम",
		burkina_faso: "बुर्किना फासो",
		bulgaria: "बल्गेरिया",
		bahrain: "बहरीन",
		burundi: "बुरुंडी",
		benin: "बेनिन",
		saint_barthelemy: "सेंट बार्थेलेमी",
		bermuda: "बरमूडा",
		brunei: "ब्रुनेई",
		bolivia: "बोलीविया",
		bonaire_sint_eustatius_and_saba: "बोनेर, सेंट यूस्टेसियस और साबा",
		brazil: "ब्राजील",
		bahamas: "बहामा",
		bhutan: "भूटान",
		bouvet_island: "बुवे द्वीप",
		botswana: "बोत्सवाना",
		belarus: "बेलारूस",
		belize: "बेलीज",
		canada: "कनाडा",
		cocos_islands: "कोकोस द्वीपसमूह",
		dr_congo: "कांगो - किंशासा",
		central_african_republic: "मध्य अफ्रीकी गणराज्य",
		congo_republic: "कांगो - ब्राज़ाविल",
		switzerland: "स्विट्जरलैंड",
		ivory_coast: "कोट डाइवोआर",
		cook_islands: "कुक द्वीपसमूह",
		chile: "चिली",
		cameroon: "कैमरून",
		china: "चीन",
		colombia: "कोलंबिया",
		costa_rica: "कोस्टा रिका",
		cuba: "क्यूबा",
		cabo_verde: "केप वर्दे",
		curacao: "क्यूरासाओ",
		christmas_island: "क्रिसमस द्वीप",
		cyprus: "साइप्रस",
		czechia: "चेकिया",
		germany: "जर्मनी",
		djibouti: "जिबूती",
		denmark: "डेनमार्क",
		dominica: "डोमिनिका",
		dominican_republic: "डोमिनिकन गणराज्य",
		algeria: "अल्जीरिया",
		ecuador: "इक्वाडोर",
		estonia: "एस्तोनिया",
		egypt: "मिस्र",
		western_sahara: "पश्चिमी सहारा",
		eritrea: "इरिट्रिया",
		spain: "स्पेन",
		ethiopia: "इथियोपिया",
		finland: "फिनलैंड",
		fiji: "फिजी",
		falkland_islands: "फ़ॉकलैंड द्वीपसमूह",
		micronesia: "माइक्रोनेशिया संघ राज्य",
		faroe_islands: "फैरो द्वीपसमूह",
		france: "फ्रांस",
		gabon: "गैबॉन",
		united_kingdom: "संयुक्त राज्य",
		grenada: "ग्रेनेडा",
		georgia: "जॉर्जिया",
		french_guiana: "फ्रेंच गयाना",
		guernsey: "गर्नसी",
		ghana: "घाना",
		gibraltar: "जिब्राल्टर",
		greenland: "ग्रीनलैंड",
		gambia: "गैंबिया",
		guinea: "गिनी",
		guadeloupe: "ग्वाडेलूप",
		equatorial_guinea: "इक्वेटोरियल गिनी",
		greece: "यूनान",
		south_georgia_and_south_sandwich_islands:
			"दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीपसमूह",
		guatemala: "ग्वाटेमाला",
		guam: "गुआम",
		guinea_bissau: "गिनी-बिसाउ",
		guyana: "गयाना",
		hong_kong: "हाँगकाँग",
		heard_island_and_mcdonald_islands: "हर्ड द्वीप और मैकडोनाल्ड द्वीपसमूह",
		honduras: "हौण्डुरास",
		croatia: "क्रोएशिया",
		haiti: "हैती",
		hungary: "हंगरी",
		indonesia: "इंडोनेशिया",
		ireland: "आयरलैंड",
		israel: "इज़राइल",
		isle_of_man: "आइल ऑफ़ मैन",
		india: "भारत",
		british_indian_ocean_territory: "ब्रिटिश हिंद महासागर क्षेत्र",
		iraq: "इराक",
		iran: "ईरान",
		iceland: "आइसलैंड",
		italy: "इटली",
		jersey: "जर्सी",
		jamaica: "जमैका",
		jordan: "जॉर्डन",
		japan: "जापान",
		kenya: "केन्या",
		kyrgyzstan: "किर्गिज़स्तान",
		cambodia: "कम्बोडिया",
		kiribati: "किरिबाती",
		comoros: "कोमोरोस",
		st_kitts_and_nevis: "सेंट किट्स और नेविस",
		north_korea: "उत्तर कोरिया",
		south_korea: "दक्षिण कोरिया",
		kuwait: "कुवैत",
		cayman_islands: "केमैन द्वीपसमूह",
		kazakhstan: "कज़ाखस्तान",
		laos: "लाओस",
		lebanon: "लेबनान",
		saint_lucia: "सेंट लूसिया",
		liechtenstein: "लिकटेंस्टीन",
		sri_lanka: "श्रीलंका",
		liberia: "लाइबीरिया",
		lesotho: "लेसोथो",
		lithuania: "लिथुआनिया",
		luxembourg: "लक्समबर्ग",
		latvia: "लातविया",
		libya: "लीबिया",
		morocco: "मोरक्को",
		monaco: "मोनाको",
		moldova: "मोल्डोवा",
		montenegro: "मोंटेनेग्रो",
		saint_martin: "सेंट मार्टिन",
		madagascar: "मेडागास्कर",
		marshall_islands: "मार्शल द्वीपसमूह",
		north_macedonia: "उत्तर मैसेडोनिया",
		mali: "माली",
		myanmar: "म्यांमार",
		mongolia: "मंगोलिया",
		macao: "मकाओ",
		northern_mariana_islands: "उत्तरी मरियाना द्वीपसमूह",
		martinique: "मार्टीनिक",
		mauritania: "मॉरिटानिया",
		montserrat: "मॉन्टसेराट",
		malta: "माल्टा",
		mauritius: "मॉरीशस",
		maldives: "मालदीव",
		malawi: "मलावी",
		mexico: "मेक्सिको",
		malaysia: "मलेशिया",
		mozambique: "मोजांबिक",
		namibia: "नामीबिया",
		new_caledonia: "न्यू कैलेडोनिया",
		niger: "नाइजर",
		norfolk_is: "नॉरफ़ॉक द्वीप",
		nigeria: "नाइजीरिया",
		nicaragua: "निकारागुआ",
		netherlands: "नीदरलैंड्स",
		norway: "नॉर्वे",
		nepal: "नेपाल",
		nauru: "नौरू",
		niue: "नियूए",
		new_zealand: "न्यूज़ीलैंड",
		oman: "ओमान",
		panama: "पनामा",
		peru: "पेरू",
		french_polynesia: "फ़्रेंच पॉलिनेशिया",
		papua_new_guinea: "पापुआ न्यू गिनी",
		philippines: "फ़िलीपींस",
		pakistan: "पाकिस्तान",
		poland: "पोलैंड",
		saint_pierre_and_miquelon: "सेंट पिएर और मिकेलॉन",
		pitcairn_islands: "पिटकैर्न द्वीपसमूह",
		puerto_rico: "प्यूर्टो रिको",
		palestine: "फ़िलिस्तीन",
		portugal: "पुर्तगाल",
		palau: "पलाऊ",
		paraguay: "पैराग्वे",
		qatar: "क़तार",
		reunion: "रियूनियन",
		romania: "रोमानिया",
		serbia: "सर्बिया",
		russia: "रूस",
		rwanda: "रवांडा",
		saudi_arabia: "सऊदी अरब",
		solomon_islands: "सोलोमन द्वीपसमूह",
		seychelles: "सेशेल्स",
		sudan: "सूडान",
		sweden: "स्वीडन",
		singapore: "सिंगापुर",
		saint_helena: "सेंट हेलेना",
		slovenia: "स्लोवेनिया",
		svalbard_and_jan_mayen: "स्वालबार्ड और जान मायेन",
		slovakia: "स्लोवाकिया",
		sierra_leone: "सिएरा लियोन",
		san_marino: "सैन मारिनो",
		senegal: "सेनेगल",
		somalia: "सोमालिया",
		suriname: "सूरीनाम",
		south_sudan: "दक्षिण सूडान",
		sao_tome_and_principe: "साओ टोमे और प्रिंसिप",
		el_salvador: "एल साल्वाडोर",
		sint_maarten: "सिंट मार्टेन",
		syria: "सीरिया",
		eswatini: "इस्वातिनी",
		turks_and_caicos_islands: "टर्क्स और कैकोस द्वीपसमूह",
		chad: "चाड",
		french_southern_territories: "फ़्रेंच साउद टेरिटरीज़",
		togo: "टोगो",
		thailand: "थाईलैंड",
		tajikistan: "ताजिकिस्तान",
		tokelau: "टोकेलाउ",
		timor_leste: "तिमोर-लेस्ते",
		turkmenistan: "तुर्कमेनिस्तान",
		tunisia: "ट्यूनीशिया",
		tonga: "टोंगा",
		turkiye: "तुर्की",
		trinidad_and_tobago: "त्रिनिदाद और टोबैगो",
		tuvalu: "तुवालु",
		taiwan: "ताइवान",
		tanzania: "तंज़ानिया",
		ukraine: "यूक्रेन",
		uganda: "युगांडा",
		us_minor_outlying_islands: "संयुक्त राज्य के छोटे बाह्यी द्वीपसमूह",
		united_states: "संयुक्त राज्य अमरीका",
		uruguay: "उरुग्वे",
		uzbekistan: "उजबेकिस्तान",
		vatican_city: "वेटिकन सिटी",
		st_vincent_and_grenadines: "सेंट विनसेंट और ग्रेनाडाइन्स",
		venezuela: "वेनेज़ुएला",
		british_virgin_islands: "ब्रिटिश वर्जिन आइलैंड्स",
		us_virgin_islands: "यूएस वर्जिन आइलैंड्स",
		vietnam: "वियतनाम",
		vanuatu: "वानुआतु",
		wallis_and_futuna: "वालिस और फ़्यूचुना",
		samoa: "सामोआ",
		kosovo: "कोसोवो",
		yemen: "यमन",
		mayotte: "मायोट",
		south_africa: "दक्षिण अफ्रीका",
		zambia: "ज़ाम्बिया",
		zimbabwe: "ज़िम्बाब्वे",
	},
	$currency: {
		us_dollar: "अमेरिकी डॉलर",
		euro: "यूरो",
		turkish_lira: "तुर्की लीरा",
	},
	$employeeType: {
		admin: "प्रशासक",
		default: "साधारित",
		exporter: "निर्यातक",
		importer: "आयातक",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Antrego में आपका स्वागत है",
			description:
				"Antrego के साथ आपकी आयात-निर्यात प्रक्रियाओं को नियंत्रित रखें!",
			sign_in_title: "क्या आपके पास एक खाता है?",
			sign_in_subtitle: "यहां से लॉगिन करें",
			sign_up_title: "क्या आप एक खाता बनाना चाहते हैं?",
			sign_up_subtitle: "यहां से सदस्य बनें",
		},
		$noCompany: {
			title: "स्वागत है {name}",
			description:
				"आपने सफलतापूर्वक साइन इन किया है। अब एक कंपनी में शामिल होना चाहिए या अपनी कंपनी बनाना चाहिए।",
			join_company_title: "क्या आप किसी मौजूदा कंपनी में शामिल होना चाहते हैं?",
			join_company_subtitle:
				"यहां अपना निमंत्रण कोड डालें और कंपनी में शामिल हों।",
			create_company_title: "क्या आप अपनी कंपनी बनाना चाहते हैं?",
			create_company_subtitle: "यहां एक नई कंपनी बनाएं।",
		},
	},
	$invitationType: {
		employee: "कर्मचारी निमंत्रण",
		company_partner: "वाणिज्यिक साझेदारी निमंत्रण",
		customs_partner: "कस्टम्स साझेदार निमंत्रण",
	},
	$landing: {
		$question: {
			$title: {
				0: "एंट्रीगो क्या है?",
				1: "एंट्रीगो शिपमेंट प्रक्रिया में मेरे व्यापार को कैसे सहायक होगा?",
				2: "एंट्रीगो द्वारा समर्थित लॉजिस्टिक प्रकार क्या हैं?",
				3: "मैं एंट्रीगो का उपयोग क्यों करना चाहिए?",
				4: "क्या एंट्रीगो एक लाइसेंसदार एप्लिकेशन है?",
				5: "क्या हमारे ग्राहकों को भी एंट्रीगो के लिए भुगतान करना होगा?",
			},
			$text: {
				0: "<p>यह एक शिपमेंट ट्रैकिंग प्रोग्राम है जो शिपमेंट करने वाली कंपनियों और उनके ग्राहकों को सहयोग करता है, जबकि ट्रैकिंग स्थिति में हो सकने वाले देरियाद के कारण होने वाले क्षति से बचाता है।</p>",
				1: `<p>- अपने सभी लाइव अपडेट कस्टमर के साथ साझा करें। आपकी सेवा की गुणवत्ता को बढ़ावा दें।<br>
				- शिपमेंट के सभी दस्तावेज़ों को एक ही सिस्टम में देखें, अपने ग्राहकों के साथ साझा करें।<br>
				- सभी शिपमेंट्स को एक ही स्थान से ट्रैक करें। डायनामिक रिपोर्टिंग के साथ अपने वित्त को नियंत्रण में रखें।</p>`,
				2: `<p>- समुद्र<br>
				- हवा<br>
				- सड़क<br>
				- रेल</p>`,
				3: `<p>इसने उपलब्ध सेवाओं के साथ शिपमेंट प्रक्रिया को सरल बनाने के लिए हमेशा हाथ में रखा है।<br>
				<strong>1. सरल पहुंच</strong><br>
				वेब ब्राउज़र के माध्यम से सभी उपकरणों से और हर जगह से पहुंचा जा सकता है।<br>
				<strong>2. डेटा सुरक्षा</strong><br>
				सभी डेटा SHA-256 एन्क्रिप्टेड तकनीक के साथ सुरक्षित रूप से रखा जाता है।<br>
				<strong>3. साझा ट्रैकिंग</strong><br>
				व्यावसायिक प्रक्रिया में शामिल सभी हिस्सेदारों को ट्रैकिंग की सुविधा प्रदान करता है।</p>`,
				4: "<p>हाँ, यह एक लाइसेंसदार एप्लिकेशन है। आपकी शिपमेंट्स की सभी प्रक्रियाएं डिजिटल रूप में लेकर, हम आपके व्यापार को सरल बनाए रखना और आपको उपयुक्त सुधार करना चाहते हैं। इन सुधारों को जारी रखने और आपके द्वारा उपयोग किए जाने वाले सर्वर पर आपके द्वारा उपयुक्त क्षेत्र के लिए हर महीने एक शुल्क हम से मांगते हैं।</p>",
				5: "<p>नहीं, आपके ग्राहकों को आपकी की गई शिपमेंट्स का ट्रैकिंग करते समय किसी भी शुल्क की आवश्यकता नहीं है।</p>",
			},
		},
		$lifecycle: {
			headline: "सभी प्रक्रियाएं आपके हाथों में",
			$title: {
				0: "सदस्य बनें",
				1: "प्रोफॉर्मा इनवॉयस भेजें",
				2: "शिपमेंट बनाएं",
				3: "वित्तीय गतिविधियाँ ट्रैक करें",
			},
			$text: {
				0: "कंपनी की जानकारी और उत्पाद दर्ज करें, अपने ग्राहकों को बनाएं।",
				1: "प्रोफॉर्मा इनवॉयस बनाएं और उन्हें मन्जूरी के लिए अपने ग्राहकों के साथ साझा करें।",
				2: "अपनी शिपमेंट के बारे में जानकारी दें और दस्तावेज़ जोड़ें। अपनी शिपमेंट की सभी प्रक्रियाएं एक ही चैनल से अपने ग्राहकों के साथ प्रबंधित करें।",
				3: "आयात और निर्यात से संबंधित सभी वित्तीय स्थिति को तुरंत ट्रैक करें।",
			},
		},
		$content: {
			headline: "समर्थित लॉजिस्टिक प्रकार",
			$title: {
				0: "समुद्र",
				1: "हवा",
				2: "सड़क",
				3: "रेल",
			},
		},
		$pricing: {
			0: {
				title: "मुफ्त",
				subtitle: "व्यक्तिगत",
				features: ["1 उपयोगकर्ता", "3 साझेदार", "10 शिपमेंट", "10 इनवॉयस"],
			},
			1: {
				title: "वार्षिक",
				subtitle: "कंपनियाँ",
				features: [
					"20 उपयोगकर्ता",
					"100 साझेदार",
					"1000 शिपमेंट",
					"1000 इनवॉयस",
				],
			},
			2: {
				title: "मासिक",
				subtitle: "छोटे व्यापार",
				features: ["10 उपयोगकर्ता", "30 साझेदार", "250 शिपमेंट", "250 इनवॉयस"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function} सुनिश्चित करें कि आप इसे करना चाहते हैं?",
		created: "{item} बनाया गया।",
		deleted: "{item} हटा दिया गया।",
		file_oversize: "फ़ाइल का आकार अधिकतम {size} होना चाहिए।",
		invalid: "अमान्य {item}",
		sent_email: "ईमेल भेजा गया। ({value})",
		sign_in_failed: "ईमेल या पासवर्ड गलत है",
		updated: "{item} अपडेट किया गया।",
		migrated: "{item} स्थानांतरित किया गया। ({value})",
	},
	$productGroup: {
		add_child: "उप-समूह जोड़ें",
		add_to_root: "मुख्य स्तर पर जोड़ें",
		has_children_fail_message:
			"उप-समूह वाले उत्पाद समूह को हटाया नहीं जा सकता है",
		initial_message: "पहले उत्पाद समूह जोड़ने के लिए {0} बटन दबाएं।",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} द्वारा बनाया गया।",
			update: "{name} द्वारा अपडेट किया गया।",
			send: "{name} ने अनुमोदन के लिए धारक को भेजा।",
			revoke: "{name} ने वापस लिया।",
			approve: "{name} ने मान्यता दी।",
			demand_revise: "{name} ने संशोधन की मांग की।",
			reject: "{name} ने इनकार किया।",
			mark_as_processed: "{name} ने प्रसंस्कृत के रूप में चिह्नित किया।",
			create_attachment: "{name} ने एक संलग्नक बनाया।",
			update_attachment: "{name} ने एक संलग्नक को अपडेट किया।",
			delete_attachment: "{name} ने एक संलग्नक को हटा दिया।",
		},
		$actionDescription2: {
			send: '{name} को "भेजा गया" रूप में चिह्नित किया गया।',
			revoke: '{name} को "रद्द किया गया" रूप में चिह्नित किया गया।',
			approve: '{name} को "मान्यता प्राप्त" रूप में चिह्नित किया गया।',
			demand_revise:
				'{name} को "संशोधन की प्रतीक्षा" रूप में चिह्नित किया गया।',
			reject: "{name} को इनकार किया गया।",
			mark_as_processed: `{name} को "प्रसंस्कृत" रूप में चिह्नित किया गया।`,
		},
		$actions: {
			approve: "मान्यता दें",
			demand_revise: "संशोधन की मांग करें",
			mark_as_approved: "मान्यता प्राप्त",
			mark_as_processed: "प्रसंस्कृत",
			mark_as_rejected: "इनकार किया गया",
			mark_as_revise_demanded: "संशोधन की मांग की गई",
			mark_as_revoked: "रद्द किया गया",
			mark_as_sent: "भेजा गया",
			reject: "इनकार करें",
			revoke: "रद्द करें",
			send: "भेजें",
		},
		$status: {
			approved: "मान्यता प्राप्त",
			pending_approval: "मान्यता की प्रतीक्षा में",
			pending_revise: "संशोधन की प्रतीक्षा में",
			processed: "प्रसंस्कृत",
			rejected: "इनकार किया गया",
			revoked: "रद्द किया गया",
			template: "टेम्पलेट",
		},
		send_confirm_message:
			"क्या आप प्रोफ़ॉर्मा इनवॉयस को धारक को भेजना चाहते हैं?",
		send_success_message: "प्रोफ़ॉर्मा इनवॉयस को धारक को भेजा गया।",
		approve_confirm_message:
			"क्या आप प्रोफ़ॉर्मा इनवॉयस को मान्यता देना चाहते हैं?",
		approve_success_message: "प्रोफ़ॉर्मा इनवॉयस को मान्यता प्राप्त हुई।",
		demand_revise_success_message:
			"प्रोफ़ॉर्मा इनवॉयस संशोधन के लिए सफलतापूर्वक भेजा गया।",
		reject_confirm_message:
			"क्या आप प्रोफ़ॉर्मा इनवॉयस को इनकार करना चाहते हैं?",
		reject_success_message: "प्रोफ़ॉर्मा इनवॉयस को इनकार कर दिया गया।",
		mark_as_processed_confirm_message:
			"क्या आप प्रोफ़ॉर्मा इनवॉयस को प्रसंस्कृत के रूप में चिह्नित करना चाहते हैं?",
		mark_as_processed_success_message:
			"प्रोफ़ॉर्मा इनवॉयस को प्रसंस्कृत के रूप में चिह्नित किया गया।",
		revoke_confirm_message:
			"क्या आप प्रोफ़ॉर्मा इनवॉयस को वापस लेना चाहते हैं?",
		revoke_success_message: "प्रोफ़ॉर्मा इनवॉयस को वापस लिया गया।",
		demand_revise_description_message:
			"कृपया बताएं कि आप कैसे संशोधन चाहते हैं:",
		no_attachment_text: "अबतक कोई संलग्नक अपलोड नहीं किया गया है।",
	},
	$serviceMessage: {
		already_subs_module: "इस मॉड्यूल के लिए पहले से ही एक सदस्यता है।",
		bad_request: "बुरा अनुरोध",
		code_already_used: "कोड पहले से ही उपयोग किया गया है।",
		container_status_not_available: "कंटेनर स्थिति उपलब्ध नहीं है।",
		existing_email_warning: "ईमेल पहले से ही मौजूद है।",
		existing_employee_error:
			"इस उपयोगकर्ता को आपकी कंपनी में पहले से ही जोड़ दिया गया है।",
		existing_employee_for_email_warning:
			"इस ईमेल के लिए पहले से ही एक आमंत्रण कोड भेजा गया है।",
		existing_invitation_warning:
			"इस ईमेल के लिए पहले से ही एक आमंत्रण कोड भेजा गया है।",
		existing_token_already_have_time:
			"मौजूद टोकन का पहले से ही पर्याप्त समय है।",
		existing_user: "उपयोगकर्ता पहले से ही मौजूद है।",
		five_min_reset_password_mail:
			"एक बार पासवर्ड रीसेट मेल का अनुरोध करने के बाद कम से कम 5 मिनट का इंतजार करें।",
		forbidden: "निषिद्ध",
		internal_server_error: "आंतरिक सर्वर त्रुटि।",
		instance_can_no_delete_existing_booking_code:
			"आइटम, मौजूदा बुकिंग कोड के कारण अब हटा नहीं सकता है।",
		instance_cannot_delete: "आइटम अब हटा नहीं सकता।",
		instance_not_found: "आइटम नहीं मिला।",
		invitation_accepting_is_due_to_the_admin:
			"आमंत्रण स्वीकृति प्रशासक के कारण है।",
		invitation_already_discarded: "आमंत्रण पहले से ही निरस्त किया गया है।",
		invitation_already_used: "आमंत्रण पहले से ही उपयोग किया गया है।",
		invitation_has_been_used: "आमंत्रण पहले से ही उपयोग किया गया है।",
		invitation_not_exist_email: "आमंत्रण ईमेल पता मौजूद नहीं है।",
		invitation_not_for_custom_agency:
			"यह आमंत्रण एक कस्टम एजेंसी के लिए नहीं है।",
		invitation_not_for_company: "यह आमंत्रण किसी कंपनी के लिए नहीं है।",
		invitation_not_found: "आमंत्रण नहीं मिला।",
		invitation_not_valid: "आमंत्रण अब वैध नहीं है।",
		invalid_current_password: "आपका वर्तमान पासवर्ड अमान्य है।",
		invalid_identifier_or_password: "अमान्य ईमेल या पासवर्ड",
		invalid_invitation_code: "अमान्य आमंत्रण कोड।",
		invalid_moving_request: "अमान्य मूविंग रिक्वेस्ट।",
		invalid_refresh_token: "अमान्य रिफ्रेश टोकन।",
		invalid_operation: "अमान्य क्रिया।",
		invalid_unit: "अमान्य इकाई।",
		have_no_corporation: "आपकी कंपनी नहीं है।",
		have_no_custom_agency: "आपकी कस्टम एजेंसी नहीं है।",
		have_no_employee: "आपका कर्मचारी नहीं है।",
		link_not_be_used_anymore: "इस लिंक का और उपयोग नहीं किया जा सकता है।",
		link_has_been_expired: "लिंक का समय समाप्त हो गया है।",
		missing_required_fields: "आवश्यक क्षेत्रों की कमी है।",
		no_employee_found: "कोई कर्मचारी नहीं मिला।",
		no_proper_partner_found: "उचित साथी नहीं मिला।",
		not_acceptable: "स्वीकार्य नहीं है।",
		not_allowed_action: "आपकी इस क्रिया की अनुमति नहीं है।",
		not_allowed_use_api: "इस API का उपयोग करने की आपकी अनुमति नहीं है।",
		not_found: "नहीं मिला।",
		not_permitted_use_api: "इस API का उपयोग करने की आपकी अनुमति नहीं है",
		own_invitation_cannot_accept: "आप खुद को आमंत्रित करने के लिए नहीं हैं।",
		parent_group_not_found: "माता-पिता समूह नहीं मिला।",
		partnerId_required: "पार्टनर आईडी आवश्यक है।",
		partnership_already_exists: "साझेदारी पहले से ही मौजूद है।",
		password_cant_involve_space_char:
			"पासवर्ड में रिक्त स्थान शामिल नहीं किया जा सकता है।",
		password_min_chars_least: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए।",
		ports_are_required: "पोर्ट्स आवश्यक हैं।",
		product_group_tree_not_found: "उत्पाद समूह पेड़ नहीं मिला।",
		proforma_invoice_action_not_available:
			"प्रोफॉर्मा चालान की स्थिति इस क्रिया के लिए उपलब्ध नहीं है।",
		proforma_invoice_template_update:
			"प्रोफॉर्मा चालान को अपडेट करने के लिए टेम्पलेट होना चाहिए।",
		shipment_method_is_required: "शिपमेंट मेथड आवश्यक है।",
		shipping_term_is_required: "शिपिंग टर्म आवश्यक है।",
		shipment_email_not_exist: "कृपया एक वैध ईमेल दर्ज करें",
		someone_else_uses_this_email:
			"इस ईमेल कोड को कोई अन्य व्यक्ति उपयोग कर रहा है।",
		someone_else_uses_this_username:
			"इस उपयोगकर्ता नाम कोड को कोई अन्य व्यक्ति उपयोग कर रहा है।",
		something_went_wrong: "कुछ गड़बड़ हो गई है।",
		type_can_be_abstract_company:
			'"type" विशेषता केवल "abstract_company" हो सकती है।',
		unauthorized: "अनधिकृत",
		undefined_result: "अनिर्दिष्ट परिणाम।",
		unexpected_error_occurred: "अचानक एक त्रुटि हुई है।",
		unit_already_added: "इकाई पहले से ही जोड़ी गई है।",
		username_min_chars_error: '"उपयोगकर्ता नाम" कम से कम 6 अक्षर होना चाहिए।',
		user_already_employee: "उपयोगकर्ता पहले से ही कर्मचारी है।",
		user_already_exists:
			"उपयोगकर्ता पहले से ही किसी कंपनी के कर्मचारी के रूप में है।",
		user_does_not_exist: "उपयोगकर्ता मौजूद नहीं है।",
		user_not_found: "उपयोगकर्ता नहीं मिला।",
	},
	$shipment: {
		$actionDescription: {
			create: "{name} द्वारा बनाया गया।",
			update: "{name} द्वारा अपडेट किया गया।",
			create_container: "{name} ने एक कंटेनर जोड़ा।",
			update_container: "{name} ने एक कंटेनर को अपडेट किया।",
			delete_container: "{name} ने एक कंटेनर को हटा दिया।",
			create_attachment: "{name} ने एक अटैचमेंट बनाया।",
			update_attachment: "{name} ने एक अटैचमेंट को अपडेट किया।",
			delete_attachment: "{name} ने एक अटैचमेंट को हटा दिया।",
			$land: {
				create_container: "{name} ने एक वाहन जोड़ा।",
				update_container: "{name} ने एक वाहन को अपडेट किया।",
				delete_container: "{name} ने एक वाहन को हटा दिया।",
			},
		},
		$containerStatus: {
			reserved: "रिजर्व किया गया",
			on_load: "लोड हो गया",
			on_way: "रास्ता पर",
			arrived: "पहुंच गया",
			off_load: "अनलोड हो गया",
		},
		$method: {
			sea: "समुद्री",
			aerial: "हवाई",
			land: "भूमि",
			railway: "रेलवे",
		},
		$vehicle: {
			license_number: "लाइसेंस नंबर",
		},
		archiving_success_message: "शिपमेंट को संग्रहीत किया गया है।",
		leave_message: "अपना संदेश या टिप्पणी छोड़ें",
		no_booking_info:
			"अभी तक बुकिंग जानकारी परिभाषित नहीं है। जोड़ने के लिए {0} बटन दबाएं।",
		no_transportation_info:
			"अभी तक बुकिंग शिपमेंट विवरण परिभाषित नहीं हैं। परिभाषित करने के लिए {0} बटन दबाएं।",
		un_archiving_success_message: "शिपमेंट को संग्रहण से बाहर निकाला गया है।",
	},
	$shortening: {
		number: "संख्या",
	},
	$socialMedia: {
		facebook: "फेसबुक",
		instagram: "इंस्टाग्राम",
		linked_in: "लिंक्डइन",
		twitter: "ट्विटर",
	},
	$unit: {
		millimeter: "मिलीमीटर",
		meter: "मीटर",
		kilometer: "किलोमीटर",
		milligram: "मिलीग्राम",
		gram: "ग्राम",
		kilogram: "किलोग्राम",
		square_meter: "वर्ग मीटर",
		hectare: "हेक्टेयर",
		square_kilometer: "वर्ग किलोमीटर",
		milliliter: "मिलीलीटर",
		liter: "लीटर",
		cubic_meter: "क्यूबिक मीटर",
		acres: "एकड़",
		feet: "फीट",
		fluid_ounces: "फ्लूइड आउंस",
		gallons: "गैलन",
		inches: "इंच",
		miles: "मील",
		ounces: "आउंस",
		pounds: "पाउंड",
		quarts: "क्वार्ट",
		square_feet: "वर्ग फीट",
		square_miles: "वर्ग मील",
		square_yards: "वर्ग यार्ड",
		square_inches: "वर्ग इंच",
		yards: "यार्ड",
		pieces: "टुकड़े",
		boxes: "बॉक्स",
		pallets: "पैलेट",
		dozen: "डजन",
		ton: "टन",
	},
	$validation: {
		required: "{item} खाली नहीं छोड़ा जा सकता",
		required_for_default_language:
			"{item} जानकारी कंपनी के डिफ़ॉल्ट भाषा के लिए परिभाषित होनी चाहिए।",
		invalid: "{item} अवैध है",
		email: "कृपया एक वैध ईमेल दर्ज करें",
		min_length: "{item} कम से कम {length} अक्षर होने चाहिए",
		max_length: "{item} अधिकतम {length} अक्षर होने चाहिए",
		password_repeat_warning:
			"पासवर्ड दोहराव नए पासवर्ड के साथ समान होना चाहिए।",
	},

	accept_invitation_success_message:
		"आपने आमंत्रण सफलतापूर्वक स्वीकार किया है।",
	accessibility: "पहुंचने की सुविधा",
	actions: "क्रियाएँ",
	active: "सक्रिय",
	add: "जोड़ें",
	add_item: "{item} जोड़ें",
	address: "पता",
	again: "फिर",
	alert: "चेतावनी",
	already_have_employee_message: "आप पहले से ही किसी कंपनी के कर्मचारी हैं।",
	amount: "रकम",
	archive: "संग्रहीत करें",
	attachment: "अटैचमेंट",
	attachments: "अटैचमेंट्स",

	booking: "बुकिंग",
	booking_code: "बुकिंग कोड",
	business_partner: "व्यापारिक साथी",
	business_partners: "व्यापारिक साथी",

	cancel: "रद्द करें",
	change_password: "पासवर्ड बदलें",
	checking_invitation_code_message: "आमंत्रण कोड की जाँच हो रही है",
	choose_image: "छवि चुनें",
	clear: "साफ़ करें",
	close: "बंद करें",
	code: "कोड",
	companies: "कंपनियाँ",
	company: "कंपनी",
	company_profile: "कंपनी प्रोफ़ाइल",
	commercial: "वाणिज्यिक",
	commercial_partners: "वाणिज्यिक साथी",
	content: "सामग्री",
	configuration: "कॉन्फ़िगरेशन",
	confirm: "पुष्टि करें",
	contact: "संपर्क",
	contact_info: "संपर्क जानकारी",
	contact_to_us: "हमसे संपर्क करें",
	container: "कंटेनर",
	containers: "कंटेनर्स",
	content: "सामग्री",
	countries: "देश",
	country: "देश",
	create: "बनाएं",
	create_company: "कंपनी बनाएं",
	created_at: "बनाया गया",
	currencies: "मुद्राएँ",
	currency: "मुद्रा",
	current_password: "वर्तमान पासवर्ड",
	customs_agency: "कस्टम्स एजेंसी",
	customs_agencies: "कस्टम्स एजेंसिज़",

	date: "तारीख",
	dashboard: "डैशबोर्ड",
	default: "डिफ़ॉल्ट",
	default_language: "डिफ़ॉल्ट भाषा",
	delete: "हटाएं",
	delete_confirm_message: "क्या आप हटाने की पुष्टि करते हैं?",
	description: "विवरण",
	detail: "विवरण",
	discard: "छोड़ें",
	document_number: "दस्तावेज़ संख्या",
	download: "डाउनलोड",

	edit: "संपादित करें",
	edit_item: "{item} संपादित करें",
	email: "ईमेल",
	employee: "कर्मचारी",
	employee_type: "कर्मचारी का प्रकार",
	employees: "कर्मचारी",
	estimated_arrival_day: "अनुमानित पहुंचने का दिन",
	expense: "खर्च",
	expenses: "खर्चे",
	exported_containers: "निर्यातित कंटेनर",

	faq: "सामान्य प्रश्न",
	file: "फ़ाइल",
	filter: "फिल्टर",
	first_name: "पहला नाम",
	forget_password: "पासवर्ड भूल गए",
	forget_password_message: "क्या आप अपना पासवर्ड भूल गए हैं?",
	from: "से",

	hide: "छुपाएँ",
	home_page: "होम पेज",

	imported_containers: "आयातित कंटेनर",
	incoming: "आउटगोइंग",
	incoming_shipments: "इनकमिंग शिपमेंट्स",
	initial: "प्रारंभिक",
	invitation: "आमंत्रण",
	invitation_code: "आमंत्रण कोड",
	invitation_mail_sent: "आमंत्रण मेल भेजा गया",
	invitations: "आमंत्रण",

	join: "शामिल हों",

	language_options: "भाषा विकल्प",
	last_name: "उपनाम",
	last_one_year: "पिछला 1 वर्ष",
	latitude: "अक्षांश",
	loading_country: "लोडिंग कंट्री",
	loading_point: "लोडिंग पॉइंट",
	loading_port: "लोडिंग पोर्ट",
	logo: "लोगो",
	longitude: "देशान्तर",

	go_to_panel: "पैनल पर जाएं",

	mail_sent: "मेल भेजा गया",
	migrate: "माईग्रेट",
	migrate_partner: "साथी को माईग्रेट करें",
	message: "संदेश",
	message_company_create: "कंपनी बन गई है।",
	message_company_join: "कंपनी में शामिल हों",
	message_company_joined: "आपने कंपनी में शामिल हो गए हैं",
	message_employee_delete: "कर्मचारी हटा दिया गया है।",
	message_employee_save: "कर्मचारी सहेजा गया है।",
	message_join_company:
		"किसी कंपनी में शामिल होने के लिए अपना आमंत्रण कोड दर्ज करें।",
	method: "विधि",
	mobile_number: "मोबाइल नंबर",
	my_role: "मेरी भूमिका",

	name: "नाम",
	next: "आगे",
	new: "नया",
	new_password: "नया पासवर्ड",
	no: "नहीं",
	no_content: "कोई सामग्री नहीं",
	no_text: "कोई मजेदार बात नहीं",
	not_found_page_message: "मुझे खेद है, मैं यह पृष्ठ नहीं मिला सकता :(",
	note: "टिप्पणी",

	ok: "ठीक है",
	outgoing: "आउटगोइंग",
	outgoing_shipments: "आउटगोइंग शिपमेंट्स",

	parent_group: "मुख्य समूह",
	partner: "साथी",
	partners: "साथी",
	passive: "निष्क्रिय",
	password: "पासवर्ड",
	phone_number: "फ़ोन नंबर",
	previous: "पिछला",
	pricing: "मूल्य निर्धारण",
	product: "उत्पाद",
	product_group: "उत्पाद समूह",
	product_groups: "उत्पाद समूह",
	products: "उत्पाद",
	profile: "प्रोफ़ाइल",
	proforma_invoice: "पूर्व-मान्यता चालान",
	proforma_invoices: "पूर्व-मान्यता चालान",
	proforma_number: "पूर्व-मान्यता नंबर",
	purchase: "खरीद",

	quantity: "मात्रा",

	receiver: "प्राप्तकर्ता",
	receiver_company: "प्राप्तकर्ता कंपनी",
	receiver_customs_agency: "प्राप्तकर्ता कस्टम्स एजेंसी",
	redirection_to_home_link: "यहां क्लिक करें",
	redirection_to_home_message: "मुख्य पृष्ठ पर वापस जाने के लिए ",
	redirection_to_sign_in_message: "क्या आपका एक खाता है?",
	redirection_to_sign_up_message: "अब तक कोई खाता नहीं है?",
	reload: "पुनरारंभ करें",
	reset_password: "पासवर्ड रीसेट करें",

	sales: "बिक्री",
	save: "सहेजें",
	search: "खोज",
	select_file: "फ़ाइल चुनें",
	select_file_place_holder: "कृपया एक छवि चुनें",
	selected_partner: "चयनित साथी",
	send: "भेजें",
	send_again: "फिर से भेजें",
	send_email: "ईमेल भेजें",
	send_invitation: "आमंत्रण भेजें",
	sender: "भेजने वाला",
	sender_company: "भेजने वाली कंपनी",
	sender_customs_agency: "भेजने वाली कस्टम्स एजेंसी",
	show: "दिखाएं",
	shipment: "शिपमेंट",
	shipment_method: "शिपमेंट विधि",
	shipments: "शिपमेंट्स",
	shipments_filter: "शिपमेंट फ़िल्टर",
	shipping_term: "शिपिंग टर्म",
	shipping_terms: "शिपिंग टर्म्स",
	sign_in: "साइन इन करें",
	sign_out: "साइन आउट करें",
	sign_out_confirm_message:
		"क्या आप सत्यापित करना चाहेंगे कि आप सत्र समाप्त करना चाहते हैं?",
	sign_out_success_message: "अलविदा।",
	sign_up: "साइन अप",
	social_media: "सोशल मीडिया",
	social_media_account: "सोशल मीडिया खाता",
	status: "स्थिति",
	subscribe: "सदस्यता लें",
	super: "सुपर",
	switch_language: "भाषा बदलें",
	switch_theme: "थीम बदलें",

	target: "लक्ष्य",
	target_partner: "लक्ष्य साथी",
	theme: "थीम",
	this_month: "इस महीने",
	this_year: "इस वर्ष",
	title: "शीर्षक",
	to: "को",
	toDelete: "हटाने के लिए",
	total: "कुल",
	total_amount: "कुल राशि",
	type: "प्रकार",
	types: "प्रकार",

	un_archive: "आपात से बाहर निकालें",
	unit: "इकाई",
	units: "इकाइयां",
	unloading_country: "अनलोडिंग देश",
	unloading_point: "अनलोडिंग पॉइंट",
	unloading_port: "अनलोडिंग पोर्ट",
	use_code: "कोड का उपयोग करें",
	use_invitation_code: "निमंत्रण कोड का उपयोग करें",
	use_invitation_code_description:
		"आपके ईमेल में भेजे गए निमंत्रण कोड को दर्ज करें।",
	use_invitation_code_success_message:
		"निमंत्रण कोड सफलतापूर्वक उपयोग किया गया।",
	user_profile: "मेरी प्रोफ़ाइल",

	vehicle: "वाहन",
	vehicles: "वाहन",
	visibility: "दृश्यता",

	warehouse: "गोदाम",
	warehouses: "गोदाम",
	website: "वेबसाइट",
	welcome: "स्वागत है",

	yes: "हाँ",
};

<template v-slot:extension>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="socialMedias"
			hide-default-footer
			:loading="loading"
			class="ma-4"
			style="cursor: pointer"
			@click:row="dialog.show"
			disable-sort
		>
			<template v-slot:item.type="{ item }">
				{{ $t(`$socialMedia.${item.type}`) }}
			</template>
		</v-data-table>
		<v-btn color="accent" elevation="0" fixed bottom right fab @click="dialog.show(null)">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<social-media-dialog
			v-model="dialog.visibility"
			:item="dialog.item"
			@saved="dialog.saved"
			@deleted="dialog.deleted"
		/>
	</v-container>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import SocialMediaDialog from "./dialogs/SocialMediaDialog";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	components: { SocialMediaDialog },
	data() {
		return {
			loading: false,
			socialMedias: [],
			headers: [
				{ text: this.$t("type"), value: "type" },
				{ text: this.$t("name"), value: "value" },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (socialMedia) => {
					this.dialog.item = socialMedia;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.socialMedia.filter(this.company.id, {
				onSuccess: (result) => {
					this.socialMedias = result;
					//TODO: REMOVE HERE (change '_id' to 'id')
					this.socialMedias.forEach((sm) => (sm.id = sm._id));
					this.loading = false;
				},
				onFail: (error) => {
					if (error) console.error(error);
					this.loading = false;
				},
			});
		},
	},
	created() {
		this.loadItems();
	},
};
</script>

import Model from "../_model";

export default class WarehouseModel extends Model {
	constructor(provider) {
		super(provider, "companies/{companyId}/warehouses");
	}

	_getUrl(companyId, { id }) {
		let url = this.apiMethod.replace("{companyId}", companyId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(companyId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(companyId, {}),
			params: {},
			onSuccess,
			onFail,
		});
	}

	find(companyId, id, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(companyId, { id }),
			id,
			onSuccess,
			onFail,
		});
	}

	create(companyId, warehouse, { onSuccess, onFail }) {
		const body = {
			name: warehouse.name,
			address: warehouse.address,
			latitude: warehouse.latitude,
			longitude: warehouse.longitude,
			phoneNumber: warehouse.phoneNumber,
			mobileNumber: warehouse.mobileNumber,
			email: warehouse.email,
		};
		return super.create(body, {
			url: this._getUrl(companyId, {}),
			onSuccess,
			onFail,
		});
	}

	update(companyId, warehouse, { onSuccess, onFail }) {
		const body = {
			name: warehouse.name,
			address: warehouse.address,
			latitude: warehouse.latitude,
			longitude: warehouse.longitude,
			phoneNumber: warehouse.phoneNumber,
			mobileNumber: warehouse.mobileNumber,
			email: warehouse.email,
		};
		return super.update(body, {
			url: this._getUrl(companyId, { id: warehouse.id }),
			id: warehouse.id,
			onSuccess,
			onFail,
		});
	}

	delete(companyId, id, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(companyId, { id }),
			id,
			onSuccess,
			onFail,
		});
	}
}

<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:options.sync="tableOptions"
		:server-items-length="pagination.totalItemCount"
		:footer-props="tableFooterProps"
		:loading="loading"
		style="cursor: pointer"
		@click:row="itemAction"
		disable-sort
	>
		<template v-slot:item.buttons="{ item }">
			<v-tooltip left open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon v-on="on">
						<v-icon v-if="item.type == 'abstract_company'" small>
							mdi-clipboard-edit-outline
						</v-icon>
						<v-icon v-else-if="item.type == 'company'" small>
							mdi-clipboard-search-outline
						</v-icon>
					</v-btn>
				</template>
				<span v-if="item.type == 'abstract_company'">{{ $t("edit") }}</span>
				<span v-else-if="item.type == 'company'">{{ $t("show") }}</span>
			</v-tooltip>
		</template>

		<template slot="body.append">
			<partner-dialog
				v-model="dialog.visibility"
				:item="dialog.item"
				:itemId="dialog.itemId"
				@saved="dialog.saved"
				@deleted="dialog.deleted"
				@closed="dialog.closed"
			/>
		</template>
	</v-data-table>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import remote from "../../../../data/remote";
import { mapState, mapGetters } from "vuex";
import PartnerDialog from "../dialogs/PartnerDialog.vue";
import uiMixin from "../../../../store/interfaces/ui.mixin";

export default {
	props: {
		corporationId: String,
		types: Array, // ["company", "abstract_company", "customs_agency"]
		partnerDialogState: Boolean,
		invitationCodeDialogState: Boolean,
		needRefresh: Boolean,
	},
	components: { PartnerDialog },
	mixins: [paginationMixin, uiMixin],
	data() {
		return {
			items: [],
			loading: false,
			headers: [
				{
					text: this.$t("name"),
					value: "tableName",
				},
				{ text: this.$t("country"), value: "tableCountryName" },
				{ text: "", value: "buttons", width: 70, align: "right" },
			],
			dialog: {
				visibility: false,
				item: null,
				itemId: null,
				show: (item, { itemId }) => {
					this.dialog.item = item;
					this.dialog.itemId = itemId;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
					this.$emit("partnerDialogClosed");
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				closed: () => {
					this.$emit("partnerDialogClosed");
				},
			},
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company", "customsAgency"]),
	},
	watch: {
		partnerDialogState(value) {
			if (value) this.dialog.show(null, {});
		},
		invitationCodeDialogState(value) {
			if (value) {
				this.showCodeInputDialog();
			}
		},
		needRefresh(value) {
			if (value) {
				this.loadItems();
				this.$emit("refreshed");
			}
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.corporations.partners.filter(
				this.corporationId,
				{
					filter: { types: this.types },
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.items = result.items.sort((a, b) => {
							if (a.createdAt && b.createdAt)
								return a.createdAt < b.createdAt ? 1 : -1;
							else return !a.createdAt ? 1 : -1;
						});
						for (const item of this.items) {
							if (this.company) {
								item.tableName = item.name;
								item.tableCountryName = this.$t(
									`$country.${
										this.countries.find((i) => i.id == item.countryId).name
									}`
								);
							} else if (this.customsAgency) {
								item.tableName = item.ownerCompany.name;
								item.tableCountryName = this.$t(
									`$country.${
										this.countries.find(
											(i) => i.id == item.ownerCompany.countryId
										).name
									}`
								);
							}
						}
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: () => (this.loading = false),
				}
			);
		},
		itemAction(item) {
			this.dialog.show(item, { itemId: this.customsAgency ? item.id : null });
		},
		showCodeInputDialog() {
			this.showTextInputDialog({
				title: this.$t("use_invitation_code"),
				message: this.$t("use_invitation_code_description"),
				inputTitle: this.$t("code"),
				inputRules: [
					(v) =>
						(Boolean(v) && Boolean(v.trim())) ||
						this.$t("$validation.required", { item: this.$t("code") }),
				],
				onSubmit: (text) => {
					return new Promise((resolve, reject) => {
						this.showProgressDialog();
						remote.invitations.accept(text.trim(), {
							onSuccess: (result) => {
								this.showSnackBar({
									message: this.$t(
										"$proformaInvoice.demand_revise_success_message"
									),
								});
								this.hideProgressDialog();
								this.loadItems();
								this.$emit("saved", result);
								return resolve(true);
							},
							onFail: (e) => {
								this.hideProgressDialog();
								reject(e);
							},
						});
					});
				},
				didClose: () => this.$emit("invitationCodeDialogClosed"),
			});
		},
	},
	created() {
		this.loadItems();
	},
};
</script>

import HttpModule from "./_http_module";

export default class StructureApi extends HttpModule {
	getCountries({ onSuccess, onFail }) {
		super._get("countries", { onSuccess, onFail });
	}
	getCurrencies({ status, onSuccess, onFail }) {
		super._get("currencies", { params: { status }, onSuccess, onFail });
	}
	getCountryPorts(countryId, { onSuccess, onFail }) {
		super._get(`countries/${countryId}/ports`, { onSuccess, onFail });
	}
	getShippingTerms({ onSuccess, onFail }) {
		super._get("shipping_terms", { onSuccess, onFail });
	}
	getUnits({ onSuccess, onFail }) {
		super._get("units", { onSuccess, onFail });
	}
}

<template>
	<v-container fill-height align-center justify-center>
		<v-card elevation="1" max-width="420" min-width="320">
			<v-row>
				<v-col class="px-4 pt-4 d-flex justify-center">
					<span>{{ message }}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col v-if="inProgress" class="d-flex justify-center">
					<v-progress-circular
						:size="100"
						:width="7"
						indeterminate
						color="accent"
						class="ma-3"
					/>
				</v-col>
				<v-col v-else class="d-flex justify-center">
					<v-btn class="ma-2" @click="homeAction" color="info">
						<v-icon left>mdi-home</v-icon>
						{{ $t("home_page") }}
					</v-btn>
					<v-btn v-if="isAuthenticated" class="ma-2" @click="signOutAction">
						{{ $t("sign_out") }}<v-icon right>mdi-exit-run</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import remote from "../../data/remote";

const InvitationType = {
	Employee: "employee",
	CompanyPartner: "company_partner",
	CustomsPartner: "customs_partner",
};

export default {
	mixins: [uiMixin],
	data() {
		return {
			inProgress: undefined,
			type: undefined,
			code: undefined,
			message: "",
			failed: undefined,
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapGetters("auth", ["isAuthenticated"]),
		...mapState(["employee"]),
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		...mapActions(["prepare", "resetAppData"]),
		homeAction() {
			this.$router.replace({ name: "home" });
		},
		signOutAction() {
			this.signOut(() => {
				this.showSnackBar({
					message: this.$t("sign_out_success_message"),
				});
				this.$router.replace({
					name: "signin",
					query: { invitationType: this.type, invitationCode: this.code },
				});
			});
		},
	},
	created() {
		this.type = this.$route.params.type;
		this.code = this.$route.query.code;
		if (
			![
				InvitationType.Employee,
				InvitationType.CompanyPartner,
				InvitationType.CustomsPartner,
			].includes(this.type) ||
			!this.code
		)
			this.$router.replace({ name: "404" });
		else if (this.type == InvitationType.Employee && this.employee)
			this.message = this.$t("already_have_employee_message");
		else if (!this.isAuthenticated) {
			if (this.type == InvitationType.Employee)
				this.$router.replace({
					name: "signup",
					query: { invitationType: this.type, invitationCode: this.code },
				});
			else
				this.$router.replace({
					name: "signin",
					query: { invitationType: this.type, invitationCode: this.code },
				});
		} else {
			this.inProgress = true;
			this.message = this.$t("checking_invitation_code_message") + "...";
			remote.invitations.accept(this.code, {
				onSuccess: () => {
					this.resetAppData();
					this.$router.replace({ name: "home" });
					this.prepare();
					this.inProgress = false;
					this.showSnackBar({
						message: this.$t("accept_invitation_success_message"),
					});
				},
				onFail: (error) => {
					this.message = error.message;
					this.inProgress = false;
				},
			});
		}
	},
};
</script>

<template>
	<v-card :dark="dark" elevation="1" :color="backgroundColor">
		<div
			v-if="title"
			class="font-subtitle-1 text-center font-weight-light pt-3"
		>
			{{ title }}
		</div>
		<v-row v-if="data">
			<v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(data.salesReport.currentYearTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("last_one_year") }}<br />({{ $t("sales") }})
				</v-row>
			</v-col>
			<v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(data.purchaseReport.currentYearTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("last_one_year") }}<br />({{ $t("purchase") }})
				</v-row>
			</v-col>
			<v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(data.salesReport.currentMonthTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("this_month") }}<br />({{ $t("sales") }})
				</v-row>
			</v-col>
			<v-col cols="6" sm="3" class="pb-0">
				<v-row class="text-h5 text--darken-1 text-center ma-0 d-block">
					{{ currencyFormatter.format(data.purchaseReport.currentMonthTotal) }}
				</v-row>
				<v-row class="text-caption text-center ma-0 d-block">
					{{ $t("this_month") }}<br />({{ $t("purchase") }})
				</v-row>
			</v-col>
		</v-row>

		<vue-apex-charts
			v-if="showChart"
			height="120"
			:options="chart.options"
			:series="chart.series"
		/>
	</v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
	props: {
		data: Object,
		title: String,
		dark: Boolean,
		backgroundColor: String,
	},
	components: { VueApexCharts },
	data() {
		return {
			showChart: false,
			showSalesChart: false,
			showPurchaseChart: false,
			currencyFormatter: undefined,
			chart: {
				series: [],
				options: {
					chart: {
						type: "area",
						background: "transparent",
						sparkline: { enabled: true },
					},
					dataLabels: { enabled: false },
					theme: { mode: this.dark ? "dark" : "light" },
					stroke: { curve: "smooth", lineCap: "butt", width: 1.5 },
					colors: [
						this.$vuetify.theme.currentTheme.info,
						this.$vuetify.theme.currentTheme.accent,
					],
					fill: {
						colors: [
							this.$vuetify.theme.currentTheme.info,
							this.$vuetify.theme.currentTheme.accent,
						],
						opacity: 0.3,
						type: "gradient",
					},
					xaxis: {
						categories: [],
					},
					yaxis: {
						min: 0,
						max: undefined,
					},
					tooltip: {
						enabled: true,
						x: {
							formatter: function (value, data) {
								return new moment.utc(
									data.w.config.series[data.seriesIndex].data[
										data.dataPointIndex
									].x
								).format("MMMM");
							},
						},
						y: {
							formatter: (value) => this.currencyFormatter.format(value),
						},
					},
				},
			},
		};
	},
	watch: {
		data(newValue) {
			this.handleIncomingData();
		},
	},
	created() {
		this.handleIncomingData();
	},
	methods: {
		handleIncomingData() {
			if (this.data) {
				this.currencyFormatter = new Intl.NumberFormat(moment.locale, {
					style: "currency",
					currency: this.data.currency.code,
				});

				for (const key in this.data.salesReport.monthBasedTotals) {
					if (this.data.salesReport.monthBasedTotals[key] > 0) {
						this.showSalesChart = true;
						break;
					}
				}
				for (const key in this.data.purchaseReport.monthBasedTotals) {
					if (this.data.purchaseReport.monthBasedTotals[key] > 0) {
						this.showPurchaseChart = true;
						break;
					}
				}

				let reports = [];
				if (this.showSalesChart) reports.push("sales");
				if (this.showPurchaseChart) reports.push("purchase");

				let maxY = 0;
				for (let i = 0; i < reports.length; i++) {
					const type = reports[i];
					this.chart.series[i] = {};
					this.chart.series[i].name = this.$t(type);
					this.chart.series[i].data = [];
					for (const key in this.data[`${type}Report`].monthBasedTotals) {
						let val = this.data[`${type}Report`].monthBasedTotals[key];
						this.chart.series[i].data.push({
							x: key,
							y: val,
						});
						if (val * 1.1 > maxY) maxY = val * 1.1;
					}
				}
				this.chart.options.yaxis.max = maxY;

				this.showChart = true;
			}
		},
	},
};
</script>

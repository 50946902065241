export default {
	$accessibility: {
		present: "Laufend",
		archived: "Archiviert",
	},
	$accessModifier: {
		$description: {
			private: "Privat",
			hidden_from_receiver: "Vor Empfänger versteckt",
			hidden_from_sender: "Vor Absender versteckt",
			shared_with_everyone: "Mit allen geteilt",
			shared_with_custom: "Mit benutzerdefinierten geteilt",
			shared_with_receiver: "Mit Empfänger geteilt",
			shared_with_sender: "Mit Absender geteilt",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andorra",
		united_arab_emirates: "Vereinigte Arabische Emirate",
		afghanistan: "Afghanistan",
		antigua_and_barbuda: "Antigua und Barbuda",
		anguilla: "Anguilla",
		albania: "Albanien",
		armenia: "Armenien",
		angola: "Angola",
		antarctica: "Antarktis",
		argentina: "Argentinien",
		american_samoa: "Amerikanisch-Samoa",
		austria: "Österreich",
		australia: "Australien",
		aruba: "Aruba",
		aland: "Åland",
		azerbaijan: "Aserbaidschan",
		bosnia_and_herzegovina: "Bosnien und Herzegowina",
		barbados: "Barbados",
		bangladesh: "Bangladesch",
		belgium: "Belgien",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgarien",
		bahrain: "Bahrain",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint-Barthélemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivien",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius und Saba",
		brazil: "Brasilien",
		bahamas: "Bahamas",
		bhutan: "Bhutan",
		bouvet_island: "Bouvetinsel",
		botswana: "Botswana",
		belarus: "Weißrussland",
		belize: "Belize",
		canada: "Kanada",
		cocos_islands: "Kokosinseln",
		dr_congo: "Demokratische Republik Kongo",
		central_african_republic: "Zentralafrikanische Republik",
		congo_republic: "Republik Kongo",
		switzerland: "Schweiz",
		ivory_coast: "Elfenbeinküste",
		cook_islands: "Cookinseln",
		chile: "Chile",
		cameroon: "Kamerun",
		china: "China",
		colombia: "Kolumbien",
		costa_rica: "Costa Rica",
		cuba: "Kuba",
		cabo_verde: "Kap Verde",
		curacao: "Curaçao",
		christmas_island: "Weihnachtsinsel",
		cyprus: "Zypern",
		czechia: "Tschechien",
		germany: "Deutschland",
		djibouti: "Dschibuti",
		denmark: "Dänemark",
		dominica: "Dominica",
		dominican_republic: "Dominikanische Republik",
		algeria: "Algerien",
		ecuador: "Ecuador",
		estonia: "Estland",
		egypt: "Ägypten",
		western_sahara: "Westsahara",
		eritrea: "Eritrea",
		spain: "Spanien",
		ethiopia: "Äthiopien",
		finland: "Finnland",
		fiji: "Fidschi",
		falkland_islands: "Falklandinseln",
		micronesia: "Mikronesien",
		faroe_islands: "Färöer",
		france: "Frankreich",
		gabon: "Gabun",
		united_kingdom: "Vereinigtes Königreich",
		grenada: "Grenada",
		georgia: "Georgien",
		french_guiana: "Französisch-Guayana",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Grönland",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadeloupe",
		equatorial_guinea: "Äquatorialguinea",
		greece: "Griechenland",
		south_georgia_and_south_sandwich_islands:
			"Südgeorgien und die Südlichen Sandwichinseln",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		hong_kong: "Hongkong",
		heard_island_and_mcdonald_islands: "Heard und McDonaldinseln",
		honduras: "Honduras",
		croatia: "Kroatien",
		haiti: "Haiti",
		hungary: "Ungarn",
		indonesia: "Indonesien",
		ireland: "Irland",
		israel: "Israel",
		isle_of_man: "Isle of Man",
		india: "Indien",
		british_indian_ocean_territory: "Britisches Territorium im Indischen Ozean",
		iraq: "Irak",
		iran: "Iran",
		iceland: "Island",
		italy: "Italien",
		jersey: "Jersey",
		jamaica: "Jamaika",
		jordan: "Jordanien",
		japan: "Japan",
		kenya: "Kenia",
		kyrgyzstan: "Kirgisistan",
		cambodia: "Kambodscha",
		kiribati: "Kiribati",
		comoros: "Komoren",
		st_kitts_and_nevis: "St. Kitts und Nevis",
		north_korea: "Nordkorea",
		south_korea: "Südkorea",
		kuwait: "Kuwait",
		cayman_islands: "Kaimaninseln",
		kazakhstan: "Kasachstan",
		laos: "Laos",
		lebanon: "Libanon",
		saint_lucia: "St. Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesotho",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		latvia: "Lettland",
		libya: "Libyen",
		morocco: "Marokko",
		monaco: "Monaco",
		moldova: "Moldawien",
		montenegro: "Montenegro",
		saint_martin: "St. Martin",
		madagascar: "Madagaskar",
		marshall_islands: "Marshallinseln",
		north_macedonia: "Nordmazedonien",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mongolei",
		macao: "Macao",
		northern_mariana_islands: "Nördliche Marianen",
		martinique: "Martinique",
		mauritania: "Mauretanien",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Malediven",
		malawi: "Malawi",
		mexico: "Mexiko",
		malaysia: "Malaysia",
		mozambique: "Mosambik",
		namibia: "Namibia",
		new_caledonia: "Neukaledonien",
		niger: "Niger",
		norfolk_island: "Norfolkinsel",
		nigeria: "Nigeria",
		nicaragua: "Nicaragua",
		netherlands: "Niederlande",
		norway: "Norwegen",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Neuseeland",
		oman: "Oman",
		panama: "Panama",
		peru: "Peru",
		french_polynesia: "Französisch-Polynesien",
		papua_new_guinea: "Papua-Neuguinea",
		philippines: "Philippinen",
		pakistan: "Pakistan",
		poland: "Polen",
		saint_pierre_and_miquelon: "St. Pierre und Miquelon",
		pitcairn_islands: "Pitcairninseln",
		puerto_rico: "Puerto Rico",
		palestine: "Palästina",
		portugal: "Portugal",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Katar",
		reunion: "Réunion",
		romania: "Rumänien",
		serbia: "Serbien",
		russia: "Russland",
		rwanda: "Ruanda",
		saudi_arabia: "Saudi-Arabien",
		solomon_islands: "Salomonen",
		seychelles: "Seychellen",
		sudan: "Sudan",
		sweden: "Schweden",
		singapore: "Singapur",
		saint_helena: "St. Helena",
		slovenia: "Slowenien",
		svalbard_and_jan_mayen: "Svalbard und Jan Mayen",
		slovakia: "Slowakei",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Suriname",
		south_sudan: "Südsudan",
		sao_tome_and_principe: "São Tomé und Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Syrien",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Turks- und Caicosinseln",
		chad: "Tschad",
		french_southern_territories: "Französische Südgebiete",
		togo: "Togo",
		thailand: "Thailand",
		tajikistan: "Tadschikistan",
		tokelau: "Tokelau",
		timor_leste: "Timor-Leste",
		turkmenistan: "Turkmenistan",
		tunisia: "Tunesien",
		tonga: "Tonga",
		turkiye: "Türkei",
		trinidad_and_tobago: "Trinidad und Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwan",
		tanzania: "Tansania",
		ukraine: "Ukraine",
		uganda: "Uganda",
		us_minor_outlying_islands: "Amerikanische Überseeinseln",
		united_states: "Vereinigte Staaten",
		uruguay: "Uruguay",
		uzbekistan: "Usbekistan",
		vatican_city: "Vatikanstadt",
		st_vincent_and_grenadines: "St. Vincent und die Grenadinen",
		venezuela: "Venezuela",
		british_virgin_islands: "Britische Jungferninseln",
		us_virgin_islands: "Amerikanische Jungferninseln",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis und Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Jemen",
		mayotte: "Mayotte",
		south_africa: "Südafrika",
		zambia: "Sambia",
		zimbabwe: "Simbabwe",
	},
	$currency: {
		us_dollar: "US-Dollar",
		euro: "Euro",
		turkish_lira: "Türkische Lira",
	},
	$employeeType: {
		admin: "Administrator",
		default: "Standard",
		exporter: "Exporteur",
		importer: "Importeur",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Willkommen bei Antrego",
			description:
				"Halten Sie Ihre Import-Export-Prozesse mit Antrego unter Kontrolle!",
			sign_in_title: "Haben Sie bereits ein Konto?",
			sign_in_subtitle: "Hier einloggen",
			sign_up_title: "Möchten Sie ein Konto erstellen?",
			sign_up_subtitle: "Hier registrieren",
		},
		$noCompany: {
			title: "Willkommen, {name}",
			description:
				"Sie haben sich erfolgreich angemeldet. Sie müssen jetzt einem Unternehmen beitreten oder Ihr eigenes Unternehmen erstellen.",
			join_company_title:
				"Möchten Sie einem bestehenden Unternehmen beitreten?",
			join_company_subtitle:
				"Geben Sie hier Ihren Einladungscode ein und treten Sie dem Unternehmen bei.",
			create_company_title: "Möchten Sie Ihr eigenes Unternehmen erstellen?",
			create_company_subtitle: "Erstellen Sie hier ein neues Unternehmen.",
		},
	},
	$invitationType: {
		employee: "Mitarbeiter Einladung",
		company_partner: "Geschäftspartnerschaft Einladung",
		customs_partner: "Zollpartner Einladung",
	},
	$landing: {
		$question: {
			$title: {
				0: "Was ist Antrego?",
				1: "Welchen Beitrag leistet Antrego zu meinem Versandprozess?",
				2: "Welche Arten von Logistik unterstützt Antrego?",
				3: "Warum sollte ich Antrego verwenden?",
				4: "Ist Antrego eine kostenpflichtige Anwendung?",
				5: "Müssen unsere Kunden auch für Antrego bezahlen?",
			},
			$text: {
				0: "<p>Ein Versandverfolgungsprogramm, das Unternehmen, die Versand betreiben, und ihren Kunden hilft, gemeinsam zu verfolgen und mögliche Schäden durch Verzögerungen während des Verfolgungsprozesses zu verhindern.</p>",
				1: `<p>- Teilen Sie alle Echtzeit-Updates mit Ihren Kunden. Verbessern Sie die Servicequalität.<br>
				- Zeigen Sie alle Dokumente zu Ihrer Sendung in einem System an und teilen Sie sie mit Ihren Kunden.<br>
				- Verfolgen Sie alle Ihre Sendungen von einem Ort aus. Behalten Sie mit dynamischen Berichten die Finanzen im Griff.</p>`,
				2: `<p>- Seeversand<br>
				- Luftversand<br>
				- Landtransport<br>
				- Zugtransport</p>`,
				3: `<p>Es erleichtert den Versandprozess mit den angebotenen Dienstleistungen jederzeit und überall.<br>
				<strong>1. Einfacher Zugriff</strong><br>
				Von jedem Gerät und überall über den Webbrowser zugänglich.<br>
				<strong>2. Datensicherheit</strong><br>
				Alle Daten werden mit der SHA-256-Verschlüsselungstechnologie gespeichert.<br>
				<strong>3. Gemeinsame Verfolgung</strong><br>
				Bietet die Möglichkeit zur Verfolgung für alle Stakeholder im Geschäftsprozess.</strong>`,
				4: "<p>Ja, es ist eine kostenpflichtige Anwendung. Wir möchten Ihre Geschäftsprozesse erleichtern und gleichzeitig nützliche Verbesserungen vornehmen, indem wir alle Prozesse Ihrer Sendungen digitalisieren. Um diese Verbesserungen fortsetzen zu können und Platz auf unseren Servern zu nutzen, erheben wir von Ihnen eine monatliche Gebühr.</p>",
				5: "<p>Nein, Ihre Kunden müssen keine Gebühr zahlen, während sie Ihre Sendungen verfolgen.</p>",
			},
		},
		$lifecycle: {
			headline: "ALLE PROZESSE IM GRIFF",
			$title: {
				0: "Registrieren Sie sich",
				1: "Senden Sie eine Proforma-Rechnung",
				2: "Erstellen Sie einen Versand",
				3: "Verfolgen Sie finanzielle Aktivitäten",
			},
			$text: {
				0: "Geben Sie Firmeninformationen und Produkte ein und erstellen Sie Ihre Kunden.",
				1: "Erstellen Sie eine Proforma-Rechnung und teilen Sie sie zur Genehmigung mit Ihren Kunden.",
				2: "Geben Sie Informationen zu Ihrem Versand ein und fügen Sie Dokumente hinzu. Verwalten Sie alle Prozesse Ihres Versands über einen Kanal mit Ihren Kunden.",
				3: "Verfolgen Sie alle finanziellen Situationen im Zusammenhang mit Import und Export in Echtzeit.",
			},
		},
		$content: {
			headline: "UNTERSTÜTZTE LOGISTIKARTEN",
			$title: {
				0: "See",
				1: "Luft",
				2: "Land",
				3: "Zug",
			},
		},
		$pricing: {
			0: {
				title: "KOSTENLOS",
				subtitle: "Einzelpersonen",
				features: ["1 Benutzer", "3 Partner", "10 Sendungen", "10 Rechnungen"],
			},
			1: {
				title: "JÄHRLICH",
				subtitle: "Unternehmen",
				features: [
					"20 Benutzer",
					"100 Partner",
					"1000 Sendungen",
					"1000 Rechnungen",
				],
			},
			2: {
				title: "MONATLICH",
				subtitle: "Kleine Unternehmen",
				features: [
					"10 Benutzer",
					"30 Partner",
					"250 Sendungen",
					"250 Rechnungen",
				],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Sind Sie sicher, dass Sie {function} möchten?",
		created: "{item} wurde erstellt.",
		deleted: "{item} wurde gelöscht.",
		file_oversize: "Dateigröße darf maximal {size} betragen.",
		invalid: "Ungültig {item}",
		sent_email: "E-Mail wurde gesendet. ({value})",
		sign_in_failed: "E-Mail oder Passwort falsch",
		updated: "{item} wurde aktualisiert.",
		migrated: "{item} wurde migriert. ({value})",
	},
	$productGroup: {
		add_child: "Untergruppe hinzufügen",
		add_to_root: "Zur Wurzel hinzufügen",
		has_children_fail_message:
			"Produktgruppe mit Untergruppen kann nicht gelöscht werden",
		initial_message:
			"Um Ihre erste Produktgruppe hinzuzufügen, drücken Sie die Taste {0}.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} erstellt.",
			update: "{name} aktualisiert.",
			send: "{name} hat es an den Empfänger zur Genehmigung gesendet.",
			revoke: "{name} hat es zurückgezogen.",
			approve: "{name} hat es genehmigt.",
			demand_revise: "{name} forderte eine Überarbeitung an.",
			reject: "{name} hat es abgelehnt.",
			mark_as_processed: "{name} hat es als bearbeitet markiert.",
			create_attachment: "{name} hat einen Anhang erstellt.",
			update_attachment: "{name} hat einen Anhang aktualisiert.",
			delete_attachment: "{name} hat einen Anhang gelöscht.",
		},
		$actionDescription2: {
			send: '{name} wurde als "Gesendet" markiert.',
			revoke: '{name} wurde als "Zurückgezogen" markiert.',
			approve: '{name} wurde als "Genehmigt" markiert.',
			demand_revise: '{name} wurde als "Überarbeitung erwartet" markiert.',
			reject: "{name} wurde abgelehnt.",
			mark_as_processed: `{name} wurde als "Bearbeitet" markiert.`,
		},
		$actions: {
			approve: "Genehmigen",
			demand_revise: "Überarbeitung anfordern",
			mark_as_approved: "Genehmigt markieren",
			mark_as_processed: "Bearbeitet markieren",
			mark_as_rejected: "Abgelehnt markieren",
			mark_as_revise_demanded: "Überarbeitung angefordert markieren",
			mark_as_revoked: "Zurückgezogen markieren",
			mark_as_sent: "Gesendet markieren",
			reject: "Ablehnen",
			revoke: "Zurückziehen",
			send: "Senden",
		},
		$status: {
			approved: "Genehmigt",
			pending_approval: "Ausstehende Genehmigung",
			pending_revise: "Ausstehende Überarbeitung",
			processed: "Bearbeitet",
			rejected: "Abgelehnt",
			revoked: "Zurückgezogen",
			template: "Vorlage",
		},
		send_confirm_message:
			"Sind Sie sicher, dass Sie die Proforma-Rechnung dem Empfänger senden möchten?",
		send_success_message:
			"Die Proforma-Rechnung wurde an den Empfänger gesendet.",
		approve_confirm_message:
			"Sind Sie sicher, dass Sie die Proforma-Rechnung genehmigen möchten?",
		approve_success_message: "Die Proforma-Rechnung wurde genehmigt.",
		demand_revise_success_message:
			"Die Proforma-Rechnung wurde zur Überarbeitung zurückgeschickt.",
		reject_confirm_message:
			"Sind Sie sicher, dass Sie die Proforma-Rechnung ablehnen möchten?",
		reject_success_message: "Die Proforma-Rechnung wurde abgelehnt.",
		mark_as_processed_confirm_message:
			"Sind Sie sicher, dass Sie die Proforma-Rechnung als bearbeitet markieren möchten?",
		mark_as_processed_success_message:
			"Die Proforma-Rechnung wurde als bearbeitet markiert.",
		revoke_confirm_message:
			"Sind Sie sicher, dass Sie die Proforma-Rechnung zurückziehen möchten?",
		revoke_success_message: "Die Proforma-Rechnung wurde zurückgezogen.",
		demand_revise_description_message:
			"Bitte erläutern Sie, welche Überarbeitung Sie wünschen:",
		no_attachment_text: "Es wurde noch kein Anhang hochgeladen.",
	},
	$serviceMessage: {
		already_subs_module: "Für dieses Modul besteht bereits ein Abonnement.",
		bad_request: "Schlechte Anfrage",
		code_already_used: "Code wurde bereits verwendet.",
		container_status_not_available: "Containerstatus ist nicht verfügbar.",
		existing_email_warning: "E-Mail existiert bereits.",
		existing_employee_error:
			"Dieser Benutzer wurde bereits zu Ihrem Unternehmen hinzugefügt.",
		existing_employee_for_email_warning:
			"Einladungscode wurde bereits an diese E-Mail gesendet.",
		existing_invitation_warning:
			"Einladungscode wurde bereits an diese E-Mail gesendet.",
		existing_token_already_have_time:
			"Das aktuelle Token hat bereits ausreichend Zeit zum Verwenden.",
		existing_user: "Benutzer existiert bereits.",
		five_min_reset_password_mail:
			"Nachdem Sie einmal ein Passwort zurücksetzen angefordert haben, müssen Sie mindestens 5 Minuten warten.",
		forbidden: "Verboten",
		internal_server_error: "Interner Serverfehler.",
		instance_can_no_delete_existing_booking_code:
			"Das Element kann aufgrund des vorhandenen Buchungscodes nicht gelöscht werden.",
		instance_cannot_delete: "Das Element kann nicht gelöscht werden.",
		instance_not_found: "Element nicht gefunden.",
		invitation_accepting_is_due_to_the_admin:
			"Die Annahme der Einladung hängt vom Administrator ab.",
		invitation_already_discarded: "Die Einladung wurde bereits verworfen.",
		invitation_already_used: "Die Einladung wurde bereits verwendet.",
		invitation_has_been_used: "Die Einladung wurde bereits verwendet.",
		invitation_not_exist_email:
			"Die Einladung mit dieser E-Mail existiert nicht.",
		invitation_not_for_custom_agency:
			"Diese Einladung ist nicht für eine Zollagentur.",
		invitation_not_for_company:
			"Diese Einladung ist nicht für ein Unternehmen.",
		invitation_not_found: "Einladung nicht gefunden.",
		invitation_not_valid: "Einladung ist nicht mehr gültig.",
		invalid_current_password: "Ihr aktuelles Passwort ist ungültig.",
		invalid_identifier_or_password: "Ungültige E-Mail oder Passwort",
		invalid_invitation_code: "Ungültiger Einladungscode.",
		invalid_moving_request: "Ungültige Umzugsanfrage.",
		invalid_refresh_token: "Ungültiges Aktualisierungstoken.",
		invalid_operation: "Ungültige Operation.",
		invalid_unit: "Ungültige Einheit.",
		have_no_corporation: "Sie haben kein Unternehmen.",
		have_no_custom_agency: "Sie haben keine Zollagentur.",
		have_no_employee: "Sie haben keine Mitarbeiter.",
		link_not_be_used_anymore: "Dieser Link kann nicht mehr verwendet werden.",
		link_has_been_expired: "Der Link ist abgelaufen.",
		missing_required_fields: "Alle Pflichtfelder müssen ausgefüllt werden.",
		no_employee_found: "Mitarbeiter nicht gefunden.",
		no_proper_partner_found: "Kein passender Partner gefunden.",
		not_acceptable: "Nicht akzeptabel.",
		not_allowed_action: "Sie sind nicht berechtigt, diese Aktion auszuführen.",
		not_allowed_use_api: "Sie sind nicht berechtigt, dieses API zu verwenden.",
		not_found: "Nicht gefunden.",
		not_permitted_use_api:
			"Sie sind nicht berechtigt, dieses API zu verwenden.",
		own_invitation_cannot_accept:
			"Sie können keine Einladung an sich selbst senden.",
		parent_group_not_found: "Übergeordnete Produktgruppe nicht gefunden.",
		partnerId_required: "Partner-ID ist erforderlich.",
		partnership_already_exists: "Partnerschaft wurde bereits hinzugefügt.",
		password_cant_involve_space_char:
			"Das Passwort darf keine Leerzeichen enthalten.",
		password_min_chars_least:
			"Das Passwort muss mindestens 6 Zeichen lang sein.",
		ports_are_required: "Ports sind erforderlich.",
		product_group_tree_not_found: "Produktgruppenbaum nicht gefunden.",
		proforma_invoice_action_not_available:
			"Aktion für die Proforma-Rechnung nicht verfügbar.",
		proforma_invoice_template_update:
			"Die Proforma-Rechnung muss eine Vorlage sein, um aktualisiert zu werden.",
		shipment_method_is_required: "Versandmethode ist erforderlich.",
		shipping_term_is_required: "Versandbedingung ist erforderlich.",
		shipment_email_not_exist:
			"Bitte geben Sie eine gültige E-Mail-Adresse ein.",
		someone_else_uses_this_email:
			"Jemand anderes verwendet bereits diese E-Mail-Adresse.",
		someone_else_uses_this_username:
			"Jemand anderes verwendet bereits diesen Benutzernamen.",
		something_went_wrong: "Etwas ist schief gelaufen.",
		type_can_be_abstract_company: '"type" kann nur "abstract_company" sein.',
		unauthorized: "Unberechtigt",
		undefined_result: "Undefiniertes Ergebnis.",
		unexpected_error_occurred: "Ein unerwarteter Fehler ist aufgetreten.",
		unit_already_added: "Die Einheit wurde bereits hinzugefügt.",
		username_min_chars_error:
			'"Benutzername" muss mindestens 6 Zeichen lang sein.',
		user_already_employee: "Der Benutzer ist bereits ein Mitarbeiter.",
		user_already_exists:
			"Der Benutzer ist bereits als Mitarbeiter in Ihrem Unternehmen vorhanden.",
		user_does_not_exist: "Benutzer existiert nicht.",
		user_not_found: "Benutzer nicht gefunden.",
	},
	$shipment: {
		$actionDescription: {
			create: "{name} hat erstellt.",
			update: "{name} hat aktualisiert.",
			create_container: "{name} hat einen Container hinzugefügt.",
			update_container: "{name} hat einen Container aktualisiert.",
			delete_container: "{name} hat einen Container gelöscht.",
			create_attachment: "{name} hat einen Anhang erstellt.",
			update_attachment: "{name} hat einen Anhang aktualisiert.",
			delete_attachment: "{name} hat einen Anhang gelöscht.",
			$land: {
				create_container: "{name} hat ein Fahrzeug hinzugefügt.",
				update_container: "{name} hat ein Fahrzeug aktualisiert.",
				delete_container: "{name} hat ein Fahrzeug gelöscht.",
			},
		},
		$containerStatus: {
			reserved: "Reserviert",
			on_load: "Beladen",
			on_way: "Unterwegs",
			arrived: "Angekommen",
			off_load: "Entladen",
		},
		$method: {
			sea: "Meer",
			aerial: "Luft",
			land: "Land",
			railway: "Eisenbahn",
		},
		$vehicle: {
			license_number: "Kennzeichen",
		},
		archiving_success_message: "Versand wurde archiviert.",
		leave_message: "Hinterlassen Sie Ihre Nachricht oder Ihren Kommentar",
		no_booking_info:
			"Es sind noch keine Buchungsinformationen definiert. Drücken Sie die Taste {0}, um welche hinzuzufügen.",
		no_transportation_info:
			"Es sind noch keine Versanddetails für die Buchung definiert. Drücken Sie die Taste {0}, um welche hinzuzufügen.",
		un_archiving_success_message: "Versand wurde aus dem Archiv entfernt.",
	},
	$shortening: {
		number: "Nr.",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Millimeter",
		meter: "Meter",
		kilometer: "Kilometer",
		milligram: "Milligramm",
		gram: "Gramm",
		kilogram: "Kilogramm",
		square_meter: "Quadratmeter",
		hectare: "Hektar",
		square_kilometer: "Quadratkilometer",
		milliliter: "Milliliter",
		liter: "Liter",
		cubic_meter: "Kubikmeter",
		acres: "Acre",
		feet: "Fuß",
		fluid_ounces: "Flüssigunzen",
		gallons: "Gallonen",
		inches: "Zoll",
		miles: "Meilen",
		ounces: "Unzen",
		pounds: "Pfund",
		quarts: "Quarts",
		square_feet: "Quadratfuß",
		square_miles: "Quadratmeilen",
		square_yards: "Quadratyards",
		square_inches: "Quadratzoll",
		yards: "Yards",
		pieces: "Stücke",
		boxes: "Boxen",
		pallets: "Paletten",
		dozen: "Dutzend",
		ton: "Tonne",
	},
	$validation: {
		required: "{item} darf nicht leer sein",
		required_for_default_language:
			"{item} muss für die Standardsprache des Unternehmens definiert sein.",
		invalid: "{item} ist ungültig",
		email: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
		min_length: "{item} muss mindestens {length} Zeichen lang sein",
		max_length: "{item} darf maximal {length} Zeichen lang sein",
		password_repeat_warning:
			"Das Passwort muss mit dem neuen Passwort übereinstimmen.",
	},

	accept_invitation_success_message: "Einladung erfolgreich angenommen.",
	accessibility: "Barrierefreiheit",
	actions: "Aktionen",
	active: "Aktiv",
	add: "Hinzufügen",
	add_item: "{item} Hinzufügen",
	address: "Adresse",
	again: "Erneut",
	alert: "Warnung",
	already_have_employee_message:
		"Sie sind bereits ein Mitarbeiter des Unternehmens.",
	amount: "Betrag",
	archive: "Archivieren",
	attachment: "Anhang",
	attachments: "Anhänge",

	booking: "Buchung",
	booking_code: "Buchungscode",
	business_partner: "Geschäftspartner",
	business_partners: "Geschäftspartner",

	cancel: "Abbrechen",
	change_password: "Passwort ändern",
	checking_invitation_code_message: "Einladungscode wird überprüft",
	choose_image: "Bild auswählen",
	clear: "Leeren",
	close: "Schließen",
	code: "Code",
	companies: "Unternehmen",
	company: "Unternehmen",
	company_profile: "Unternehmensprofil",
	commercial: "Kommerziell",
	commercial_partners: "Kommerzielle Partner",
	content: "Inhalt",
	configuration: "Konfiguration",
	confirm: "Bestätigen",
	contact: "Kontakt",
	contact_info: "Kontaktinformationen",
	contact_to_us: "Kontaktieren Sie uns",
	container: "Container",
	containers: "Container",
	content: "Inhalt",
	countries: "Länder",
	country: "Land",
	create: "Erstellen",
	create_company: "Unternehmen erstellen",
	created_at: "Erstellt am",
	currencies: "Währungen",
	currency: "Währung",
	current_password: "Aktuelles Passwort",
	customs_agency: "Zollagentur",
	customs_agencies: "Zollagenturen",

	date: "Datum",
	dashboard: "Dashboard",
	default: "Standard",
	default_language: "Standardsprache",
	delete: "Löschen",
	delete_confirm_message: "Möchten Sie diesen Vorgang wirklich löschen?",
	description: "Beschreibung",
	detail: "Detail",
	discard: "Verwerfen",
	document_number: "Dokumentennummer",
	download: "Herunterladen",

	edit: "Bearbeiten",
	edit_item: "{item} Bearbeiten",
	email: "E-Mail",
	employee: "Mitarbeiter",
	employee_type: "Mitarbeitertyp",
	employees: "Mitarbeiter",
	estimated_arrival_day: "Voraussichtlicher Ankunftstag",
	expense: "Ausgabe",
	expenses: "Ausgaben",
	exported_containers: "Exportierte Container",

	faq: "FAQ",
	file: "Datei",
	filter: "Filter",
	first_name: "Vorname",
	forget_password: "Passwort vergessen",
	forget_password_message: "Passwort vergessen?",
	from: "Von",

	hide: "Ausblenden",
	home_page: "Startseite",

	imported_containers: "Importierte Container",
	incoming: "Eingehend",
	incoming_shipments: "Eingehende Sendungen",
	initial: "Anfang",
	invitation: "Einladung",
	invitation_code: "Einladungscode",
	invitation_mail_sent: "Einladungs-E-Mail wurde gesendet",
	invitations: "Einladungen",

	join: "Beitreten",

	language_options: "Sprachoptionen",
	last_name: "Nachname",
	last_one_year: "Letztes Jahr",
	latitude: "Breitengrad",
	loading_country: "Ladeland",
	loading_point: "Ladepunkt",
	loading_port: "Ladehafen",
	logo: "Logo",
	longitude: "Längengrad",

	go_to_panel: "Zurück zum Panel",

	mail_sent: "E-Mail gesendet",
	migrate: "Umziehen",
	migrate_partner: "Partner migrieren",
	message: "Nachricht",
	message_company_create: "Unternehmen wurde erstellt.",
	message_company_join: "Treten Sie dem Unternehmen bei",
	message_company_joined: "Sie sind dem Unternehmen beigetreten",
	message_employee_delete: "Mitarbeiter wurde gelöscht.",
	message_employee_save: "Mitarbeiter wurde gespeichert.",
	message_join_company:
		"Geben Sie Ihren Einladungscode ein, um einem Unternehmen beizutreten.",
	method: "Methode",
	mobile_number: "Handynummer",
	my_role: "Meine Rolle",

	name: "Name",
	next: "Weiter",
	new: "Neu",
	new_password: "Neues Passwort",
	no: "Nein",
	no_content: "Kein Inhalt",
	no_text: "Kein Text",
	not_found_page_message: "Entschuldigung, diese Seite wurde nicht gefunden :(",
	note: "Hinweis",

	ok: "OK",
	outgoing: "Ausgehend",
	outgoing_shipments: "Ausgehende Sendungen",

	parent_group: "Übergeordnete Gruppe",
	partner: "Partner",
	partners: "Partner",
	passive: "Passiv",
	password: "Passwort",
	phone_number: "Telefonnummer",
	previous: "Zurück",
	pricing: "Preisgestaltung",
	product: "Produkt",
	product_group: "Produktgruppe",
	product_groups: "Produktgruppen",
	products: "Produkte",
	profile: "Profil",
	proforma_invoice: "Proforma-Rechnung",
	proforma_invoices: "Proforma-Rechnungen",
	proforma_number: "Proforma-Nummer",
	purchase: "Kauf",

	quantity: "Menge",

	receiver: "Empfänger",
	receiver_company: "Empfängerunternehmen",
	receiver_customs_agency: "Empfängerzollagentur",
	redirection_to_home_link: "klicken Sie hier",
	redirection_to_home_message: "Um zur Startseite zurückzukehren, ",
	redirection_to_sign_in_message: "Haben Sie bereits ein Konto?",
	redirection_to_sign_up_message: "Haben Sie noch kein Konto?",
	reload: "Neu laden",
	reset_password: "Passwort zurücksetzen",

	sales: "Verkauf",
	save: "Speichern",
	search: "Suchen",
	select_file: "Datei auswählen",
	select_file_place_holder: "Bitte ein Bild auswählen",
	selected_partner: "Ausgewählter Partner",
	send: "Senden",
	send_again: "Erneut senden",
	send_email: "E-Mail senden",
	send_invitation: "Einladung senden",
	sender: "Absender",
	sender_company: "Absenderunternehmen",
	sender_customs_agency: "Absenderzollagentur",
	show: "Anzeigen",
	shipment: "Sendung",
	shipment_method: "Versandmethode",
	shipments: "Sendungen",
	shipments_filter: "Sendungsfilter",
	shipping_term: "Versandbedingung",
	shipping_terms: "Versandbedingungen",
	sign_in: "Anmelden",
	sign_out: "Abmelden",
	sign_out_confirm_message: "Möchten Sie sich wirklich abmelden?",
	sign_out_success_message: "Auf Wiedersehen.",
	sign_up: "Registrieren",
	social_media: "Soziale Medien",
	social_media_account: "Soziales-Media-Konto",
	status: "Status",
	subscribe: "Abonnieren",
	super: "Super",
	switch_language: "Sprache wechseln",
	switch_theme: "Thema wechseln",

	target: "Ziel",
	target_partner: "Zielpartner",
	theme: "Thema",
	this_month: "Dieser Monat",
	this_year: "Dieses Jahr",
	title: "Titel",
	to: "Zu",
	toDelete: "Löschen",
	total: "Gesamt",
	total_amount: "Gesamtbetrag",
	type: "Typ",
	types: "Typen",

	un_archive: "Archivierung aufheben",
	unit: "Einheit",
	units: "Einheiten",
	unloading_country: "Entladeland",
	unloading_point: "Entladepunkt",
	unloading_port: "Entladehafen",
	use_code: "Code verwenden",
	use_invitation_code: "Einladungscode verwenden",
	use_invitation_code_description:
		"Geben Sie den Einladungscode ein, den Sie per E-Mail erhalten haben.",
	use_invitation_code_success_message: "Einladungscode erfolgreich verwendet.",
	user_profile: "Benutzerprofil",

	vehicle: "Fahrzeug",
	vehicles: "Fahrzeuge",
	visibility: "Sichtbarkeit",

	warehouse: "Lager",
	warehouses: "Lager",
	website: "Website",
	welcome: "Willkommen",

	yes: "Ja",
};

<template>
	<v-row class="ma-0">
		<v-col cols="4" class="pa-0">
			<v-select
				v-model="currentLanguage"
				:items="currentOptions"
				:disabled="currentOptions.length === 1"
			>
				<template slot="selection" slot-scope="{ item }">
					{{ $t(`$language.${item}`) }}
				</template>
				<template slot="item" slot-scope="{ item }">
					{{ $t(`$language.${item}`) }}
				</template>
			</v-select>
		</v-col>
		<v-col cols="8" class="pa-0 pl-4">
			<v-text-field
				v-model="value[currentLanguage]"
				:rules="rules"
				:label="label"
			/>
		</v-col>
	</v-row>
</template>

<script>
import { getSetting, Setting } from "../../data/local/ls";

export default {
	props: ["value", "options", "defaultLanguage", "rules", "label"],
	data() {
		return {
			currentLanguage: null,
			currentOptions: [],
		};
	},
	watch: {},
	methods: {
		handleItem() {},
	},
	created() {
		if (this.options && this.options.length) this.currentOptions = this.options;
		else
			this.currentOptions = Object.keys(this.value).filter(
				(i) => i !== "_default"
			);
		var currentLocale = getSetting(Setting.locale);
		this.currentLanguage =
			this.currentOptions.includes(currentLocale) &&
			this.value[currentLocale] &&
			this.value[currentLocale] !== ""
				? currentLocale
				: this.defaultLanguage;
	},
};
</script>

import Model from "../_model";

export default class FeedbackModel extends Model {
	constructor(provider) {
		super(provider, "feedback");
	}

	serialize(feedback) {
		return {
			name: feedback.name,
			email: feedback.email,
			message: feedback.message,
			file: {
				key: feedback.file.key,
				type: feedback.file.type,
			},
		};
	}

	create(feedback, { onSuccess, onFail }) {
		const body = this.serialize(feedback);
		return super.create(body, {
			onSuccess,
			onFail,
		});
	}
}

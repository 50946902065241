<template>
	<v-dialog v-model="value" fullscreen hide-overlay transition="dialog-bottom-transition">
		<v-card tile v-if="this.currentItem">
			<v-toolbar elevation="1">
				<v-btn icon @click="closeAction">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>{{ $t(isLandShipment ? "vehicle" : "container") }}</v-toolbar-title>
				<v-spacer />
			</v-toolbar>

			<v-container>
				<v-row>
					<v-col cols="6" sm="4" md="3">
						<title-value-list-item
							:value="currentItem.code"
							:title="
								$t(isLandShipment ? '$shipment.$vehicle.license_number' : '$shortening.number')
							"
						/>
					</v-col>
					<v-col cols="6" sm="4" md="3">
						<title-value-list-item
							:value="$t(`$shipment.$containerStatus.${currentItem.status}`)"
							:title="$t('status')"
						/>
					</v-col>
					<v-col cols="6" sm="4" md="3">
						<title-value-list-item
							:value="
								currentItem.estimatedArrivalDay
									? currentItem.estimatedArrivalDay.format($t('$format.date'))
									: ''
							"
							:title="$t('estimated_arrival_day')"
						/>
					</v-col>
					<v-col cols="6" sm="4" md="3" v-if="totalAmount != null">
						<title-value-list-item
							:value="
								totalAmount.toLocaleString($lang, {
									style: 'currency',
									currency: shipment.currency.code,
								})
							"
							:title="$t('total_amount')"
						/>
					</v-col>
					<v-col cols="12" v-if="currentItem.note">
						<title-value-list-item :value="currentItem.note" :title="$t('note')" />
					</v-col>
				</v-row>

				<v-divider />

				<!-- Products -->
				<v-card elevation="1" class="mt-4">
					<v-toolbar flat dense>
						<v-toolbar-title>
							{{ $t("products") }}
						</v-toolbar-title>
						<v-spacer />
						<v-btn
							v-if="isEditable && iAmOwner && !customsAgency"
							rounded
							small
							color="primary"
							@click="productDialog.show()"
						>
							{{ $t("add") }}
							<v-icon right> mdi-plus-circle </v-icon>
						</v-btn>
					</v-toolbar>
					<v-data-table
						:headers="productListHeaders"
						:items="currentItem.products"
						:items-per-page="-1"
						:hide-default-header="!currentItem.products.length"
						hide-default-footer
						@click:row="productDialog.show"
						style="cursor: pointer"
						disable-sort
					>
						<template v-slot:item.name="{ item }">
							{{
								item.name[$lang] && item.name[$lang].length
									? item.name[$lang]
									: item.name["_default"]
							}}
						</template>
						<template v-slot:item.quantity="{ item }">
							{{ item.quantity }} {{ item.unitCode }}
						</template>
						<template v-slot:item.amount="{ item }">
							{{
								item.amount.toLocaleString($lang, {
									style: "currency",
									currency: shipment.currency.code,
								})
							}}
							<!-- {{ `${item.amount} ${shipment.currency.symbol}` }} -->
						</template>
						<template slot="body.append" v-if="currentItem.products.length">
							<tr>
								<td class="text-right" colspan="3">
									{{
										`${$t("total")}: ${totalProductAmount.toLocaleString($lang, {
											style: "currency",
											currency: shipment.currency.code,
										})} `
									}}
								</td>
							</tr>
						</template>
					</v-data-table>

					<container-product-dialog
						v-if="iAmOwner && !customsAgency"
						v-model="productDialog.visibility"
						:item="productDialog.item"
						:shipment="shipment"
						:container="currentItem"
						@saved="productDialog.saved"
						@deleted="productDialog.deleted"
					/>
				</v-card>

				<v-divider class="mt-4" />

				<!-- Expenses -->
				<v-card elevation="1" class="mt-4">
					<v-toolbar flat dense>
						<v-toolbar-title>
							{{ $t("expenses") }}
						</v-toolbar-title>
						<v-spacer />
						<v-btn
							v-if="isEditable && iAmOwner && !customsAgency"
							rounded
							small
							color="primary"
							@click="expenseDialog.show()"
						>
							{{ $t("add") }}
							<v-icon right> mdi-plus-circle </v-icon>
						</v-btn>
					</v-toolbar>
					<v-data-table
						:headers="expenseListHeaders"
						:items="currentItem.expenses"
						:items-per-page="-1"
						:hide-default-header="!currentItem.expenses.length"
						hide-default-footer
						@click:row="expenseDialog.show"
						style="cursor: pointer"
						disable-sort
					>
						<template v-slot:item.amount="{ item }">
							{{
								item.amount.toLocaleString($lang, {
									style: "currency",
									currency: shipment.currency.code,
								})
							}}
							<!-- {{ `${item.amount} ${shipment.currency.symbol}` }} -->
						</template>
						<template slot="body.append" v-if="currentItem.products.length">
							<tr>
								<td class="text-right" colspan="3">
									{{
										`${$t("total")}: ${totalExpenseAmount.toLocaleString($lang, {
											style: "currency",
											currency: shipment.currency.code,
										})} `
									}}
								</td>
							</tr>
						</template>
					</v-data-table>

					<container-expense-dialog
						v-if="iAmOwner && !customsAgency"
						v-model="expenseDialog.visibility"
						:item="expenseDialog.item"
						:shipment="shipment"
						:container="currentItem"
						@saved="expenseDialog.saved"
						@deleted="expenseDialog.deleted"
					/>
				</v-card>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import TitleValueListItem from "../../../partials/TitleValueListItem";
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import ContainerProductDialog from "./ContainerProductDialog";
import ContainerExpenseDialog from "./ContainerExpenseDialog";
import { mapState, mapGetters } from "vuex";

export default {
	props: { value: Boolean, item: Object, shipment: Object, iAmOwner: Boolean },
	components: {
		TitleValueListItem,
		ContainerProductDialog,
		ContainerExpenseDialog,
	},
	mixins: [uiMixin],
	data() {
		return {
			currentItem: null,
			productListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			],
			productDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.productDialog.item = item;
					this.productDialog.visibility = true;
				},
				hide: () => {
					this.productDialog.item = null;
					this.productDialog.visibility = false;
				},
				saved: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
					this.$emit("changed");
				},
				deleted: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
					this.$emit("changed");
				},
			},
			expenseListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			],
			expenseDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.expenseDialog.item = item;
					this.expenseDialog.visibility = true;
				},
				hide: () => {
					this.expenseDialog.item = null;
					this.expenseDialog.visibility = false;
				},
				saved: (item) => {
					this.loadItem(this.currentItem.id);
					this.expenseDialog.hide();
					this.$emit("changed");
				},
				deleted: (_) => {
					this.loadItem(this.currentItem.id);
					this.expenseDialog.hide();
					this.$emit("changed");
				},
			},
		};
	},
	computed: {
		...mapGetters(["customsAgency"]),
		isLandShipment() {
			return this.shipment.method === "land";
		},
		isEditable() {
			return ["reserved", "on_load"].includes(this.currentItem.status);
		},
		totalProductAmount() {
			if (this.currentItem) {
				let total = 0;
				for (const item of this.currentItem.products) total += item.quantity * item.amount;
				return total;
			} else return null;
		},
		totalExpenseAmount() {
			if (this.currentItem) {
				let total = 0;
				for (const item of this.currentItem.expenses) total += item.quantity * item.amount;
				return total;
			} else return null;
		},
		totalAmount() {
			if (this.totalProductAmount != null || this.totalExpenseAmount != null)
				return Number(this.totalProductAmount) + Number(this.totalExpenseAmount);
			else return null;
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (!this.currentItem && this.item && this.shipment) this.loadItem();
		},
		reset() {
			this.currentItem = null;
		},
		loadItem() {
			this.showProgressDialog();
			remote.shipments.containers.find(this.item.id, this.shipment.id, {
				onSuccess: (result) => {
					this.currentItem = result;
					this.hideProgressDialog();
				},
			});
		},
		closeAction() {
			this.$emit("input", false);
			this.$emit("closed", false);
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>

import Model from "../_model";
import PartnerModel from "./partner.model";

export default class CorporationModel extends Model {
	constructor(provider) {
		super(provider, "corporations");
		this.partners = new PartnerModel(provider);
	}

	setProvider(value) {
		super.setProvider(value);
		this.partners.setProvider(value);
	}

	getMyCorporation({ onSuccess, onFail }) {
		return super.filter({ url: "corporation", onSuccess, onFail });
	}
}

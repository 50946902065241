<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-form ref="form" v-model="valid">
			<v-card v-if="currentItem">
				<v-card-title class="headline">
					{{
						`${$t(currentItem.id ? "edit_item" : "add_item", {
							item: $t("product_group"),
						})}`
					}}
				</v-card-title>
				<v-card-text>
					<localized-text-field
						v-model="currentItem.name"
						:defaultLanguage="companyDefaultLanguage"
						:rules="rules.name"
						:options="companyLanguageOptions"
						:label="$t('name')"
					/>
					<product-group-select
						v-if="productGroupOptions"
						v-model="currentItem.parentGroupId"
						clearable
						:eager="true"
						:options="productGroupOptions"
						:disabled="!productGroupOptions.length"
						:label="$t('parent_group')"
						icon="mdi-graph-outline"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						color="error"
						v-if="currentItem.id"
						:disabled="deleting || saving"
						:loading="deleting"
						@click="deleteButtonAction"
					>
						{{ $t("delete") }}
					</v-btn>
					<v-spacer />
					<v-btn :disabled="deleting || saving" @click="closeButtonAction">
						{{ $t("cancel") }}
					</v-btn>
					<v-btn
						color="accent"
						:disabled="deleting || saving"
						:loading="saving"
						@click="saveAction"
					>
						{{ $t("save") }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import ProductGroupSelect from "../../controls/ProductGroupSelect";
import { mapGetters } from "vuex";
import LocalizedTextField from "../../controls/LocalizedTextField";

export default {
	mixins: [uiMixin],

	props: ["value", "item", "productGroupTree", "maxParentCount"],

	components: { ProductGroupSelect, LocalizedTextField },

	data() {
		return {
			rootGroupId: null,
			initialParentGroupId: null,
			itemTemplate: {
				parentGroupId: null,
				name: null,
			},
			currentItem: null,
			saving: false,
			deleting: false,
			productGroupOptions: null,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
			},
			valid: true,
		};
	},
	computed: {
		...mapGetters(["companyDefaultLanguage", "companyLanguageOptions"]),
	},
	watch: {
		value(val) {
			if (val) {
				if (typeof this.item === "object") {
					this.currentItem = Object.assign({}, this.item);
					const parent = this.findParentGroupOfCurrentItem(this.productGroupTree);
					this.currentItem.parentGroupId = parent.id;
					this.initialParentGroupId = parent.id;
				} else if (typeof this.item === "string") {
					this.currentItem = Object.assign({}, this.itemTemplate);
					this.currentItem.parentGroupId = this.item;
					this.initialParentGroupId = this.item;
				} else {
					this.currentItem = Object.assign({}, this.itemTemplate);
					this.initialParentGroupId = null;
				}
				this.currentItem.name = Object.assign({}, this.currentItem.name);
				this.productGroupOptions = [];
				this.handleProductGroupTree(this.productGroupTree);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
				this.initialParentGroupId = null;
			}
		},
	},
	methods: {
		handleProductGroupTree(productGroupTree) {
			if (
				(this.item ? this.item.id !== productGroupTree.id : true) &&
				productGroupTree.level < this.maxParentCount
			) {
				if (productGroupTree.name === undefined) this.rootGroupId = productGroupTree.id;
				else
					this.productGroupOptions.push({
						id: productGroupTree.id || "",
						name: productGroupTree.name || {
							_default: this.$t("main_group"),
						},
						level: productGroupTree.level,
					});
				for (const child of productGroupTree.children) this.handleProductGroupTree(child);
			}
		},
		findParentGroupOfCurrentItem(productGroup) {
			for (const child of productGroup.children) {
				if (child.id === this.currentItem.id) {
					return productGroup;
				} else {
					let result = this.findParentGroupOfCurrentItem(child);
					if (result) return result;
				}
			}
			return null;
		},
		saveAction() {
			if (this.valid) {
				if (this.currentItem.name && !this.currentItem.name[this.companyDefaultLanguage]) {
					this.showAlertDialog({
						message: `${this.$t("$validation.required_for_default_language", {
							item: this.$t("name"),
						})} (${this.$t(`$language.${this.companyDefaultLanguage}`)})`,
					});
				} else {
					this.saving = true;
					const onSuccess = () => {
						this.saving = false;
						if (this.currentItem.id) this.$editEvent("product_group");
						else this.$addEvent("product_group");
						this.$emit("saved");
						this.showSnackBar({
							message: this.$t("$message.updated", {
								item: this.$t("product_group"),
							}),
						});
					};
					const onFail = (error) => {
						this.saving = false;
						if (error) console.error(error);
					};
					if (this.currentItem.id) {
						if (this.initialParentGroupId === this.currentItem.parentGroupId) {
							remote.productGroups.update(this.currentItem, null, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						} else {
							remote.productGroups.update(
								this.currentItem,
								this.currentItem.parentGroupId || this.rootGroupId,
								{
									onSuccess: onSuccess,
									onFail: onFail,
								}
							);
						}
					} else {
						if (this.currentItem.parentGroupId) {
							remote.productGroups.create(this.currentItem, this.currentItem.parentGroupId, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						} else {
							remote.productGroups.create(this.currentItem, null, {
								onSuccess: onSuccess,
								onFail: onFail,
							});
						}
					}
				}
			} else this.$refs.form.validate();
		},
		deleteButtonAction() {
			if (this.item.children.length <= 0) {
				this.showConfirmDialog({
					message: this.$t("delete_confirm_message"),
					onConfirm: () => {
						this.deleting = true;
						remote.productGroups.delete(this.item.id, {
							onSuccess: () => {
								this.$emit("deleted");
								this.showSnackBar({
									message: this.$t("$message.deleted", {
										item: this.$t("product_group"),
									}),
								});
							},
							onFail: () => {
								if (error) console.error(error);
							},
						});
						this.deleting = false;
					},
				});
			} else
				this.showAlertDialog({
					message: this.$t("$productGroup.has_children_fail_message"),
				});
		},
		closeButtonAction() {
			this.$emit("closed");
		},
	},
};
</script>

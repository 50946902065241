<template>
	<v-container>
		<v-card>
			<v-tabs v-model="tab" background-color="primary" grow>
				<v-tab v-for="item in items" :key="item">
					{{ item }}
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item v-for="item in items" :key="item">
					<v-card flat class="py-4 px-16">
						<v-card-text>
							<v-row class="justify-center align-center">
								<v-spacer />
								<v-col cols="12" sm="4" md="4" lg="2">
									<v-img
										width="480"
										src="https://cdn2.iconfinder.com/data/icons/water-transport/165/Water_transport_01-512.png"
									/>
								</v-col>
								<v-spacer />
								<v-col cols="12" sm="8" md="8" lg="6">
									{{ text }}
								</v-col>
								<v-spacer />
							</v-row>
						</v-card-text>
					</v-card>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			tab: null,
			items: ["AppetIzers", "Entrees", "Deserts", "CocktaIls"],
			text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
		};
	},
	computed: {
		//
	},
	methods: {
		//
	},
	created() {
		//
	},
};
</script>

export default {
	$accessibility: {
		present: "In corso",
		archived: "Archiviato",
	},
	$accessModifier: {
		$description: {
			private: "Privato",
			hidden_from_receiver: "Nascosto dal destinatario",
			hidden_from_sender: "Nascosto dal mittente",
			shared_with_everyone: "Condiviso con tutti",
			shared_with_custom: "Condiviso personalizzato",
			shared_with_receiver: "Condiviso con il destinatario",
			shared_with_sender: "Condiviso con il mittente",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andorra",
		united_arab_emirates: "Emirati Arabi Uniti",
		afghanistan: "Afghanistan",
		antigua_and_barbuda: "Antigua e Barbuda",
		anguilla: "Anguilla",
		albania: "Albania",
		armenia: "Armenia",
		angola: "Angola",
		antarctica: "Antartide",
		argentina: "Argentina",
		american_samoa: "Samoa Americane",
		austria: "Austria",
		australia: "Australia",
		aruba: "Aruba",
		aland: "Isole Aland",
		azerbaijan: "Azerbaigian",
		bosnia_and_herzegovina: "Bosnia-Erzegovina",
		barbados: "Barbados",
		bangladesh: "Bangladesh",
		belgium: "Belgio",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgaria",
		bahrain: "Bahrein",
		burundi: "Burundi",
		benin: "Benin",
		saint_barthelemy: "Saint Barthelemy",
		bermuda: "Bermuda",
		brunei: "Brunei",
		bolivia: "Bolivia",
		bonaire_sint_eustatius_and_saba: "Bonaire, Sint Eustatius e Saba",
		brazil: "Brasile",
		bahamas: "Bahamas",
		bhutan: "Bhutan",
		bouvet_island: "Isola Bouvet",
		botswana: "Botswana",
		belarus: "Bielorussia",
		belize: "Belize",
		canada: "Canada",
		cocos_islands: "Isole Cocos",
		dr_congo: "Congo (RDC)",
		central_african_republic: "Repubblica Centrafricana",
		congo_republic: "Congo (Brazzaville)",
		switzerland: "Svizzera",
		ivory_coast: "Costa d'Avorio",
		cook_islands: "Isole Cook",
		chile: "Cile",
		cameroon: "Camerun",
		china: "Cina",
		colombia: "Colombia",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Capo Verde",
		curacao: "Curaçao",
		christmas_island: "Isola di Natale",
		cyprus: "Cipro",
		czechia: "Cechia",
		germany: "Germania",
		djibouti: "Gibuti",
		denmark: "Danimarca",
		dominica: "Dominica",
		dominican_republic: "Repubblica Dominicana",
		algeria: "Algeria",
		ecuador: "Ecuador",
		estonia: "Estonia",
		egypt: "Egitto",
		western_sahara: "Sahara Occidentale",
		eritrea: "Eritrea",
		spain: "Spagna",
		ethiopia: "Etiopia",
		finland: "Finlandia",
		fiji: "Figi",
		falkland_islands: "Isole Falkland",
		micronesia: "Micronesia",
		faroe_islands: "Isole Faroe",
		france: "Francia",
		gabon: "Gabon",
		united_kingdom: "Regno Unito",
		grenada: "Grenada",
		georgia: "Georgia",
		french_guiana: "Guyana francese",
		guernsey: "Guernsey",
		ghana: "Ghana",
		gibraltar: "Gibilterra",
		greenland: "Groenlandia",
		gambia: "Gambia",
		guinea: "Guinea",
		guadeloupe: "Guadalupa",
		equatorial_guinea: "Guinea Equatoriale",
		greece: "Grecia",
		south_georgia_and_south_sandwich_islands:
			"Georgia del Sud e Isole Sandwich del Sud",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinea-Bissau",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Isole Heard e McDonald",
		honduras: "Honduras",
		croatia: "Croazia",
		haiti: "Haiti",
		hungary: "Ungheria",
		indonesia: "Indonesia",
		ireland: "Irlanda",
		israel: "Israele",
		isle_of_man: "Isola di Man",
		india: "India",
		british_indian_ocean_territory: "Territorio britannico dell'oceano Indiano",
		iraq: "Iraq",
		iran: "Iran",
		iceland: "Islanda",
		italy: "Italia",
		jersey: "Jersey",
		jamaica: "Giamaica",
		jordan: "Giordania",
		japan: "Giappone",
		kenya: "Kenya",
		kyrgyzstan: "Kirghizistan",
		cambodia: "Cambogia",
		kiribati: "Kiribati",
		comoros: "Comore",
		st_kitts_and_nevis: "Saint Kitts e Nevis",
		north_korea: "Corea del Nord",
		south_korea: "Corea del Sud",
		kuwait: "Kuwait",
		cayman_islands: "Isole Cayman",
		kazakhstan: "Kazakistan",
		laos: "Laos",
		lebanon: "Libano",
		saint_lucia: "Santa Lucia",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Liberia",
		lesotho: "Lesotho",
		lithuania: "Lituania",
		luxembourg: "Lussemburgo",
		latvia: "Lettonia",
		libya: "Libia",
		morocco: "Marocco",
		monaco: "Monaco",
		moldova: "Moldavia",
		montenegro: "Montenegro",
		saint_martin: "Saint Martin",
		madagascar: "Madagascar",
		marshall_islands: "Isole Marshall",
		north_macedonia: "Macedonia del Nord",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mongolia",
		macao: "Macao",
		northern_mariana_islands: "Isole Marianne Settentrionali",
		martinique: "Martinica",
		mauritania: "Mauritania",
		montserrat: "Montserrat",
		malta: "Malta",
		mauritius: "Mauritius",
		maldives: "Maldive",
		malawi: "Malawi",
		mexico: "Messico",
		malaysia: "Malaysia",
		mozambique: "Mozambico",
		namibia: "Namibia",
		new_caledonia: "Nuova Caledonia",
		niger: "Niger",
		norfolk_island: "Isola Norfolk",
		nigeria: "Nigeria",
		nicaragua: "Nicaragua",
		netherlands: "Paesi Bassi",
		norway: "Norvegia",
		nepal: "Nepal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Nuova Zelanda",
		oman: "Oman",
		panama: "Panama",
		peru: "Perù",
		french_polynesia: "Polinesia Francese",
		papua_new_guinea: "Papua Nuova Guinea",
		philippines: "Filippine",
		pakistan: "Pakistan",
		poland: "Polonia",
		saint_pierre_and_miquelon: "Saint Pierre e Miquelon",
		pitcairn_islands: "Isole Pitcairn",
		puerto_rico: "Porto Rico",
		palestine: "Territori Palestinesi",
		portugal: "Portogallo",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Romania",
		serbia: "Serbia",
		russia: "Russia",
		rwanda: "Ruanda",
		saudi_arabia: "Arabia Saudita",
		solomon_islands: "Isole Salomone",
		seychelles: "Seychelles",
		sudan: "Sudan",
		sweden: "Svezia",
		singapore: "Singapore",
		saint_helena: "Sant'Elena",
		slovenia: "Slovenia",
		svalbard_and_jan_mayen: "Svalbard e Jan Mayen",
		slovakia: "Slovacchia",
		sierra_leone: "Sierra Leone",
		san_marino: "San Marino",
		senegal: "Senegal",
		somalia: "Somalia",
		suriname: "Suriname",
		south_sudan: "Sud Sudan",
		sao_tome_and_principe: "São Tomé e Príncipe",
		el_salvador: "El Salvador",
		sint_maarten: "Sint Maarten",
		syria: "Siria",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Isole Turks e Caicos",
		chad: "Ciad",
		french_southern_territories: "Terre australi francesi",
		togo: "Togo",
		thailand: "Thailandia",
		tajikistan: "Tagikistan",
		tokelau: "Tokelau",
		timor_leste: "Timor Est",
		turkmenistan: "Turkmenistan",
		tunisia: "Tunisia",
		tonga: "Tonga",
		turkiye: "Turchia",
		trinidad_and_tobago: "Trinidad e Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taiwan",
		tanzania: "Tanzania",
		ukraine: "Ucraina",
		uganda: "Uganda",
		us_minor_outlying_islands: "Isole minori esterne degli Stati Uniti",
		united_states: "Stati Uniti d'America",
		uruguay: "Uruguay",
		uzbekistan: "Uzbekistan",
		vatican_city: "Città del Vaticano",
		st_vincent_and_grenadines: "Saint Vincent e Grenadine",
		venezuela: "Venezuela",
		british_virgin_islands: "Isole Vergini Britanniche",
		us_virgin_islands: "Isole Vergini americane",
		vietnam: "Vietnam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis e Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yemen",
		mayotte: "Mayotte",
		south_africa: "Sudafrica",
		zambia: "Zambia",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		us_dollar: "Dollaro americano",
		euro: "Euro",
		turkish_lira: "Lira turca",
	},
	$employeeType: {
		admin: "Amministratore",
		default: "Normale",
		exporter: "Esportatore",
		importer: "Importatore",
	},
	$format: {
		date: "DD/MM/YYYY",
		date_time: "DD/MM/YYYY HH:mm:SS",
		date_time_without_second: "DD/MM/YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Benvenuto su Antrego",
			description:
				"Controlla i tuoi processi di importazione ed esportazione con Antrego!",
			sign_in_title: "Hai un account?",
			sign_in_subtitle: "Accedi qui",
			sign_up_title: "Vuoi creare un account?",
			sign_up_subtitle: "Iscriviti qui",
		},
		$noCompany: {
			title: "Benvenuto, {name}",
			description:
				"Hai effettuato l'accesso con successo. Ora devi unirti a una società esistente o crearne una nuova.",
			join_company_title: "Vuoi unirti a una società esistente?",
			join_company_subtitle:
				"Inserisci qui il tuo codice invito e unisciti alla società.",
			create_company_title: "Vuoi creare la tua società?",
			create_company_subtitle: "Crea una nuova società da qui.",
		},
	},
	$invitationType: {
		employee: "Invito come Dipendente",
		company_partner: "Invito per Collaborazione Commerciale",
		customs_partner: "Invito per Collaborazione Doganale",
	},
	$landing: {
		$question: {
			$title: {
				0: "Cos'è Antrego?",
				1: "Quali vantaggi Antrego offre al mio business nel processo di spedizione?",
				2: "Quali tipi di logistica supporta Antrego?",
				3: "Perché dovrei usare Antrego?",
				4: "Antrego è un'app a pagamento?",
				5: "I nostri clienti devono pagare per Antrego?",
			},
			$text: {
				0: "<p>Un programma di tracciamento delle spedizioni che aiuta le aziende e i clienti a tenere traccia condivisa dei loro processi di spedizione, prevenendo danni dovuti a ritardi nella fase di monitoraggio.</p>",
				1: `<p>- Condividi tutte le tue aggiornamenti in tempo reale con i tuoi clienti per migliorare la qualità del servizio.<br>
            - Visualizza tutti i documenti relativi alla spedizione in un unico sistema e condividili con i tuoi clienti.<br>
            - Monitora tutte le tue spedizioni da un unico punto. Tieni sotto controllo le finanze con report dinamici.</p>`,
				2: `<p>- Marittimo<br>
            - Aereo<br>
            - Terrestre<br>
            - Ferroviario</p>`,
				3: `<p>Antrego è sempre a portata di mano con i suoi servizi per semplificare il processo di spedizione.<br>
            <strong>1. Facile Accesso</strong><br>
            Accessibile da qualsiasi dispositivo e da qualsiasi luogo tramite il browser web.<br>
            <strong>2. Sicurezza dei Dati</strong><br>
            Tutti i dati vengono archiviati con la tecnologia di crittografia SHA-256.<br>
            <strong>3. Monitoraggio Condiviso</strong><br>
            Fornisce la possibilità di monitorare il processo agli stakeholder del business.</p>`,
				4: "<p>Sì, è un'app a pagamento. Vogliamo facilitare il tuo lavoro digitalizzando tutti i processi di spedizione e continuare a offrirti miglioramenti utili. Chiediamo una tariffa mensile per consentire il proseguimento di queste migliorie e per l'uso dello spazio del server che stai utilizzando.</p>",
				5: "<p>No, i tuoi clienti non devono pagare alcuna tariffa per seguire le tue spedizioni.</p>",
			},
		},
		$lifecycle: {
			headline: "TUTTI I PROCESSI SONO A PORTATA DI MANO",
			$title: {
				0: "Iscriviti",
				1: "Invia la Proforma Fattura",
				2: "Crea una Spedizione",
				3: "Monitora le Attività Finanziarie",
			},
			$text: {
				0: "Inserisci le informazioni sulla tua azienda e sui tuoi prodotti, crea i tuoi clienti.",
				1: "Crea e condividi la tua proforma fattura con i clienti per l'approvazione.",
				2: "Inserisci le informazioni sulla tua spedizione e allega i documenti. Gestisci tutte le fasi della spedizione con i tuoi clienti da un unico canale.",
				3: "Monitora in tempo reale tutte le attività finanziarie relative all'importazione ed esportazione.",
			},
		},
		$content: {
			headline: "TIPI DI LOGISTICA SUPPORTATI",
			$title: {
				0: "Marittimo",
				1: "Aereo",
				2: "Terrestre",
				3: "Ferroviario",
			},
		},
		$pricing: {
			0: {
				title: "GRATIS",
				subtitle: "Individuale",
				features: ["1 Utente", "3 Partner", "10 Spedizioni", "10 Fatture"],
			},
			1: {
				title: "ANNUALE",
				subtitle: "Aziendale",
				features: [
					"20 Utenti",
					"100 Partner",
					"1000 Spedizioni",
					"1000 Fatture",
				],
			},
			2: {
				title: "MENSILE",
				subtitle: "Piccole Imprese",
				features: ["10 Utenti", "30 Partner", "250 Spedizioni", "250 Fatture"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Siete sicuri di voler {function}?",
		created: "{item} creato.",
		deleted: "{item} eliminato.",
		file_oversize: "Le dimensioni del file devono essere al massimo {size}.",
		invalid: "{item} non valido.",
		sent_email: "Email inviata. ({value})",
		sign_in_failed: "Email o password errati.",
		updated: "{item} aggiornato.",
		migrated: "{item} spostato. ({value})",
	},
	$productGroup: {
		add_child: "Aggiungi Sotto-Gruppo",
		add_to_root: "Aggiungi al Livello Principale",
		has_children_fail_message:
			"Il gruppo di prodotti con sotto-gruppi non può essere eliminato.",
		initial_message:
			"Premi il pulsante {0} per aggiungere il tuo primo gruppo di prodotti.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "Creato da {name}.",
			update: "Aggiornato da {name}.",
			send: "{name} ha inviato all'acquirente per l'approvazione.",
			revoke: "{name} ha revocato.",
			approve: "{name} ha approvato.",
			demand_revise: "{name} ha richiesto una revisione.",
			reject: "{name} ha respinto.",
			mark_as_processed: "{name} ha contrassegnato come elaborato.",
			create_attachment: "{name} ha creato un allegato.",
			update_attachment: "{name} ha aggiornato un allegato.",
			delete_attachment: "{name} ha eliminato un allegato.",
		},
		$actionDescription2: {
			send: '{name} è stato contrassegnato come "Inviato".',
			revoke: '{name} è stato contrassegnato come "Revocato".',
			approve: '{name} è stato contrassegnato come "Approvato".',
			demand_revise:
				'{name} è stato contrassegnato come "Revisione Richiesta".',
			reject: "{name} è stato respinto.",
			mark_as_processed: `{name} è stato contrassegnato come "Elaborato".`,
		},
		$actions: {
			approve: "Approva",
			demand_revise: "Richiedi Revisione",
			mark_as_approved: "Approvato",
			mark_as_processed: "Elaborato",
			mark_as_rejected: "Respinto",
			mark_as_revise_demanded: "Revisione Richiesta",
			mark_as_revoked: "Revocato",
			mark_as_sent: "Inviato",
			reject: "Rifiuta",
			revoke: "Revoca",
			send: "Invia",
		},
		$status: {
			approved: "Approvato",
			pending_approval: "In attesa di Approvazione",
			pending_revise: "In attesa di Revisione",
			processed: "Elaborato",
			rejected: "Respinto",
			revoked: "Revocato",
			template: "Modello",
		},
		send_confirm_message: "Confermate l'invio della proforma all'acquirente?",
		send_success_message: "Proforma inviata con successo all'acquirente.",
		approve_confirm_message: "Siete sicuri di voler approvare la proforma?",
		approve_success_message: "Proforma approvata con successo.",
		demand_revise_success_message:
			"La proforma è stata rimandata per la revisione.",
		reject_confirm_message: "Siete sicuri di voler respingere la proforma?",
		reject_success_message: "Proforma respinta con successo.",
		mark_as_processed_confirm_message:
			"Confermate di voler contrassegnare la proforma come elaborata?",
		mark_as_processed_success_message:
			"Proforma contrassegnata come elaborata con successo.",
		revoke_confirm_message: "Siete sicuri di voler revocare la proforma?",
		revoke_success_message: "Proforma revocata con successo.",
		demand_revise_description_message:
			"Si prega di specificare la revisione desiderata:",
		no_attachment_text: "Nessun allegato è stato ancora caricato.",
	},
	$serviceMessage: {
		already_subs_module: "Questo modulo è già sottoscritto.",
		bad_request: "Richiesta non valida",
		code_already_used: "Il codice è già stato utilizzato.",
		container_status_not_available:
			"Lo stato del contenitore non è disponibile.",
		existing_email_warning: "L'email esiste già.",
		existing_employee_error:
			"Questo utente è già stato aggiunto alla tua azienda.",
		existing_employee_for_email_warning:
			"Il codice di invito è già stato inviato a questa email.",
		existing_invitation_warning:
			"Il codice di invito è già stato inviato a questa email.",
		existing_token_already_have_time:
			"Il token attuale ha già abbastanza tempo per essere utilizzato.",
		existing_user: "L'utente esiste già.",
		five_min_reset_password_mail:
			"Dopo aver richiesto il reset della password, devi attendere almeno 5 minuti.",
		forbidden: "Vietato",
		internal_server_error: "Errore interno del server.",
		instance_can_no_delete_existing_booking_code:
			"L'elemento non può essere eliminato a causa del codice di prenotazione esistente.",
		instance_cannot_delete: "L'elemento non può più essere eliminato.",
		instance_not_found: "Elemento non trovato.",
		invitation_accepting_is_due_to_the_admin:
			"L'accettazione dell'invito è a discrezione dell'amministratore.",
		invitation_already_discarded: "L'invito è già stato annullato.",
		invitation_already_used: "L'invito è già stato utilizzato.",
		invitation_has_been_used: "L'invito è stato utilizzato.",
		invitation_not_exist_email: "L'indirizzo email dell'invito non esiste.",
		invitation_not_for_custom_agency:
			"Questo invito non è destinato a un'agenzia doganale.",
		invitation_not_for_company: "Questo invito non è destinato a un'azienda.",
		invitation_not_found: "Invito non trovato.",
		invitation_not_valid: "L'invito non è più valido.",
		invalid_current_password: "La tua password attuale non è valida.",
		invalid_identifier_or_password: "Identificatore o password non validi.",
		invalid_invitation_code: "Codice di invito non valido.",
		invalid_moving_request: "Richiesta di spostamento non valida.",
		invalid_refresh_token: "Token di aggiornamento non valido.",
		invalid_operation: "Operazione non valida.",
		invalid_unit: "Unità non valida.",
		have_no_corporation: "Non hai un'azienda.",
		have_no_custom_agency: "Non hai un'agenzia doganale.",
		have_no_employee: "Non hai dipendenti.",
		link_not_be_used_anymore:
			"Questo collegamento non può più essere utilizzato.",
		link_has_been_expired: "Il collegamento è scaduto.",
		missing_required_fields: "Alcuni campi obbligatori sono vuoti.",
		no_employee_found: "Nessun dipendente trovato.",
		no_proper_partner_found: "Nessun partner adatto trovato.",
		not_acceptable: "Non accettabile.",
		not_allowed_action: "Non sei autorizzato a eseguire questa azione.",
		not_allowed_use_api: "Non sei autorizzato a utilizzare questa API.",
		not_found: "Non trovato.",
		not_permitted_use_api: "Non sei autorizzato a utilizzare questa API.",
		own_invitation_cannot_accept: "Non puoi inviarti un invito.",
		parent_group_not_found: "Gruppo di prodotti principale non trovato.",
		partnerId_required: "Il campo Partner ID è obbligatorio.",
		partnership_already_exists: "La partnership è già stata creata.",
		password_cant_involve_space_char: "La password non può contenere spazi.",
		password_min_chars_least: "La password deve contenere almeno 6 caratteri.",
		ports_are_required: "I porti sono obbligatori.",
		product_group_tree_not_found: "Albero del gruppo di prodotti non trovato.",
		proforma_invoice_action_not_available:
			"L'azione della proforma fattura non è disponibile per questo stato.",
		proforma_invoice_template_update:
			"Per aggiornare la proforma fattura, deve essere un modello.",
		shipment_method_is_required: "Il metodo di spedizione è obbligatorio.",
		shipping_term_is_required: "La condizione di spedizione è obbligatoria.",
		shipment_email_not_exist: "Inserisci un'email valida.",
		someone_else_uses_this_email: "Qualcun altro sta utilizzando questa email.",
		someone_else_uses_this_username:
			"Qualcun altro sta utilizzando questo nome utente.",
		something_went_wrong: "Qualcosa è andato storto.",
		type_can_be_abstract_company: '"type" può essere solo "abstract_company".',
		unauthorized: "Non autorizzato",
		undefined_result: "Risultato non definito.",
		unexpected_error_occurred: "Si è verificato un errore imprevisto.",
		unit_already_added: "L'unità è già stata aggiunta.",
		username_min_chars_error:
			'"Nome utente" deve contenere almeno 6 caratteri.',
		user_already_employee: "L'utente è già un dipendente.",
		user_already_exists: "L'utente fa già parte del personale dell'azienda.",
		user_does_not_exist: "L'utente non esiste.",
		user_not_found: "Utente non trovato.",
	},
	$shipment: {
		$actionDescription: {
			create: "Creato da {name}.",
			update: "Aggiornato da {name}.",
			create_container: "{name} ha aggiunto un contenitore.",
			update_container: "{name} ha aggiornato un contenitore.",
			delete_container: "{name} ha eliminato un contenitore.",
			create_attachment: "{name} ha creato un allegato.",
			update_attachment: "{name} ha aggiornato un allegato.",
			delete_attachment: "{name} ha eliminato un allegato.",
			$land: {
				create_container: "{name} ha aggiunto un veicolo.",
				update_container: "{name} ha aggiornato un veicolo.",
				delete_container: "{name} ha eliminato un veicolo.",
			},
		},
		$containerStatus: {
			reserved: "Prenotato",
			on_load: "Caricato",
			on_way: "In transito",
			arrived: "Arrivato",
			off_load: "Scaricato",
		},
		$method: {
			sea: "Mare",
			aerial: "Aereo",
			land: "Terra",
			railway: "Ferrovia",
		},
		$vehicle: {
			license_number: "Targa",
		},
		archiving_success_message: "La spedizione è stata archiviata.",
		leave_message: "Lascia un messaggio o un commento",
		no_booking_info:
			"Ancora nessuna informazione di prenotazione definita. Premi il pulsante {0} per aggiungere.",
		no_transportation_info:
			"Ancora nessun dettaglio di spedizione definito. Premi il pulsante {0} per definire.",
		un_archiving_success_message:
			"La spedizione è stata rimossa dall'archivio.",
	},
	$shortening: {
		number: "N.",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Millimetro",
		meter: "Metro",
		kilometer: "Chilometro",
		milligram: "Milligrammo",
		gram: "Grammo",
		kilogram: "Chilogrammo",
		square_meter: "Metro quadrato",
		hectare: "Ettaro",
		square_kilometer: "Chilometro quadrato",
		milliliter: "Millilitro",
		liter: "Litro",
		cubic_meter: "Metro cubo",
		acres: "Acro",
		feet: "Piede",
		fluid_ounces: "Once liquide",
		gallons: "Gallone",
		inches: "Pollice",
		miles: "Miglio",
		ounces: "Onza",
		pounds: "Libbra",
		quarts: "Quarto",
		square_feet: "Piede quadrato",
		square_miles: "Miglio quadrato",
		square_yards: "Iarda quadrata",
		square_inches: "Pollice quadrato",
		yards: "Iarda",
		pieces: "Pezzi",
		boxes: "Scatole",
		pallets: "Pallet",
		dozen: "Dozzina",
		ton: "Tonnellata",
	},
	$validation: {
		required: "{item} non può essere vuoto",
		required_for_default_language:
			"{item} deve essere definito per la lingua predefinita dell'azienda.",
		invalid: "{item} non è valido",
		email: "Inserisci un indirizzo email valido",
		min_length: "{item} deve contenere almeno {length} caratteri",
		max_length: "{item} può contenere al massimo {length} caratteri",
		password_repeat_warning:
			"La ripetizione della password deve corrispondere alla nuova password.",
	},

	accept_invitation_success_message: "Invito accettato con successo.",
	accessibility: "Accessibilità",
	actions: "Azioni",
	active: "Attivo",
	add: "Aggiungi",
	add_item: "Aggiungi {item}",
	address: "Indirizzo",
	again: "Ancora",
	alert: "Avviso",
	already_have_employee_message: "Sei già un dipendente dell'azienda.",
	amount: "Importo",
	archive: "Archivia",
	attachment: "Allegato",
	attachments: "Allegati",

	booking: "Prenotazione",
	booking_code: "Codice prenotazione",
	business_partner: "Partner commerciale",
	business_partners: "Partner commerciali",

	cancel: "Annulla",
	change_password: "Cambia password",
	checking_invitation_code_message: "Controllo del codice di invito in corso",
	choose_image: "Scegli immagine",
	clear: "Cancella",
	close: "Chiudi",
	code: "Codice",
	companies: "Aziende",
	company: "Azienda",
	company_profile: "Profilo aziendale",
	commercial: "Commerciale",
	commercial_partners: "Partner commerciali",
	content: "Contenuto",
	configuration: "Configurazione",
	confirm: "Conferma",
	contact: "Contatto",
	contact_info: "Informazioni di contatto",
	contact_to_us: "Contattaci",
	container: "Contenitore",
	containers: "Contenitori",
	content: "Contenuto",
	countries: "Paesi",
	country: "Paese",
	create: "Crea",
	create_company: "Crea azienda",
	created_at: "Creato il",
	currencies: "Valute",
	currency: "Valuta",
	current_password: "Password attuale",
	customs_agency: "Agenzia doganale",
	customs_agencies: "Agenzie doganali",

	date: "Data",
	dashboard: "Dashboard",
	default: "Predefinito",
	default_language: "Lingua predefinita",
	delete: "Elimina",
	delete_confirm_message: "Confermi l'eliminazione?",
	description: "Descrizione",
	detail: "Dettaglio",
	discard: "Scarta",
	document_number: "Numero documento",
	download: "Scarica",

	edit: "Modifica",
	edit_item: "Modifica {item}",
	email: "Email",
	employee: "Dipendente",
	employee_type: "Tipo di dipendente",
	employees: "Dipendenti",
	estimated_arrival_day: "Giorno di arrivo stimato",
	expense: "Spesa",
	expenses: "Spese",
	exported_containers: "Container esportati",

	faq: "Domande frequenti",
	file: "File",
	filter: "Filtro",
	first_name: "Nome",
	forget_password: "Password dimenticata",
	forget_password_message: "Hai dimenticato la tua password?",
	from: "Da",

	hide: "Nascondi",
	home_page: "Pagina principale",

	imported_containers: "Container importati",
	incoming: "In arrivo",
	incoming_shipments: "Spedizioni in arrivo",
	initial: "Iniziale",
	invitation: "Invito",
	invitation_code: "Codice invito",
	invitation_mail_sent: "Email di invito inviata",
	invitations: "Inviti",

	join: "Unisciti",

	language_options: "Opzioni di lingua",
	last_name: "Cognome",
	last_one_year: "Ultimo anno",
	latitude: "Latitudine",
	loading_country: "Paese di carico",
	loading_point: "Punto di carico",
	loading_port: "Porto di carico",
	logo: "Logo",
	longitude: "Longitudine",

	go_to_panel: "Vai al pannello",

	mail_sent: "Email inviata",
	migrate: "Migra",
	migrate_partner: "Migra partner",
	message: "Messaggio",
	message_company_create: "Azienda creata.",
	message_company_join: "Unisciti all'azienda",
	message_company_joined: "Sei entrato nell'azienda",
	message_employee_delete: "Dipendente eliminato.",
	message_employee_save: "Dipendente salvato.",
	message_join_company:
		"Inserisci il tuo codice di invito per unirti a un'azienda.",
	method: "Metodo",
	mobile_number: "Numero di cellulare",
	my_role: "Il mio ruolo",

	name: "Nome",
	next: "Avanti",
	new: "Nuovo",
	new_password: "Nuova password",
	no: "No",
	no_content: "Nessun contenuto",
	no_text: "Nessun testo",
	not_found_page_message: "Spiacenti, non riesco a trovare questa pagina :(",
	note: "Nota",

	ok: "Ok",
	outgoing: "In uscita",
	outgoing_shipments: "Spedizioni in uscita",

	parent_group: "Gruppo padre",
	partner: "Partner",
	partners: "Partner",
	passive: "Passivo",
	password: "Password",
	phone_number: "Numero di telefono",
	previous: "Precedente",
	pricing: "Prezzo",
	product: "Prodotto",
	product_group: "Gruppo di prodotti",
	product_groups: "Gruppi di prodotti",
	products: "Prodotti",
	profile: "Profilo",
	proforma_invoice: "Fattura Proforma",
	proforma_invoices: "Fatture Proforma",
	proforma_number: "Numero Proforma",
	purchase: "Acquista",

	quantity: "Quantità",

	receiver: "Destinatario",
	receiver_company: "Azienda destinataria",
	receiver_customs_agency: "Agenzia doganale destinataria",
	redirection_to_home_link: "clicca qui",
	redirection_to_home_message: "Per tornare alla homepage, ",
	redirection_to_sign_in_message: "Hai già un account?",
	redirection_to_sign_up_message: "Non hai ancora un account?",
	reload: "Ricarica",
	reset_password: "Ripristina password",

	sales: "Vendite",
	save: "Salva",
	search: "Cerca",
	select_file: "Seleziona file",
	select_file_place_holder: "Seleziona un'immagine",
	selected_partner: "Partner selezionato",
	send: "Invia",
	send_again: "Invia di nuovo",
	send_email: "Invia email",
	send_invitation: "Invia invito",
	sender: "Mittente",
	sender_company: "Azienda mittente",
	sender_customs_agency: "Agenzia doganale mittente",
	show: "Mostra",
	shipment: "Spedizione",
	shipment_method: "Metodo di spedizione",
	shipments: "Spedizioni",
	shipments_filter: "Filtro spedizioni",
	shipping_term: "Termine di spedizione",
	shipping_terms: "Termini di spedizione",
	sign_in: "Accedi",
	sign_out: "Esci",
	sign_out_confirm_message: "Confermi l'uscita?",
	sign_out_success_message: "Addio.",
	sign_up: "Registrati",
	social_media: "Social media",
	social_media_account: "Account social media",
	status: "Stato",
	subscribe: "Iscriviti",
	super: "Super",
	switch_language: "Cambia lingua",
	switch_theme: "Cambia tema",

	target: "Obiettivo",
	target_partner: "Partner di destinazione",
	theme: "Tema",
	this_month: "Questo mese",
	this_year: "Quest'anno",
	title: "Titolo",
	to: "A",
	toDelete: "Elimina",
	total: "Totale",
	total_amount: "Importo totale",
	type: "Tipo",
	types: "Tipi",

	un_archive: "Rimuovi dall'archivio",
	unit: "Unità",
	units: "Unità",
	unloading_country: "Paese di scarico",
	unloading_point: "Punto di scarico",
	unloading_port: "Porto di scarico",
	use_code: "Usa codice",
	use_invitation_code: "Usa codice invito",
	use_invitation_code_description:
		"Inserisci il codice di invito che hai ricevuto nella tua casella di posta.",
	use_invitation_code_success_message:
		"Il codice di invito è stato utilizzato con successo.",
	user_profile: "Profilo utente",

	vehicle: "Veicolo",
	vehicles: "Veicoli",
	visibility: "Visibilità",

	warehouse: "Magazzino",
	warehouses: "Magazzini",
	website: "Sito web",
	welcome: "Benvenuto",

	yes: "Sì",
};

<template>
	<v-footer color="primary lighten-1" padless>
		<v-row justify="center" no-gutters>
			<v-btn
				v-for="(item, i) in items"
				:key="i"
				class="my-2"
				color="white"
				text
				rounded
				@click="goToElement(item.tag)"
			>
				{{ $t(`${item.tag}`) }}
			</v-btn>
			<v-col class="primary py-4 text-center white--text" cols="12">
				{{ new Date().getFullYear() }} — <strong>Slush Software™</strong>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	data() {
		return {
			items: [
				{ tag: "home_page" },
				{ tag: "content" },
				{ tag: "pricing" },
				{ tag: "faq" },
				{ tag: "contact" },
			],
		};
	},
	methods: {
		goToElement(tag) {
			this.$emit("goTo", tag);
		},
	},
};
</script>

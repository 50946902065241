import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "./i18n";
import MainLayout from "../components/layouts/MainLayout.vue";
import Home from "../components/pages/home/Home.main.vue";
import AcceptInvitation from "../components/pages/AcceptInvitation.vue";
import CompanyProfile from "../components/pages/company_profile/CompanyProfile.main.vue";
import Landing from "../components/pages/landing/Landing.vue";
import Partners from "../components/pages/partners/Partners.vue";
import Products from "../components/pages/product/Products.vue";
import ProductGroups from "../components/pages/ProductGroups.vue";
import ProformaInvoices from "../components/pages/proforma_invoice/ProformaInvoices.vue";
import Shipment from "../components/pages/shipment/Shipment.vue";
import Shipments from "../components/pages/shipment/Shipments.vue";
import Subscription from "../components/pages/finance/Subscription.vue";
import UserProfile from "../components/pages/UserProfile.vue";
import SignIn from "../components/pages/SignIn.vue";
import SignUp from "../components/pages/SignUp.vue";
import Error404 from "../components/pages/404.vue";

Vue.use(VueRouter);

const SITE_NAME = "Antrego";

export const necessity = {
	required: 1,
	notRequired: 0,
	notAllowed: -1,
};

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/",
			name: "landing",
			component: Landing,
			meta: { title: "landing_page" },
		},
		{
			path: "/signin",
			name: "signin",
			component: SignIn,
			meta: { title: "sign_in" },
		},
		{
			path: "/signup",
			name: "signup",
			component: SignUp,
			meta: { title: "sign_up" },
		},
		{
			path: "/accept_invitation/:type",
			name: "accept_invitation",
			component: AcceptInvitation,
			meta: { title: "accept_invitation" },
		},
		{
			path: "",
			component: MainLayout,
			children: [
				{
					path: "/home",
					name: "home",
					component: Home,
					meta: { title: "home" },
				},
				{
					path: "/company_profile",
					name: "companyProfile",
					component: CompanyProfile,
					meta: { title: "company_profile" },
				},
				{
					path: "/partners",
					name: "partners",
					component: Partners,
					meta: { title: "partners" },
				},
				{
					path: "/products",
					name: "products",
					component: Products,
					meta: { title: "products" },
				},
				{
					path: "/product_groups",
					name: "productGroups",
					component: ProductGroups,
					meta: { title: "product_groups" },
				},
				{
					path: "/proforma_invoices",
					name: "proformaInvoices",
					component: ProformaInvoices,
					meta: { title: "proforma_invoices" },
				},
				{
					path: "/shipments",
					name: "shipments",
					component: Shipments,
					meta: { title: "shipments" },
				},
				{
					path: "/shipment/:id",
					name: "shipment",
					component: Shipment,
					meta: { title: "shipment_details" },
				},
				{
					path: "/subscription",
					name: "subscription",
					component: Subscription,
					meta: { title: "subscription" },
				},
				{
					path: "/user_profile",
					name: "userProfile",
					component: UserProfile,
					meta: { title: "user_profile" },
				},
			],
		},
		{
			// Always leave this as last one
			path: "*",
			name: "404",
			component: Error404,
			meta: { title: "not_found_page_message" },
		},
	],
});

export function requiresAuthorization(pageName) {
	if (["signin", "/signin", "signup", "/signup"].includes(pageName)) return necessity.notAllowed;
	else if (["/", "", "home", "404", "accept_invitation", "landing"].includes(pageName))
		return necessity.notRequired;
	else return necessity.required;
}

export function requiresCompany(pageName) {
	if (
		[
			"products",
			"/products",
			"product_groups",
			"/product_groups",
			"proforma_invoices",
			"/proforma_invoices",
		].includes(pageName)
	)
		return necessity.required;
	else return necessity.notRequired;
}

router.beforeEach((to, from, next) => {
	if (store.getters.isReady) {
		const isAuthenticated = store.getters["auth/isAuthenticated"];
		const company = store.getters["company"];
		if (requiresAuthorization(to.name) === necessity.required && !isAuthenticated) {
			next("/signin");
		} else if (requiresAuthorization(to.name) === necessity.notAllowed && isAuthenticated) {
			next("/");
		} else if (requiresCompany(to.name) === necessity.required && !company) next("/");
		else next();
	} else next();

	if (to.meta.title) {
		document.title = `${SITE_NAME} | ${i18n.t(`$pageTitles.${to.meta.title}`)}`;
	} else {
		document.title = `${SITE_NAME}`;
	}
});

export default router;

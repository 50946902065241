export default {
	$accessibility: {
		present: "Активный",
		archived: "Архивированный",
	},
	$accessModifier: {
		$description: {
			private: "Приватный",
			hidden_from_receiver: "Скрыто от получателя",
			hidden_from_sender: "Скрыто от отправителя",
			shared_with_everyone: "Общий доступ для всех",
			shared_with_custom: "Доступ для выбранных",
			shared_with_receiver: "Доступ для получателя",
			shared_with_sender: "Доступ для отправителя",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Андорра",
		united_arab_emirates: "Объединенные Арабские Эмираты",
		afghanistan: "Афганистан",
		antigua_and_barbuda: "Антигуа и Барбуда",
		anguilla: "Ангилья",
		albania: "Албания",
		armenia: "Армения",
		angola: "Ангола",
		antarctica: "Антарктида",
		argentina: "Аргентина",
		american_samoa: "Американское Самоа",
		austria: "Австрия",
		australia: "Австралия",
		aruba: "Аруба",
		aland: "Аландские острова",
		azerbaijan: "Азербайджан",
		bosnia_and_herzegovina: "Босния и Герцеговина",
		barbados: "Барбадос",
		bangladesh: "Бангладеш",
		belgium: "Бельгия",
		burkina_faso: "Буркина-Фасо",
		bulgaria: "Болгария",
		bahrain: "Бахрейн",
		burundi: "Бурунди",
		benin: "Бенин",
		saint_barthelemy: "Сен-Бартельми",
		bermuda: "Бермуды",
		brunei: "Бруней",
		bolivia: "Боливия",
		bonaire_sint_eustatius_and_saba: "Бонэйр, Синт-Эстатиус и Саба",
		brazil: "Бразилия",
		bahamas: "Багамы",
		bhutan: "Бутан",
		bouvet_island: "Остров Буве",
		botswana: "Ботсвана",
		belarus: "Беларусь",
		belize: "Белиз",
		canada: "Канада",
		cocos_islands: "Кокосовые острова",
		dr_congo: "Демократическая Республика Конго",
		central_african_republic: "Центральноафриканская Республика",
		congo_republic: "Республика Конго",
		switzerland: "Швейцария",
		ivory_coast: "Кот-д'Ивуар",
		cook_islands: "Острова Кука",
		chile: "Чили",
		cameroon: "Камерун",
		china: "Китай",
		colombia: "Колумбия",
		costa_rica: "Коста-Рика",
		cuba: "Куба",
		cabo_verde: "Кабо-Верде",
		curacao: "Кюрасао",
		christmas_island: "Остров Рождества",
		cyprus: "Кипр",
		czechia: "Чехия",
		germany: "Германия",
		djibouti: "Джибути",
		denmark: "Дания",
		dominica: "Доминика",
		dominican_republic: "Доминиканская Республика",
		algeria: "Алжир",
		ecuador: "Эквадор",
		estonia: "Эстония",
		egypt: "Египет",
		western_sahara: "Западная Сахара",
		eritrea: "Эритрея",
		spain: "Испания",
		ethiopia: "Эфиопия",
		finland: "Финляндия",
		fiji: "Фиджи",
		falkland_islands: "Фолклендские острова",
		micronesia: "Федеративные Штаты Микронезии",
		faroe_islands: "Фарерские острова",
		france: "Франция",
		gabon: "Габон",
		united_kingdom: "Великобритания",
		grenada: "Гренада",
		georgia: "Грузия",
		french_guiana: "Французская Гвиана",
		guernsey: "Гернси",
		ghana: "Гана",
		gibraltar: "Гибралтар",
		greenland: "Гренландия",
		gambia: "Гамбия",
		guinea: "Гвинея",
		guadeloupe: "Гваделупа",
		equatorial_guinea: "Экваториальная Гвинея",
		greece: "Греция",
		south_georgia_and_south_sandwich_islands:
			"Южная Джорджия и Южные Сандвичевы острова",
		guatemala: "Гватемала",
		guam: "Гуам",
		guinea_bissau: "Гвинея-Бисау",
		guyana: "Гайана",
		hong_kong: "Гонконг",
		heard_island_and_mcdonald_islands: "Остров Херд и острова Макдональд",
		honduras: "Гондурас",
		croatia: "Хорватия",
		haiti: "Гаити",
		hungary: "Венгрия",
		indonesia: "Индонезия",
		ireland: "Ирландия",
		israel: "Израиль",
		isle_of_man: "Остров Мэн",
		india: "Индия",
		british_indian_ocean_territory: "Британская территория в Индийском океане",
		iraq: "Ирак",
		iran: "Иран",
		iceland: "Исландия",
		italy: "Италия",
		jersey: "Джерси",
		jamaica: "Ямайка",
		jordan: "Иордания",
		japan: "Япония",
		kenya: "Кения",
		kyrgyzstan: "Киргизия",
		cambodia: "Камбоджа",
		kiribati: "Кирибати",
		comoros: "Коморы",
		st_kitts_and_nevis: "Сент-Китс и Невис",
		north_korea: "Северная Корея",
		south_korea: "Южная Корея",
		kuwait: "Кувейт",
		cayman_islands: "Каймановы острова",
		kazakhstan: "Казахстан",
		laos: "Лаос",
		lebanon: "Ливан",
		saint_lucia: "Сент-Люсия",
		liechtenstein: "Лихтенштейн",
		sri_lanka: "Шри-Ланка",
		liberia: "Либерия",
		lesotho: "Лесото",
		lithuania: "Литва",
		luxembourg: "Люксембург",
		latvia: "Латвия",
		libya: "Ливия",
		morocco: "Марокко",
		monaco: "Монако",
		moldova: "Молдова",
		montenegro: "Черногория",
		saint_martin: "Сен-Мартен",
		madagascar: "Мадагаскар",
		marshall_islands: "Маршалловы острова",
		north_macedonia: "Северная Македония",
		mali: "Мали",
		myanmar: "Мьянма",
		mongolia: "Монголия",
		macao: "Макао",
		northern_mariana_islands: "Северные Марианские острова",
		martinique: "Мартиника",
		mauritania: "Мавритания",
		montserrat: "Монтсеррат",
		malta: "Мальта",
		mauritius: "Маврикий",
		maldives: "Мальдивы",
		malawi: "Малави",
		mexico: "Мексика",
		malaysia: "Малайзия",
		mozambique: "Мозамбик",
		namibia: "Намибия",
		new_caledonia: "Новая Каледония",
		niger: "Нигер",
		norfolk_island: "Остров Норфолк",
		nigeria: "Нигерия",
		nicaragua: "Никарагуа",
		netherlands: "Нидерланды",
		norway: "Норвегия",
		nepal: "Непал",
		nauru: "Науру",
		niue: "Ниуэ",
		new_zealand: "Новая Зеландия",
		oman: "Оман",
		panama: "Панама",
		peru: "Перу",
		french_polynesia: "Французская Полинезия",
		papua_new_guinea: "Папуа – Новая Гвинея",
		philippines: "Филиппины",
		pakistan: "Пакистан",
		poland: "Польша",
		saint_pierre_and_miquelon: "Сен-Пьер и Микелон",
		pitcairn_islands: "Острова Питкэрн",
		puerto_rico: "Пуэрто-Рико",
		palestine: "Государство Палестина",
		portugal: "Португалия",
		palau: "Палау",
		paraguay: "Парагвай",
		qatar: "Катар",
		reunion: "Реюньон",
		romania: "Румыния",
		serbia: "Сербия",
		russia: "Россия",
		rwanda: "Руанда",
		saudi_arabia: "Саудовская Аравия",
		solomon_islands: "Соломоновы острова",
		seychelles: "Сейшельские острова",
		sudan: "Судан",
		sweden: "Швеция",
		singapore: "Сингапур",
		saint_helena: "Остров Святой Елены",
		slovenia: "Словения",
		svalbard_and_jan_mayen: "Шпицберген и Ян-Майен",
		slovakia: "Словакия",
		sierra_leone: "Сьерра-Леоне",
		san_marino: "Сан-Марино",
		senegal: "Сенегал",
		somalia: "Сомали",
		suriname: "Суринам",
		south_sudan: "Южный Судан",
		sao_tome_and_principe: "Сан-Томе и Принсипи",
		el_salvador: "Сальвадор",
		sint_maarten: "Синт-Маартен",
		syria: "Сирия",
		eswatini: "Эсватини",
		turks_and_caicos_islands: "Теркс и Кайкос",
		chad: "Чад",
		french_southern_territories: "Французские Южные территории",
		togo: "Того",
		thailand: "Таиланд",
		tajikistan: "Таджикистан",
		tokelau: "Токелау",
		timor_leste: "Восточный Тимор",
		turkmenistan: "Туркменистан",
		tunisia: "Тунис",
		tonga: "Тонга",
		turkiye: "Турция",
		trinidad_and_tobago: "Тринидад и Тобаго",
		tuvalu: "Тувалу",
		taiwan: "Тайвань",
		tanzania: "Танзания",
		ukraine: "Украина",
		uganda: "Уганда",
		us_minor_outlying_islands: "Внешние малые острова США",
		united_states: "Соединенные Штаты",
		uruguay: "Уругвай",
		uzbekistan: "Узбекистан",
		vatican_city: "Ватикан",
		st_vincent_and_grenadines: "Сент-Винсент и Гренадины",
		venezuela: "Венесуэла",
		british_virgin_islands: "Британские Виргинские острова",
		us_virgin_islands: "Американские Виргинские острова",
		vietnam: "Вьетнам",
		vanuatu: "Вануату",
		wallis_and_futuna: "Уоллис и Футуна",
		samoa: "Самоа",
		kosovo: "Косово",
		yemen: "Йемен",
		mayotte: "Майотта",
		south_africa: "Южная Африка",
		zambia: "Замбия",
		zimbabwe: "Зимбабве",
	},
	$currency: {
		us_dollar: "Доллар США",
		euro: "Евро",
		turkish_lira: "Турецкая лира",
	},
	$employeeType: {
		admin: "Администратор",
		default: "Обычный",
		exporter: "Экспортер",
		importer: "Импортер",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Добро пожаловать в Antrego",
			description: "Управляйте процессами импорта и экспорта с Antrego!",
			sign_in_title: "У вас уже есть аккаунт?",
			sign_in_subtitle: "Войдите здесь",
			sign_up_title: "Хотите создать аккаунт?",
			sign_up_subtitle: "Зарегистрируйтесь здесь",
		},
		$noCompany: {
			title: "Добро пожаловать, {name}",
			description:
				"Вы успешно вошли в систему. Теперь вам нужно присоединиться к компании или создать свою собственную.",
			join_company_title: "Хотите присоединиться к существующей компании?",
			join_company_subtitle:
				"Введите код приглашения здесь и присоединитесь к компании.",
			create_company_title: "Хотите создать свою компанию?",
			create_company_subtitle: "Создайте новую компанию здесь.",
		},
	},
	$invitationType: {
		employee: "Приглашение для сотрудника",
		company_partner: "Приглашение на коммерческое сотрудничество",
		customs_partner: "Приглашение для таможенного партнера",
	},
	$landing: {
		$question: {
			$title: {
				0: "Что такое Antrego?",
				1: "Какой вклад в мою компанию может внести Antrego в процессе доставки?",
				2: "Какие виды логистики поддерживает Antrego?",
				3: "Почему мне стоит использовать Antrego?",
				4: "Является ли Antrego платным приложением?",
				5: "Клиенты также должны платить за использование Antrego?",
			},
			$text: {
				0: "<p>Программа отслеживания отправок, помогающая компаниям и клиентам в совместном отслеживании, предотвращая ущерб, связанный с задержками в этапе отслеживания.</p>",
				1: `<p>- Делитесь всеми моментальными обновлениями с вашим клиентом для улучшения качества обслуживания.<br>
				- Отслеживайте все документы по доставке в одной системе и делитесь ими с вашим клиентом.<br>
				- Отслеживайте все отправки с одного места. Управляйте своим финансовым положением с помощью динамической отчетности.</p>`,
				2: `<p>- Морская<br>
				- Воздушная<br>
				- Наземная<br>
				- Железнодорожная</p>`,
				3: `<p>Обеспечивает удобство в процессе доставки с помощью предоставляемых услуг.<br>
				<strong>1. Легкий доступ</strong><br>
				Доступ из веб-браузера с любого устройства в любом месте.<br>
				<strong>2. Безопасность данных</strong><br>
				Все данные хранятся с использованием шифрования SHA-256.<br>
				<strong>3. Общее отслеживание</strong><br>
				Предоставляет возможность отслеживать всем участникам бизнес-процесса.</strong>`,
				4: "<p>Да, Antrego - платное приложение. Мы хотим упростить ваши бизнес-процессы, переведя все процессы доставки в цифровой формат, и продолжать делать для вас полезные улучшения. Для поддержки этих улучшений и предоставления вам места на сервере мы взимаем ежемесячную плату.</p>",
				5: "<p>Нет, ваши клиенты не обязаны платить за отслеживание ваших отправок с использованием Antrego.</p>",
			},
		},
		$lifecycle: {
			headline: "ВСЕ ПРОЦЕССЫ ПОД КОНТРОЛЕМ",
			$title: {
				0: "Зарегистрируйтесь",
				1: "Отправьте Проформа-счет",
				2: "Создайте Отправку",
				3: "Отслеживайте Финансовую Активность",
			},
			$text: {
				0: "Вводите информацию о компании и продукции, создавайте клиентов.",
				1: "Создайте проформа-счет и поделитесь им с клиентами для утверждения.",
				2: "Вводите информацию о вашей отправке и добавляйте документы. Управляйте всеми процессами вашей отправки с клиентами через один канал.",
				3: "Отслеживайте все финансовые аспекты связанные с импортом и экспортом в реальном времени.",
			},
		},
		$content: {
			headline: "ПОДДЕРЖИВАЕМЫЕ ВИДЫ ЛОГИСТИКИ",
			$title: {
				0: "Морская",
				1: "Воздушная",
				2: "Наземная",
				3: "Железнодорожная",
			},
		},
		$pricing: {
			0: {
				title: "БЕСПЛАТНО",
				subtitle: "Для физических лиц",
				features: ["1 Пользователь", "3 Партнера", "10 Отправок", "10 Счетов"],
			},
			1: {
				title: "ЕЖЕГОДНО",
				subtitle: "Для компаний",
				features: [
					"20 Пользователей",
					"100 Партнеров",
					"1000 Отправок",
					"1000 Счетов",
				],
			},
			2: {
				title: "ЕЖЕМЕСЯЧНО",
				subtitle: "Для малого бизнеса",
				features: [
					"10 Пользователей",
					"30 Партнеров",
					"250 Отправок",
					"250 Счетов",
				],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Вы уверены, что хотите {function}?",
		created: "{item} создано.",
		deleted: "{item} удалено.",
		file_oversize: "Размер файла не должен превышать {size}.",
		invalid: "Недопустимый {item}",
		sent_email: "Письмо отправлено. ({value})",
		sign_in_failed: "Неправильный адрес электронной почты или пароль",
		updated: "{item} обновлено.",
		migrated: "{item} перенесено. ({value})",
	},
	$productGroup: {
		add_child: "Добавить Дочернюю Группу",
		add_to_root: "Добавить в Корень",
		has_children_fail_message:
			"Группа товаров с дочерней группой не может быть удалена",
		initial_message: "Для добавления первой группы товаров нажмите кнопку {0}.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} создал(а).",
			update: "{name} обновил(а).",
			send: "{name} отправил(а) на утверждение покупателю.",
			revoke: "{name} отозвал(а).",
			approve: "{name} утвердил(а).",
			demand_revise: "{name} запросил(а) ревизию.",
			reject: "{name} отклонил(а).",
			mark_as_processed: "{name} пометил(а) как обработано.",
			create_attachment: "{name} создал(а) вложение.",
			update_attachment: "{name} обновил(а) вложение.",
			delete_attachment: "{name} удалил(а) вложение.",
		},
		$actionDescription2: {
			send: '{name} помечен(а) как "Отправлено".',
			revoke: '{name} помечен(а) как "Отозвано".',
			approve: '{name} помечен(а) как "Утверждено".',
			demand_revise: '{name} помечен(а) как "Ожидает ревизии".',
			reject: "{name} отклонено.",
			mark_as_processed: `{name} помечен(а) как "Обработано".`,
		},
		$actions: {
			approve: "Утвердить",
			demand_revise: "Запросить ревизию",
			mark_as_approved: "Утверждено",
			mark_as_processed: "Обработано",
			mark_as_rejected: "Отклонено",
			mark_as_revise_demanded: "Запрошена ревизия",
			mark_as_revoked: "Отозвано",
			mark_as_sent: "Отправлено",
			reject: "Отклонить",
			revoke: "Отозвать",
			send: "Отправить",
		},
		$status: {
			approved: "Утверждено",
			pending_approval: "Ожидает утверждения",
			pending_revise: "Ожидает ревизии",
			processed: "Обработано",
			rejected: "Отклонено",
			revoked: "Отозвано",
			template: "Шаблон",
		},
		send_confirm_message:
			"Вы уверены, что хотите отправить проформа-счет покупателю?",
		send_success_message: "Проформа-счет отправлен покупателю.",
		approve_confirm_message: "Вы уверены, что хотите утвердить проформа-счет?",
		approve_success_message: "Проформа-счет утвержден.",
		demand_revise_success_message: "Проформа-счет отправлен на ревизию.",
		reject_confirm_message: "Вы уверены, что хотите отклонить проформа-счет?",
		reject_success_message: "Проформа-счет отклонен.",
		mark_as_processed_confirm_message:
			"Вы уверены, что хотите пометить проформа-счет как обработанный?",
		mark_as_processed_success_message:
			"Проформа-счет помечен как обработанный.",
		revoke_confirm_message: "Вы уверены, что хотите отозвать проформа-счет?",
		revoke_success_message: "Проформа-счет отозван.",
		demand_revise_description_message:
			"Пожалуйста, укажите, какую ревизию вы ожидаете:",
		no_attachment_text: "Вложения еще не загружены.",
	},
	$serviceMessage: {
		already_subs_module: "Уже подписан на этот модуль.",
		bad_request: "Неверный запрос",
		code_already_used: "Код уже использован.",
		container_status_not_available: "Статус контейнера недоступен.",
		existing_email_warning: "Электронная почта уже существует.",
		existing_employee_error: "Этот пользователь уже добавлен в вашу компанию.",
		existing_employee_for_email_warning:
			"Пригласительный код уже отправлен на этот адрес электронной почты.",
		existing_invitation_warning:
			"Пригласительный код уже отправлен на этот адрес электронной почты.",
		existing_token_already_have_time:
			"У текущего токена уже есть достаточное время для использования.",
		existing_user: "Пользователь уже существует.",
		five_min_reset_password_mail:
			"Пожалуйста, подождите минимум 5 минут после запроса письма для сброса пароля.",
		forbidden: "Запрещено",
		internal_server_error: "Внутренняя ошибка сервера.",
		instance_can_no_delete_existing_booking_code:
			"Элемент нельзя удалить из-за существующего кода бронирования.",
		instance_cannot_delete: "Элемент нельзя удалить.",
		instance_not_found: "Элемент не найден.",
		invitation_accepting_is_due_to_the_admin:
			"Прием приглашения зависит от администратора.",
		invitation_already_discarded: "Приглашение уже отклонено.",
		invitation_already_used: "Приглашение уже использовано.",
		invitation_has_been_used: "Приглашение было использовано.",
		invitation_not_exist_email:
			"Приглашение с указанным адресом электронной почты не существует.",
		invitation_not_for_custom_agency:
			"Это приглашение не для таможенного агентства.",
		invitation_not_for_company: "Это приглашение не для компании.",
		invitation_not_found: "Приглашение не найдено.",
		invitation_not_valid: "Приглашение больше не действительно.",
		invalid_current_password: "Неверный текущий пароль.",
		invalid_identifier_or_password: "Неверный идентификатор или пароль",
		invalid_invitation_code: "Неверный код приглашения.",
		invalid_moving_request: "Недопустимый запрос на перемещение.",
		invalid_refresh_token: "Неверный токен обновления.",
		invalid_operation: "Недопустимая операция.",
		invalid_unit: "Недопустимая единица.",
		have_no_corporation: "У вас нет компании.",
		have_no_custom_agency: "У вас нет таможенного агентства.",
		have_no_employee: "У вас нет сотрудника.",
		link_not_be_used_anymore: "Эту ссылку больше нельзя использовать.",
		link_has_been_expired: "Срок действия ссылки истек.",
		missing_required_fields: "Отсутствуют обязательные поля.",
		no_employee_found: "Сотрудник не найден.",
		no_proper_partner_found: "Подходящий партнер не найден.",
		not_acceptable: "Неприемлемо.",
		not_allowed_action: "У вас нет разрешения на выполнение этого действия.",
		not_allowed_use_api: "У вас нет разрешения использовать это API.",
		not_found: "Не найдено.",
		not_permitted_use_api: "У вас нет разрешения использовать это поле.",
		own_invitation_cannot_accept:
			"Вы не можете отправить приглашение самому себе.",
		parent_group_not_found: "Группа товаров не найдена.",
		partnerId_required: "Требуется идентификатор партнера.",
		partnership_already_exists: "Партнерство уже существует.",
		password_cant_involve_space_char: "Пароль не может содержать пробелы.",
		password_min_chars_least: "Пароль должен содержать как минимум 6 символов.",
		ports_are_required: "Требуются порты.",
		product_group_tree_not_found: "Дерево группы товаров не найдено.",
		proforma_invoice_action_not_available:
			"Действие с проформой-счетом недоступно для текущего состояния.",
		proforma_invoice_template_update:
			"Необходимо обновить шаблон проформы-счета.",
		shipment_method_is_required: "Требуется метод отправки.",
		shipping_term_is_required: "Требуется условие доставки.",
		shipment_email_not_exist:
			"Пожалуйста, введите действительный адрес электронной почты",
		someone_else_uses_this_email:
			"Кто-то другой уже использует этот адрес электронной почты.",
		someone_else_uses_this_username:
			"Кто-то другой уже использует это имя пользователя.",
		something_went_wrong: "Что-то пошло не так.",
		type_can_be_abstract_company:
			'Свойство "type" может быть только "abstract_company".',
		unauthorized: "Недопустимо",
		undefined_result: "Неопределенный результат.",
		unexpected_error_occurred: "Произошла неожиданная ошибка.",
		unit_already_added: "Единица уже добавлена.",
		username_min_chars_error:
			'"Имя пользователя" должно содержать как минимум 6 символов.',
		user_already_employee: "Пользователь уже является сотрудником.",
		user_already_exists: "Пользователь уже является сотрудником компании.",
		user_does_not_exist: "Пользователь не существует.",
		user_not_found: "Пользователь не найден.",
	},
	$shipment: {
		$actionDescription: {
			create: "Создано {name}.",
			update: "Обновлено {name}.",
			create_container: "{name} добавил контейнер.",
			update_container: "{name} обновил информацию о контейнере.",
			delete_container: "{name} удалил контейнер.",
			create_attachment: "{name} добавил вложение.",
			update_attachment: "{name} обновил вложение.",
			delete_attachment: "{name} удалил вложение.",
			$land: {
				create_container: "{name} добавил транспортное средство.",
				update_container: "{name} обновил информацию о транспортном средстве.",
				delete_container: "{name} удалил транспортное средство.",
			},
		},
		$containerStatus: {
			reserved: "Зарезервирован",
			on_load: "Загружен",
			on_way: "В пути",
			arrived: "Прибыл",
			off_load: "Разгружен",
		},
		$method: {
			sea: "Море",
			aerial: "Воздушный",
			land: "Наземный",
			railway: "Железнодорожный",
		},
		$vehicle: {
			license_number: "Номер лицензии",
		},
		archiving_success_message: "Отгрузка архивирована.",
		leave_message: "Оставьте свое сообщение или комментарий",
		no_booking_info:
			"Информация о бронировании еще не определена. Нажмите кнопку {0}, чтобы добавить.",
		no_transportation_info:
			"Информация о деталях отгрузки еще не определена. Нажмите кнопку {0}, чтобы добавить.",
		un_archiving_success_message: "Отгрузка извлечена из архива.",
	},
	$shortening: {
		number: "Номер",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Миллиметр",
		meter: "Метр",
		kilometer: "Километр",
		milligram: "Миллиграмм",
		gram: "Грамм",
		kilogram: "Килограмм",
		square_meter: "Квадратный метр",
		hectare: "Гектар",
		square_kilometer: "Квадратный километр",
		milliliter: "Миллилитр",
		liter: "Литр",
		cubic_meter: "Кубический метр",
		acres: "Акр",
		feet: "Фут",
		fluid_ounces: "Жидкий унций",
		gallons: "Галлон",
		inches: "Дюйм",
		miles: "Миля",
		ounces: "Унция",
		pounds: "Фунт",
		quarts: "Кварт",
		square_feet: "Квадратный фут",
		square_miles: "Квадратная миля",
		square_yards: "Квадратный ярд",
		square_inches: "Квадратный дюйм",
		yards: "Ярд",
		pieces: "Штука",
		boxes: "Коробка",
		pallets: "Поддоны",
		dozen: "Десятка",
		ton: "Тонна",
	},
	$validation: {
		required: "{item} не может быть пустым",
		required_for_default_language:
			"{item} должно быть определено для языка компании по умолчанию.",
		invalid: "{item} недопустимо",
		email: "Пожалуйста, введите действительный адрес электронной почты",
		min_length: "{item} должно содержать как минимум {length} символов",
		max_length: "{item} должно содержать не более {length} символов",
		password_repeat_warning: "Повтор пароля должен совпадать с новым паролем.",
	},

	accept_invitation_success_message: "Приглашение успешно принято.",
	accessibility: "Доступность",
	actions: "Действия",
	active: "Активный",
	add: "Добавить",
	add_item: "Добавить {item}",
	address: "Адрес",
	again: "Снова",
	alert: "Предупреждение",
	already_have_employee_message: "Вы уже являетесь сотрудником компании.",
	amount: "Сумма",
	archive: "Архивировать",
	attachment: "Вложение",
	attachments: "Вложения",

	booking: "Бронирование",
	booking_code: "Код бронирования",
	business_partner: "Бизнес-партнер",
	business_partners: "Бизнес-партнеры",

	cancel: "Отмена",
	change_password: "Изменить пароль",
	checking_invitation_code_message: "Проверка кода приглашения",
	choose_image: "Выберите изображение",
	clear: "Очистить",
	close: "Закрыть",
	code: "Код",
	companies: "Компании",
	company: "Компания",
	company_profile: "Профиль компании",
	commercial: "Коммерческий",
	commercial_partners: "Торговые партнеры",
	content: "Содержание",
	configuration: "Конфигурация",
	confirm: "Подтвердить",
	contact: "Контакт",
	contact_info: "Контактная информация",
	contact_to_us: "Свяжитесь с нами",
	container: "Контейнер",
	containers: "Контейнеры",
	content: "Содержание",
	countries: "Страны",
	country: "Страна",
	create: "Создать",
	create_company: "Создать компанию",
	created_at: "Создано в",
	currencies: "Валюты",
	currency: "Валюта",
	current_password: "Текущий пароль",
	customs_agency: "Таможенное агентство",
	customs_agencies: "Таможенные агентства",

	date: "Дата",
	dashboard: "Панель управления",
	default: "По умолчанию",
	default_language: "Язык по умолчанию",
	delete: "Удалить",
	delete_confirm_message: "Вы уверены, что хотите удалить это?",
	description: "Описание",
	detail: "Детали",
	discard: "Отклонить",
	document_number: "Номер документа",
	download: "Скачать",

	edit: "Редактировать",
	edit_item: "Редактировать {item}",
	email: "Электронная почта",
	employee: "Сотрудник",
	employee_type: "Тип сотрудника",
	employees: "Сотрудники",
	estimated_arrival_day: "Предполагаемый день прибытия",
	expense: "Расход",
	expenses: "Расходы",
	exported_containers: "Экспортированные контейнеры",

	faq: "Часто задаваемые вопросы",
	file: "Файл",
	filter: "Фильтр",
	first_name: "Имя",
	forget_password: "Забыли пароль",
	forget_password_message: "Забыли пароль?",
	from: "От",

	hide: "Скрыть",
	home_page: "Главная страница",

	imported_containers: "Импортированные контейнеры",
	incoming: "Входящий",
	incoming_shipments: "Входящие отгрузки",
	initial: "Инициальный",
	invitation: "Приглашение",
	invitation_code: "Код приглашения",
	invitation_mail_sent: "Пригласительное письмо отправлено",
	invitations: "Приглашения",

	join: "Присоединиться",

	language_options: "Варианты языка",
	last_name: "Фамилия",
	last_one_year: "Последний год",
	latitude: "Широта",
	loading_country: "Страна загрузки",
	loading_point: "Точка загрузки",
	loading_port: "Порт загрузки",
	logo: "Логотип",
	longitude: "Долгота",

	go_to_panel: "Перейти в панель",

	mail_sent: "Письмо отправлено",
	migrate: "Перенести",
	migrate_partner: "Перенести партнера",
	message: "Сообщение",
	message_company_create: "Компания создана.",
	message_company_join: "Присоединиться к компании",
	message_company_joined: "Вы присоединились к компании",
	message_employee_delete: "Сотрудник удален.",
	message_employee_save: "Сотрудник сохранен.",
	message_join_company:
		"Введите код приглашения, чтобы присоединиться к компании.",
	method: "Метод",
	mobile_number: "Мобильный номер",
	my_role: "Моя роль",

	name: "Имя",
	next: "Далее",
	new: "Новый",
	new_password: "Новый пароль",
	no: "Нет",
	no_content: "Нет контента",
	no_text: "Нет текста",
	not_found_page_message: "Извините, не удалось найти эту страницу :(",
	note: "Примечание",

	ok: "Ок",
	outgoing: "Исходящий",
	outgoing_shipments: "Исходящие отгрузки",

	parent_group: "Родительская группа",
	partner: "Партнер",
	partners: "Партнеры",
	passive: "Пассивный",
	password: "Пароль",
	phone_number: "Номер телефона",
	previous: "Предыдущий",
	pricing: "Ценообразование",
	product: "Продукт",
	product_group: "Группа продуктов",
	product_groups: "Группы продуктов",
	products: "Продукты",
	profile: "Профиль",
	proforma_invoice: "Проформа-счет",
	proforma_invoices: "Проформа-счета",
	proforma_number: "Номер проформы",
	purchase: "Покупка",

	quantity: "Количество",

	receiver: "Получатель",
	receiver_company: "Компания-получатель",
	receiver_customs_agency: "Таможенное агентство-получатель",
	redirection_to_home_link: "нажмите здесь",
	redirection_to_home_message: "Чтобы вернуться на главную страницу, ",
	redirection_to_sign_in_message: "У вас уже есть аккаунт?",
	redirection_to_sign_up_message: "Еще нет аккаунта?",
	reload: "Перезагрузить",
	reset_password: "Сбросить пароль",

	sales: "Продажи",
	save: "Сохранить",
	search: "Поиск",
	select_file: "Выбрать файл",
	select_file_place_holder: "Выберите изображение",
	selected_partner: "Выбранный партнер",
	send: "Отправить",
	send_again: "Отправить снова",
	send_email: "Отправить электронное письмо",
	send_invitation: "Отправить приглашение",
	sender: "Отправитель",
	sender_company: "Компания-отправитель",
	sender_customs_agency: "Таможенное агентство-отправитель",
	show: "Показать",
	shipment: "Отгрузка",
	shipment_method: "Метод отгрузки",
	shipments: "Отгрузки",
	shipments_filter: "Фильтр отгрузок",
	shipping_term: "Условие доставки",
	shipping_terms: "Условия доставки",
	sign_in: "Войти",
	sign_out: "Выйти",
	sign_out_confirm_message: "Вы уверены, что хотите выйти?",
	sign_out_success_message: "До свидания.",
	sign_up: "Зарегистрироваться",
	social_media: "Социальные медиа",
	social_media_account: "Аккаунт в социальных сетях",
	status: "Статус",
	subscribe: "Подписаться",
	super: "Супер",
	switch_language: "Сменить язык",
	switch_theme: "Сменить тему",

	target: "Цель",
	target_partner: "Целевой партнер",
	theme: "Тема",
	this_month: "В этом месяце",
	this_year: "В этом году",
	title: "Заголовок",
	to: "Куда",
	toDelete: "Удалить",
	total: "Итого",
	total_amount: "Общая сумма",
	type: "Тип",
	types: "Типы",

	un_archive: "Извлечь из архива",
	unit: "Единица",
	units: "Единицы",
	unloading_country: "Страна выгрузки",
	unloading_point: "Точка выгрузки",
	unloading_port: "Порт выгрузки",
	use_code: "Использовать код",
	use_invitation_code: "Использовать код приглашения",
	use_invitation_code_description:
		"Введите код приглашения, отправленный вам на почту.",
	use_invitation_code_success_message: "Код приглашения успешно использован.",
	user_profile: "Мой профиль",

	vehicle: "Транспортное средство",
	vehicles: "Транспортные средства",
	visibility: "Видимость",

	warehouse: "Склад",
	warehouses: "Склады",
	website: "Веб-сайт",
	welcome: "Добро пожаловать",

	yes: "Да",
};

export default {
	$accessibility: {
		present: "진행 중",
		archived: "보관됨",
	},
	$accessModifier: {
		$description: {
			private: "비공개",
			hidden_from_receiver: "수신자에게 숨겨짐",
			hidden_from_sender: "송신자에게 숨겨짐",
			shared_with_everyone: "모두와 공유",
			shared_with_custom: "사용자 정의로 공유",
			shared_with_receiver: "수신자와 공유",
			shared_with_sender: "송신자와 공유",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "안도라",
		united_arab_emirates: "아랍에미리트",
		afghanistan: "아프가니스탄",
		antigua_and_barbuda: "앤티과 바부다",
		anguilla: "앵귈라",
		albania: "알바니아",
		armenia: "아르메니아",
		angola: "앙골라",
		antarctica: "남극",
		argentina: "아르헨티나",
		american_samoa: "아메리칸 사모아",
		austria: "오스트리아",
		australia: "호주",
		aruba: "아루바",
		aland: "올란드 제도",
		azerbaijan: "아제르바이잔",
		bosnia_and_herzegovina: "보스니아 헤르체고비나",
		barbados: "바베이도스",
		bangladesh: "방글라데시",
		belgium: "벨기에",
		burkina_faso: "부르키나 파소",
		bulgaria: "불가리아",
		bahrain: "바레인",
		burundi: "부룬디",
		benin: "베냉",
		saint_barthelemy: "세인트 바르텔레미",
		bermuda: "버뮤다",
		brunei: "브루나이",
		bolivia: "볼리비아",
		bonaire_sint_eustatius_and_saba: "보네르, 신트 유스타티우스 및 사바",
		brazil: "브라질",
		bahamas: "바하마",
		bhutan: "부탄",
		bouvet_island: "부베 섬",
		botswana: "보츠와나",
		belarus: "벨라루스",
		belize: "벨리즈",
		canada: "캐나다",
		cocos_islands: "코코스 제도",
		dr_congo: "콩고 민주 공화국",
		central_african_republic: "중앙 아프리카 공화국",
		congo_republic: "콩고 공화국",
		switzerland: "스위스",
		ivory_coast: "아이보리 해안",
		cook_islands: "쿡 제도",
		chile: "칠레",
		cameroon: "카메룬",
		china: "중국",
		colombia: "콜롬비아",
		costa_rica: "코스타리카",
		cuba: "쿠바",
		cabo_verde: "카보베르데",
		curacao: "퀴라소",
		christmas_island: "크리스마스 섬",
		cyprus: "키프로스",
		czechia: "체코",
		germany: "독일",
		djibouti: "지부티",
		denmark: "덴마크",
		dominica: "도미니카",
		dominican_republic: "도미니카 공화국",
		algeria: "알제리",
		ecuador: "에콰도르",
		estonia: "에스토니아",
		egypt: "이집트",
		western_sahara: "서부 사하라",
		eritrea: "에리트레아",
		spain: "스페인",
		ethiopia: "에티오피아",
		finland: "핀란드",
		fiji: "피지",
		falkland_islands: "포클랜드 제도",
		micronesia: "미크로네시아",
		faroe_islands: "페로 제도",
		france: "프랑스",
		gabon: "가봉",
		united_kingdom: "영국",
		grenada: "그레나다",
		georgia: "조지아",
		french_guiana: "프랑스 기아나",
		guernsey: "건지",
		ghana: "가나",
		gibraltar: "지브롤터",
		greenland: "그린란드",
		gambia: "감비아",
		guinea: "기니",
		guadeloupe: "과들루프",
		equatorial_guinea: "적도 기니",
		greece: "그리스",
		south_georgia_and_south_sandwich_islands:
			"사우스조지아 사우스샌드위치 제도",
		guatemala: "과테말라",
		guam: "괌",
		guinea_bissau: "기니 비사우",
		guyana: "가이아나",
		hong_kong: "홍콩",
		heard_island_and_mcdonald_islands: "허드 섬 및 맥도날드 제도",
		honduras: "온두라스",
		croatia: "크로아티아",
		haiti: "아이티",
		hungary: "헝가리",
		indonesia: "인도네시아",
		ireland: "아일랜드",
		israel: "이스라엘",
		isle_of_man: "맨 섬",
		india: "인도",
		british_indian_ocean_territory: "영국령 인도양 지역",
		iraq: "이라크",
		iran: "이란",
		iceland: "아이슬란드",
		italy: "이탈리아",
		jersey: "저지",
		jamaica: "자메이카",
		jordan: "요르단",
		japan: "일본",
		kenya: "케냐",
		kyrgyzstan: "키르기스스탄",
		cambodia: "캄보디아",
		kiribati: "키리바시",
		comoros: "코모로스",
		st_kitts_and_nevis: "세인트 키츠 네비스",
		north_korea: "북한",
		south_korea: "대한민국",
		kuwait: "쿠웨이트",
		cayman_islands: "케이맨 제도",
		kazakhstan: "카자흐스탄",
		laos: "라오스",
		lebanon: "레바논",
		saint_lucia: "세인트 루시아",
		liechtenstein: "리히텐슈타인",
		sri_lanka: "스리랑카",
		liberia: "라이베리아",
		lesotho: "레소토",
		lithuania: "리투아니아",
		luxembourg: "룩셈부르크",
		latvia: "라트비아",
		libya: "리비아",
		morocco: "모로코",
		monaco: "모나코",
		moldova: "몰도바",
		montenegro: "몬테네그로",
		saint_martin: "세인트 마틴",
		madagascar: "마다가스카르",
		marshall_islands: "마셜 제도",
		north_macedonia: "북마케도니아",
		mali: "말리",
		myanmar: "미얀마",
		mongolia: "몽골",
		macao: "마카오",
		northern_mariana_islands: "북마리아나 제도",
		martinique: "마르티니크",
		mauritania: "모리타니아",
		montserrat: "몬세라트",
		malta: "몰타",
		mauritius: "모리셔스",
		maldives: "몰디브",
		malawi: "말라위",
		mexico: "멕시코",
		malaysia: "말레이시아",
		mozambique: "모잠비크",
		namibia: "나미비아",
		new_caledonia: "뉴 칼레도니아",
		niger: "니제르",
		norfolk_island: "노퍽 섬",
		nigeria: "나이지리아",
		nicaragua: "니카라과",
		netherlands: "네덜란드",
		norway: "노르웨이",
		nepal: "네팔",
		nauru: "나우루",
		niue: "니우에",
		new_zealand: "뉴질랜드",
		oman: "오만",
		panama: "파나마",
		peru: "페루",
		french_polynesia: "프랑스령 폴리네시아",
		papua_new_guinea: "파푸아 뉴기니",
		philippines: "필리핀",
		pakistan: "파키스탄",
		poland: "폴란드",
		saint_pierre_and_miquelon: "생 피에르 및 미클롱",
		pitcairn_islands: "핏케언 제도",
		puerto_rico: "푸에르토리코",
		palestine: "팔레스타인",
		portugal: "포르투갈",
		palau: "팔라우",
		paraguay: "파라과이",
		qatar: "카타르",
		reunion: "리유니온",
		romania: "루마니아",
		serbia: "세르비아",
		russia: "러시아",
		rwanda: "르완다",
		saudi_arabia: "사우디 아라비아",
		solomon_islands: "솔로몬 제도",
		seychelles: "세이셸",
		sudan: "수단",
		sweden: "스웨덴",
		singapore: "싱가포르",
		saint_helena: "세인트 헬레나",
		slovenia: "슬로베니아",
		svalbard_and_jan_mayen: "스발바르 얀 마옌 제도",
		slovakia: "슬로바키아",
		sierra_leone: "시에라리온",
		san_marino: "산마리노",
		senegal: "세네갈",
		somalia: "소말리아",
		suriname: "수리남",
		south_sudan: "남수단",
		sao_tome_and_principe: "상투메 프린시페",
		el_salvador: "엘살바도르",
		sint_maarten: "신트마르턴",
		syria: "시리아",
		eswatini: "에스와티니",
		turks_and_caicos_islands: "터크스 케이커스 제도",
		chad: "차드",
		french_southern_territories: "프랑스령 남부 지역",
		togo: "토고",
		thailand: "태국",
		tajikistan: "타지키스탄",
		tokelau: "토켈라우",
		timor_leste: "동티모르",
		turkmenistan: "투르크메니스탄",
		tunisia: "튀니지",
		tonga: "통가",
		turkiye: "터키",
		trinidad_and_tobago: "트리니다드 토바고",
		tuvalu: "투발루",
		taiwan: "대만",
		tanzania: "탄자니아",
		ukraine: "우크라이나",
		uganda: "우간다",
		us_minor_outlying_islands: "미국령 작은 외딴 섬",
		united_states: "미국",
		uruguay: "우루과이",
		uzbekistan: "우즈베키스탄",
		vatican_city: "바티칸 시국",
		st_vincent_and_grenadines: "세인트 빈센트 그레나딘",
		venezuela: "베네수엘라",
		british_virgin_islands: "영국령 버진 아일랜드",
		us_virgin_islands: "미국령 버진 아일랜드",
		vietnam: "베트남",
		vanuatu: "바누아투",
		wallis_and_futuna: "왈리스 퓌튀나",
		samoa: "사모아",
		kosovo: "코소보",
		yemen: "예멘",
		mayotte: "마요트",
		south_africa: "남아프리카",
		zambia: "잠비아",
		zimbabwe: "짐바브웨",
	},
	$currency: {
		us_dollar: "미국 달러",
		euro: "유로",
		turkish_lira: "터키 리라",
	},
	$employeeType: {
		admin: "관리자",
		default: "일반",
		exporter: "수출업자",
		importer: "수입업자",
	},
	$format: {
		date: "YYYY.MM.DD",
		date_time: "YYYY.MM.DD HH:mm:SS",
		date_time_without_second: "YYYY.MM.DD HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "안트레고에 오신 것을 환영합니다",
			description:
				"안트레고를 사용하여 수입 및 수출 프로세스를 효과적으로 관리하세요!",
			sign_in_title: "계정이 있으신가요?",
			sign_in_subtitle: "여기에서 로그인하세요",
			sign_up_title: "계정을 만들고 싶으세요?",
			sign_up_subtitle: "여기에서 가입하세요",
		},
		$noCompany: {
			title: "{name}님, 환영합니다",
			description:
				"성공적으로 로그인했습니다. 이제 기존 회사에 가입하거나 새로운 회사를 만들어야 합니다.",
			join_company_title: "기존 회사에 가입하고 싶으세요?",
			join_company_subtitle: "여기에서 초대 코드를 입력하고 회사에 가입하세요.",
			create_company_title: "회사를 만들고 싶으세요?",
			create_company_subtitle: "여기에서 새로운 회사를 만드세요.",
		},
	},
	$invitationType: {
		employee: "직원 초대",
		company_partner: "상업적 협력 초대",
		customs_partner: "세관 협력 초대",
	},
	$landing: {
		$question: {
			$title: {
				0: "안트레고란?",
				1: "안트레고는 운송 과정에서 내 회사에 어떤 기여를 할 것인가요?",
				2: "안트레고가 지원하는 물류 유형은 무엇인가요?",
				3: "왜 안트레고를 사용해야 하나요?",
				4: "안트레고는 유료 애플리케이션인가요?",
				5: "안트레고를 사용하는 고객도 요금을 지불해야 하나요?",
			},
			$text: {
				0: "<p>운송 회사와 고객이 공동 추적을 도와 지연으로 인한 손해를 방지하는 운송 추적 프로그램입니다.</p>",
				1: `<p>- 모든 실시간 업데이트를 고객과 공유하세요. 서비스 품질을 향상시킵니다.<br>
				- 모든 운송 관련 문서를 단일 시스템에서 볼 수 있고 고객과 공유하세요.<br>
				- 모든 운송을 동일한 위치에서 추적하세요. 동적 보고서로 재무를 관리하세요.</p>`,
				2: `<p>- 해상<br>
				- 항공<br>
				- 육상<br>
				- 기차</p>`,
				3: `<p>제공되는 서비스로 운송 과정을 쉽게 만들기 위해 언제든지 사용 가능합니다.<br>
				<strong>1. 쉬운 액세스</strong><br>
				웹 브라우저를 통해 모든 장치 및 위치에서 액세스할 수 있습니다.<br>
				<strong>2. 데이터 보안</strong><br>
				모든 데이터는 SHA-256 암호화 기술로 안전하게 저장됩니다.<br>
				<strong>3. 공동 추적</strong><br>
				비즈니스 프로세스의 모든 이해 관계자에게 추적 기회를 제공합니다.</strong>`,
				4: "<p>네, 이것은 유료 애플리케이션입니다. 선적 프로세스의 모든 단계를 디지털로 이동하여 비즈니스를 간편하게 만들고 계속해서 유용한 개선을 하고자 합니다. 이러한 개선이 계속되기 위해 사용한 서버 공간에 대한 월간 요금을 부과합니다.</p>",
				5: "<p>아니요. 고객이 당신의 선적을 추적하는 동안 별도의 요금이 부과되지 않습니다.</p>",
			},
		},
		$lifecycle: {
			headline: "모든 프로세스가 손에 닿습니다",
			$title: {
				0: "가입하기",
				1: "프로포르마 송장 보내기",
				2: "운송 생성",
				3: "재무 활동 추적",
			},
			$text: {
				0: "회사 정보 및 제품을 입력하고 고객을 만듭니다.",
				1: "프로포르마 송장을 작성하고 고객에게 승인을 받기 위해 공유하세요.",
				2: "운송과 관련된 정보를 입력하고 문서를 첨부하세요. 운송의 모든 프로세스를 단일 채널에서 고객과 관리하세요.",
				3: "수입 및 수출과 관련된 모든 재무 상태를 실시간으로 추적하세요.",
			},
		},
		$content: {
			headline: "지원되는 물류 유형",
			$title: {
				0: "해상",
				1: "항공",
				2: "육상",
				3: "기차",
			},
		},
		$pricing: {
			0: {
				title: "무료",
				subtitle: "개인",
				features: ["1 사용자", "3 파트너", "10 운송", "10 송장"],
			},
			1: {
				title: "연간",
				subtitle: "기업",
				features: ["20 사용자", "100 파트너", "1000 운송", "1000 송장"],
			},
			2: {
				title: "월간",
				subtitle: "소기업",
				features: ["10 사용자", "30 파트너", "250 운송", "250 송장"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function}을(를) 진행하시겠습니까?",
		created: "{item}이(가) 생성되었습니다.",
		deleted: "{item}이(가) 삭제되었습니다.",
		file_oversize: "파일 크기는 최대 {size}여야 합니다.",
		invalid: "유효하지 않은 {item}",
		sent_email: "이메일이 전송되었습니다. ({value})",
		sign_in_failed: "이메일 또는 비밀번호가 잘못되었습니다.",
		updated: "{item}이(가) 업데이트되었습니다.",
		migrated: "{item}이(가) 이동되었습니다. ({value})",
	},
	$productGroup: {
		add_child: "하위 그룹 추가",
		add_to_root: "루트에 추가",
		has_children_fail_message:
			"하위 그룹이 있는 제품 그룹은 삭제할 수 없습니다.",
		initial_message: "{0} 버튼을 눌러 첫 번째 제품 그룹을 추가하세요.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} 님이 작성했습니다.",
			update: "{name} 님이 업데이트했습니다.",
			send: "{name} 님이 수신자에게 전송했습니다.",
			revoke: "{name} 님이 철회했습니다.",
			approve: "{name} 님이 승인했습니다.",
			demand_revise: "{name} 님이 개정을 요청했습니다.",
			reject: "{name} 님이 거절했습니다.",
			mark_as_processed: "{name} 님이 처리 완료로 표시했습니다.",
			create_attachment: "{name} 님이 첨부 파일을 생성했습니다.",
			update_attachment: "{name} 님이 첨부 파일을 업데이트했습니다.",
			delete_attachment: "{name} 님이 첨부 파일을 삭제했습니다.",
		},
		$actionDescription2: {
			send: '{name}이(가) "전송됨"으로 표시되었습니다.',
			revoke: '{name}이(가) "취소됨"으로 표시되었습니다.',
			approve: '{name}이(가) "승인됨"으로 표시되었습니다.',
			demand_revise: '{name}이(가) "개정 대기 중"으로 표시되었습니다.',
			reject: "{name}이(가) 거부했습니다.",
			mark_as_processed: `{name}이(가) "처리됨"으로 표시되었습니다.`,
		},
		$actions: {
			approve: "승인",
			demand_revise: "개정 요청",
			mark_as_approved: "승인됨",
			mark_as_processed: "처리됨",
			mark_as_rejected: "거부됨",
			mark_as_revise_demanded: "개정 요청됨",
			mark_as_revoked: "철회됨",
			mark_as_sent: "전송됨",
			reject: "거부",
			revoke: "철회",
			send: "전송",
		},
		$status: {
			approved: "승인됨",
			pending_approval: "승인 대기 중",
			pending_revise: "개정 대기 중",
			processed: "처리됨",
			rejected: "거부됨",
			revoked: "철회됨",
			template: "템플릿",
		},
		send_confirm_message: "프로포르마 송장을 수신자에게 전송하시겠습니까?",
		send_success_message: "프로포르마 송장이 수신자에게 전송되었습니다.",
		approve_confirm_message: "프로포르마 송장을 승인하시겠습니까?",
		approve_success_message: "프로포르마 송장이 승인되었습니다.",
		demand_revise_success_message:
			"프로포르마 송장이 개정을 위해 반환되었습니다.",
		reject_confirm_message: "프로포르마 송장을 거부하시겠습니까?",
		reject_success_message: "프로포르마 송장이 거부되었습니다.",
		mark_as_processed_confirm_message:
			"프로포르마 송장을 처리 완료로 표시하시겠습니까?",
		mark_as_processed_success_message:
			"프로포르마 송장이 처리 완료로 표시되었습니다.",
		revoke_confirm_message: "프로포르마 송장을 철회하시겠습니까?",
		revoke_success_message: "프로포르마 송장이 철회되었습니다.",
		demand_revise_description_message: "원하는 개정 사항을 설명하세요:",
		no_attachment_text: "아직 첨부 파일이 업로드되지 않았습니다.",
	},
	$serviceMessage: {
		already_subs_module: "이 모듈에는 이미 구독이 있습니다.",
		bad_request: "잘못된 요청",
		code_already_used: "이 코드는 이미 사용되었습니다.",
		container_status_not_available: "컨테이너 상태를 사용할 수 없습니다.",
		existing_email_warning: "이미 존재하는 이메일입니다.",
		existing_employee_error: "이 사용자는 이미 귀하의 회사에 추가되었습니다.",
		existing_employee_for_email_warning:
			"이 초대 코드는 이미이 이메일로 전송되었습니다.",
		existing_invitation_warning:
			"이 초대 코드는 이미이 이메일로 전송되었습니다.",
		existing_token_already_have_time:
			"기존 토큰에는 이미 충분한 시간이 있습니다.",
		existing_user: "사용자가 이미 존재합니다.",
		five_min_reset_password_mail:
			"한 번의 비밀번호 재설정 이메일을 요청한 후에는 최소 5분을 기다려야 합니다.",
		forbidden: "금지된 요청",
		internal_server_error: "내부 서버 오류.",
		instance_can_no_delete_existing_booking_code:
			"요소는 기존 예약 코드 때문에 더 이상 삭제할 수 없습니다.",
		instance_cannot_delete: "요소는 더 이상 삭제할 수 없습니다.",
		instance_not_found: "요소를 찾을 수 없습니다.",
		invitation_accepting_is_due_to_the_admin:
			"초대 수락은 관리자에게 달려 있습니다.",
		invitation_already_discarded: "초대가 이미 취소되었습니다.",
		invitation_already_used: "초대가 이미 사용되었습니다.",
		invitation_has_been_used: "초대가 이미 사용되었습니다.",
		invitation_not_exist_email: "초대 이메일이 존재하지 않습니다.",
		invitation_not_for_custom_agency:
			"이 초대는 관세 대리점을 위한 것이 아닙니다.",
		invitation_not_for_company: "이 초대는 회사를 위한 것이 아닙니다.",
		invitation_not_found: "초대를 찾을 수 없습니다.",
		invitation_not_valid: "초대는 더 이상 유효하지 않습니다.",
		invalid_current_password: "현재 비밀번호가 잘못되었습니다.",
		invalid_identifier_or_password: "유효하지 않은 이메일 또는 비밀번호",
		invalid_invitation_code: "초대 코드가 유효하지 않습니다.",
		invalid_moving_request: "유효하지 않은 이동 요청입니다.",
		invalid_refresh_token: "유효하지 않은 리프레시 토큰입니다.",
		invalid_operation: "유효하지 않은 작업.",
		invalid_unit: "유효하지 않은 단위입니다.",
		have_no_corporation: "회사가 없습니다.",
		have_no_custom_agency: "관세 대리점이 없습니다.",
		have_no_employee: "귀하의 회사에는 직원이 없습니다.",
		link_not_be_used_anymore: "이 링크는 더 이상 사용할 수 없습니다.",
		link_has_been_expired: "링크의 유효 기간이 만료되었습니다.",
		missing_required_fields: "필수 필드를 입력해야 합니다.",
		no_employee_found: "직원을 찾을 수 없습니다.",
		no_proper_partner_found: "적절한 파트너를 찾을 수 없습니다.",
		not_acceptable: "허용되지 않는 요청",
		not_allowed_action: "이 작업을 수행할 권한이 없습니다.",
		not_allowed_use_api: "이 API를 사용할 권한이 없습니다.",
		not_found: "찾을 수 없습니다.",
		not_permitted_use_api: "이 API를 사용할 권한이 없습니다.",
		own_invitation_cannot_accept: "자신에게 초대를 보낼 수 없습니다.",
		parent_group_not_found: "상위 제품 그룹을 찾을 수 없습니다.",
		partnerId_required: "파트너 ID 필드는 필수입니다.",
		partnership_already_exists: "이미 파트너십이 추가되었습니다.",
		password_cant_involve_space_char:
			"비밀번호에는 공백 문자를 포함할 수 없습니다.",
		password_min_chars_least: "비밀번호는 최소 6자 이상이어야 합니다.",
		ports_are_required: "포트는 필수입니다.",
		product_group_tree_not_found: "제품 그룹 트리를 찾을 수 없습니다.",
		proforma_invoice_action_not_available:
			"Proforma 송장의 상태로 이 작업을 수행할 수 없습니다.",
		proforma_invoice_template_update:
			"Proforma 송장을 업데이트하려면 템플릿이 있어야 합니다.",
		shipment_method_is_required: "운송 방법은 필수입니다.",
		shipping_term_is_required: "운송 조건은 필수입니다.",
		shipment_email_not_exist: "유효한 이메일을 입력하세요.",
		someone_else_uses_this_email: "다른 사람이이 이메일 주소를 사용 중입니다.",
		someone_else_uses_this_username:
			"다른 사람이이 사용자 이름을 사용 중입니다.",
		something_went_wrong: "문제가 발생했습니다.",
		type_can_be_abstract_company:
			'"type" 속성은 "abstract_company" 여야 합니다.',
		unauthorized: "인증되지 않은 요청",
		undefined_result: "정의되지 않은 결과입니다.",
		unexpected_error_occurred: "예기치 않은 오류가 발생했습니다.",
		unit_already_added: "단위가 이미 추가되었습니다.",
		username_min_chars_error: '"사용자 이름"은 최소 6자 이상이어야 합니다.',
		user_already_employee: "사용자는 이미 직원입니다.",
		user_already_exists: "사용자는 이미 회사의 직원으로 등록되어 있습니다.",
		user_does_not_exist: "사용자가 존재하지 않습니다.",
		user_not_found: "사용자를 찾을 수 없습니다.",
	},
	$shipment: {
		$actionDescription: {
			create: "{name}에 의해 생성되었습니다.",
			update: "{name}에 의해 업데이트되었습니다.",
			create_container: "{name}이(가) 컨테이너를 추가했습니다.",
			update_container: "{name}이(가) 컨테이너를 업데이트했습니다.",
			delete_container: "{name}이(가) 컨테이너를 삭제했습니다.",
			create_attachment: "{name}이(가) 첨부 파일을 생성했습니다.",
			update_attachment: "{name}이(가) 첨부 파일을 업데이트했습니다.",
			delete_attachment: "{name}이(가) 첨부 파일을 삭제했습니다.",
			$land: {
				create_container: "{name}이(가) 차량을 추가했습니다.",
				update_container: "{name}이(가) 차량을 업데이트했습니다.",
				delete_container: "{name}이(가) 차량을 삭제했습니다.",
			},
		},
		$containerStatus: {
			reserved: "예약됨",
			on_load: "적재됨",
			on_way: "이동 중",
			arrived: "도착함",
			off_load: "하차됨",
		},
		$method: {
			sea: "해상",
			aerial: "공기",
			land: "육지",
			railway: "철도",
		},
		$vehicle: {
			license_number: "차량 등록 번호",
		},
		archiving_success_message: "운송이 보관되었습니다.",
		leave_message: "메시지 또는 의견을 남겨주세요",
		no_booking_info:
			"아직 예약 정보가 정의되지 않았습니다. 추가하려면 {0} 버튼을 클릭하세요.",
		no_transportation_info:
			"아직 운송 세부 정보가 정의되지 않았습니다. 정의하려면 {0} 버튼을 클릭하세요.",
		un_archiving_success_message: "운송이 보관 해제되었습니다.",
	},
	$shortening: {
		number: "번호",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "밀리미터",
		meter: "미터",
		kilometer: "킬로미터",
		milligram: "밀리그램",
		gram: "그램",
		kilogram: "킬로그램",
		square_meter: "제곱 미터",
		hectare: "헥타르",
		square_kilometer: "제곱 킬로미터",
		milliliter: "밀리리터",
		liter: "리터",
		cubic_meter: "미터3",
		acres: "에이커",
		feet: "피트",
		fluid_ounces: "액체 온스",
		gallons: "갤런",
		inches: "인치",
		miles: "마일",
		ounces: "온스",
		pounds: "파운드",
		quarts: "쿼트",
		square_feet: "제곱 피트",
		square_miles: "제곱 마일",
		square_yards: "제곱 야드",
		square_inches: "제곱 인치",
		yards: "야드",
		pieces: "조각",
		boxes: "상자",
		pallets: "팔렛",
		dozen: "다스",
		ton: "톤",
	},
	$validation: {
		required: "{item}은(는) 비워 둘 수 없습니다.",
		required_for_default_language:
			"{item} 정보는 기본 언어로 정의되어야 합니다.",
		invalid: "{item}이(가) 잘못되었습니다.",
		email: "유효한 이메일을 입력하세요.",
		min_length: "{item}은(는) 최소 {length}자여야 합니다.",
		max_length: "{item}은(는) 최대 {length}자여야 합니다.",
		password_repeat_warning: "비밀번호 재입력은 새 비밀번호와 동일해야 합니다.",
	},

	accept_invitation_success_message: "초대를 성공적으로 수락했습니다.",
	accessibility: "접근성",
	actions: "동작",
	active: "활성",
	add: "추가",
	add_item: "{item} 추가",
	address: "주소",
	again: "다시",
	alert: "경고",
	already_have_employee_message: "이미 회사 직원입니다.",
	amount: "금액",
	archive: "보관",
	attachment: "첨부 파일",
	attachments: "첨부 파일",

	booking: "예약",
	booking_code: "예약 코드",
	business_partner: "비즈니스 파트너",
	business_partners: "비즈니스 파트너",

	cancel: "취소",
	change_password: "비밀번호 변경",
	checking_invitation_code_message: "초대 코드 확인 중",
	choose_image: "이미지 선택",
	clear: "지우기",
	close: "닫기",
	code: "코드",
	companies: "회사",
	company: "회사",
	company_profile: "회사 프로필",
	commercial: "상업",
	commercial_partners: "상업 파트너",
	content: "콘텐츠",
	configuration: "구성",
	confirm: "확인",
	contact: "연락처",
	contact_info: "연락처 정보",
	contact_to_us: "문의하기",
	container: "컨테이너",
	containers: "컨테이너",
	content: "콘텐츠",
	countries: "국가",
	country: "국가",
	create: "생성",
	create_company: "회사 생성",
	created_at: "생성 시간",
	currencies: "통화",
	currency: "통화",
	current_password: "현재 비밀번호",
	customs_agency: "세관 대행사",
	customs_agencies: "세관 대행사",

	date: "날짜",
	dashboard: "대시보드",
	default: "기본",
	default_language: "기본 언어",
	delete: "삭제",
	delete_confirm_message: "삭제 작업을 확인하시겠습니까?",
	description: "설명",
	detail: "세부 정보",
	discard: "취소",
	document_number: "문서 번호",
	download: "다운로드",

	edit: "편집",
	edit_item: "{item} 편집",
	email: "이메일",
	employee: "직원",
	employee_type: "직원 유형",
	employees: "직원",
	estimated_arrival_day: "예상 도착일",
	expense: "비용",
	expenses: "비용",
	exported_containers: "수출된 컨테이너",

	faq: "자주 묻는 질문",
	file: "파일",
	filter: "필터",
	first_name: "이름",
	forget_password: "비밀번호 분실",
	forget_password_message: "비밀번호를 잊으셨나요?",
	from: "출발지",

	hide: "숨기기",
	home_page: "홈 페이지",

	imported_containers: "수입된 컨테이너",
	incoming: "수신",
	incoming_shipments: "수신되는 발송물",
	initial: "초기",
	invitation: "초대",
	invitation_code: "초대 코드",
	invitation_mail_sent: "초대 이메일이 전송되었습니다.",
	invitations: "초대",

	join: "참여",

	language_options: "언어 옵션",
	last_name: "성",
	last_one_year: "지난 1년",
	latitude: "위도",
	loading_country: "적재 국가",
	loading_point: "적재 지점",
	loading_port: "적재 항구",
	logo: "로고",
	longitude: "경도",

	go_to_panel: "패널로 이동",

	mail_sent: "메일 발송",
	migrate: "이전",
	migrate_partner: "파트너 이전",
	message: "메시지",
	message_company_create: "회사가 생성되었습니다.",
	message_company_join: "회사에 가입하세요.",
	message_company_joined: "회사에 가입했습니다.",
	message_employee_delete: "직원이 삭제되었습니다.",
	message_employee_save: "직원이 저장되었습니다.",
	message_join_company: "회사에 가입하려면 초대 코드를 입력하세요.",
	method: "방법",
	mobile_number: "휴대 전화 번호",
	my_role: "내 역할",

	name: "이름",
	next: "다음",
	new: "새로운",
	new_password: "새 비밀번호",
	no: "아니오",
	no_content: "콘텐츠 없음",
	no_text: "텍스트 없음",
	not_found_page_message: "죄송합니다. 페이지를 찾을 수 없습니다 :(",
	note: "노트",

	ok: "확인",
	outgoing: "나가는",
	outgoing_shipments: "나가는 발송물",

	parent_group: "상위 그룹",
	partner: "파트너",
	partners: "파트너",
	passive: "비활성",
	password: "비밀번호",
	phone_number: "전화번호",
	previous: "이전",
	pricing: "가격 책정",
	product: "제품",
	product_group: "제품 그룹",
	product_groups: "제품 그룹",
	products: "제품",
	profile: "프로필",
	proforma_invoice: "프로포마 송장",
	proforma_invoices: "프로포마 송장",
	proforma_number: "프로포마 번호",
	purchase: "구매",

	quantity: "수량",

	receiver: "수신자",
	receiver_company: "수신자 회사",
	receiver_customs_agency: "수신자 세관 대행사",
	redirection_to_home_link: "여기를 클릭하세요",
	redirection_to_home_message: "홈으로 돌아가려면 ",
	redirection_to_sign_in_message: "계정이 있나요?",
	redirection_to_sign_up_message: "아직 계정이 없으신가요?",
	reload: "새로고침",
	reset_password: "비밀번호 재설정",

	sales: "판매",
	save: "저장",
	search: "검색",
	select_file: "파일 선택",
	select_file_place_holder: "이미지를 선택하세요",
	selected_partner: "선택된 파트너",
	send: "보내기",
	send_again: "다시 보내기",
	send_email: "이메일 보내기",
	send_invitation: "초대 보내기",
	sender: "송신자",
	sender_company: "송신자 회사",
	sender_customs_agency: "송신자 세관 대행사",
	show: "보기",
	shipment: "선적",
	shipment_method: "선적 방법",
	shipments: "선적",
	shipments_filter: "선적 필터",
	shipping_term: "선적 조건",
	shipping_terms: "선적 조건",
	sign_in: "로그인",
	sign_out: "로그아웃",
	sign_out_confirm_message: "로그아웃을 확인하시겠습니까?",
	sign_out_success_message: "안녕히 가세요.",
	sign_up: "가입",
	social_media: "소셜 미디어",
	social_media_account: "소셜 미디어 계정",
	status: "상태",
	subscribe: "구독",
	super: "슈퍼",
	switch_language: "언어 변경",
	switch_theme: "테마 변경",

	target: "대상",
	target_partner: "대상 파트너",
	theme: "테마",
	this_month: "이번 달",
	this_year: "올해",
	title: "제목",
	to: "받는 사람",
	toDelete: "삭제",
	total: "총",
	total_amount: "총액",
	type: "유형",
	types: "유형",

	un_archive: "보관 해제",
	unit: "단위",
	units: "단위",
	unloading_country: "언로딩 국가",
	unloading_point: "언로딩 포인트",
	unloading_port: "언로딩 항구",
	use_code: "코드 사용",
	use_invitation_code: "초대 코드 사용",
	use_invitation_code_description: "이메일로 받은 초대 코드를 입력하세요.",
	use_invitation_code_success_message: "초대 코드가 성공적으로 사용되었습니다.",
	user_profile: "사용자 프로필",

	vehicle: "차량",
	vehicles: "차량",
	visibility: "가시성",

	warehouse: "창고",
	warehouses: "창고",
	website: "웹 사이트",
	welcome: "환영합니다",

	yes: "예",
};

<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:items-per-page="-1"
		hide-default-footer
		class="background"
		@click:row="rowAction"
		disable-sort
	>
		<template v-slot:item.estimatedArrivalDay="{ item }">
			{{
				item.estimatedArrivalDay
					? item.estimatedArrivalDay.format($t("$format.date"))
					: ""
			}}
		</template>
		<template v-slot:item.buttons="{ item }" v-if="useActions">
			<v-tooltip bottom open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="containerDetailDialog.show(item)" v-on="on">
						<v-icon small>mdi-magnify-expand</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("detail") }}</span>
			</v-tooltip>
			<v-tooltip bottom open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="containerDialog.show(item)" v-on="on">
						<v-icon small>mdi-pencil</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("edit") }}</span>
			</v-tooltip>
		</template>

		<template v-slot:footer v-if="useActions">
			<container-dialog
				v-model="containerDialog.visibility"
				:item="containerDialog.item"
				:shipmentId="shipment.id"
				@saved="containerDialog.saved"
				@deleted="containerDialog.deleted"
			/>

			<container-detail-dialog
				v-model="containerDetailDialog.visibility"
				:item="containerDetailDialog.item"
				:shipment="shipment"
			/>
		</template>
	</v-data-table>
</template>

<script>
import ContainerDialog from "../dialog/ContainerDialog";
import ContainerDetailDialog from "../dialog/ContainerDetailDialog";

export default {
	props: {
		containers: Array,
		useActions: Boolean,
		showContentSummary: Boolean,
		shipment: Object | undefined,
	},
	components: { ContainerDialog, ContainerDetailDialog },
	data() {
		return {
			headers: [],
			items: [],
			containerDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.containerDialog.item = item;
					this.containerDialog.visibility = true;
				},
				hide: () => {
					this.containerDialog.item = null;
					this.containerDialog.visibility = false;
				},
				saved: () => {
					// TODO: modify to use in shipment page
					// this.containerDialog.hide();
					// this.showSnackBar({
					// 	message: this.$t(
					// 		this.containerDialog.item
					// 			? "$message.created"
					// 			: "$message.updated",
					// 		{
					// 			item: this.$t("container"),
					// 		}
					// 	),
					// });
					// this.loadContainers();
				},
				deleted: () => {
					// TODO: modify to use in shipment page
					// this.containerDialog.hide();
					// this.showSnackBar({
					// 	message: this.$t("$message.confirm", {
					// 		function: this.$t("toDelete"),
					// 	}),
					// });
					// this.loadContainers();
				},
			},
			containerDetailDialog: {
				visibility: false,
				item: null,
				hasChange: false,
				show: (item) => {
					this.containerDetailDialog.item = item;
					this.containerDetailDialog.visibility = true;
				},
				hide: () => {
					this.containerDetailDialog.item = null;
					this.containerDetailDialog.visibility = false;
					this.containerDetailDialog.hasChange = false;
				},
				changed: () => {
					this.containerDetailDialog.hasChange = true;
				},
				closed: () => {
					// TODO: modify to use in shipment page
					// if (this.containerDetailDialog.hasChange)
					// 	this.loadShipment(this.shipment.id);
				},
			},
		};
	},
	methods: {
		handleProps() {
			this.headers.push({
				text: `${this.$t("container")} ${this.$t("$shortening.number")}`,
				value: "code",
				sortable: false,
				width: "20%",
			});
			if (this.showContentSummary)
				this.headers.push({
					text: this.$t("content"),
					value: "content",
					sortable: false,
					width: "40%",
				});
			this.headers.push({
				text: this.$t("status"),
				value: "status",
				sortable: false,
				width: "20%",
			});
			this.headers.push({
				text: this.$t("estimated_arrival_day"),
				value: "estimatedArrivalDay",
				sortable: false,
				width: "20%",
			});
			if (this.useActions)
				this.headers.push({
					value: "buttons",
					align: "right",
					sortable: false,
					width: 145,
				});

			for (const container of this.containers) {
				let names = [];
				for (let i = 0; i < Math.min(2, container.products.length); i++)
					names.push(container.products[i].name[this.$lang]);
				if (container.products.length > 2) names.push["..."];
				this.items.push({
					code: container.code,
					status: this.$t(`$shipment.$containerStatus.${container.status}`),
					estimatedArrivalDay: container.estimatedArrivalDay,
					content: names.join(", "),
				});
			}
		},
		rowAction(item) {
			this.$emit("selected", item);
		},
	},
	created() {
		this.handleProps();
	},
};
</script>

import Vue from "vue";
import Vuex, { Store } from "vuex";
import { state, getters, mutations, actions } from "./modules/root.vuex";
import ui from "./modules/ui.vuex";
import auth from "./modules/auth.vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
	state,
	getters,
	mutations,
	actions,
	modules: {
		ui,
		auth,
	},
});

export default store;

import Model from "../_model";

export default class AntProductModel extends Model {
	constructor(provider) {
		super(provider, "antrego/products");
	}

	filter({ onSuccess, onFail }) {
		return super.filter({ onSuccess, onFail });
	}
}

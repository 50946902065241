<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("attachment") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<!-- <title-value-list-item
						v-if="editMode"
						:value="currentItem.name"
						:title="$t('file')"
						icon="mdi-file-outline"
					/> -->
					<v-file-input
						v-if="!editMode"
						v-model="localFile"
						:label="$t('file')"
						:show-size="currentItem.id ? false : true"
						:placeholder="$t('select_file')"
						truncate-length="32"
						:disabled="hasAnyProcess"
						:loading="uploading"
						:rules="rules.file"
						@change="localFileChangeAction"
						append-outer-icon="mdi-paperclip"
						prepend-icon="mdi-file-outline"
					>
						<template v-slot:selection="{ text }">
							{{ text }}
						</template>
					</v-file-input>
					<v-text-field
						v-model="currentItem.name"
						:label="$t('name')"
						prepend-icon="mdi-pencil"
						:rules="rules.name"
					/>

					<v-select
						v-model="currentItem.accessModifier"
						:items="accessModifierOptions"
						:label="$t('visibility')"
						:rules="rules.accessibility"
						:disabled="hasAnyProcess"
						prepend-icon="mdi-lock-outline"
					/>

					<v-textarea
						v-model="currentItem.note"
						:label="$t('note')"
						:disabled="hasAnyProcess"
						prepend-icon="mdi-notebook-edit-outline"
						rows="2"
					/>
				</v-form>
			</v-card-text>
			<v-divider />

			<v-card-actions>
				<v-btn
					v-if="editMode"
					color="error"
					:disabled="hasAnyProcess"
					:loading="deleting"
					@click="deleteAction"
				>
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="hasAnyProcess" @click="cancelAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					color="accent"
					:disabled="hasAnyProcess"
					:loading="saving"
					:text="editMode"
					@click="saveAction"
				>
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import TitleValueListItem from "../../../partials/TitleValueListItem";
import { mapState } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
	props: {
		value: Boolean,
		item: Object,
		shipmentId: String,
		myRole: "sender" | "receiver" | "sender_customs_agency" | "receiver_customs_agency",
	},
	mixins: [uiMixin],
	components: { TitleValueListItem },
	data() {
		return {
			currentItem: null,
			localFile: null,
			valid: null,
			saving: false,
			deleting: false,
			uploading: false,
			downloading: false,
			accessModifierOptions: [],
			rules: {
				file: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("file") }),
					(v) => !v || v.size <= 5242880 || this.$t("$message.file_oversize", { size: "5MB" }),
				],
				name: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("name") })],
				accessibility: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("accessibility") }),
				],
			},
		};
	},
	computed: {
		...mapState(["corporation"]),
		editMode() {
			return Boolean(this.currentItem.id);
		},
		iAmSenderCompany() {
			return this.myRole == "sender";
		},
		iAmReceiverCompany() {
			return this.myRole == "receiver";
		},
		iAmSenderCustomsAgency() {
			return this.myRole == "sender_customs_agency";
		},
		iAmReceiverCustomsAgency() {
			return this.myRole == "receiver_customs_agency";
		},
		iAmAnySender() {
			return this.iAmSenderCompany || this.iAmSenderCustomsAgency;
		},
		iAmAnyReceiver() {
			return this.iAmReceiverCompany || this.iAmReceiverCustomsAgency;
		},
		hasAnyProcess() {
			return this.saving || this.deleting || this.uploading || this.downloading;
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	mounted() {
		if (!this.currentItem) this.handleProps();
	},
	methods: {
		handleProps() {
			this.currentItem = this.item
				? cloneDeep(this.item)
				: {
						file: {
							name: null,
							type: null,
						},
						note: null,
						accessModifier: null,
				  };
			if (this.iAmSenderCompany) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "sender_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_custom"),
						value: "sender_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_receiver"),
						value: "sender_receiver",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "sender_private";
			} else if (this.iAmReceiverCompany) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "receiver_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_custom"),
						value: "receiver_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_sender"),
						value: "sender_receiver",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "receiver_private";
			} else if (this.iAmSenderCustomsAgency) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.hidden_from_receiver"),
						value: "sender_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier) this.currentItem.accessModifier = "sender_protected";
			} else if (this.iAmReceiverCustomsAgency) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.hidden_from_sender"),
						value: "receiver_protected",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_everyone"),
						value: "public",
					}
				);
				if (!this.currentItem.accessModifier)
					this.currentItem.accessModifier = "receiver_protected";
			}
		},
		reset() {
			this.currentItem = null;
			this.localFile = null;
			this.accessModifierOptions = [];
		},
		localFileChangeAction(file) {
			this.currentItem.name = file.name.split(".")[0];
		},
		cancelAction() {
			this.reset();
			this.$emit("input", false);
			this.$emit("closed");
		},
		saveAction() {
			if (this.valid) {
				if (this.editMode) {
					this.saving = true;
					remote.shipments.attachments.update(this.currentItem, this.shipmentId, {
						onSuccess: (_result) => {
							this.saving = false;
							this.reset();
							this.$emit("saved");
						},
						onFail: (_) => (this.saving = false),
					});
				} else {
					this.uploading = true;
					remote.files.send(this.localFile, this.corporation.id, {
						onSuccess: (response) => {
							this.uploading = false;
							this.currentItem.file = response;
							this.saving = true;
							remote.shipments.attachments.create(this.currentItem, this.shipmentId, {
								onSuccess: (_result) => {
									this.saving = false;
									this.reset();
									this.$emit("saved");
								},
								onFail: (_) => (this.saving = false),
							});
						},
						onFail: (_) => (this.uploading = false),
					});
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("$message.confirm", {
					function: this.$t("toDelete"),
				}),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.attachments.delete(this.currentItem.id, this.shipmentId, {
						onSuccess: () => {
							this.deleting = false;
							this.$emit("deleted");
						},
						onFail: (_) => (this.deleting = false),
					});
				},
			});
		},
	},
};
</script>

<template>
	<v-container v-if="innerCompany" class="pb-0">
		<v-form v-model="valid" ref="form">
			<v-row class="ma-4">
				<v-col align="center" cols="12" sm="5" xl="2" offset-xl="2">
					<v-avatar
						size="200"
						style="position: relative"
						:color="!editMode || innerCompany.logo ? null : 'primary'"
					>
						<v-img
							v-if="logoUrl || newLogoUrl"
							:style="`position: absolute; opacity: ${editMode ? 0.5 : 1}`"
							alt="user"
							:src="newLogoUrl || logoUrl"
							lazy-src="https://picsum.photos/id/11/100/60"
							aspect-ratio="1"
						>
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate />
								</v-row>
							</template>
						</v-img>
						<v-icon v-else-if="!logoUrl && !editMode" class="transparency" x-large>
							mdi-image-outline
						</v-icon>
						<v-btn
							v-if="editMode"
							icon
							x-large
							absolute
							:loading="loading"
							elevation="2"
							@click="$refs.selectedFile.$refs.input.click()"
						>
							<v-icon x-large elevation="2"> mdi-pencil </v-icon>
						</v-btn>
						<v-file-input
							v-show="false"
							ref="selectedFile"
							v-model="innerCompany.logo"
							@change="changeLogo"
							accept="image/png, image/jpg, image/jpeg"
						/>
					</v-avatar>
				</v-col>
				<v-col cols="12" sm="7" xl="6">
					<v-row>
						<v-col cols="12" class="py-0">
							<title-value-list-item
								v-if="!editMode"
								:title="$t('name')"
								:value="innerCompany.name"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.name"
								:label="$t('name')"
								:rules="rules.name"
							/>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode"
								:title="$t('country')"
								:value="`${$t(`$country.${innerCompany.country.name}`)} (${
									innerCompany.country.code
								})`"
							/>
							<country-select
								v-else-if="editMode"
								v-model="dummyCompany.countryId"
								:label="$t(`country`)"
								:options="this.countries"
								:rules="rules.country"
							/>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode && innerCompany.phoneNumber"
								:title="$t('phone_number')"
								:value="innerCompany.phoneNumber"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.phoneNumber"
								:label="$t('phone_number')"
								:rules="rules.phoneNumber"
								type="number"
								hide-spin-buttons
							/>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode && innerCompany.email"
								:title="$t('email')"
								:value="innerCompany.email"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.email"
								:label="$t('email')"
								:rules="rules.email"
							/>
						</v-col>

						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode && innerCompany.website"
								:title="$t('website')"
								:value="innerCompany.website"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.website"
								:label="$t('website')"
								:rules="rules.website"
							/>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode && innerCompany.taxNumber"
								:title="$t('tax_number')"
								:value="innerCompany.taxNumber"
								counter="11"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.taxNumber"
								:label="$t('tax_number')"
								:rules="rules.taxNumber"
								type="number"
								hide-spin-buttons
								counter="11"
							/>
						</v-col>
						<v-col cols="12" class="py-0">
							<title-value-list-item
								v-if="!editMode && innerCompany.address"
								:title="$t('address')"
								:value="innerCompany.address"
								counter="100"
							/>
							<v-text-field
								v-else-if="editMode"
								v-model="dummyCompany.address"
								:label="$t('address')"
								:rules="rules.address"
								counter="100"
							/>
						</v-col>
					</v-row>
					<v-divider v-if="company" class="my-4" />
					<v-row v-if="company">
						<v-col cols="12" class="pa-0">
							<v-subheader>{{ $t("configuration") }}</v-subheader>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode"
								:title="$t('default_language')"
								:value="$t(`$language.${innerCompany.settings.localization.defaultLanguageCode}`)"
							/>
							<v-select
								v-else
								v-model="dummyCompany.settings.localization.defaultLanguageCode"
								:label="$t('default_language')"
								:items="languageOptions"
								@change="defaultLanguageChangeAction"
							/>
						</v-col>
						<v-col cols="12" sm="6" class="py-0">
							<title-value-list-item
								v-if="!editMode"
								:title="$t('language_options')"
								:value="
									() =>
										innerCompany.settings.localization.options
											.map((i) => $t(`$language.${i}`))
											.join(', ')
								"
							/>
							<v-select
								v-else
								v-model="dummyCompany.settings.localization.options"
								:label="$t('language_options')"
								:items="languageOptions"
								small-chips
								multiple
							/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>
		<v-divider />
		<v-row class="ma-0">
			<v-spacer />
			<v-btn
				v-if="!editMode"
				class="ma-4"
				elevation="1"
				text
				:disabled="loading"
				@click="setEditMode(true)"
			>
				{{ $t("edit") }}
			</v-btn>
			<v-btn
				v-if="editMode"
				class="my-4 mx-2"
				elevation="1"
				text
				:disabled="loading"
				@click="setEditMode(false)"
			>
				{{ $t("cancel") }}
			</v-btn>
			<v-btn
				v-if="editMode"
				class="my-4 mx-2"
				elevation="1"
				:disabled="loading"
				:loading="saving"
				@click="saveAction"
				color="primary"
			>
				<v-icon left> mdi-content-save </v-icon>
				{{ $t("save") }}
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import uiMixin from "../../../store/interfaces/ui.mixin";
import TitleValueListItem from "../../partials/TitleValueListItem";
import remote from "../../../data/remote";
import CountrySelect from "../../controls/CountrySelect";
import cloneDeep from "lodash.clonedeep";

export default {
	mixins: [uiMixin],
	components: { TitleValueListItem, CountrySelect },
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company", "customsAgency", "employee"]),

		loading() {
			return this.saving || this.uploading;
		},
	},
	data() {
		return {
			innerCompany: null,
			dummyCompany: null,
			absolute: true,
			overlay: false,
			valid: true,
			editMode: false,
			uploading: false,
			saving: false,
			logoUrl: null,
			newLogoUrl: null,
			selectedFile: null,
			statuses: {
				0: this.$t("passive"),
				1: this.$t("active"),
			},
			languageOptions: [
				{ text: this.$t("$language.en"), value: "en" },
				{ text: this.$t("$language.tr"), value: "tr" },
			],
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						else if (v && v.length < 3)
							return this.$t("$validation.min_length", {
								item: this.$t("name"),
								length: "3",
							});
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "50",
							});
						return true;
					},
				],
				country: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("country"),
							});
						return true;
					},
				],
				taxNumber: [
					(v) => {
						if (!v) {
							return this.$t("$validation.required", {
								item: this.$t("tax_number"),
							});
						} else if (isNaN(v)) {
							return this.$t("$validation.invalid", {
								item: this.$t("tax_number"),
							});
						} else if (v.length < 10) {
							return this.$t("$validation.min_length", {
								item: this.$t("tax_number"),
								length: "10",
							});
						} else if (v.length > 11) {
							return this.$t("$validation.max_length", {
								item: this.$t("tax_number"),
								length: "11",
							});
						}
						return true;
					},
				],
				address: [
					(v) => {
						if (!v) {
							return this.$t("$validation.required", {
								item: this.$t("address"),
							});
						} else if (v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("address"),
								length: "100",
							});
						}
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("phone_number"),
							});
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: "20",
							});
						return true;
					},
				],
				email: [
					(v) => {
						if (v === "") return true;
						else if (v && !/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("email"),
								length: "100",
							});
						return true;
					},
				],
				website: [
					(v) => {
						if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("website"),
								length: "50",
							});
						return true;
					},
				],
			},
		};
	},
	methods: {
		...mapActions("file", ["sendFile"]),
		setInnerCompany() {
			if (this.company) this.innerCompany = cloneDeep(this.company);
			else if (this.customsAgency) this.innerCompany = cloneDeep(this.customsAgency);
			this.handleData();
		},
		handleData() {
			this.setCompanyCountry();
			this.loadLogo();
			this.setStatus();
		},
		setCompanyCountry() {
			if (this.innerCompany) {
				var dummy = this.countries.find((country) => country.id === this.innerCompany.countryId);
				this.innerCompany.country = dummy
					? { name: dummy.name, id: dummy.id, code: dummy.code }
					: { name: "", id: 0, code: "" };
			}
		},
		setStatus() {
			if (this.innerCompany) this.innerCompany.statusText = this.statuses[this.innerCompany.status];
		},
		loadLogo() {
			if (this.innerCompany.logo) {
				remote.files.get(this.innerCompany.logo.key, {
					onSuccess: (result) => {
						this.logoUrl = window.URL.createObjectURL(new Blob([result]));
					},
				});
			}
		},
		changeLogo(item) {
			if (item && item.size <= 262144) {
				this.selectedFile = item;
				this.newLogoUrl = URL.createObjectURL(item);
			} else if (item)
				this.showSnackBar({
					message: this.$t("$message.file_oversize", { size: "256kB" }),
				});
		},
		setEditMode(val) {
			this.editMode = val;
			this.dummyCompany = val ? cloneDeep(this.innerCompany) : null;
			if (this.newLogoUrl) this.$refs.selectedFile.reset();
			this.newLogoUrl = null;
		},
		defaultLanguageChangeAction() {
			if (
				this.company &&
				!this.dummyCompany.settings.localization.options.includes(
					this.dummyCompany.settings.localization.defaultLanguageCode
				)
			)
				this.dummyCompany.settings.localization.options.push(
					this.dummyCompany.settings.localization.defaultLanguageCode
				);
		},
		saveAction() {
			if (this.valid) {
				if (this.selectedFile) {
					this.uploading = true;
					remote.files.send(this.selectedFile, "companyLogo", {
						onSuccess: (response) => {
							this.uploading = false;
							this.dummyCompany.logo = {
								key: response.key,
								type: response.type,
							};
							if (this.company) this.saveCompany(this.dummyCompany);
							else if (this.customsAgency) this.saveCustomsAgency(this.dummyCompany);
						},
						onFail: () => (this.uploading = false),
					});
				} else if (this.company) this.saveCompany(this.dummyCompany);
				else if (this.customsAgency) this.saveCustomsAgency(this.dummyCompany);
			} else this.$refs.form.validate();
		},
		saveCompany(item) {
			this.saving = true;
			remote.companies.update(item, {
				onSuccess: (response) => {
					this.saving = false;
					this.$store.commit("SET_CORPORATION", response);
					this.innerCompany = response;
					this.handleData();
					this.setEditMode(false);
					this.$editEvent("company");
				},
				onFail: () => (this.saving = false),
			});
		},
		saveCustomsAgency(item) {
			this.saving = true;
			remote.customsAgencies.update(item, {
				onSuccess: (response) => {
					this.saving = false;
					this.$store.commit("SET_CORPORATION", response);
					this.innerCompany = response;
					this.handleData();
					this.setEditMode(false);
				},
				onFail: () => (this.saving = false),
			});
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.setInnerCompany();
	},
};
</script>

<style scoped>
.transparency {
	background-color: rgba(255, 255, 255, 0.1);
}
</style>

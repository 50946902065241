<template>
	<v-main class="background">
		<v-container class="my-8">
			<v-col align="center">
				<h1 class="text-h4">
					{{ $t(`$landing.$content.headline`) }}
				</h1>
				<v-row justify="center">
					<v-col
						v-for="(item, i) in items"
						:key="i"
						cols="12"
						sm="3"
						md="3"
						lg="3"
					>
						<v-card
							class="mx-auto mt-16"
							max-width="240"
							align="center"
							color="transparent"
						>
							<v-img
								:src="item.imageUrl"
								class="align-end"
								gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
								height="240px"
							>
								<v-card-title class="white--text text-h5">
									{{ $t(`$landing.$content.$title.${i}`) }}
								</v-card-title>
							</v-img>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-container>
	</v-main>
</template>

<script>
import remote from "../../../../data/remote";

export default {
	data() {
		return {
			items: [
				{
					filePath: "landing/ship.png",
					imageUrl: "",
				},
				{
					filePath: "landing/plain.png",
					imageUrl: "",
				},
				{
					filePath: "landing/truck.png",
					imageUrl: "",
				},
				{
					filePath: "landing/train.png",
					imageUrl: "",
				},
			],
		};
	},
	methods: {
		loadImages() {
			this.items.forEach((i) => {
				remote.files.get(i.filePath, {
					onSuccess: (result) => {
						i.imageUrl = window.URL.createObjectURL(new Blob([result]));
					},
				});
			});
		},
	},
	created() {
		this.loadImages();
	},
};
</script>

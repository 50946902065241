<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t(isLandShipment ? "vehicle" : "container") }}
			</v-card-title>
			<v-card-text>
				<v-form v-model="valid" ref="form">
					<v-text-field
						prepend-icon="mdi-numeric"
						v-model="currentItem.code"
						:label="$t(isLandShipment ? `$shipment.$vehicle.license_number` : `$shortening.number`)"
						:rules="rules.code"
					/>
					<v-autocomplete
						v-model="currentItem.status"
						:items="statusOptions"
						item-text="text"
						item-value="value"
						:label="$t(`status`)"
						:rules="rules.status"
						prepend-icon="mdi-list-status"
					/>
					<date-picker-moment
						v-model="currentItem.estimatedArrivalDay"
						showPrependIcon
						:label="$t('estimated_arrival_day')"
					/>
					<v-text-field
						:rules="rules.note"
						prepend-icon="mdi-pen"
						v-model="currentItem.note"
						:label="$t('note')"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn
					v-if="currentItem.id"
					color="error"
					:disabled="deleting || saving"
					:loading="deleting"
					@click="deleteButtonAction"
				>
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="deleting || saving" @click="cancelButtonAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					color="accent"
					:disabled="deleting || saving"
					:loading="saving"
					@click="saveButtonAction"
				>
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import cloneDeep from "lodash.clonedeep";
import DatePickerMoment from "../../../controls/DatePickerMoment";

export default {
	mixins: [uiMixin],
	props: { value: Boolean, item: Object, shipment: Object },
	components: { DatePickerMoment },
	data() {
		return {
			valid: null,
			saving: false,
			deleting: false,
			currentItem: Object,
			statusOptions: [
				{
					text: this.$t("$shipment.$containerStatus.reserved"),
					value: "reserved",
				},
				{
					text: this.$t("$shipment.$containerStatus.on_load"),
					value: "on_load",
				},
				{
					text: this.$t("$shipment.$containerStatus.on_way"),
					value: "on_way",
				},
				{
					text: this.$t("$shipment.$containerStatus.arrived"),
					value: "arrived",
				},
				{
					text: this.$t("$shipment.$containerStatus.off_load"),
					value: "off_load",
				},
			],
			containerTemplate: {
				code: "",
				status: "reserved",
				estimatedArrivalDay: null,
			},
			rules: {
				code: [
					(v) => {
						if (!v) return Boolean(v) || this.$t("$validation.required", { item: this.$t("code") });
						else if (v && v.length > 50)
							return this.$t("$validation.max_length", {
								item: this.$t("code"),
								length: "50",
							});
						return true;
					},
				],
				status: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("status") })],
				note: [
					(v) => {
						if (v && v.length > 240)
							return this.$t("$validation.max_length", {
								item: this.$t("note"),
								length: "240",
							});
						return true;
					},
				],
			},
		};
	},

	computed: {
		isLandShipment() {
			return this.shipment.method === "land";
		},
	},

	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},

	methods: {
		handleProps() {
			this.currentItem = cloneDeep(this.item || this.containerTemplate);
		},
		reset() {
			if (!this.currentItem.id) {
				this.containerTemplate.estimatedArrivalDay = this.currentItem.estimatedArrivalDay;
				this.containerTemplate.status = this.currentItem.status;
			}
			this.currentItem = null;
			this.saving = false;
			this.deleting = false;
		},
		saveButtonAction() {
			if (this.valid) {
				this.saving = true;
				if (this.currentItem.id) {
					remote.shipments.containers.update(this.currentItem, this.shipment.id, {
						onSuccess: () => {
							this.saving = false;
							this.$emit("saved");
						},
						onFail: (error) => {
							this.saving = false;
							if (error) console.error(error);
						},
					});
				} else {
					remote.shipments.containers.create(this.currentItem, this.shipment.id, {
						onSuccess: () => {
							this.saving = false;
							this.$emit("saved");
						},
						onFail: (error) => {
							this.saving = false;
							if (error) console.error(error);
						},
					});
				}
			} else this.$refs.form.validate();
		},
		deleteButtonAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.shipments.containers.delete(this.currentItem.id, this.shipment.id, {
						onSuccess: () => {
							this.deleting = false;
							this.$emit("deleted");
						},
						onFail: (error) => {
							this.deleting = false;
							if (error) console.error(error);
						},
					});
				},
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
	mounted() {
		this.handleProps();
	},
};
</script>

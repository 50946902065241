<template>
	<v-main>
		<v-navigation-drawer
			v-if="corporation"
			v-model="$store.state.ui.navigationDrawer.state"
			app
			color="primary"
			dark
		>
			<v-list dense v-if="isAuthenticated" class="mt-0">
				<v-list-item style="height: 56px">
					<v-img max-height="46" cover src="@/assets/antrego_logo.png" />
				</v-list-item>
				<v-divider />
				<v-list-item v-for="(item, i) in listItems" :key="i" link :to="item.url">
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<v-toolbar :class="toolbarBottomMargin" color="accent">
					<v-row>
						<v-col cols="3" class="px-2">
							<v-tooltip top open-delay="500" v-if="isAuthenticated">
								<template v-slot:activator="{ on }">
									<v-btn icon @click="userProfileAction" v-on="on">
										<v-icon>mdi-account</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("user_profile") }}</span>
							</v-tooltip>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-tooltip top open-delay="500" v-if="corporation">
								<template v-slot:activator="{ on }">
									<v-btn icon @click="corporationProfileAction" v-on="on">
										<v-icon>mdi-domain</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("company_profile") }}</span>
							</v-tooltip>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-tooltip top open-delay="500">
								<template v-slot:activator="{ on }">
									<v-btn icon @click="switchTheme()" v-on="on">
										<v-icon>mdi-theme-light-dark</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("switch_theme") }}</span>
							</v-tooltip>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-tooltip top open-delay="500" v-if="isAuthenticated">
								<template v-slot:activator="{ on }">
									<v-btn icon @click="signOutAction" v-on="on">
										<v-icon>mdi-exit-run</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("sign_out") }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-toolbar>
			</template>
			<!-- <template v-slot:append>
				<div class="pa-2">
					<v-btn block @click="logOut()">Logout</v-btn>
				</div>
			</template> -->
		</v-navigation-drawer>

		<router-view />
	</v-main>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import { getSetting, setSetting, Setting } from "../../data/local/ls";

export default {
	mixins: [uiMixin],
	data() {
		return {
			listItems: [],
		};
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		...mapGetters(["company", "customsAgency", "hasSubscription"]),
		...mapState(["corporation"]),
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
		toolbarBottomMargin() {
			return this.isMobile ? "mb-16" : "mb-0";
		},
		showSubscriptionButton() {
			return (
				!this.customsAgency &&
				(!this.hasSubscription || (this.hasSubscription && !this.corporation?.payTrInfo?.uToken))
			);
		},
	},
	methods: {
		...mapActions("auth", ["signOut"]),
		switchTheme() {
			setSetting(Setting.dark_mode, !getSetting(Setting.dark_mode));
			location.reload();
		},
		prepareList() {
			this.listItems.push({
				icon: "mdi-monitor-dashboard",
				title: this.$t("dashboard"),
				url: "/home",
			});
			if (this.company) {
				this.listItems.push({
					icon: "mdi-package-variant-closed",
					title: this.$t("products"),
					url: "/products",
				});
				this.listItems.push({
					icon: "mdi-file-tree",
					title: this.$t("product_groups"),
					url: "/product_groups",
				});
				this.listItems.push({
					icon: "mdi-file-document",
					title: this.$t("proforma_invoices"),
					url: "/proforma_invoices",
				});
			}
			this.listItems.push({
				icon: "mdi-truck-delivery",
				title: this.$t("shipments"),
				url: "/shipments",
			});
			this.listItems.push({
				icon: "mdi-handshake",
				title: this.$t("partners"),
				url: "/partners",
			});
			// if (this.showSubscriptionButton)
			this.listItems.push({
				icon: "mdi-credit-card-outline",
				title: this.$t("subscription"),
				url: "/subscription",
			});
		},
		userProfileAction() {
			this.$router.push({ name: "userProfile" });
		},
		signOutAction() {
			this.showConfirmDialog({
				message: this.$t("sign_out_confirm_message"),
				onConfirm: () => {
					this.signOut(() => {
						this.showSnackBar({
							message: this.$t("sign_out_success_message"),
						});
					});
				},
			});
		},
		corporationProfileAction() {
			this.$router.push({ name: "companyProfile" });
		},
	},
	created() {
		this.prepareList();
	},
};
</script>

import Model from "../_model";

export default class ProformaInvoiceProductModel extends Model {
	constructor(provider) {
		super(provider, "proforma_invoices/{proformaInvoiceId}/products");
	}

	_getUrl(proformaInvoiceId, id) {
		let url = this.apiMethod.replace("{proformaInvoiceId}", proformaInvoiceId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(proformaInvoiceId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(proformaInvoiceId),
			onSuccess,
			onFail,
		});
	}

	find(proformaInvoiceId, id, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(proformaInvoiceId, id),
			onSuccess,
			onFail,
		});
	}

	create(proformaInvoiceId, instance, { onSuccess, onFail }) {
		const body = {
			productId: instance.productId,
			name: instance.name,
			unitCode: instance.unitCode,
			quantity: instance.quantity,
			amount: instance.amount,
		};
		return super.create(body, {
			url: this._getUrl(proformaInvoiceId),
			onSuccess,
			onFail,
		});
	}

	update(proformaInvoiceId, instance, { onSuccess, onFail }) {
		const body = {
			productId: instance.productId,
			name: instance.name,
			unitCode: instance.unitCode,
			quantity: instance.quantity,
			amount: instance.amount,
		};
		return super.update(body, {
			url: this._getUrl(proformaInvoiceId, instance.id),
			onSuccess,
			onFail,
		});
	}

	delete(proformaInvoiceId, id, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(proformaInvoiceId, id),
			onSuccess,
			onFail,
		});
	}
}

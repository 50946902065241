import Vue from "vue";
import VueI18n from "vue-i18n";
import ar from "./ar";
import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import hi from "./hi";
import ind from "./in";
import it from "./it";
import ja from "./ja";
import ko from "./ko";
import pt from "./pt";
import ru from "./ru";
import tr from "./tr";
import vi from "./vi";
import zn from "./zn";

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages: { ar, de, en, es, fr, hi, ind, it, ja, ko, pt, ru, tr, vi, zn },
});

export default i18n;

import Model from "../_model";

export default class SocialMediaModel extends Model {
	constructor(provider) {
		super(provider, "companies/{companyId}/socialMediaAccounts");
	}

	_getUrl(companyId, { id }) {
		let url = this.apiMethod.replace("{companyId}", companyId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(companyId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(companyId, {}),
			params: {},
			onSuccess,
			onFail,
		});
	}

	find(companyId, id, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(companyId, { id }),
			id,
			onSuccess,
			onFail,
		});
	}

	create(companyId, socialMedia, { onSuccess, onFail }) {
		const body = {
			type: socialMedia.type,
			value: socialMedia.value,
		};
		return super.create(body, {
			url: this._getUrl(companyId, {}),
			onSuccess,
			onFail,
		});
	}

	update(companyId, socialMedia, { onSuccess, onFail }) {
		const body = {
			type: socialMedia.type,
			value: socialMedia.value,
		};
		return super.update(body, {
			url: this._getUrl(companyId, { id: socialMedia.id }),
			id: socialMedia.id,
			onSuccess,
			onFail,
		});
	}

	delete(companyId, id, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(companyId, { id }),
			id,
			onSuccess,
			onFail,
		});
	}
}

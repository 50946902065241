import Model from "../_model";

export default class InvitationModel extends Model {
	constructor(provider) {
		super(provider, "invitations");
	}

	filter({ status, types }, { pager, sorter }, { onSuccess, onFail }) {
		return super.filter({
			params: { status, types },
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}

	find(id, { onSuccess, onFail }) {
		return super.find({
			id,
			onSuccess,
			onFail,
		});
	}

	create(invitation, { onSuccess, onFail }) {
		const body = {
			email: invitation.email,
			type: invitation.type,
		};
		return super.create(body, {
			onSuccess,
			onFail,
		});
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({
			id,
			onSuccess,
			onFail,
		});
	}

	accept(code, { onSuccess, onFail }) {
		return super._get(`invitations/${code}/accept`, {
			onSuccess,
			onFail,
		});
	}

	sendMail(id, { onSuccess, onFail }) {
		return super._get(`invitations/${id}/send_email`, {
			onSuccess,
			onFail,
		});
	}
}

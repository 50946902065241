<template>
	<v-navigation-drawer v-model="visibility" absolute temporary fixed @input="changeAction">
		<v-list dense nav>
			<v-list-item v-for="(item, i) in items" :key="i" class="my-1">
				<v-btn text @click="goToElement(item.tag)">
					<v-icon class="mr-5" color="accent">{{ item.icon }}</v-icon>
					{{ $t(`${item.tag}`) }}
				</v-btn>
			</v-list-item>

			<v-divider />

			<v-list-item class="mx-2">
				<v-switch v-model="isDark" :label="$t(`theme`)" color="accent" @change="switchTheme" />
				<v-btn text @click="changeLanguageDialog.show" plain>
					<v-icon class="mr-2" plain text rounded color="accent"> mdi-translate </v-icon>
					{{ $t(`$language.${currentLanguage}`) }}
				</v-btn>
			</v-list-item>
			<!-- <v-list-item class="my-2">
				<v-btn text @click="switchLanguage" plain>
					<v-icon class="mr-5" color="accent">mdi-translate</v-icon>
					{{ $t(`$language.${currentLanguage}`) }}
				</v-btn>
			</v-list-item> -->
			<v-list-item class="my-1">
				<v-btn color="accent" rounded @click="goToPanel" width="100%">
					<v-icon class="mr-5">mdi-login</v-icon>
					{{ $t("go_to_panel") }}
				</v-btn>
			</v-list-item>
		</v-list>
		<change-language-dialog
			v-model="changeLanguageDialog.visibility"
			@closed="changeLanguageDialog.hide"
		/>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { getSetting, setSetting, Setting } from "../../../../data/local/ls";
import ChangeLanguageDialog from "../../../partials/dialogs/ChangeLanguageDialog.vue";

export default {
	components: {
		ChangeLanguageDialog,
	},
	props: { value: Boolean },
	data() {
		return {
			visibility: false,
			isDark: null,
			items: [
				{ icon: "mdi-home", tag: "home_page" },
				{ icon: "mdi-view-dashboard-variant", tag: "content" },
				{ icon: "mdi-cash", tag: "pricing" },
				{ icon: "mdi-frequently-asked-questions", tag: "faq" },
				{ icon: "mdi-card-account-mail", tag: "contact" },
			],
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},

	watch: {
		value(val) {
			this.visibility = val;
		},
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		currentLanguage() {
			return getSetting(Setting.locale);
		},
		logoLocation() {
			return this.isDark ? "@/assets/antrego_logo_light.png" : "@/assets/antrego_logo_dark.png";
		},
	},
	methods: {
		changeAction(val) {
			if (!val) this.$emit("input", false);
		},
		switchTheme() {
			setSetting(Setting.dark_mode, this.isDark);
			location.reload();
		},
		goToPanel() {
			this.$router.push({ name: this.isAuthenticated ? "home" : "signin" });
		},
		goToElement(tag) {
			this.changeAction(false);
			this.$emit("goTo", tag);
		},
	},
	created() {
		this.visibility = this.value;
		this.isDark = getSetting(Setting.dark_mode);
	},
};
</script>

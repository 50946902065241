<template>
	<v-dialog
		v-model="value"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
		:scrollable="false"
	>
		<v-card>
			<v-toolbar style="position: fixed; width: 100%; z-index: 3" elevation="1">
				<v-btn icon @click="closeAction">
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>{{ title }}</v-toolbar-title>
				<v-spacer />
				<v-btn
					v-if="currentItem && currentItem.id && editMode"
					class="d-none d-sm-inline-flex"
					@click="cancelAction"
				>
					<v-icon left>mdi-cancel</v-icon>
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					v-if="currentItem && currentItem.id && editMode"
					class="d-sm-none"
					icon
					@click="cancelAction"
				>
					<v-icon>mdi-cancel</v-icon>
				</v-btn>
				<v-btn
					v-if="currentItem && editMode"
					class="d-none d-sm-inline-flex"
					color="accent"
					@click="saveAction"
				>
					<v-icon left>mdi-content-save</v-icon>
					{{ $t("save") }}
				</v-btn>
				<v-btn v-if="currentItem && editMode" class="d-sm-none" icon @click="saveAction">
					<v-icon>mdi-content-save</v-icon>
				</v-btn>
				<div v-if="!isMobile && !editMode">
					<v-row>
						<v-col v-for="(item, index) in menuItems" :key="index" cols="auto" class="ma-5">
							<v-btn icon @click="menuItemAction(item.key)">
								<v-icon :color="item.color">{{ item.icon }}</v-icon>
								{{ item.title }}
							</v-btn>
						</v-col>
					</v-row>
				</div>
				<v-menu bottom right offset-y v-if="isMobile && menuItems && menuItems.length && !editMode">
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>

					<v-list>
						<v-list-item
							v-for="(item, index) in menuItems"
							:key="index"
							@click="menuItemAction(item.key)"
						>
							<v-icon left :color="item.color">{{ item.icon }}</v-icon>
							{{ item.title }}
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar>

			<v-container class="pt-16" v-if="currentItem">
				<v-form ref="form" v-model="valid">
					<v-row>
						<!-- Status -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<title-value-list-item
								:title="$t('status')"
								:value="$t(`$proformaInvoice.$status.${currentItem.status}`)"
								icon="mdi-state-machine"
							/>
						</v-col>

						<!-- Sender -->
						<v-col cols="12" sm="6" lg="9" class="pb-0" v-if="iAmReceiver">
							<title-value-list-item
								:title="$t('sender')"
								:value="currentItem.sender.name"
								icon="mdi-office-building"
							/>
						</v-col>

						<!-- Receiver -->
						<v-col cols="12" sm="6" lg="9" class="pb-0" v-if="iAmSender">
							<partner-select
								v-if="editMode && (!currentItem.id || currentItem.status === 'template')"
								v-model="currentItem.receiver.id"
								:label="$t('receiver')"
								:rules="rules.receiver"
								:eager="true"
								:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
								prepend-icon="mdi-office-building"
								@change="receiverChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('receiver')"
								:value="currentItem.receiver.name"
								icon="mdi-office-building"
							/>
						</v-col>

						<!-- Proforma Invoice Number -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<v-text-field
								v-if="editMode"
								v-model="currentItem.no"
								:label="$t('proforma_number')"
								prepend-icon="mdi-numeric"
								:rules="rules.proformaNumber"
							/>
							<title-value-list-item
								v-else
								:title="$t('proforma_number')"
								:value="currentItem.no"
								icon="mdi-numeric"
							/>
						</v-col>

						<!-- Date -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<date-picker-moment
								v-if="editMode"
								v-model="currentItem.date"
								showPrependIcon
								:label="$t('date')"
								:rules="rules.date"
							/>
							<title-value-list-item
								v-else
								:title="$t('date')"
								:value="currentItem.date ? currentItem.date.format($t('$format.date')) : ''"
								icon="mdi-calendar-outline"
							/>
						</v-col>

						<!-- Currency -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<currency-select
								v-if="editMode"
								v-model="currentItem.currency.code"
								:eager="true"
								:label="$t('currency')"
								:rules="rules.currency"
								prepend-icon="mdi-cash"
								@change="currencyChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('currency')"
								:value="$t(`$currency.${currentItem.currency.name}`)"
								icon="mdi-cash"
							/>
						</v-col>
					</v-row>

					<v-divider class="my-3" />

					<v-subheader>{{ $t("shipment") }}</v-subheader>
					<v-row>
						<!-- Shipment Method -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<shipment-method-select
								v-if="editMode"
								v-model="currentItem.shipmentMethod"
								:rules="rules.shipmentMethod"
								:disabled="!hasReceiver"
								@change="shipmentMethodChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('shipment_method')"
								:value="`${$t(`$shipment.$method.${currentItem.shipmentMethod}`)}`"
								:icon="getShipmentMethodIcon(currentItem.shipmentMethod)"
							/>
						</v-col>

						<!-- Shipping Term -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<shipping-term-select
								v-if="editMode"
								v-model="currentItem.shippingTerm.code"
								:label="$t('shipping_term')"
								:rules="rules.shippingTerm"
								:clearable="true"
								:eager="true"
								:disabled="!hasReceiver"
								prepend-icon="mdi-arrow-decision-outline"
								@change="shippingTermChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('shipping_term')"
								:value="`${currentItem.shippingTerm.name} (${currentItem.shippingTerm.code})`"
								icon="mdi-arrow-decision-outline"
							/>
						</v-col>
					</v-row>

					<v-row v-if="hasShipmentMethod">
						<!-- Loading Country -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<country-select
								v-if="editMode"
								v-model="currentItem.loadingCountry.id"
								:options="countries"
								:label="$t('loading_country')"
								prepend-icon="mdi-earth-box"
								@change="loadingCountryChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('loading_country')"
								:value="currentItemLoadingCountryName"
								icon="mdi-earth-box"
							/>
						</v-col>

						<!-- Loading Port / Loading Point -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<port-select
								v-if="editMode && isSeaShipment"
								v-model="currentItem.loadingPort.code"
								:label="$t('loading_port')"
								:countryId="loadingCountryId"
								:disabled="!loadingCountryId"
								:eager="Boolean(loadingCountryId)"
								:rules="rules.loadingPort"
								prepend-icon="mdi-location-exit"
								@change="loadingPortChangeAction"
							/>
							<v-text-field
								v-else-if="editMode && hasShipmentMethod"
								v-model="currentItem.loadingPoint"
								:label="$t('loading_point')"
								:rules="rules.loadingPoint"
								prepend-icon="mdi-location-exit"
							/>
							<title-value-list-item
								v-else-if="isSeaShipment"
								:title="$t('loading_port')"
								:value="`${currentItem.loadingPort.name} (${currentItem.loadingPort.code})`"
								icon="mdi-location-exit"
							/>
							<title-value-list-item
								v-else
								:title="$t('loading_point')"
								:value="`${currentItem.loadingPoint}`"
								icon="mdi-location-exit"
							/>
						</v-col>

						<!-- Unloading Country -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<country-select
								v-if="editMode"
								v-model="currentItem.unloadingCountry.id"
								:disabled="!hasReceiver"
								:options="countries"
								:label="$t('unloading_country')"
								:rules="rules.loadingCountry"
								prepend-icon="mdi-earth-box"
								@change="unloadingCountryChangeAction"
							/>
							<title-value-list-item
								v-else
								:title="$t('unloading_country')"
								:value="currentItemUnloadingCountryName"
								icon="mdi-earth-box"
							/>
						</v-col>

						<!-- Unloading Port / Loading Point -->
						<v-col cols="12" sm="6" lg="3" class="pb-0">
							<port-select
								v-if="editMode && isSeaShipment"
								v-model="currentItem.unloadingPort.code"
								:label="$t('unloading_port')"
								:countryId="unloadingCountryId"
								:disabled="!unloadingCountryId"
								:eager="Boolean(unloadingCountryId)"
								:rules="rules.unloadingPort"
								prepend-icon="mdi-location-enter"
								@change="unloadingPortChangeAction"
							/>
							<v-text-field
								v-else-if="editMode && hasShipmentMethod"
								v-model="currentItem.unloadingPoint"
								:label="$t('unloading_point')"
								:rules="rules.unloadingPort"
								prepend-icon="mdi-location-enter"
							/>
							<title-value-list-item
								v-else-if="isSeaShipment"
								:title="$t('unloading_port')"
								:value="`${currentItem.unloadingPort.name} (${currentItem.unloadingPort.code})`"
								icon="mdi-location-enter"
							/>
							<title-value-list-item
								v-else
								:title="$t('unloading_point')"
								:value="`${currentItem.unloadingPoint}`"
								icon="mdi-location-enter"
							/>
						</v-col>
					</v-row>
				</v-form>

				<v-divider v-if="!editMode" class="my-3" />

				<!-- Products -->
				<v-card v-if="!editMode" elevation="1">
					<v-toolbar flat dense>
						<v-toolbar-title>
							{{ $t("products") }}
						</v-toolbar-title>
						<v-spacer />
						<v-btn
							v-if="['template', 'pending_revise'].includes(currentItem.status)"
							rounded
							small
							color="primary"
							@click="productDialog.show()"
						>
							{{ $t("add") }}
							<v-icon right> mdi-plus-circle </v-icon>
						</v-btn>
					</v-toolbar>

					<v-data-table
						:headers="productListHeaders"
						:items="currentItem.products"
						:items-per-page="-1"
						hide-default-footer
						@click:row="productDialog.show"
						style="cursor: pointer"
						disable-sort
					>
						<template v-slot:item.name="{ item }">
							{{
								item.name[$lang] && item.name[$lang].length
									? item.name[$lang]
									: item.name["_default"]
							}}
						</template>
						<template v-slot:item.quantity="{ item }">
							{{ item.quantity }} {{ item.unitCode }}
						</template>
						<template v-slot:item.amount="{ item }">
							{{
								item.amount.toLocaleString($lang, {
									style: "currency",
									currency: currentItem.currency.code,
								})
							}}
						</template>
						<template slot="body.append" v-if="currentItem.products.length">
							<tr>
								<td class="text-right" colspan="3">
									{{ $t("total") }}:
									{{
										totalAmount.toLocaleString($lang, {
											style: "currency",
											currency: currentItem.currency.code,
										})
									}}
									{{ currentItem.currency.symbol }}
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card>
				<!-- Products -->

				<v-divider v-if="!editMode" class="mt-2 mb-4" />

				<proforma-invoice-attachments
					v-if="!editMode"
					:proformaInvoice="currentItem"
					@changed="attachmentListChanged"
				/>

				<v-divider v-if="!editMode" class="mt-4" />

				<v-subheader v-if="!editMode">{{ $t("actions") }}</v-subheader>

				<v-card class="pr-4" elevation="1">
					<proforma-invoice-action-timeline v-model="currentItem" v-if="!editMode" />
				</v-card>

				<proforma-invoice-product-dialog
					v-model="productDialog.visibility"
					:item="productDialog.item"
					:proformaInvoice="currentItem"
					@saved="productDialog.saved"
					@deleted="productDialog.deleted"
				/>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import { mapState, mapGetters } from "vuex";
import TitleValueListItem from "../../../partials/TitleValueListItem";
import PartnerSelect from "../../../controls/PartnerSelect";
import PortSelect from "../../../controls/PortSelect";
import ShippingTermSelect from "../../../controls/ShippingTermSelect";
import CurrencySelect from "../../../controls/CurrencySelect";
import CountrySelect from "../../../controls/CountrySelect";
import ShipmentMethodSelect from "../../../controls/ShipmentMethodSelect.vue";
import ProformaInvoiceProductDialog from "./ProformaInvoiceProductDialog";
import ProformaInvoiceActionTimeline from "../partial/ProformaInvoiceActionTimeline";
import ProformaInvoiceAttachments from "../partial/ProformaInvoiceAttachments.vue";
import DatePickerMoment from "../../../controls/DatePickerMoment";
import moment from "moment";
import { iconHelper as iconHelperMixin } from "../../../mixins/utils.mixin";
import { partnerTypes } from "../../../../utils/enum";

export default {
	props: { value: Boolean, item: Object, itemId: String },
	components: {
		TitleValueListItem,
		PartnerSelect,
		PortSelect,
		ShippingTermSelect,
		CurrencySelect,
		CountrySelect,
		ShipmentMethodSelect,
		ProformaInvoiceProductDialog,
		ProformaInvoiceActionTimeline,
		ProformaInvoiceAttachments,
		DatePickerMoment,
	},
	mixins: [uiMixin, iconHelperMixin],
	data() {
		return {
			rules: {
				receiver: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("receiver") }),
				],
				loadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("loading_country"),
						}),
				],
				unloadingCountry: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_country"),
						}),
				],
				loadingPort: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_port") }),
				],
				unloadingPort: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_port"),
						}),
				],
				loadingPoint: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("loading_point") }),
				],
				unloadingPoint: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("unloading_point"),
						}),
				],
				proformaNumber: [
					(v) => {
						if (!Boolean(v)) {
							return this.$t("$validation.required", {
								item: this.$t("proforma_number"),
							});
						} else if (v.length > 25) {
							return this.$t("$validation.max_length", {
								item: this.$t("proforma_number"),
								length: "25",
							});
						}
						return true;
					},
				],

				date: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("date") })],
				currency: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("currency") }),
				],
				shipmentMethod: [
					(v) =>
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("shipment_method"),
						}),
				],
				shippingTerm: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("shipping_term") }),
				],
			},
			menuItems: Array,
			editMode: false,
			currentItem: null,
			loadingCountryId: null,
			unloadingCountryId: null,
			listItems: [],
			valid: true,
			productListHeaders: [
				{
					text: this.$t("name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("quantity"),
					value: "quantity",
					align: "end",
					sortable: false,
				},
				{
					text: this.$t("amount"),
					value: "amount",
					align: "end",
					sortable: false,
				},
			],
			productDialog: {
				visibility: false,
				item: null,
				show: (item) => {
					this.productDialog.item = item;
					this.productDialog.visibility = true;
				},
				hide: () => {
					this.productDialog.item = null;
					this.productDialog.visibility = false;
				},
				saved: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
				},
				deleted: (_) => {
					this.loadItem(this.currentItem.id);
					this.productDialog.hide();
				},
			},
		};
	},
	computed: {
		...mapState(["countries"]),
		...mapGetters(["company"]),
		partnerTypes: () => partnerTypes,
		iAmSender() {
			return (
				this.currentItem &&
				(!this.currentItem.sender || this.company.id === this.currentItem.sender.referenceId)
			);
		},
		iAmReceiver() {
			return this.currentItem && this.company.id === this.currentItem.receiver.referenceId;
		},
		hasReceiver() {
			return Boolean(this.currentItem.receiver.referenceId);
		},
		hasShipmentMethod() {
			return Boolean(this.currentItem.shipmentMethod);
		},
		isSeaShipment() {
			return this.hasShipmentMethod ? this.currentItem.shipmentMethod == "sea" : false;
		},
		currentItemLoadingCountryName() {
			if (this.isSeaShipment) {
				return this.currentItem.loadingCountry
					? `${this.$t(`$country.${this.currentItem.loadingCountry.name}`)} (${
							this.currentItem.loadingCountry.code
					  })`
					: null;
			} else if (this.hasShipmentMethod) {
				if (this.currentItem.loadingCountry.id) {
					let country = this.countries.find((c) => c.id == this.currentItem.loadingCountry.id);
					return country ? `${this.$t(`$country.${country.name}`)} (${country.code})` : null;
				}
			}
			return undefined;
		},

		currentItemUnloadingCountryName() {
			if (this.isSeaShipment) {
				return this.currentItem.unloadingCountry
					? `${this.$t(`$country.${this.currentItem.unloadingCountry.name}`)} (${
							this.currentItem.unloadingCountry.code
					  })`
					: null;
			} else if (this.hasShipmentMethod) {
				if (this.currentItem.unloadingCountryId) {
					let country = this.countries.find((c) => c.id == this.currentItem.unloadingCountryId);
					return country ? `${this.$t(`$country.${country.name}`)} (${country.code})` : null;
				}
			}
			return undefined;
		},
		title() {
			if (this.currentItem)
				if (this.currentItem.id)
					return `${this.$t(this.iAmSender ? "outgoing" : "incoming")} ${this.$t(
						"proforma_invoice"
					)}`;
				else return `${this.$t("new")} ${this.$t("proforma_invoice")}`;
		},
		totalAmount() {
			if (this.currentItem && this.currentItem.products) {
				let total = 0;
				for (const item of this.currentItem.products) total += item.quantity * item.amount;
				return total;
			} else return null;
		},
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown;
		},
	},
	watch: {
		value(val) {
			val ? this.handleProps() : this.reset();
		},
		currentItem(val) {
			if (val && val.id) {
				this.prepareMenu();
			}
		},
	},
	methods: {
		handleProps() {
			if (this.itemId || this.item) this.loadItem(this.itemId || this.item.id);
			else {
				this.useItemTemplate();
				const country = this.countries.find((c) => c.id === this.company.countryId);
				this.loadingCountryId = country.id;
				this.currentItem.loadingCountry.id = country.id;
				this.currentItem.loadingCountry.name = country.name;
				this.currentItem.loadingCountry.code = country.code;
				this.editMode = true;
			}
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
			this.listItems = [];
			this.menuItems = null;
			this.editMode = false;
			this.loadingCountryId = null;
			this.unloadingCountryId = null;
		},
		useItemTemplate() {
			this.currentItem = {
				status: "template",
				sender: {
					referenceId: this.company.id,
					name: this.company.name,
					countryId: this.company.countryId,
				},
				receiver: {
					referenceId: "",
					isAbstract: undefined,
					name: null,
					countryId: "",
				},
				date: moment(),
				no: "",
				loadingCountry: { id: null },
				loadingPort: { code: null },
				loadingPoint: null,
				unloadingCountry: { id: null },
				unloadingPort: { code: null },
				unloadingPoint: null,
				shippingTerm: { code: null },
				currency: { code: null },
			};
		},
		loadItem(id) {
			this.showProgressDialog();
			remote.proformaInvoices.find(id, {
				onSuccess: (result) => {
					this.handleItem(result);
					this.hideProgressDialog();
				},
				onFail: (_) => this.hideProgressDialog(),
			});
		},
		handleItem(item) {
			this.currentItem = item;
			let isLoadingCountryFound = null;
			let isUnloadingCountryFound = null;
			if (this.isSeaShipment && !this.currentItem.shippingTerm)
				this.currentItem.shippingTerm = { code: "", name: "" };
			for (const country of this.countries) {
				if (
					!isLoadingCountryFound &&
					this.isSeaShipment &&
					country.ports.find((i) => i.code === item.loadingPort.code)
				) {
					isLoadingCountryFound = true;
					this.loadingCountryId = country.id;
					this.currentItem.loadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				} else if (country.id === item.loadingCountryId) {
					isLoadingCountryFound = true;
					this.loadingCountryId = country.id;
					this.currentItem.loadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				}
				if (
					!isUnloadingCountryFound &&
					this.isSeaShipment &&
					country.ports.find((i) => i.code === item.unloadingPort.code)
				) {
					isUnloadingCountryFound = true;
					this.unloadingCountryId = country.id;
					this.currentItem.unloadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				} else if (country.id === item.unloadingCountryId) {
					isLoadingCountryFound = true;
					this.unloadingCountryId = country.id;
					this.currentItem.unloadingCountry = {
						id: country.id,
						name: country.name,
						code: country.code,
					};
				}
				if (isLoadingCountryFound && isUnloadingCountryFound) break;
			}
		},
		prepareMenu() {
			const ria = this.currentItem.receiver.isAbstract || false;
			this.menuItems = [];
			const edit = {
				key: "edit",
				title: this.$t("edit"),
				icon: "mdi-file-edit",
			};
			const send = {
				key: "send",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_sent" : "send"}`),
				icon: "mdi-file-send",
				color: ria ? undefined : "info",
			};
			const delete_ = {
				key: "delete",
				title: this.$t("delete"),
				icon: "mdi-file-remove",
				color: "error",
			};
			const revoke = {
				key: "revoke",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_revoked" : "revoke"}`),
				icon: "mdi-file-undo",
				color: "error",
			};
			const markAsProcessed = {
				key: "mark_as_processed",
				title: this.$t("$proformaInvoice.$actions.mark_as_processed"),
				icon: "mdi-file-cabinet",
			};
			const approve = {
				key: "approve",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_approved" : "approve"}`),
				icon: "mdi-file-check",
				color: ria ? undefined : "info",
			};
			const demandRevise = {
				key: "demand_revise",
				title: this.$t(
					`$proformaInvoice.$actions.${ria ? "mark_as_revise_demanded" : "demand_revise"}`
				),
				icon: "mdi-file-edit",
			};
			const reject = {
				key: "reject",
				title: this.$t(`$proformaInvoice.$actions.${ria ? "mark_as_rejected" : "reject"}`),
				icon: "mdi-file-send",
				color: ria ? "warning" : "error",
			};

			if (this.iAmSender) {
				switch (this.currentItem.status) {
					case "template":
						this.menuItems.push(edit, send, delete_);
						break;
					case "pending_approval":
						this.menuItems.push(revoke);
						break;
					case "pending_revise":
						this.menuItems.push(edit, send, revoke);
						break;
					case "approved":
						this.menuItems.push(markAsProcessed);
						break;
					default:
						break;
				}
			}
			if (this.iAmReceiver || this.currentItem.receiver.isAbstract) {
				switch (this.currentItem.status) {
					case "pending_approval":
						this.menuItems.push(approve, demandRevise, reject);
						break;
					default:
						break;
				}
			}
		},
		menuItemAction(key) {
			const ria = this.currentItem.receiver.isAbstract || false;
			const onFail = (_) => this.hideProgressDialog();
			switch (key) {
				case "edit":
					this.editMode = true;
					break;
				case "delete":
					this.showConfirmDialog({
						message: this.$t("$message.confirm", {
							function: this.$t("toDelete"),
						}),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.delete(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$message.deleted", {
											item: this.$t("proforma_invoice"),
										}),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("deleted", result);
								},
								onFail,
							});
						},
					});
					break;
				case "send":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.send_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.send(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.send_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("saved", result);
								},
								onFail,
							});
						},
					});
					break;
				case "approve":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.approve_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.approve(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.approve_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("saved", result);
								},
								onFail,
							});
						},
					});
					break;
				case "demand_revise":
					this.showTextInputDialog({
						title: this.$t(
							`$proformaInvoice.$actions.${ria ? "mark_as_revise_demanded" : "demand_revise"}`
						),
						message: ria
							? undefined
							: this.$t("$proformaInvoice.demand_revise_description_message"),
						inputTitle: this.$t("description"),
						useTextArea: true,
						onSubmit: (text) => {
							return new Promise((resolve, reject) => {
								this.showProgressDialog();
								remote.proformaInvoices.demandRevise(this.currentItem.id, {
									message: text,
									onSuccess: (result) => {
										this.showSnackBar({
											message: this.$t("$proformaInvoice.demand_revise_success_message"),
										});
										this.hideProgressDialog();
										this.handleItem(result);
										this.$emit("saved", result);
										return resolve(true);
									},
									onFail: (e) => {
										this.hideProgressDialog();
										reject(e);
									},
								});
							});
						},
					});
					break;
				case "reject":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.reject_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.reject(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.reject_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("saved", result);
								},
								onFail,
							});
						},
					});
					break;
				case "mark_as_processed":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.mark_as_processed_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.markAsProcessed(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.mark_as_processed_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("saved", result);
								},
								onFail,
							});
						},
					});
					break;
				case "revoke":
					this.showConfirmDialog({
						message: this.$t("$proformaInvoice.revoke_confirm_message"),
						onConfirm: () => {
							this.showProgressDialog();
							remote.proformaInvoices.revoke(this.currentItem.id, {
								onSuccess: (result) => {
									this.showSnackBar({
										message: this.$t("$proformaInvoice.revoke_success_message"),
									});
									this.hideProgressDialog();
									this.handleItem(result);
									this.$emit("saved", result);
								},
								onFail,
							});
						},
					});
					break;
				default:
					break;
			}
		},
		saveAction() {
			if (this.valid) {
				this.showProgressDialog();
				const onSuccess = (result) => {
					if (this.currentItem.id) {
						this.$editEvent("proforma_invoice");
					} else {
						this.$addEvent("proforma_invoice");
					}

					this.showSnackBar({
						message: this.$t(this.currentItem.id ? "$message.updated" : "$message.created", {
							item: this.$t("proforma_invoice"),
						}),
					});
					this.handleItem(result);
					this.editMode = false;
					this.hideProgressDialog();
					this.$emit("saved", result);
				};
				const onFail = (_) => this.hideProgressDialog();
				if (this.currentItem.id)
					remote.proformaInvoices.update(this.currentItem, {
						onSuccess,
						onFail,
					});
				else
					remote.proformaInvoices.create(this.currentItem, {
						onSuccess,
						onFail,
					});
			} else this.$refs.form.validate();
		},
		cancelAction() {
			this.editMode = false;
			this.loadItem(this.currentItem.id);
		},
		closeAction() {
			this.$emit("input", false);
		},
		receiverChangeAction(val) {
			var isAbstract = val.type == this.partnerTypes.AbstractCompany;
			this.currentItem.receiver.referenceId = !isAbstract ? val.corporationId : val.id;
			this.currentItem.receiver.name = val.name;
			this.currentItem.receiver.countryId = val.countryId;
			this.currentItem.receiver.isAbstract = isAbstract;
			const country = val ? this.countries.find((i) => i.id === val.countryId) : null;
			this.unloadingCountryId = country.id || undefined;
			this.currentItem.unloadingCountry.id = country.id || undefined;
			this.currentItem.unloadingCountry.code = country.code || undefined;
			this.currentItem.unloadingCountry.name = country.name || undefined;
		},
		dateChangeAction(val) {
			this.currentItem.date = val;
		},
		shipmentMethodChangeAction() {
			this.currentItem.loadingPort = { code: null };
			this.currentItem.loadingPoint = null;
			this.currentItem.unloadingPort = { code: null };
			this.currentItem.unloadingPoint = null;
		},
		loadingCountryChangeAction(val) {
			this.loadingCountryId = val.id;
			this.currentItem.loadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.currentItem.loadingPort.code = null;
				this.currentItem.loadingPort.name = "";
				this.currentItem.loadingPort.portId = null;
				this.currentItem.loadingPort.type = null;
			} else {
				this.currentItem.loadingCountryId = val.id;
				this.currentItem.loadingPoint = null;
			}
		},
		loadingPortChangeAction(val) {
			this.currentItem.loadingPort.portId = val?.id;
			this.currentItem.loadingPort.name = val?.name;
			this.currentItem.loadingPort.code = val?.code;
			this.currentItem.loadingPort.type = val?.type;
		},
		unloadingCountryChangeAction(val) {
			this.unloadingCountryId = val.id;
			this.currentItem.unloadingCountry = {
				id: val.id,
				name: val.name,
				code: val.code,
			};
			if (this.isSeaShipment) {
				this.currentItem.unloadingPort.code = null;
				this.currentItem.unloadingPort.name = "";
				this.currentItem.unloadingPort.portId = null;
				this.currentItem.unloadingPort.type = null;
			} else {
				this.currentItem.unloadingCountryId = val.id;
				this.currentItem.unloadingPoint = null;
			}
		},
		unloadingPortChangeAction(val) {
			this.currentItem.unloadingPort.portId = val?.id;
			this.currentItem.unloadingPort.name = val?.name;
			this.currentItem.unloadingPort.code = val?.code;
			this.currentItem.unloadingPort.type = val?.type;
		},
		shippingTermChangeAction(val) {
			this.currentItem.shippingTerm.name = val?.name ?? null;
		},
		currencyChangeAction(val) {
			this.currentItem.currency.name = val.name;
			this.currentItem.currency.symbol = val.symbol;
		},
		attachmentListChanged() {
			this.loadItem(this.currentItem.id);
		},
	},
	created() {
		// if (this.itemId || this.item) this.handleProps();
	},
};
</script>

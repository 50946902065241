import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
	install(Vue, options) {
		if (options && "measurementId" in options) {
			const app = initializeApp(options);
			const analytics = getAnalytics(app);
			Vue.prototype.$trackPageView = function (url) {
				logEvent(analytics, "page_view", { page_path: url });
			};

			Vue.prototype.$addEvent = function (context, params) {
				logEvent(analytics, `add_${context}`, params);
			};

			Vue.prototype.$trackPageView = function (url) {
				logEvent(analytics, "page_view", { page_path: url });
			};

			Vue.prototype.$addEvent = function (context, params) {
				logEvent(analytics, `add_${context}`, params);
			};

			Vue.prototype.$editEvent = function (context, params) {
				logEvent(analytics, `edit_${context}`, params);
			};

			Vue.prototype.$trackScrolling = function (scrollPosition, currentSection) {
				logEvent(analytics, "scroll_event", {
					scroll_position: scrollPosition,
					section: currentSection,
				});
			};
			Vue.prototype.$trackClickEvent = function (elementId) {
				logEvent(analytics, "click_event", {
					element_id: elementId,
				});
			};
			Vue.prototype.$googleAnalyticsTrackingID = options.measurementId;
		} else {
			console.error("Invalid Firebase options.");
		}
	},
};

<template>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="invitations"
			:options.sync="tableOptions"
			:server-items-length="pagination.totalItemCount"
			:footer-props="tableFooterProps"
			:loading="loading"
			style="cursor: pointer"
			@click:row="dialog.show"
			disable-sort
		>
			<template v-slot:item.type="{ item }">
				{{ $t(`$invitationType.${item.type}`) }}
			</template>
		</v-data-table>
		<v-btn color="accent" fixed bottom right fab @click="dialog.show(null)">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<invitation-dialog
			v-if="dialog.visibility"
			v-model="dialog.visibility"
			:item="dialog.item"
			:onlyCompany="true"
			@saved="dialog.saved"
			@emailSent="dialog.emailSent"
			@deleted="dialog.deleted"
		/>
	</v-container>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import InvitationDialog from "../../company_profile/dialogs/InvitationDialog.vue";
import { mapGetters } from "vuex";
import { invitationTypes } from "../../../../utils/enum";

export default {
	mixins: [paginationMixin, uiMixin],
	components: { InvitationDialog },
	computed: {
		...mapGetters(["invitationTypes"]),
		invitationTypes: () => invitationTypes,
	},
	data() {
		return {
			loading: false,
			invitations: [],
			headers: [
				{ text: this.$t("type"), value: "type" },
				{ text: this.$t("email"), value: "email" },
				{ text: this.$t("code"), value: "code" },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (invitation) => {
					this.dialog.item = invitation;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				emailSent: () => {
					this.dialog.hide();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
		};
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.invitations.filter(
				{
					status: "active",
					types: `${invitationTypes.CompanyPartner},${invitationTypes.CustomsPartner}`,
				},
				{
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.invitations = result.items;
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: (error) => {
						if (error) console.error(error);
						this.loading = false;
					},
				}
			);
		},
	},
	created() {
		this.loadItems();
	},
};
</script>

<template>
	<v-data-table
		:headers="headers"
		:items="items"
		:options.sync="tableOptions"
		:server-items-length="pagination.totalItemCount"
		:footer-props="tableFooterProps"
		:loading="loading"
		style="cursor: pointer"
		@click:row="rowAction"
		disable-sort
	>
		<template v-slot:item.date="{ item }">
			{{ item.date ? item.date.format($t("$format.date")) : "" }}
		</template>
		<template v-slot:item.status="{ item }">
			{{ $t(`$proformaInvoice.$status.${item.status}`) }}
		</template>
	</v-data-table>
</template>

<script>
import { pagination as paginationMixin } from "../../../mixins/utils.mixin";
import remote from "../../../../data/remote";

export default {
	props: {
		role: "sender" | "receiver",
		filterData: Object,
		initialPage: Number,
		atFront: Boolean,
		listChanged: Boolean,
	},
	mixins: [paginationMixin],
	data() {
		return {
			items: [],
			loading: false,
			lastFilter: {},
			outgoingHeaders: [
				{ text: this.$t("proforma_number"), value: "no" },
				{ text: this.$t("receiver"), value: "receiver.name" },
				{ text: this.$t("date"), value: "date" },
				{ text: this.$t("status"), value: "status" },
			],
			incomingHeaders: [
				{ text: this.$t("proforma_number"), value: "no" },
				{ text: this.$t("sender"), value: "sender.name" },
				{ text: this.$t("date"), value: "date" },
				{ text: this.$t("status"), value: "status" },
			],
		};
	},
	computed: {
		oppositeRole() {
			return this.role == "sender" ? "receiver" : "sender";
		},
		headers() {
			return this.role == "sender" ? this.outgoingHeaders : this.incomingHeaders;
		},
	},
	watch: {
		filterData(val) {
			if (JSON.stringify(val) != JSON.stringify(this.lastFilter)) {
				this.lastFilter = val;
				this.loadItems();
			}
		},
		atFront(val) {
			if (val) {
				let query = Object.assign({}, this.$route.query);
				if (this.tableOptions.page != query.page) {
					query.page = this.tableOptions.page;
					this.$router.replace({ query });
				}
			}
		},
		listChanged() {
			this.loadItems();
		},
	},
	methods: {
		loadItems() {
			this.loading = true;

			const query = Object.assign({}, this.$route.query);
			if (query.page != this.tableOptions.page) query.page = this.tableOptions.page;
			query.referenceId = this.filterData.referenceId || undefined;
			query.statuses = this.filterData.statuses.length
				? this.filterData.statuses.join()
				: undefined;
			if (JSON.stringify(query) != JSON.stringify(this.$route.query))
				this.$router.replace({ query });

			remote.proformaInvoices.filter(
				{
					filter: this.filterData,
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.pagination = result.pagination;
						this.items = result.items.sort((a, b) => {
							return a.date && b.date ? (a.date < b.date ? 1 : -1) : !a.date ? 1 : -1;
						});
						this.loading = false;
					},
					onFail: (_) => (this.loading = false),
				}
			);
		},
		rowAction(item) {
			this.$emit("rowAction", item);
		},
	},
	created() {
		if (this.initialPage) this.tableOptions.page = this.initialPage;
		this.loadItems();
	},
};
</script>

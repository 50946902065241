import Model from "../_model";

export default class PartnerModel extends Model {
	constructor(provider) {
		super(provider, "corporations/{corporationId}/partners");
	}

	_getUrl(corporationId, { id, ownerCompanyId, isMigrate = false }) {
		let url = this.apiMethod.replace("{corporationId}", corporationId);
		if (id) {
			url = url.concat(`/${id}`);
			if (ownerCompanyId) url = url.concat(`/owner_company/${ownerCompanyId}`);
		}
		if (isMigrate) url = url.replace("partners", "migrate_abstract_partner");
		return url;
	}

	/**
	 * @param { status, types } filter
	 */
	filter(corporationId, { filter, pager, sorter }, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(corporationId, {}),
			params: {
				status: filter ? filter.status : undefined,
				types: filter && Array.isArray(filter.types) ? filter.types.join() : undefined,
			},
			pager,
			sorter,
			onSuccess,
			onFail,
		});
	}

	find(corporationId, id, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(corporationId, { id }),
			onSuccess,
			onFail,
		});
	}

	findByCorporationId(corporationId, id, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(corporationId, {}).concat(`/find_by_corporation_id/${id}`),
			onSuccess,
			onFail,
		});
	}

	findCorporationForCustomsAgency(corporationId, id, ownerCompanyId, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(corporationId, { id, ownerCompanyId }),
			onSuccess,
			onFail,
		});
	}

	create(corporationId, instance, { onSuccess, onFail }) {
		const body = {
			name: instance.name,
			countryId: instance.countryId,
			address: instance.address,
			phoneNumber: instance.phoneNumber,
			taxNumber: instance.taxNumber,
			email: instance.email,
			website: instance.website,
		};
		return super.create(body, {
			url: this._getUrl(corporationId, {}),
			onSuccess,
			onFail,
		});
	}

	update(corporationId, instance, { onSuccess, onFail }) {
		const body = {
			name: instance.name,
			countryId: instance.countryId,
			address: instance.address,
			phoneNumber: instance.phoneNumber,
			taxNumber: instance.taxNumber,
			email: instance.email,
			website: instance.website,
		};
		return super.update(body, {
			url: this._getUrl(corporationId, { id: instance.id }),
			onSuccess,
			onFail,
		});
	}

	delete(corporationId, id, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(corporationId, { id }),
			onSuccess,
			onFail,
		});
	}

	migrate(corporationId, instance, { onSuccess, onFail }) {
		const body = {
			selectedId: instance.selectedId,
			targetId: instance.targetId,
		};
		return super.update(body, {
			url: this._getUrl(corporationId, { isMigrate: true }),
			onSuccess,
			onFail,
		});
	}
}

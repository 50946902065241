<template>
	<v-app-bar absolute elevate-on-scroll elevation="1" style="z-index: 2">
		<v-toolbar-title>
			<v-img max-height="48" max-width="196" cover src="@/assets/antrego_logo_neutral.png" />
		</v-toolbar-title>

		<v-spacer />
		<!-- WEB VIEW START-->
		<div v-if="!isMobile">
			<v-btn v-for="(item, i) in items" :key="i" text color="accent" @click="goToElement(item.tag)">
				{{ $t(`${item.tag}`) }}
			</v-btn>
		</div>
		<v-spacer v-if="!isMobile" />
		<v-tooltip v-if="!isMobile" top open-delay="500">
			<template v-slot:activator="{ on }">
				<v-btn icon @click="switchTheme" v-on="on" plain color="accent">
					<v-icon>mdi-theme-light-dark</v-icon>
				</v-btn>
			</template>
			<span>{{ $t("switch_theme") }}</span>
		</v-tooltip>
		<v-tooltip v-if="!isMobile" top open-delay="500">
			<template v-slot:activator="{ on }">
				<v-btn icon @click="changeLanguageDialog.show" v-on="on" plain color="accent">
					<v-icon>mdi-translate</v-icon>
				</v-btn>
			</template>
			<span>{{ $t("switch_language") }}</span>
		</v-tooltip>
		<v-btn v-if="!isMobile" color="primary" rounded @click="goToPanel">
			{{ $t("go_to_panel") }}
		</v-btn>
		<change-language-dialog
			v-model="changeLanguageDialog.visibility"
			@closed="changeLanguageDialog.hide"
		/>
		<!-- WEB VIEW END -->

		<!-- MOBILE MENU START-->
		<v-btn v-if="isMobile" icon @click="drawerAction">
			<v-icon color="accent">mdi-menu</v-icon>
		</v-btn>
		<!-- MOBILE MENU END-->
	</v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { getSetting, setSetting, Setting } from "../../../../data/local/ls";
import ChangeLanguageDialog from "../../../partials/dialogs/ChangeLanguageDialog.vue";

export default {
	props: { showDrawer: Boolean, isMobile: Boolean },
	components: {
		ChangeLanguageDialog,
	},
	data() {
		return {
			items: [
				{ tag: "home_page" },
				{ tag: "content" },
				{ tag: "pricing" },
				{ tag: "faq" },
				{ tag: "contact" },
			],
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		menuIcon() {
			return this.showDrawer ? "mdi-menu-open" : "mdi-menu";
		},
		isDark() {
			return getSetting(Setting.dark_mode);
		},
		logoLocation() {
			return this.isDark ? "@/assets/antrego_logo_light.png" : "@/assets/antrego_logo_dark.png";
		},
	},
	methods: {
		drawerAction() {
			this.$emit("switchDrawerVisibility", true);
		},
		switchTheme() {
			setSetting(Setting.dark_mode, !this.isDark);
			location.reload();
		},
		goToPanel() {
			this.$router.push({ name: this.isAuthenticated ? "home" : "signin" });
		},
		goToElement(tag) {
			this.$emit("goTo", tag);
		},
	},
};
</script>

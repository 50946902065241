import HttpModule from "./_http_module";

export default class FileModule extends HttpModule {
	get(name, { onSuccess, onFail }) {
		return super._get(`${name}`, {
			headers: {
				Accept:
					"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
			},
			requiresToken: false,
			responseType: "blob",
			onSuccess,
			onFail,
		});
	}

	send(file, folder, { onSuccess, onFail }) {
		let formData = new FormData();
		formData.append("file", file);
		return super._post(`${folder}`, formData, {
			headers: { "Content-Type": "multipart/form-data" },
			onSuccess,
			onFail,
		});
	}
}

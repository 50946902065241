import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import en from "vuetify/es5/locale/en";
import tr from "vuetify/es5/locale/tr";
import { Setting, getSetting } from "../data/local/ls";

Vuetify.config.silent = false; // suppresses all Vuetify logs and warnings when true.

Vue.use(Vuetify);

export const color = {
	primary: "#60A3D9",
	secondary: colors.red.darken4,
	accent: "#003B73",
	error: colors.red.darken1,
	info: colors.blue.darken1,
	success: colors.green.darken1,
	warning: colors.amber.darken1,
	anchor: colors.teal.lighten5,
	backgroundLight: "#f5f5f5",
	backgroundDark: "#050505",
};

export default new Vuetify({
	theme: {
		dark: getSetting(Setting.dark_mode),
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: color.primary,
				secondary: colors.amber.darken1,
				accent: color.accent,
				error: colors.red.darken1,
				info: colors.blue.darken1,
				success: colors.green.darken1,
				warning: colors.amber.darken1,
				anchor: colors.teal.lighten5,
				background: color.backgroundLight,
				gradient: {
					primary: [colors.teal.darken4, colors.teal.lighten4],
					secondary: [colors.amber.darken4, colors.amber.lighten4],
					accent: [colors.pink.darken4, colors.pink.lighten4],
					error: [colors.red.darken4, colors.red.lighten4],
					info: [colors.blue.darken4, colors.blue.lighten4],
					success: [colors.green.darken4, colors.green.lighten4],
					warning: [colors.amber.darken4, colors.amber.lighten4],
				},
			},
			dark: {
				primary: color.accent,
				secondary: colors.amber.darken4,
				accent: color.primary,
				error: colors.red.lighten1,
				info: colors.blue.lighten1,
				success: colors.green.lighten1,
				warning: colors.amber.lighten1,
				// anchor: colors.shades.white,
				anchor: colors.teal.lighten5,
				background: color.backgroundDark,
				gradient: {
					primary: [colors.teal.lighten4, colors.teal.darken4],
					secondary: [colors.amber.lighten4, colors.amber.darken4],
					accent: [colors.pink.lighten4, colors.pink.darken4],
					error: [colors.red.lighten4, colors.red.darken4],
					info: [colors.blue.lighten4, colors.blue.darken4],
					success: [colors.green.lighten4, colors.green.darken4],
					warning: [colors.amber.lighten4, colors.amber.darken4],
				},
			},
		},
		icons: {
			iconfont: "mdiSvg", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
		},
	},
	lang: {
		locales: { en, tr },
	},
});

import remote from "../../data/remote";
import { getTokenData, setTokenData } from "../../data/local/ls";

const Base64 = require("js-base64");

function getUserFromToken(accessToken) {
	const [part1, part2, part3] = accessToken.split(".");
	if (part1 && part2 && part3) {
		const tokenDataJson = JSON.parse(Base64.decode(part2));
		console.debug("USER", tokenDataJson.user);
		return tokenDataJson.user;
	} else return null;
}

const tokenData = getTokenData();

const stateless = {
	expiresAt: tokenData ? tokenData.expiresAt : null,
	refreshToken: tokenData ? tokenData.refreshToken : null,
	refreshTimeout: null,
	refreshRetryCount: 0,
	maxRefreshRetryCount: 1,
};

const state = {
	accessToken: tokenData ? tokenData.accessToken : null,
	user: tokenData && tokenData.accessToken ? getUserFromToken(tokenData.accessToken) : null,
	timerState: false,
};

const getters = {
	isAuthenticated: (state) => Boolean(state.accessToken && state.timerState),
};

const mutations = {
	SET_TOKEN_DATA(state, { accessToken, expiresAt, refreshToken }) {
		state.accessToken = accessToken;
		stateless.expiresAt = expiresAt;
		stateless.refreshToken = refreshToken;
	},
	SET_USER: (state, value) => (state.user = value),
	SET_TIMER_STATE: (state, value) => (state.timerState = value),
};

const actions = {
	async checkForRefresh({ state, dispatch }, { didComplete }) {
		if (state.accessToken) {
			const now = new Date().getTime();
			let duration = stateless.expiresAt - now;
			if (duration > 60000) {
				duration -= 60000;
				await dispatch("startRefreshTimer", duration);
				if (didComplete) didComplete(null);
			} else dispatch("refresh", { didComplete });
		} else if (didComplete) didComplete({ code: 401 });
	},

	async handleTokenData({ commit }, { accessToken, expiresAt, refreshToken }) {
		setTokenData({
			accessToken,
			expiresAt,
			refreshToken,
		});
		await commit("SET_USER", getUserFromToken(accessToken));
		await commit("SET_TOKEN_DATA", { accessToken, expiresAt, refreshToken });
	},

	async clearTokenData({ commit, dispatch }) {
		setTokenData({});
		await commit("SET_TOKEN_DATA", {});
		await commit("SET_USER", null);
		dispatch("stopRefreshTimer");
	},

	signIn({ state, dispatch }, { identifier, password, onSuccess, onFail }) {
		remote.auth.signIn({
			identifier,
			password,
			appCode: process.env.VUE_APP_AUTH_APP_CODE,
			deviceName: "Some Browser",
			onSuccess: async (data) => {
				await dispatch("handleTokenData", data);
				if (state.user.type !== 1) {
					await dispatch("clearTokenData");
					onFail(new Error("It's none of your business :/"));
				}
			},
			onFail,
		});
	},

	signUp({ dispatch }, { email, password, firstName, lastName, onSuccess, onFail }) {
		remote.auth.signUp({
			email,
			password,
			firstName,
			lastName,
			appCode: process.env.VUE_APP_AUTH_APP_CODE,
			deviceName: "Some Browser",
			onSuccess: (data) => {
				dispatch("handleTokenData", data);
				if (onSuccess) onSuccess();
			},
			onFail,
		});
	},

	signOut({ dispatch }, onSuccess) {
		const callback = async () => {
			dispatch("resetAppData", undefined, { root: true });
			await dispatch("clearTokenData");
			onSuccess();
		};
		remote.auth.signOut({ onSuccess: callback, onFail: callback });
	},

	refresh({ dispatch }, { tryToRetry, didComplete }) {
		remote.auth.refresh({
			refreshToken: stateless.refreshToken,
			onSuccess: async (result) => {
				await dispatch("handleTokenData", result);
				if (didComplete) didComplete(null);
			},
			onFail: async (error) => {
				if (tryToRetry && error.code !== 400) {
					stateless.refreshRetryCount++;
					dispatch("refresh", {
						tryToRetry: Boolean(stateless.refreshRetryCount < stateless.maxRefreshRetryCount),
						didComplete,
					});
				} else {
					stateless.refreshRetryCount = 0;
					await dispatch("clearTokenData");
					if (didComplete) didComplete(error);
				}
			},
		});
	},

	async startRefreshTimer({ commit, dispatch }, duration) {
		if (stateless.refreshTimeout) await dispatch("stopRefreshTimer");
		const max = 2147483647;
		const dur = duration < max ? duration : max;
		stateless.refreshTimeout = setTimeout(() => {
			dispatch("refresh", {
				tryToRetry: Boolean(stateless.refreshRetryCount < stateless.maxRefreshRetryCount),
			});
		}, dur);
		commit("SET_TIMER_STATE", true);
	},

	stopRefreshTimer({ commit }) {
		if (stateless.refreshTimeout) {
			clearTimeout(stateless.refreshTimeout);
			stateless.refreshTimeout = null;
			commit("SET_TIMER_STATE", false);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

<template>
	<page-template :hasAppBarExtension="Boolean(company || customsAgency)">
		<template slot="title">{{ $t("partners") }}</template>
		<template slot="buttons">
			<v-btn v-if="company && currentTab == 0" icon @click="migrateDialog.show">
				<v-icon>mdi-call-merge</v-icon>
			</v-btn>
		</template>

		<template slot="appBarExtension">
			<v-tabs v-model="currentTab" centered color="anchor">
				<v-tab v-for="(tab, i) in tabs" :key="i">
					{{ $t(tab) }}
				</v-tab>
			</v-tabs>
		</template>

		<template slot="content">
			<v-tabs-items v-model="currentTab">
				<v-tab-item>
					<partners-table
						:types="partnerTableTypes"
						:corporationId="corporation.id"
						:partnerDialogState="newItemDialogState"
						@partnerDialogClosed="newItemDialogState = false"
						:invitationCodeDialogState="invitationCodeDialogState"
						@invitationCodeDialogClosed="invitationCodeDialogState = false"
						:needRefresh="needRefresh"
						@refreshed="needRefresh = false"
					/>
				</v-tab-item>
				<v-tab-item v-if="company">
					<partners-table :types="[partnerTypes.CustomsAgency]" :corporationId="corporation.id" />
				</v-tab-item>

				<v-tab-item>
					<invitations-table />
				</v-tab-item>
			</v-tabs-items>

			<migrate-dialog v-model="migrateDialog.visibility" @migrated="migrateDialog.migrated" />

			<v-btn
				v-if="customsAgency && currentTab != 1"
				color="accent"
				fixed
				bottom
				right
				rounded
				large
				class="d-none d-sm-flex"
				elevation="0"
				@click="invitationCodeDialogState = true"
			>
				{{ $t("use_code") }}
				<v-icon right> mdi-dialpad </v-icon>
			</v-btn>

			<v-speed-dial
				v-else-if="company && currentTab == 0"
				fixed
				bottom
				right
				direction="top"
				transition="slide-y-reverse-transition"
			>
				<template v-slot:activator>
					<v-btn color="accent" fab elevation="0">
						<v-icon> mdi-plus </v-icon>
					</v-btn>
				</template>

				<v-tooltip left>
					<template v-slot:activator="{ on }">
						<v-btn
							v-on="on"
							fab
							small
							color="accent"
							elevation="0"
							@click="newItemDialogState = true"
						>
							<v-icon> mdi-clipboard-edit-outline </v-icon>
						</v-btn>
					</template>
					<span>{{ $t("create") }}</span>
				</v-tooltip>

				<v-tooltip left>
					<template v-slot:activator="{ on }">
						<v-btn
							v-on="on"
							fab
							small
							color="accent"
							elevation="0"
							@click="invitationCodeDialogState = true"
						>
							<v-icon> mdi-dialpad </v-icon>
						</v-btn>
					</template>
					<span>{{ $t("use_invitation_code") }}</span>
				</v-tooltip>
			</v-speed-dial>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import PageTemplate from "../../templates/PageTemplate.vue";
import { mapGetters, mapState } from "vuex";
import PartnersTable from "./partial/PartnersTable.vue";
import InvitationsTable from "./partial/InvitationsTable.vue";
import { partnerTypes } from "../../../../src/utils/enum";
import MigrateDialog from "./dialogs/MigrateDialog.vue";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		PartnersTable,
		InvitationsTable,
		MigrateDialog,
	},
	data() {
		return {
			currentTab: 0,
			tabs: [],
			companyPartners: [],
			customsAgencyPartners: [],
			newItemDialogState: false,
			invitationCodeDialogState: false,
			needRefresh: false,
			migrateDialog: {
				visibility: false,
				show: () => {
					this.migrateDialog.visibility = true;
				},
				migrated: (result) => {
					this.migrateDialog.visibility = false;
					this.needRefresh = true;
					this.showSnackBar({
						message: this.$t("$message.migrated", {
							item: this.$t("business_partner"),
							value: result.name,
						}),
					});
				},
			},
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState(["corporation"]),
		partnerTypes: () => partnerTypes,
		partnerTableTypes() {
			return this.company
				? [partnerTypes.Company, partnerTypes.AbstractCompany]
				: this.customsAgency
				? [partnerTypes.Company, partnerTypes.CustomsAgency]
				: [];
		},
	},
	created() {
		this.updateTabs();
		this.$trackPageView(this.$route.path);
	},
	methods: {
		updateTabs() {
			if (this.company) {
				this.tabs = ["commercial_partners", "customs_agencies", "invitations"];
			} else if (this.customsAgency) {
				this.tabs = ["partners", "invitations"];
			}
		},
	},
};
</script>

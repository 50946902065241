<template>
	<v-dialog v-model="value" persistent max-width="360">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("filter") }}
			</v-card-title>
			<v-card-text>
				<partner-select
					v-model="currentItem.referenceId"
					:label="$t('partner')"
					:eager="true"
					:types="[partnerTypes.Company, partnerTypes.AbstractCompany]"
					:clearable="true"
				/>
				<v-autocomplete
					v-model="currentItem.statuses"
					:label="$t('status')"
					:items="statusOptions"
					multiple
					small-chips
					deletable-chips
					clearable
					:item-text="(i) => (i.value ? $t(`$proformaInvoice.$status.${i.value}`) : '')"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn color="warning" @click="clearButtonAction">
					{{ $t("clear") }}
				</v-btn>
				<v-spacer />
				<v-btn @click="cancelButtonAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn color="accent" @click="okButtonAction">
					{{ $t("ok") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import PartnerSelect from "../../../controls/PartnerSelect";
import { partnerTypes } from "../../../../utils/enum";

export default {
	props: { value: Boolean, data: Object },
	components: { PartnerSelect },
	data() {
		return {
			statuses: [
				{ value: "approved" },
				{ value: "pending_approval" },
				{ value: "pending_revise" },
				{ value: "processed" },
				{ value: "rejected" },
				{ value: "revoked" },
				{ value: "template" },
			],
			currentItem: {
				role: undefined,
				referenceId: undefined,
				statuses: [],
			},
		};
	},
	computed: {
		partnerTypes: () => partnerTypes,
		statusOptions() {
			if (this.data.role === "receiver") return this.statuses.filter((i) => i.value !== "template");
			else return this.statuses;
		},
	},
	watch: {
		value(val) {
			if (val) {
				this.currentItem.role = this.data.role;
				this.currentItem.referenceId = this.data.referenceId;
				this.currentItem.statuses = this.data.statuses;
			}
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				referenceId: this.currentItem.referenceId,
				statuses: this.currentItem.statuses,
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
		clearButtonAction() {
			this.$emit("done", {
				role: this.currentItem.role,
				referenceId: null,
				statuses: [],
			});
		},
	},
};
</script>

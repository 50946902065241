<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("attachment") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-file-input
						v-if="!editMode"
						v-model="localFile"
						:label="$t('file')"
						:show-size="currentItem.id ? false : true"
						:placeholder="$t('select_file')"
						truncate-length="32"
						:disabled="hasAnyProcess"
						:loading="uploading"
						:rules="rules.file"
						@change="localFileChangeAction"
						append-outer-icon="mdi-paperclip"
						prepend-icon="mdi-file-outline"
					>
						<template v-slot:selection="{ text }">
							{{ text }}
						</template>
					</v-file-input>
					<v-text-field
						v-model="currentItem.name"
						:label="$t('name')"
						prepend-icon="mdi-pencil"
						:rules="rules.name"
					/>

					<v-textarea
						v-model="currentItem.note"
						:label="$t('note')"
						:disabled="hasAnyProcess"
						prepend-icon="mdi-notebook-edit-outline"
						rows="2"
						:rules="rules.note"
					/>
				</v-form>
			</v-card-text>
			<v-divider />

			<v-card-actions>
				<v-btn
					v-if="editMode"
					color="error"
					:disabled="hasAnyProcess"
					:loading="deleting"
					@click="deleteAction"
				>
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="hasAnyProcess" @click="cancelAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					color="accent"
					:disabled="hasAnyProcess"
					:loading="saving"
					:text="editMode"
					@click="saveAction"
				>
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import TitleValueListItem from "../../../partials/TitleValueListItem";
import { mapGetters } from "vuex";
import cloneDeep from "lodash.clonedeep";

export default {
	props: {
		value: Boolean,
		item: Object,
		proformaInvoice: Object,
	},
	mixins: [uiMixin],
	components: { TitleValueListItem },
	data() {
		return {
			currentItem: null,
			localFile: null,
			valid: null,
			saving: false,
			deleting: false,
			uploading: false,
			downloading: false,
			accessModifierOptions: [],
			rules: {
				file: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("file") }),
					(v) => !v || v.size <= 5242880 || this.$t("$message.file_oversize", { size: "5MB" }),
				],
				name: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("name") }),
					(v) => {
						if (v && v.length > 100) {
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						}
						return true;
					},
				],
				accessibility: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("accessibility") }),
				],
				note: [
					(v) => {
						if (v && v.length > 240) {
							return this.$t("$validation.max_length", {
								item: this.$t("note"),
								length: "240",
							});
						}
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
		editMode() {
			return Boolean(this.currentItem.id);
		},
		iAmSender() {
			return this.myRole === "sender";
		},
		iAmReceiver() {
			return this.myRole === "receiver";
		},
		hasAnyProcess() {
			return this.saving || this.deleting || this.uploading || this.downloading;
		},
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	mounted() {
		if (!this.currentItem) this.handleProps();
	},
	methods: {
		handleProps() {
			this.currentItem = this.item
				? cloneDeep(this.item)
				: {
						file: {
							name: null,
							type: null,
						},
						note: null,
						ownerId: this.company.id,
				  };
			if (this.iAmSender) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "sender_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_receiver"),
						value: "sender_receiver",
					}
				);
				this.currentItem.accessModifier = "sender_private";
			} else if (this.iAmReceiver) {
				this.accessModifierOptions.push(
					{
						text: this.$t("$accessModifier.$description.private"),
						value: "receiver_private",
					},
					{
						text: this.$t("$accessModifier.$description.shared_with_sender"),
						value: "sender_receiver",
					}
				);
				this.currentItem.accessModifier = "receiver_private";
			}
		},
		reset() {
			this.currentItem = null;
			this.localFile = null;
			this.accessModifierOptions = [];
		},
		localFileChangeAction(file) {
			this.currentItem.name = file.name.split(".")[0];
		},
		cancelAction() {
			this.reset();
			this.$emit("input", false);
			this.$emit("closed");
		},
		saveAction() {
			if (this.valid) {
				if (this.editMode) {
					this.saving = true;
					remote.proformaInvoices.attachments.update(this.currentItem, this.proformaInvoice.id, {
						onSuccess: (_result) => {
							this.saving = false;
							this.reset();
							this.$emit("saved", false);
						},
						onFail: (_) => (this.saving = false),
					});
				} else {
					this.uploading = true;
					remote.files.send(this.localFile, this.company.id, {
						onSuccess: (response) => {
							this.uploading = false;
							this.currentItem.file = response;
							this.saving = true;
							remote.proformaInvoices.attachments.create(
								this.currentItem,
								this.proformaInvoice.id,
								{
									onSuccess: (_result) => {
										this.saving = false;
										this.reset();
										this.$emit("saved", true);
									},
									onFail: (_) => (this.saving = false),
								}
							);
						},
						onFail: (_) => (this.uploading = false),
					});
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("$message.confirm", {
					function: this.$t("toDelete"),
				}),
				onConfirm: () => {
					this.deleting = true;
					remote.proformaInvoices.attachments.delete(this.currentItem.id, this.proformaInvoice.id, {
						onSuccess: () => {
							this.deleting = false;
							this.$emit("deleted");
						},
						onFail: (_) => (this.deleting = false),
					});
				},
			});
		},
	},
};
</script>

<template>
	<v-container class="my-16" align="center" justify="center">
		<v-row>
			<v-col cols="12" sm="6" md="6" lg="4">
				<v-card align="center" justify="center">
					<v-card-title class="px-8"> {{ $t("contact_info") }} </v-card-title>
					<iframe
						class="px-2"
						:src="$t(`$company.googleMapSrc`)"
						width="100%"
						height="300"
						style="border: 0"
						loading="lazy"
						referrerpolicy="no-referrer-when-downgrade"
					/>
					<v-card-subtitle> {{ $t("$company.name") }} </v-card-subtitle>
					<v-card-text>info@antrego.net</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" sm="6" md="6" lg="8">
				<v-card>
					<v-card-title class="px-8"> {{ $t("contact_to_us") }} </v-card-title>
					<v-divider />
					<v-card-text class="px-8">
						<v-text-field
							v-model="currentItem.name"
							:label="$t('name')"
							:rules="rules.name"
						/>
						<v-text-field
							v-model="currentItem.email"
							:label="$t('email')"
							:rules="rules.email"
						/>
						<v-text-field
							v-model="currentItem.phoneNumber"
							:label="$t('phone_number')"
							:rules="rules.phoneNumber"
							placeholder="(___)___-____"
						/>
						<v-textarea
							v-model="currentItem.message"
							:label="$t('message')"
							:rules="rules.message"
							rows="2"
						/>
					</v-card-text>
					<v-card-actions class="px-8 pb-4">
						<v-spacer />
						<v-btn color="accent" :loading="sending" @click="sendAction">
							{{ $t("send") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			sending: false,
			currentItem: {
				name: null,
				phone: null,
				number: null,
				message: null,
			},
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("name"),
							});
						return true;
					},
				],
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (v && !/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
					},
				],
				phoneNumber: [
					(v) => {
						if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("phone_number"),
							});
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: "20",
							});
						return true;
					},
				],
				message: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("message"),
							});
						return true;
					},
				],
			},
		};
	},
	methods: {
		sendAction() {
			sending = true;
			console.log("Message is sending :>> ", currentItem.message);
			sending = false;
		},
	},
	created() {
		//
	},
};
</script>

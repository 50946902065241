<template>
	<v-main class="si-background">
		<v-container class="fill-height" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" md="6" lg="4">
					<v-form ref="form">
						<v-card outlined>
							<v-card-text>
								<v-card-title>{{ $t("sign_in") }}</v-card-title>
								<v-text-field
									prepend-icon="mdi-email"
									v-model="email"
									:label="$t('email')"
									:rules="rules.email"
									@keydown.enter="submit"
								/>
								<v-text-field
									prepend-icon="mdi-lock"
									v-model="password"
									:label="$t('password')"
									type="password"
									:rules="rules.password"
									@keydown.enter="submit"
								/>
							</v-card-text>
							<v-card-actions>
								<v-btn
									block
									color="primary"
									@click="submit()"
									:disabled="loading"
									:loading="loading"
								>
									{{ $t("sign_in") }}
								</v-btn>
							</v-card-actions>
							<v-card-actions>
								<v-spacer />
								<v-card-subtitle>
									{{ $t("redirection_to_sign_up_message") }}
								</v-card-subtitle>
								<v-btn
									text
									color="success"
									@click="signUpButtonAction"
									:disabled="loading"
								>
									{{ $t("sign_up") }}
								</v-btn>
								<v-spacer />
							</v-card-actions>
							<v-card-actions class="mt-n8 mb-n4">
								<v-spacer />
								<v-card-subtitle>
									{{ $t("forget_password_message") }}
								</v-card-subtitle>
								<v-btn text @click="dialog.show" :disabled="loading">
									{{ $t("reset_password") }}
								</v-btn>
								<v-spacer />
							</v-card-actions>
							<v-card-actions>
								<v-spacer />
								<v-card-subtitle>
									{{ $t("redirection_to_home_message") }}
									<router-link class="accent--text" :to="loading ? '#' : '/'">
										{{ $t("redirection_to_home_link") }}
									</router-link>
								</v-card-subtitle>
								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-form>
				</v-col>
			</v-row>

			<v-dialog v-model="dialog.visibility" persistent max-width="480">
				<v-card>
					<v-card-title class="headline">
						{{ this.$t("forget_password") }}
					</v-card-title>
					<v-card-text>
						<v-text-field :label="this.$t(`email`)" v-model="dialog.item" />
					</v-card-text>
					<v-card-actions>
						<v-btn text @click="dialog.hide">
							{{ this.$t("cancel") }}
						</v-btn>
						<v-spacer />
						<v-btn
							color="accent"
							text
							@click="sendResetPasswordMail(dialog.item)"
						>
							{{ this.$t("send") }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-container>
	</v-main>
</template>

<script>
import { mapActions } from "vuex";
import uiMixin from "../../store/interfaces/ui.mixin";
import remote from "../../data/remote";

export default {
	mixins: [uiMixin],
	data() {
		return {
			email: "",
			password: "",
			invitationType: undefined,
			invitationCode: undefined,
			loading: false,
			showErrors: false,
			rules: {
				email: [
					(v) => {
						if (this.showErrors) {
							if (!v)
								return this.$t("$validation.required", {
									item: this.$t("email"),
								});
							else if (!/.+@.+\..+/.test(v))
								return this.$t("$validation.invalid", {
									item: this.$t("email"),
								});
						}
						return true;
					},
				],
				password: [
					(v) =>
						!this.showErrors ||
						Boolean(v) ||
						this.$t("$validation.required", {
							item: this.$t("password"),
						}),
				],
			},
			dialog: {
				item: null,
				visibility: false,
				show: () => {
					this.dialog.item = "";
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
			},
		};
	},
	methods: {
		...mapActions("auth", ["signIn"]),
		async submit() {
			this.showErrors = true;
			if (await this.$refs.form.validate()) {
				this.loading = true;
				this.signIn({
					identifier: this.email,
					password: this.password,
					onSuccess: () => {
						this.showSnackBar({ message: this.$t("welcome") });
						this.loading = false;
						if (this.invitationType && this.invitationCode)
							this.$router.replace({
								name: "accept_invitation",
								params: { type: this.invitationType },
								query: { code: this.invitationCode },
							});
						else this.$router.replace({ name: "home" });
					},
					onFail: (e) => {
						this.loading = false;
						let message = e.message || "Unexpected error occurred.";
						if (e.code === 401) message = this.$t("$message.sign_in_failed");
						this.showAlertDialog({ message });
					},
				});
			}
		},
		sendResetPasswordMail(item) {
			remote.auth.resetPasswordEmail(item, {
				onSuccess: () => {
					this.showSnackBar({
						message: this.$t("mail_sent"),
					});
					this.loading = false;
					this.dialog.hide();
				},
				onFail: (e) => {
					this.loading = false;
					if (!e.code) this.showAlertDialog({ message: e.message });
				},
			});
		},
		signUpButtonAction() {
			this.$router.replace({
				name: "signup",
				query: {
					invitationType: this.invitationType,
					invitationCode: this.invitationCode,
				},
			});
		},
	},
	created() {
		this.invitationType = this.$route.query.invitationType;
		this.invitationCode = this.$route.query.invitationCode;
	},
};
</script>

<style>
.si-background {
	background-image: linear-gradient(to bottom, #60a3d9, rgba(0, 0, 0, 0.2)),
		url("https://picsum.photos/id/885/1920/1080?blur=10&grayscale");
	background-position: center center;
	background-size: cover;
}
</style>

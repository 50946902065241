export default {
	$accessibility: {
		present: "進行中",
		archived: "アーカイブ済み",
	},
	$accessModifier: {
		$description: {
			private: "プライベート",
			hidden_from_receiver: "受信者に非表示",
			hidden_from_sender: "送信者に非表示",
			shared_with_everyone: "全員と共有",
			shared_with_custom: "カスタムで共有",
			shared_with_receiver: "受信者と共有",
			shared_with_sender: "送信者と共有",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "アンドラ",
		united_arab_emirates: "アラブ首長国連邦",
		afghanistan: "アフガニスタン",
		antigua_and_barbuda: "アンティグア・バーブーダ",
		anguilla: "アンギラ",
		albania: "アルバニア",
		armenia: "アルメニア",
		angola: "アンゴラ",
		antarctica: "南極大陸",
		argentina: "アルゼンチン",
		american_samoa: "アメリカンサモア",
		austria: "オーストリア",
		australia: "オーストラリア",
		aruba: "アルバ",
		aland: "オーランド諸島",
		azerbaijan: "アゼルバイジャン",
		bosnia_and_herzegovina: "ボスニア・ヘルツェゴビナ",
		barbados: "バルバドス",
		bangladesh: "バングラデシュ",
		belgium: "ベルギー",
		burkina_faso: "ブルキナファソ",
		bulgaria: "ブルガリア",
		bahrain: "バーレーン",
		burundi: "ブルンジ",
		benin: "ベナン",
		saint_barthelemy: "サン・バルテルミー",
		bermuda: "バミューダ",
		brunei: "ブルネイ",
		bolivia: "ボリビア",
		bonaire_sint_eustatius_and_saba:
			"ボネール、シント・ユースタティウスおよびサバ",
		brazil: "ブラジル",
		bahamas: "バハマ",
		bhutan: "ブータン",
		bouvet_island: "ブーベ島",
		botswana: "ボツワナ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		canada: "カナダ",
		cocos_islands: "ココス諸島",
		dr_congo: "コンゴ民主共和国",
		central_african_republic: "中央アフリカ共和国",
		congo_republic: "コンゴ共和国",
		switzerland: "スイス",
		ivory_coast: "コートジボワール",
		cook_islands: "クック諸島",
		chile: "チリ",
		cameroon: "カメルーン",
		china: "中国",
		colombia: "コロンビア",
		costa_rica: "コスタリカ",
		cuba: "キューバ",
		cabo_verde: "カーボベルデ",
		curacao: "キュラソー",
		christmas_island: "クリスマス島",
		cyprus: "キプロス",
		czechia: "チェコ",
		germany: "ドイツ",
		djibouti: "ジブチ",
		denmark: "デンマーク",
		dominica: "ドミニカ",
		dominican_republic: "ドミニカ共和国",
		algeria: "アルジェリア",
		ecuador: "エクアドル",
		estonia: "エストニア",
		egypt: "エジプト",
		western_sahara: "西サハラ",
		eritrea: "エリトリア",
		spain: "スペイン",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		fiji: "フィジー",
		falkland_islands: "フォークランド諸島",
		micronesia: "ミクロネシア連邦",
		faroe_islands: "フェロー諸島",
		france: "フランス",
		gabon: "ガボン",
		united_kingdom: "イギリス",
		grenada: "グレナダ",
		georgia: "ジョージア",
		french_guiana: "フランス領ギアナ",
		guernsey: "ガーンジー",
		ghana: "ガーナ",
		gibraltar: "ジブラルタル",
		greenland: "グリーンランド",
		gambia: "ガンビア",
		guinea: "ギニア",
		guadeloupe: "グアドループ",
		equatorial_guinea: "赤道ギニア",
		greece: "ギリシャ",
		south_georgia_and_south_sandwich_islands:
			"サウスジョージア・サウスサンドウィッチ諸島",
		guatemala: "グアテマラ",
		guam: "グアム",
		guinea_bissau: "ギニアビサウ",
		guyana: "ガイアナ",
		hong_kong: "香港",
		heard_island_and_mcdonald_islands: "ハード島とマクドナルド諸島",
		honduras: "ホンジュラス",
		croatia: "クロアチア",
		haiti: "ハイチ",
		hungary: "ハンガリー",
		indonesia: "インドネシア",
		ireland: "アイルランド",
		israel: "イスラエル",
		isle_of_man: "マン島",
		india: "インド",
		british_indian_ocean_territory: "英領インド洋地域",
		iraq: "イラク",
		iran: "イラン",
		iceland: "アイスランド",
		italy: "イタリア",
		jersey: "ジャージー",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		japan: "日本",
		kenya: "ケニア",
		kyrgyzstan: "キルギス",
		cambodia: "カンボジア",
		kiribati: "キリバス",
		comoros: "コモロ",
		st_kitts_and_nevis: "セントクリストファー・ネビス",
		north_korea: "北朝鮮",
		south_korea: "韓国",
		kuwait: "クウェート",
		cayman_islands: "ケイマン諸島",
		kazakhstan: "カザフスタン",
		laos: "ラオス",
		lebanon: "レバノン",
		saint_lucia: "セントルシア",
		liechtenstein: "リヒテンシュタイン",
		sri_lanka: "スリランカ",
		liberia: "リベリア",
		lesotho: "レソト",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		latvia: "ラトビア",
		libya: "リビア",
		morocco: "モロッコ",
		monaco: "モナコ",
		moldova: "モルドバ",
		montenegro: "モンテネグロ",
		saint_martin: "サン・マルタン",
		madagascar: "マダガスカル",
		marshall_islands: "マーシャル諸島",
		north_macedonia: "北マケドニア",
		mali: "マリ",
		myanmar: "ミャンマー",
		mongolia: "モンゴル",
		macao: "マカオ",
		northern_mariana_islands: "北マリアナ諸島",
		martinique: "マルティニーク",
		mauritania: "モーリタニア",
		montserrat: "モントセラト",
		malta: "マルタ",
		mauritius: "モーリシャス",
		maldives: "モルディブ",
		malawi: "マラウイ",
		mexico: "メキシコ",
		malaysia: "マレーシア",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		new_caledonia: "ニューカレドニア",
		niger: "ニジェール",
		norfolk_island: "ノーフォーク島",
		nigeria: "ナイジェリア",
		nicaragua: "ニカラグア",
		netherlands: "オランダ",
		norway: "ノルウェー",
		nepal: "ネパール",
		nauru: "ナウル",
		niue: "ニウエ",
		new_zealand: "ニュージーランド",
		oman: "オマーン",
		panama: "パナマ",
		peru: "ペルー",
		french_polynesia: "フランス領ポリネシア",
		papua_new_guinea: "パプアニューギニア",
		philippines: "フィリピン",
		pakistan: "パキスタン",
		poland: "ポーランド",
		saint_pierre_and_miquelon: "サンピエール・ミクロン",
		pitcairn_islands: "ピトケアン諸島",
		puerto_rico: "プエルトリコ",
		palestine: "パレスチナ",
		portugal: "ポルトガル",
		palau: "パラオ",
		paraguay: "パラグアイ",
		qatar: "カタール",
		reunion: "レユニオン",
		romania: "ルーマニア",
		serbia: "セルビア",
		russia: "ロシア",
		rwanda: "ルワンダ",
		saudi_arabia: "サウジアラビア",
		solomon_islands: "ソロモン諸島",
		seychelles: "セーシェル",
		sudan: "スーダン",
		sweden: "スウェーデン",
		singapore: "シンガポール",
		saint_helena: "セントヘレナ",
		slovenia: "スロベニア",
		svalbard_and_jan_mayen: "スバールバル諸島およびヤンマイエン島",
		slovakia: "スロバキア",
		sierra_leone: "シエラレオネ",
		san_marino: "サンマリノ",
		senegal: "セネガル",
		somalia: "ソマリア",
		suriname: "スリナム",
		south_sudan: "南スーダン",
		sao_tome_and_principe: "サントメ・プリンシペ",
		el_salvador: "エルサルバドル",
		sint_maarten: "シントマールテン",
		syria: "シリア",
		eswatini: "エスワティニ",
		turks_and_caicos_islands: "タークス・カイコス諸島",
		chad: "チャド",
		french_southern_territories: "仏領南方地域",
		togo: "トーゴ",
		thailand: "タイ",
		tajikistan: "タジキスタン",
		tokelau: "トケラウ",
		timor_leste: "東ティモール",
		turkmenistan: "トルクメニスタン",
		tunisia: "チュニジア",
		tonga: "トンガ",
		turkiye: "トルコ",
		trinidad_and_tobago: "トリニダード・トバゴ",
		tuvalu: "ツバル",
		taiwan: "台湾",
		tanzania: "タンザニア",
		ukraine: "ウクライナ",
		uganda: "ウガンダ",
		us_minor_outlying_islands: "アメリカ合衆国外諸島",
		united_states: "アメリカ合衆国",
		uruguay: "ウルグアイ",
		uzbekistan: "ウズベキスタン",
		vatican_city: "バチカン市国",
		st_vincent_and_grenadines: "セントビンセントおよびグレナディーン諸島",
		venezuela: "ベネズエラ",
		british_virgin_islands: "イギリス領ヴァージン諸島",
		us_virgin_islands: "米領ヴァージン諸島",
		vietnam: "ベトナム",
		vanuatu: "バヌアツ",
		wallis_and_futuna: "ウォリスおよびフツナ",
		samoa: "サモア",
		kosovo: "コソボ",
		yemen: "イエメン",
		mayotte: "マヨット",
		south_africa: "南アフリカ",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
	},
	$currency: {
		us_dollar: "米ドル",
		euro: "ユーロ",
		turkish_lira: "トルコリラ",
	},
	$employeeType: {
		admin: "管理者",
		default: "通常",
		exporter: "輸出業者",
		importer: "輸入業者",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Antregoへようこそ",
			description: "Antregoを使って輸入・輸出プロセスを管理しましょう！",
			sign_in_title: "アカウントをお持ちですか？",
			sign_in_subtitle: "こちらからログイン",
			sign_up_title: "アカウントを作成しますか？",
			sign_up_subtitle: "こちらから登録",
		},
		$noCompany: {
			title: "ようこそ {name} さん",
			description:
				"ログインに成功しました。今、企業に参加するか独自の企業を作成する必要があります。",
			join_company_title: "既存の企業に参加しますか？",
			join_company_subtitle: "こちらから招待コードを入力して参加してください。",
			create_company_title: "自分の企業を作成しますか？",
			create_company_subtitle: "こちらから新しい企業を作成してください。",
		},
	},
	$invitationType: {
		employee: "従業員の招待",
		company_partner: "取引先企業の招待",
		customs_partner: "税関業者の招待",
	},
	$landing: {
		$question: {
			$title: {
				0: "Antregoとは？",
				1: "Antregoは出荷プロセスにどのような利点をもたらしますか？",
				2: "Antregoがサポートする物流の種類は何ですか？",
				3: "Antregoをなぜ利用する必要がありますか？",
				4: "Antregoは有料のアプリケーションですか？",
				5: "Antregoのお客様も料金を支払う必要がありますか？",
			},
			$text: {
				0: "<p>出荷する企業とその顧客が共同で追跡するのに役立つ、運送の段階での遅延に起因する損害を防ぐための配送追跡プログラムです。</p>",
				1: `<p>- すべてのリアルタイムの更新をお客様と共有して、サービスの品質を向上させます。<br>
				- 出荷に関連するすべての文書を単一のシステムで表示し、お客様と共有します。<br>
				- すべての出荷を同じ場所から追跡します。ダイナミックなレポートで財務を管理します。</p>`,
				2: `<p>- 海上<br>
				- 空気中<br>
				- 陸上<br>
				- 列車</p>`,
				3: `<p>提供されるサービスで出荷プロセスを容易にし、常に手の届くところにあります。<br>
				<strong>1. 簡単なアクセス</strong><br>
				Webブラウザからすべてのデバイスからどこからでもアクセスできます。<br>
				<strong>2. データのセキュリティ</strong><br>
				すべてのデータはSHA-256暗号化技術で保管されます。<br>
				<strong>3. 共同追跡</strong><br>
				ビジネスプロセスのすべてのステークホルダーに追跡の機会を提供します。</strong>`,
				4: "<p>はい、有料のアプリケーションです。貴社の出荷プロセスをデジタル化して、業務を容易にし、さらなる有益な改善を続けたいと考えています。これらの改善が続けられるように、お使いのサーバー上で使用するスペースに対して月額で料金を頂いています。</p>",
				5: "<p>いいえ、お客様はお使いの出荷を追跡する際に料金を支払う必要はありません。</p>",
			},
		},
		$lifecycle: {
			headline: "すべてのプロセスが手の届くところに",
			$title: {
				0: "登録する",
				1: "プロフォーマ請求書を送信する",
				2: "出荷を作成する",
				3: "財務活動を追跡する",
			},
			$text: {
				0: "企業情報と製品を入力し、顧客を作成します。",
				1: "プロフォーマ請求書を作成し、承認のために顧客と共有します。",
				2: "出荷に関連する情報を入力し、文書を添付します。出荷のすべてのプロセスを1つのチャネルで顧客と管理します。",
				3: "輸入と輸出に関連するすべての財務状況をリアルタイムで追跡します。",
			},
		},
		$content: {
			headline: "サポートされている物流の種類",
			$title: {
				0: "海上",
				1: "空気中",
				2: "陸上",
				3: "列車",
			},
		},
		$pricing: {
			0: {
				title: "無料",
				subtitle: "個人",
				features: ["1ユーザー", "3パートナー", "10出荷", "10請求書"],
			},
			1: {
				title: "年間",
				subtitle: "企業",
				features: ["20ユーザー", "100パートナー", "1000出荷", "1000請求書"],
			},
			2: {
				title: "月額",
				subtitle: "小規模事業者",
				features: ["10ユーザー", "30パートナー", "250出荷", "250請求書"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function}を実行してもよろしいですか？",
		created: "{item}が作成されました。",
		deleted: "{item}が削除されました。",
		file_oversize: "ファイルサイズは最大で{size}でなければなりません。",
		invalid: "無効な{item}",
		sent_email: "Eメールが送信されました。 ({value})",
		sign_in_failed: "Eメールまたはパスワードが間違っています",
		updated: "{item}が更新されました。",
		migrated: "{item}が移動されました。 ({value})",
	},
	$productGroup: {
		add_child: "サブグループを追加",
		add_to_root: "ルートに追加",
		has_children_fail_message: "サブグループがある商品グループは削除できません",
		initial_message:
			"最初の商品グループを追加するには、{0}ボタンをクリックしてください。",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name}によって作成されました。",
			update: "{name}によって更新されました。",
			send: "{name}が承認を求めるために受信者に送信しました。",
			revoke: "{name}が取り消しました。",
			approve: "{name}が承認しました。",
			demand_revise: "{name}が改訂を要求しました。",
			reject: "{name}が拒否しました。",
			mark_as_processed: "{name}が処理済みとしてマークしました。",
			create_attachment: "{name}が添付ファイルを作成しました。",
			update_attachment: "{name}が添付ファイルを更新しました。",
			delete_attachment: "{name}が添付ファイルを削除しました。",
		},
		$actionDescription2: {
			send: "{name}が「送信済み」としてマークされました。",
			revoke: "{name}が「取り消し済み」としてマークされました。",
			approve: "{name}が「承認済み」としてマークされました。",
			demand_revise: "{name}が「改訂待ち」としてマークされました。",
			reject: "{name}が拒否されました。",
			mark_as_processed: `{name}が「処理済み」としてマークされました。`,
		},
		$actions: {
			approve: "承認",
			demand_revise: "改訂を要求",
			mark_as_approved: "承認済み",
			mark_as_processed: "処理済み",
			mark_as_rejected: "拒否済み",
			mark_as_revise_demanded: "改訂要求済み",
			mark_as_revoked: "取り消し済み",
			mark_as_sent: "送信済み",
			reject: "拒否",
			revoke: "取り消し",
			send: "送信",
		},
		$status: {
			approved: "承認済み",
			pending_approval: "承認待ち",
			pending_revise: "改訂待ち",
			processed: "処理済み",
			rejected: "拒否済み",
			revoked: "取り消し済み",
			template: "テンプレート",
		},
		send_confirm_message: "プロフォーマ請求書を受信者に送信しますか？",
		send_success_message: "プロフォーマ請求書が受信者に送信されました。",
		approve_confirm_message: "プロフォーマ請求書を承認しますか？",
		approve_success_message: "プロフォーマ請求書が承認されました。",
		demand_revise_success_message:
			"プロフォーマ請求書が改訂されるように送り返されました。",
		reject_confirm_message: "プロフォーマ請求書を拒否しますか？",
		reject_success_message: "プロフォーマ請求書が拒否されました。",
		mark_as_processed_confirm_message:
			"プロフォーマ請求書を処理済みとしてマークしますか？",
		mark_as_processed_success_message:
			"プロフォーマ請求書が処理済みとしてマークされました。",
		revoke_confirm_message: "プロフォーマ請求書を取り消しますか？",
		revoke_success_message: "プロフォーマ請求書が取り消されました。",
		demand_revise_description_message: "改訂の詳細を説明してください:",
		no_attachment_text: "まだ添付ファイルがアップロードされていません。",
	},
	$serviceMessage: {
		already_subs_module: "このモジュールには既に購読があります。",
		bad_request: "不正なリクエスト",
		code_already_used: "コードはすでに使用されています。",
		container_status_not_available: "コンテナのステータスは利用できません。",
		existing_email_warning: "メールはすでに存在しています。",
		existing_employee_error: "このユーザーは既に会社に追加されています。",
		existing_employee_for_email_warning:
			"このメールには既に招待コードが送信されています。",
		existing_invitation_warning:
			"このメールには既に招待コードが送信されています。",
		existing_token_already_have_time:
			"既存のトークンはすでに使用可能な時間があります。",
		existing_user: "ユーザーは既に存在しています。",
		five_min_reset_password_mail:
			"パスワードリセットメールを一度リクエストしたら、少なくとも5分待つ必要があります。",
		forbidden: "アクセスが禁止されています。",
		internal_server_error: "内部サーバーエラー。",
		instance_can_no_delete_existing_booking_code:
			"アイテムは既存の予約コードのため削除できません。",
		instance_cannot_delete: "アイテムはもはや削除できません。",
		instance_not_found: "アイテムが見つかりません。",
		invitation_accepting_is_due_to_the_admin:
			"招待の受け入れは管理者に依存しています。",
		invitation_already_discarded: "招待はすでに破棄されています。",
		invitation_already_used: "招待は使用済みです。",
		invitation_has_been_used: "招待は使用済みです。",
		invitation_not_exist_email: "招待のメールアドレスが存在しません。",
		invitation_not_for_custom_agency:
			"この招待はカスタムエージェント向けではありません。",
		invitation_not_for_company: "この招待は企業向けではありません。",
		invitation_not_found: "招待が見つかりません。",
		invitation_not_valid: "招待はもはや有効ではありません。",
		invalid_current_password: "現在のパスワードが無効です。",
		invalid_identifier_or_password: "無効な識別子またはパスワード",
		invalid_invitation_code: "招待コードが無効です。",
		invalid_moving_request: "無効な移動リクエスト。",
		invalid_refresh_token: "無効なリフレッシュトークン。",
		invalid_operation: "無効な操作。",
		invalid_unit: "無効な単位。",
		have_no_corporation: "会社が存在しません。",
		have_no_custom_agency: "カスタムエージェントが存在しません。",
		have_no_employee: "従業員が存在しません。",
		link_not_be_used_anymore: "このリンクはもはや使用されません。",
		link_has_been_expired: "リンクの有効期限が切れました。",
		missing_required_fields: "必須フィールドが入力されていません。",
		no_employee_found: "従業員が見つかりませんでした。",
		no_proper_partner_found: "適切なパートナーが見つかりませんでした。",
		not_acceptable: "許容されません。",
		not_allowed_action: "この操作を実行する権限がありません。",
		not_allowed_use_api: "このAPIを使用する権限がありません。",
		not_found: "見つかりません。",
		not_permitted_use_api: "このAPIを使用する権限がありません。",
		own_invitation_cannot_accept: "自分に招待状を送ることはできません。",
		parent_group_not_found: "親の製品グループが見つかりませんでした。",
		partnerId_required: "パートナーIDは必須です。",
		partnership_already_exists: "すでにパートナーシップが存在しています。",
		password_cant_involve_space_char:
			"パスワードにはスペース文字を含めることはできません。",
		password_min_chars_least:
			"パスワードは少なくとも6文字以上である必要があります。",
		ports_are_required: "ポートが必要です。",
		product_group_tree_not_found: "製品グループツリーが見つかりませんでした。",
		proforma_invoice_action_not_available:
			"この操作にはプロフォーマ請求書のステータスが利用できません。",
		proforma_invoice_template_update:
			"プロフォーマ請求書を更新するにはテンプレートが必要です。",
		shipment_method_is_required: "出荷方法が必要です。",
		shipping_term_is_required: "出荷条件が必要です。",
		shipment_email_not_exist: "有効なメールアドレスを入力してください。",
		someone_else_uses_this_email:
			"他の誰かがこのメールアドレスを使用しています。",
		someone_else_uses_this_username:
			"他の誰かがこのユーザー名を使用しています。",
		something_went_wrong: "何かがうまくいかなかった。",
		type_can_be_abstract_company:
			'"type"プロパティは"abstract_company"のみ有効です。',
		unauthorized: "権限がありません",
		undefined_result: "未定義の結果。",
		unexpected_error_occurred: "予期せぬエラーが発生しました。",
		unit_already_added: "単位は既に追加されています。",
		username_min_chars_error: '"ユーザー名"は最低6文字でなければなりません。',
		user_already_employee: "ユーザーは既に従業員です。",
		user_already_exists: "ユーザーは既に企業の従業員として存在しています。",
		user_does_not_exist: "ユーザーが存在しません。",
		user_not_found: "ユーザーが見つかりません。",
	},
	$shipment: {
		$actionDescription: {
			create: "{name}によって作成されました。",
			update: "{name}によって更新されました。",
			create_container: "{name}がコンテナを追加しました。",
			update_container: "{name}がコンテナを更新しました。",
			delete_container: "{name}がコンテナを削除しました。",
			create_attachment: "{name}が添付ファイルを作成しました。",
			update_attachment: "{name}が添付ファイルを更新しました。",
			delete_attachment: "{name}が添付ファイルを削除しました。",
			$land: {
				create_container: "{name}が車両を追加しました。",
				update_container: "{name}が車両を更新しました。",
				delete_container: "{name}が車両を削除しました。",
			},
		},
		$containerStatus: {
			reserved: "予約済み",
			on_load: "積み込まれました",
			on_way: "途中",
			arrived: "到着",
			off_load: "降ろされました",
		},
		$method: {
			sea: "海",
			aerial: "空",
			land: "陸",
			railway: "鉄道",
		},
		$vehicle: {
			license_number: "ナンバープレート",
		},
		archiving_success_message: "出荷がアーカイブされました。",
		leave_message: "メッセージまたはコメントを残してください",
		no_booking_info:
			"まだ予約情報が定義されていません。追加するには {0} ボタンをクリックしてください。",
		no_transportation_info:
			"まだ出荷の詳細が定義されていません。定義するには {0} ボタンをクリックしてください。",
		un_archiving_success_message: "出荷がアーカイブから解除されました。",
	},
	$shortening: {
		number: "番号",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "LinkedIn",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "ミリメートル",
		meter: "メートル",
		kilometer: "キロメートル",
		milligram: "ミリグラム",
		gram: "グラム",
		kilogram: "キログラム",
		square_meter: "平方メートル",
		hectare: "ヘクタール",
		square_kilometer: "平方キロメートル",
		milliliter: "ミリリットル",
		liter: "リットル",
		cubic_meter: "立方メートル",
		acres: "エーカー",
		feet: "フィート",
		fluid_ounces: "液量オンス",
		gallons: "ガロン",
		inches: "インチ",
		miles: "マイル",
		ounces: "オンス",
		pounds: "ポンド",
		quarts: "クォート",
		square_feet: "平方フィート",
		square_miles: "平方マイル",
		square_yards: "平方ヤード",
		square_inches: "平方インチ",
		yards: "ヤード",
		pieces: "個",
		boxes: "ボックス",
		pallets: "パレット",
		dozen: "ダース",
		ton: "トン",
	},
	$validation: {
		required: "{item}は必須です",
		required_for_default_language:
			"{item}情報は企業のデフォルト言語に設定されている必要があります",
		invalid: "{item}が無効です",
		email: "有効なメールアドレスを入力してください",
		min_length: "{item}は少なくとも{length}文字である必要があります",
		max_length: "{item}は最大{length}文字である必要があります",
		password_repeat_warning:
			"パスワードの繰り返しは新しいパスワードと同じでなければなりません",
	},

	accept_invitation_success_message: "招待を成功裏に受け入れました。",
	accessibility: "アクセシビリティ",
	actions: "アクション",
	active: "アクティブ",
	add: "追加",
	add_item: "{item}を追加",
	address: "住所",
	again: "再び",
	alert: "アラート",
	already_have_employee_message: "既に企業の従業員です。",
	amount: "金額",
	archive: "アーカイブ",
	attachment: "添付ファイル",
	attachments: "添付ファイル",

	booking: "予約",
	booking_code: "予約コード",
	business_partner: "ビジネスパートナー",
	business_partners: "ビジネスパートナー",

	cancel: "キャンセル",
	change_password: "パスワードの変更",
	checking_invitation_code_message: "招待コードを確認しています",
	choose_image: "画像を選択",
	clear: "クリア",
	close: "閉じる",
	code: "コード",
	companies: "企業",
	company: "企業",
	company_profile: "企業プロフィール",
	commercial: "商業",
	commercial_partners: "商業パートナー",
	content: "コンテンツ",
	configuration: "構成",
	confirm: "確認",
	contact: "連絡先",
	contact_info: "連絡先情報",
	contact_to_us: "お問い合わせ",
	container: "コンテナ",
	containers: "コンテナ",
	content: "コンテンツ",
	countries: "国",
	country: "国",
	create: "作成",
	create_company: "企業を作成",
	created_at: "作成日",
	currencies: "通貨",
	currency: "通貨",
	current_password: "現在のパスワード",
	customs_agency: "税関代理店",
	customs_agencies: "税関代理店",

	date: "日付",
	dashboard: "ダッシュボード",
	default: "デフォルト",
	default_language: "デフォルト言語",
	delete: "削除",
	delete_confirm_message: "削除を確認しますか？",
	description: "説明",
	detail: "詳細",
	discard: "破棄",
	document_number: "文書番号",
	download: "ダウンロード",

	edit: "編集",
	edit_item: "{item}を編集",
	email: "メール",
	employee: "従業員",
	employee_type: "従業員タイプ",
	employees: "従業員",
	estimated_arrival_day: "予想到着日",
	expense: "経費",
	expenses: "経費",
	exported_containers: "輸出コンテナ",

	faq: "よくある質問",
	file: "ファイル",
	filter: "フィルタ",
	first_name: "名",
	forget_password: "パスワードを忘れた",
	forget_password_message: "パスワードを忘れましたか？",
	from: "から",

	hide: "非表示",
	home_page: "ホームページ",

	imported_containers: "輸入コンテナ",
	incoming: "着信",
	incoming_shipments: "着信輸送",
	initial: "初期",
	invitation: "招待",
	invitation_code: "招待コード",
	invitation_mail_sent: "招待メールが送信されました",
	invitations: "招待",

	join: "参加",

	language_options: "言語オプション",
	last_name: "姓",
	last_one_year: "最後の1年",
	latitude: "緯度",
	loading_country: "積み込み国",
	loading_point: "積み込み地点",
	loading_port: "積み込み港",
	logo: "ロゴ",
	longitude: "経度",

	go_to_panel: "パネルに移動",

	mail_sent: "メールが送信されました",
	migrate: "移行",
	migrate_partner: "パートナーを移行",
	message: "メッセージ",
	message_company_create: "企業が作成されました。",
	message_company_join: "企業に参加",
	message_company_joined: "企業に参加しました",
	message_employee_delete: "従業員が削除されました。",
	message_employee_save: "従業員が保存されました。",
	message_join_company: "企業に参加するには招待コードを入力してください。",
	method: "メソッド",
	mobile_number: "携帯電話番号",
	my_role: "私の役割",

	name: "名前",
	next: "次へ",
	new: "新しい",
	new_password: "新しいパスワード",
	no: "いいえ",
	no_content: "コンテンツがありません",
	no_text: "テキストなし",
	not_found_page_message:
		"申し訳ありませんが、このページが見つかりませんでした :(",
	note: "注意",

	ok: "OK",
	outgoing: "発信",
	outgoing_shipments: "発信輸送",

	parent_group: "親グループ",
	partner: "パートナー",
	partners: "パートナー",
	passive: "受動的",
	password: "パスワード",
	phone_number: "電話番号",
	previous: "前へ",
	pricing: "価格",
	product: "製品",
	product_group: "製品グループ",
	product_groups: "製品グループ",
	products: "製品",
	profile: "プロフィール",
	proforma_invoice: "プロフォーマ請求書",
	proforma_invoices: "プロフォーマ請求書",
	proforma_number: "プロフォーマ番号",
	purchase: "購入",

	quantity: "数量",

	receiver: "受信者",
	receiver_company: "受信企業",
	receiver_customs_agency: "受信税関代理店",
	redirection_to_home_link: "こちらをクリック",
	redirection_to_home_message: "ホームページに戻るには、",
	redirection_to_sign_in_message: "アカウントをお持ちですか？",
	redirection_to_sign_up_message: "まだアカウントをお持ちでないですか？",
	reload: "リロード",
	reset_password: "パスワードをリセット",

	sales: "販売",
	save: "保存",
	search: "検索",
	select_file: "ファイルを選択",
	select_file_place_holder: "画像を選択してください",
	selected_partner: "選択されたパートナー",
	send: "送信",
	send_again: "再送信",
	send_email: "メールを送信",
	send_invitation: "招待を送信",
	sender: "送信者",
	sender_company: "送信企業",
	sender_customs_agency: "送信税関代理店",
	show: "表示",
	shipment: "出荷",
	shipment_method: "出荷方法",
	shipments: "出荷",
	shipments_filter: "出荷フィルタ",
	shipping_term: "発送条件",
	shipping_terms: "発送条件",
	sign_in: "サインイン",
	sign_out: "サインアウト",
	sign_out_confirm_message: "サインアウトを確認しますか？",
	sign_out_success_message: "さようなら。",
	sign_up: "サインアップ",
	social_media: "ソーシャルメディア",
	social_media_account: "ソーシャルメディアアカウント",
	status: "ステータス",
	subscribe: "購読",
	super: "スーパー",
	switch_language: "言語を切り替える",
	switch_theme: "テーマを切り替える",

	target: "ターゲット",
	target_partner: "ターゲットパートナー",
	theme: "テーマ",
	this_month: "今月",
	this_year: "今年",
	title: "タイトル",
	to: "宛先",
	toDelete: "削除する",
	total: "合計",
	total_amount: "合計金額",
	type: "タイプ",
	types: "タイプ",

	un_archive: "アーカイブから復元",
	unit: "単位",
	units: "単位",
	unloading_country: "降ろし国",
	unloading_point: "降ろし地点",
	unloading_port: "降ろし港",
	use_code: "コードを使用",
	use_invitation_code: "招待コードを使用",
	use_invitation_code_description:
		"メールで送られた招待コードを入力してください。",
	use_invitation_code_success_message: "招待コードが正常に使用されました。",
	user_profile: "ユーザープロフィール",

	vehicle: "車両",
	vehicles: "車両",
	visibility: "可視性",

	warehouse: "倉庫",
	warehouses: "倉庫",
	website: "ウェブサイト",
	welcome: "ようこそ",

	yes: "はい",
};

<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t(currentItem.new ? "send_invitation" : "invitation") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-select
						v-model="currentItem.type"
						:items="typeOptions"
						:rules="rules.type"
						:label="$t('type')"
						:disabled="typeOptions.length < 2 || Boolean(currentItem.id)"
					/>
					<v-text-field
						v-model="currentItem.email"
						:label="$t('email')"
						:disabled="!currentItem.new"
						:rules="rules.email"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn
					v-if="!currentItem.new"
					:disabled="discarding || saving"
					:loading="discarding"
					color="error"
					@click="discardAction"
				>
					{{ $t("discard") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="discarding || saving" @click="closeAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					v-if="!currentItem.new"
					:disabled="discarding || saving"
					:loading="sending"
					color="accent"
					@click="sendEmailAction"
				>
					{{ $t("send_again") }}
				</v-btn>
				<v-btn
					v-if="currentItem.new"
					:disabled="discarding || saving"
					:loading="saving"
					color="accent"
					@click="saveAction"
				>
					{{ $t("create") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	props: ["value", "item", "onlyCompany"],
	components: { LocalizedTextField },
	data() {
		return {
			currentItem: null,
			valid: null,
			typeOptions: [],
			saving: false,
			sending: false,
			discarding: false,
			rules: {
				type: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("type") })],
				email: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("email"),
							});
						else if (!/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item)
				this.currentItem = Object.assign(
					{},
					{
						new: false,
						id: this.item.id,
						email: this.item.email,
						type: this.item.type,
					}
				);
			else
				this.currentItem = {
					email: "",
					type: null,
					new: true,
				};
			if (this.typeOptions.length === 1) this.currentItem.type = this.typeOptions[0].value;
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
		},
		fillTypeOptions() {
			if (!this.onlyCompany)
				this.typeOptions.push({
					text: this.$t("$invitationType.employee"),
					value: "employee",
				});
			else if (this.company) {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
				this.typeOptions.push({
					text: this.$t("$invitationType.customs_partner"),
					value: "customs_partner",
				});
			} else {
				this.typeOptions.push({
					text: this.$t("$invitationType.company_partner"),
					value: "company_partner",
				});
			}
		},
		saveAction() {
			if (this.valid) {
				this.saving = true;
				remote.invitations.create(
					{
						email: this.currentItem.email,
						type: this.currentItem.type,
					},
					{
						onSuccess: (result) => {
							this.saving = false;
							this.$emit("saved", result);
							this.showSnackBar({
								message: this.$t("$message.created", {
									item: this.$t("invitation"),
								}),
							});
						},
						onFail: () => (this.saving = false),
					}
				);
			} else this.$refs.form.validate();
		},
		discardAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.discarding = true;
					remote.invitations.delete(this.currentItem.id, {
						onSuccess: (result) => {
							this.discarding = false;
							this.$emit("deleted", result);
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("invitation"),
								}),
							});
						},
						onFail: () => (this.discarding = false),
					});
				},
			});
		},
		sendEmailAction() {
			this.sending = true;
			remote.invitations.sendMail(this.currentItem.id, {
				onSuccess: () => {
					this.sending = false;
					this.$emit("emailSent");
					this.showSnackBar({
						message: this.$t("invitation_mail_sent", {
							email: this.currentItem.email,
						}),
					});
				},
				onFail: () => (this.sending = false),
			});
		},
		closeAction() {
			this.$emit("input", false);
		},
	},
	created() {
		this.fillTypeOptions();
	},
	mounted() {
		this.handleProps();
	},
};
</script>

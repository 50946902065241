<template v-slot:extension>
	<v-container>
		<v-card v-if="!loading && hasProgressPayment" class="my-4" color="info">
			<v-card-text class="background--text">
				<v-icon color="background">mdi-cash-clock</v-icon>
				{{ $t("$subscription.$message.payment_process_is_ongoing") }}
			</v-card-text>
		</v-card>

		<v-card v-if="!loading && lastPaymentHasError" class="my-4" color="error">
			<v-card-text>
				<v-icon class="mr-2">mdi-information-variant-circle-outline</v-icon>
				{{ $t("$subscription.$message.check_your_payment_method") }}
			</v-card-text>
		</v-card>

		<div v-if="loading"></div>

		<v-card v-else-if="subscription" class="my-4">
			<v-card-text>
				<v-row>
					<v-col cols="4" class="py-0">
						<title-value-list-item
							v-if="subscription.product"
							:title="$t('name')"
							:value="subscription.product.name"
						/>
					</v-col>
					<v-col cols="3" class="py-0">
						<title-value-list-item
							:title="$t('start_time')"
							:value="formattedDate(subscription.createdAt)"
						/>
					</v-col>
					<v-col cols="3" class="py-0">
						<title-value-list-item
							:title="$t('expires_at')"
							:value="formattedDate(subscription.expiresAt)"
						/>
					</v-col>
					<v-col cols="2" class="py-0">
						<v-btn
							v-if="hasSubscription"
							class="ma-4 warning"
							elevation="1"
							text
							:disabled="loading"
							@click="unsubscribeAction"
						>
							{{ $t("unsubscribe") }}
						</v-btn>
						<v-card-text v-else class="warning--text">
							YOU DO NOT HAVE ANY SUBSCRIPTION
						</v-card-text>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card v-if="savedCardInfo" class="my-4">
			<v-card-title>
				{{ $t("saved_card_info") }}
			</v-card-title>
			<v-card-text>
				<v-row>
					<v-col cols="3" class="py-0">
						<title-value-list-item :title="$t('name')" :value="savedCardInfo.c_name" />
					</v-col>
					<v-col cols="3" class="py-0">
						<title-value-list-item :title="$t('bank')" :value="savedCardInfo.c_bank" />
					</v-col>
					<v-col cols="3" class="py-0">
						<title-value-list-item :title="$t('credit_card_no')" :value="getCardNo" />
					</v-col>
					<v-col cols="3" class="py-0">
						<title-value-list-item :title="$t('date')" :value="getCardExpireDate" />
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-card v-if="!hasSubscription" class="my-4">
			<v-card-title>
				<v-icon class="mr-4" large>mdi-information-variant-circle-outline</v-icon>
				{{ $t("$subscription.$message.you_dont_have_any_subscription") }}

				<v-spacer />
				<v-btn @click="goToSubscriptionAction" color="primary">
					<v-icon>mdi-credit-card-outline</v-icon>
					{{ $t("subscription") }}
				</v-btn>
			</v-card-title>
		</v-card>

		<v-card v-if="paymentHistory" class="my-4">
			<v-card-title>
				{{ $t("payment_history") }}
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="paymentHistory"
					:options.sync="tableOptions"
					:server-items-length="pagination.totalItemCount"
					:footer-props="tableFooterProps"
					:loading="loading"
					style="cursor: pointer"
					disable-sort
				>
					<template v-slot:item.createdAt="{ item }">
						{{ item.createdAt.format($t("$format.date_time")) }}
					</template>
					<template v-slot:item.payment_amount="{ item }">
						{{ item.payment_amount }} {{ item.currency }}
					</template>
					<template v-slot:item.payment_status="{ item }">
						{{ getPaymentStatusText(item.payment_status) }}
					</template>
					<template v-slot:item.recurring_payment="{ item }">
						{{ item.recurring_payment == 1 ? $t("renewal") : $t("purchase") }}
					</template>
					<template v-slot:item.product_id="{ item }">
						{{ getProductName(item.product_id) }}
					</template>
					<template v-slot:item.buttons="{ item }">
						<v-tooltip bottom open-delay="500" v-if="availableForPayback(item)">
							<template v-slot:activator="{ on }">
								<v-btn icon @click="repaidAction(item)" v-on="on" :disabled="beingPayback">
									<v-icon>mdi-credit-card-refund-outline</v-icon>
								</v-btn>
							</template>
							<span>{{ $t("payback") }}</span>
						</v-tooltip>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import TitleValueListItem from "../../partials/TitleValueListItem";
import { mapGetters } from "vuex";
import { paymentStatus } from "../../../utils/enum";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";

export default {
	mixins: [paginationMixin, uiMixin],
	components: { TitleValueListItem },
	data() {
		return {
			loading: false,
			beingPayback: false,
			subscription: null,
			savedCardInfo: null,
			antProducts: null,
			paymentHistory: null,
			headers: [
				{ text: this.$t("transaction_no"), value: "merchant_oid" },
				{ text: this.$t("name"), value: "product_id" },
				{ text: `${this.$t("payment_amount")}/${this.$t("currency")}`, value: "payment_amount" },
				{ text: this.$t("status"), value: "payment_status" },
				{ text: this.$t("type"), value: "recurring_payment" },
				{ text: this.$t("created_at"), value: "createdAt" },
				{ value: "buttons", align: "right", sortable: false, width: 145 },
			],
		};
	},
	computed: {
		...mapGetters(["hasSubscription", "company"]),
		lastPayment() {
			return this.paymentHistory?.length > 0 ? this.paymentHistory[0] : null;
		},
		lastPaymentHasError() {
			return this.lastPayment ? this.lastPayment.payment_status == paymentStatus.Failed : false;
		},
		hasProgressPayment() {
			return this.lastPayment ? this.lastPayment.payment_status == paymentStatus.InProgress : false;
		},
		getCardNo() {
			return "**** **** **** " + this.savedCardInfo.last_4;
		},
		getCardExpireDate() {
			return this.savedCardInfo.month + " / " + this.savedCardInfo.year;
		},
	},
	methods: {
		loadSubscriptionInfo() {
			this.loading = true;
			remote.companies.getMySubscription({
				onSuccess: (result) => {
					this.subscription = result.hasValue === false ? null : result;
					this.loading = false;
				},
				onFail: (error) => {
					if (error) console.error(error);
					this.loading = false;
				},
			});
		},

		loadItems() {
			this.loading = true;
			remote.companies.getMyPaymentHistory(
				{
					pager: {
						number: this.tableOptions.page,
						size: this.tableOptions.itemsPerPage,
					},
					sorter: this.tableOptions.sortBy.length
						? {
								property: this.tableOptions.sortBy,
								method: this.tableOptions.sortDesc ? "desc" : "asc",
						  }
						: undefined,
				},
				{
					onSuccess: (result) => {
						this.paymentHistory = result.items;
						this.pagination = result.pagination;
						this.loading = false;
					},
					onFail: (error) => {
						if (error) console.error(error);
						this.loading = false;
					},
				}
			);
		},

		loadAntProducts() {
			remote.antProduct.filter({
				onSuccess: (result) => {
					this.antProducts = result.items;
				},
				onFail: () => {},
			});
		},

		unsubscribeAction() {
			this.showConfirmDialog({
				message: this.$t("unsubscribe_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();

					remote.companies.getUnsubscribe({
						onSuccess: (_) => {
							this.hideProgressDialog();
							this.loadSubscriptionInfo();
							this.showSnackBar({ message: this.$t("$subscription.$message.unsubscribed") });
						},
						onFail: (error) => {
							if (error) console.error(error);
							this.hideProgressDialog();
							this.loadSubscriptionInfo();
						},
					});
				},
			});
		},

		availableForPayback(item) {
			return (
				this.lastPayment.merchant_oid == item.merchant_oid &&
				(item.payment_status === paymentStatus.Success ||
					item.payment_status === paymentStatus.InProgress)
			);
			// return this.subscription.merchantOid === item.merchant_oid;
		},

		repaidAction(item) {
			this.showConfirmDialog({
				message: this.$t("payback_confirm_message") + " " + this.$t("payback_warning_message"),
				onConfirm: () => {
					this.beingPayback = true;
					remote.payment.payback(item.merchant_oid, {
						onSuccess: (result) => {
							this.beingPayback = false;
							this.loadItems();
							this.loadSubscriptionInfo();
							this.showSnackBar({
								message: this.$t("$message.process_has_been_started", {
									item: this.$t("payback"),
								}),
							});
						},
						onFail: () => (this.beingPayback = false),
					});
				},
			});
		},

		formattedDate(date) {
			return date ? date.format(this.$t("$format.date")) : "";
		},

		getPaymentStatusText(val) {
			switch (val) {
				case paymentStatus.Repaid:
					return this.$t("repaid");
				case paymentStatus.Failed:
					return this.$t("failed");
				case paymentStatus.InProgress:
					return this.$t("in_progress");
				case paymentStatus.Success:
					return this.$t("success");
				default:
					return this.$t("in_progress");
			}
		},

		getMyCardInfo() {
			remote.payment.getMyCardInfo({
				onSuccess: (result) => {
					this.savedCardInfo = result == "" ? null : result;
				},
				onFail: (e) => {
					return console.error(e);
				},
			});
		},

		goToSubscriptionAction() {
			this.$router.push({ name: "subscription" });
		},

		getProductName(id) {
			for (const p of this.antProducts) if (p.id == id) return p.name;
			return "";
		},
	},
	created() {
		this.getMyCardInfo();
		this.loadSubscriptionInfo();
		this.loadItems();
		this.loadAntProducts();
	},
};
</script>

<template>
	<v-dialog v-model="value" persistent max-width="520">
		<v-card>
			<v-form ref="form" v-model="valid">
				<v-card-title class="px-4"> {{ $t("give_us_feedback") }} </v-card-title>
				<v-card-text class="px-6">
					<v-text-field
						prepend-icon="mdi-account"
						v-model="currentItem.name"
						:label="$t('name')"
						:rules="rules.name"
						:disabled="saving"
					/>
					<v-text-field
						prepend-icon="mdi-email"
						v-model="currentItem.email"
						:label="$t('email')"
						:rules="rules.email"
						:disabled="saving"
					/>

					<v-file-input
						prepend-icon="mdi-file-outline"
						:rules="rules.file"
						v-model="selectedFile"
						:label="$t('file')"
						:placeholder="$t('select_file')"
						truncate-length="32"
						:loading="uploading"
						:disabled="saving"
						@change="changeLogo"
						append-outer-icon="mdi-paperclip"
						accept="image/png, image/jpg, image/jpeg"
					>
						<template v-slot:selection="{ text }">
							{{ text }}
						</template>
					</v-file-input>
					<v-textarea
						prepend-icon="mdi-pen"
						v-model="currentItem.message"
						:label="$t('message')"
						counter="250"
						rows="2"
						:rules="rules.message"
						:disabled="saving"
					/>
				</v-card-text>
			</v-form>
			<v-card-actions>
				<v-spacer />
				<v-btn color="primary" @click="closeAction" :disabled="saving">{{ $t("close") }}</v-btn>
				<v-btn color="accent" @click="saveAction" :disabled="saving" :loading="saving">
					{{ $t("send") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import remote from "../../data/remote";
import uiMixin from "../../store/interfaces/ui.mixin";

export default {
	props: { value: { type: Boolean, required: true } },
	mixins: [uiMixin],
	data() {
		return {
			selectedFile: null,
			currentItem: {
				name: null,
				email: null,
				message: null,
				file: null,
			},
			uploading: false,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("name"), length: "100" });

						return true;
					},
				],
				email: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("email") });
						else if (v && !/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("email"), length: "100" });
						return true;
					},
				],
				message: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("message") }),
				],
				file: [(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("file") })],
				message: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("message") });
						else if (v.length > 100)
							return this.$t("$validation.max_length", { item: this.$t("message"), length: "250" });
						return true;
					},
				],
			},
			valid: true,
			saving: false,
		};
	},
	watch: {
		value(val) {
			if (!val) this.closeAction();
		},
	},
	methods: {
		changeLogo(item) {
			if (item && item.size <= 262144) {
				this.selectedFile = item;
			} else if (item) {
				this.showSnackBar({
					message: this.$t("$message.file_oversize", { size: "256kB" }),
				});
			}
		},
		closeAction() {
			this.reset();
			this.$emit("closed");
		},
		saveAction() {
			if (this.valid) {
				this.uploading = true;
				this.saving = true;
				remote.files.send(this.selectedFile, "feedback", {
					onSuccess: (response) => {
						this.uploading = false;
						this.currentItem.file = {
							key: response.key,
							type: response.type,
						};
						remote.feedback.create(this.currentItem, {
							onSuccess: () => {
								this.saving = false;
								this.closeAction();
								this.showSnackBar({
									message: this.$t("$message.sent", {
										item: this.$t("feedback"),
									}),
								});
							},
							onFail: () => (this.saving = false),
						});
					},
					onFail: () => (this.uploading = false),
				});
			} else this.$refs.form.validate();
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = {
				name: null,
				email: null,
				message: null,
				file: null,
			};
		},

		showSnackBar({ message }) {
			this.$emit("show-snackbar", { message });
		},
	},
};
</script>

<template>
	<v-dialog v-bind:value="value" persistent max-width="480px" eager>
		<v-card>
			<v-card-title>
				{{
					`${$t(currentItem && currentItem.id ? "edit_item" : "add_item", {
						item: $t("product"),
					})}`
				}}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid" v-if="currentItem">
					<localized-text-field
						v-model="currentItem.name"
						:defaultLanguage="companyDefaultLanguage"
						:options="companyLanguageOptions"
						:rules="rules.name"
						:label="$t('name')"
					/>
					<v-text-field v-model="currentItem.code" :rules="rules.code" :label="$t(`code`)" />
					<product-group-select
						v-model="currentItem.productGroupId"
						clearable
						:eager="true"
						:rules="rules.productGroupId"
						class="mt-2"
					/>
					<v-autocomplete
						v-model="currentItem.unitCodes"
						multiple
						chips
						deletable-chips
						:label="$t('units')"
						:items="unitOptions"
						:rules="rules.unit"
						:item-text="(i) => $t(`$unit.${i.name}`)"
						item-value="code"
						:loading="!unitOptions"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn v-if="currentItem && currentItem.id" color="error" @click="deleteAction">
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn @click="closeAction">{{ $t("close") }}</v-btn>
				<v-btn color="accent" @click="saveAction">{{ $t("save") }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ProductGroupSelect from "../../../controls/ProductGroupSelect";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import remote from "../../../../data/remote";
import uiMixin from "../../../../store/interfaces/ui.mixin";
import { mapGetters } from "vuex";

export default {
	props: { value: Boolean, item: Object },
	components: { ProductGroupSelect, LocalizedTextField },
	mixins: [uiMixin],
	data() {
		return {
			itemTemplate: {
				name: {},
				code: "",
				units: [],
				unitCodes: [],
				productGroupId: null,
			},
			currentItem: null,
			rules: {
				name: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
				localizedName: [
					(v) => {
						if (!v) return this.$t("$validation.required", { item: this.$t("name") });
						else if (Object.values(v).find((i) => i.length > 100))
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "100",
							});
						else return true;
					},
				],
				productGroupId: [
					(v) => Boolean(v) || this.$t("$validation.required", { item: this.$t("product_group") }),
				],
				unit: [
					(v) => Boolean(v.length) || this.$t("$validation.required", { item: this.$t("unit") }),
				],
				code: [
					(v) => {
						if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("code"),
								length: "20",
							});
						return true;
					},
				],
			},
			productGroupOptions: null,
			valid: true,
			unitOptions: [],
		};
	},
	computed: {
		...mapGetters(["companyDefaultLanguage", "companyLanguageOptions"]),
	},
	watch: {
		value(val) {
			if (val) {
				const res = this.item || this.itemTemplate;
				if (!res.name[this.companyDefaultLanguage]) res.name[this.companyDefaultLanguage] = "";
				for (const language of this.companyLanguageOptions)
					if (!res.name[language]) res.name[language] = "";
				this.currentItem = Object.assign({}, res);
				this.currentItem.name = Object.assign({}, res.name);
				this.currentItem.unitCodes = [];
				for (const unit of this.currentItem.units) this.currentItem.unitCodes.push(unit.code);
			} else {
				this.$refs.form.reset();
				this.currentItem = null;
			}
		},
	},
	methods: {
		loadUnits() {
			remote.structures.getUnits({
				onSuccess: (result) => (this.unitOptions = result.items),
			});
		},
		saveAction() {
			if (this.valid) {
				if (this.currentItem.name && this.currentItem.name[this.companyDefaultLanguage] === "") {
					this.showAlertDialog({
						message: `${this.$t("$validation.required_for_default_language", {
							item: this.$t("name"),
						})} (${this.$t(`$language.${this.companyDefaultLanguage}`)})`,
					});
				} else {
					this.currentItem.units = [];
					for (const unitCode of this.currentItem.unitCodes) {
						const unit = this.unitOptions.find((i) => i.code === unitCode);
						if (unit) this.currentItem.units.push(unit);
					}
					this.showProgressDialog();
					if (this.currentItem.id) {
						remote.products.update(this.currentItem, {
							onSuccess: (result) => {
								this.hideProgressDialog();
								this.showSnackBar({
									message: this.$t("$message.updated", {
										item: this.$t("product"),
									}),
								});
								this.$editEvent("product");
								this.$emit("saved", result);
							},
							onFail: () => this.hideProgressDialog(),
						});
					} else {
						remote.products.create(this.currentItem, {
							onSuccess: (result) => {
								this.hideProgressDialog();
								this.showSnackBar({
									message: this.$t("$message.created", {
										item: this.$t("product"),
									}),
								});
								this.$addEvent("product");
								this.$emit("saved", result);
							},
							onFail: () => this.hideProgressDialog(),
						});
					}
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.showProgressDialog();
					remote.products.delete(this.currentItem.id, {
						onSuccess: (result) => {
							this.hideProgressDialog();
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("product"),
								}),
							});
							this.$emit("deleted", result);
						},
					});
				},
			});
		},
		closeAction() {
			this.$emit("input", false);
		},
	},
	created() {
		this.loadUnits();
	},
};
</script>

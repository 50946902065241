<template>
	<v-container>
		<v-data-table
			:headers="headers"
			:items="warehouses"
			hide-default-footer
			:loading="loading"
			class="ma-4"
			style="cursor: pointer"
			@click:row="dialog.show"
			disable-sort
		/>
		<v-btn
			color="accent"
			elevation="0"
			fixed
			bottom
			right
			fab
			@click="dialog.show(null)"
		>
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<warehouse-dialog
			v-model="dialog.visibility"
			:item="dialog.item"
			@saved="dialog.saved"
			@deleted="dialog.deleted"
		/>
	</v-container>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import remote from "../../../data/remote";
import WarehouseDialog from "./dialogs/WarehouseDialog";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	components: { WarehouseDialog },
	data() {
		return {
			loading: false,
			warehouses: [],
			headers: [
				{ text: this.$t("name"), value: "name" },
				{ text: this.$t("address"), value: "address" },
				{ text: this.$t("phone_number"), value: "phoneNumber" },
				{ text: this.$t("mobile_number"), value: "mobileNumber" },
				{ text: this.$t("email"), value: "email" },
			],
			dialog: {
				visibility: false,
				item: null,
				show: (warehouse) => {
					this.dialog.item = warehouse;
					this.dialog.visibility = true;
				},
				hide: () => {
					this.dialog.item = null;
					this.dialog.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.warehouses.filter(this.company.id, {
				onSuccess: (result) => {
					this.warehouses = result;
					//TODO: REMOVE HERE (change '_id' to 'id')
					this.warehouses.forEach((w) => (w.id = w._id));
					this.loading = false;
				},
				onFail: (error) => {
					if (error) console.error(error);
					this.loading = false;
				},
			});
		},
	},
	created() {
		this.loadItems();
	},
};
</script>

<template>
	<v-select
		v-model="currentValue"
		:label="label || $t('shipment_method')"
		:prepend-icon="
			prependIcon !== undefined
				? prependIcon
				: getShipmentMethodIcon(currentValue)
		"
		:items="options"
		:rules="rules"
		:disabled="disabled"
		:clearable="clearable"
		@change="changeAction"
	/>
</template>

<script>
import { iconHelper as iconHelperMixin } from "../mixins/utils.mixin";

export default {
	props: ["value", "label", "clearable", "disabled", "rules", "prepend-icon"],
	mixins: [iconHelperMixin],
	data() {
		return {
			currentValue: null,
			options: [
				{ text: this.$t("$shipment.$method.sea"), value: "sea" },
				{ text: this.$t("$shipment.$method.aerial"), value: "aerial" },
				{ text: this.$t("$shipment.$method.railway"), value: "railway" },
				{ text: this.$t("$shipment.$method.land"), value: "land" },
			],
			ready: false,
		};
	},
	watch: {
		currentValue(val) {
			this.$emit("input", val);
		},
		value(val) {
			this.currentValue = val || null;
		},
	},
	methods: {
		changeAction(code) {
			this.$emit("change", code);
		},
	},
	created() {
		this.currentValue = this.value || null;
		this.options = this.options.sort((a, b) => (a.text > b.text ? 1 : -1));
	},
};
</script>

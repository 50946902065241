<template>
	<page-template>
		<template slot="title">{{ $t("products") }}</template>
		<template slot="buttons">
			<v-btn icon @click="filterDialog.show">
				<v-icon>{{ filterIcon }}</v-icon>
			</v-btn>
		</template>
		<template slot="content">
			<v-data-table
				:headers="headers"
				:items="products"
				:options.sync="tableOptions"
				:server-items-length="pagination.totalItemCount"
				:footer-props="tableFooterProps"
				:loading="loading"
				style="cursor: pointer"
				@click:row="productAction"
				disable-sort
			>
				<template v-slot:item.name="{ item }">
					{{
						item.name[$lang] && item.name[$lang].length ? item.name[$lang] : item.name["_default"]
					}}
				</template>
				<template v-slot:item.status="{ item }">
					{{ $t(`$status.${item.status}`) }}
				</template>
			</v-data-table>

			<v-btn
				color="accent"
				fixed
				bottom
				right
				fab
				class="d-flex d-sm-none"
				@click="dialog.show(null)"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>

			<v-btn
				color="accent"
				fixed
				bottom
				right
				rounded
				large
				elevation="0"
				class="d-none d-sm-flex"
				@click="dialog.show(null)"
			>
				{{ $t("new") }}
				<v-icon right> mdi-plus-circle</v-icon>
			</v-btn>

			<product-filter-dialog
				v-model="filterDialog.visibility"
				:data="filterDialog.data"
				@done="filterDialog.done"
			/>

			<product-dialog
				v-model="dialog.visibility"
				:item="dialog.item"
				@saved="dialog.saved"
				@deleted="dialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../../store/interfaces/ui.mixin";
import { pagination as paginationMixin } from "../../mixins/utils.mixin";
import PageTemplate from "../../templates/PageTemplate";
import remote from "../../../data/remote";
import ProductDialog from "./dialog/ProductDialog";
import ProductFilterDialog from "./dialog/ProductFilterDialog";

export default {
	mixins: [uiMixin, paginationMixin],
	components: { PageTemplate, ProductDialog, ProductFilterDialog },
	data() {
		return {
			products: [],
			loading: false,
			filterDialog: {
				visibility: false,
				data: { text: null, productGroupId: null },
				show: () => (this.filterDialog.visibility = true),
				hide: () => (this.filterDialog.visibility = false),
				done: (data) => {
					this.filterDialog.data = data;
					this.$router.push({ query: this.filterDialog.data });
					this.filterDialog.hide();
					this.loadItems();
				},
			},
			dialog: {
				visibility: false,
				item: null,
				show(product) {
					this.item = product;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
				deleted: (_) => {
					this.dialog.hide();
					this.loadItems();
				},
			},
			headers: [
				// TODO: edit
				{ text: this.$t("code"), value: "code" },
				{ text: this.$t("name"), value: "name" },
			],
		};
	},
	computed: {
		filterIcon() {
			return this.filterDialog.data.text || this.filterDialog.data.productGroupId
				? "mdi-filter"
				: "mdi-filter-outline";
		},
	},
	methods: {
		loadItems() {
			this.loading = true;
			remote.products.filter({
				filter: this.filterDialog.data,
				pager: {
					number: this.tableOptions.page,
					size: this.tableOptions.itemsPerPage,
				},
				sorter: this.tableOptions.sortBy.length
					? {
							property: this.tableOptions.sortBy,
							method: this.tableOptions.sortDesc ? "desc" : "asc",
					  }
					: undefined,
				onSuccess: (result) => {
					this.pagination = result.pagination;
					this.products = result.items.sort((a, b) => {
						if (a.createdAt && b.createdAt) return a.createdAt < b.createdAt ? 1 : -1;
						else return !a.createdAt ? 1 : -1;
					});
					this.loading = false;
				},
				onFail: () => (this.loading = false),
			});
		},
		productAction(product) {
			this.dialog.show(product);
		},
	},
	created() {
		this.$trackPageView(this.$route.path);
		this.filterDialog.data.text = this.$route.query.text;
		this.filterDialog.data.productGroupId = this.$route.query.productGroupId;
		this.loadItems();
	},
};
</script>

<template>
	<v-dialog v-model="value" persistent max-width="360">
		<v-card v-if="panelData">
			<v-card-title class="headline">
				{{ $t("filter") }}
			</v-card-title>
			<v-card-text>
				<v-text-field v-model="panelData.text" :label="`${$t('name')} / ${$t('code')}`" clearable />
				<product-group-select v-model="panelData.productGroupId" clearable :eager="true" :label="$t('product_group')" />
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn @click="cancelButtonAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn color="accent" @click="okButtonAction">
					{{ $t("ok") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ProductGroupSelect from "../../../controls/ProductGroupSelect";

export default {
	props: { value: Boolean, data: Object },
	components: { ProductGroupSelect },
	data() {
		return {
			panelData: Object,
		};
	},
	watch: {
		value(val) {
			if (val) {
				this.panelData.text = this.data.text;
				this.panelData.productGroupId = this.data.productGroupId;
			}
		},
	},
	methods: {
		okButtonAction() {
			this.$emit("done", {
				text: this.panelData.text || undefined,
				productGroupId: this.panelData.productGroupId || undefined,
			});
		},
		cancelButtonAction() {
			this.$emit("input", false);
		},
	},
};
</script>

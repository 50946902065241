import Model from "../_model";
import { CustomsAgencyInvitationModel } from "./invitation.model";

export default class CustomsAgencyModel extends Model {
	constructor(provider) {
		super(provider, "customs_agencies");
	}

	_getUrl() {
		let url = this.apiMethod.replace("customs_agencies", "customs_agency");
		return url;
	}

	filter({ pager, sorter }, { onSuccess, onFail }) {
		return super.filter({ params: {}, pager, sorter, onSuccess, onFail });
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(customsAgency, { onSuccess, onFail }) {
		const body = {
			name: customsAgency.name,
			countryId: customsAgency.countryId,
			address: customsAgency.address,
			phoneNumber: customsAgency.phoneNumber,
			email: customsAgency.email,
			website: customsAgency.website,
			logo: customsAgency.logo
				? {
						key: customsAgency.logo.key,
						type: customsAgency.logo.type,
				  }
				: null,
			settings: customsAgency.settings || "",
		};
		return super.create(body, { onSuccess, onFail });
	}

	update(customsAgency, { onSuccess, onFail }) {
		const body = {
			name: customsAgency.name,
			countryId: customsAgency.countryId,
			address: customsAgency.address,
			phoneNumber: customsAgency.phoneNumber,
			email: customsAgency.email,
			website: customsAgency.website,
			settings: customsAgency.settings,
			taxNumber: customsAgency.taxNumber,
		};
		if (customsAgency.logo)
			body.logo = {
				key: customsAgency.logo.key,
				type: customsAgency.logo.type,
			};
		else if (customsAgency.logo === null) body.logo = null;
		return super.update(body, { url: this._getUrl(), onSuccess, onFail });
	}

	myCustomsAgency({ onSuccess, onFail }) {
		return super.filter({ url: "customs_agency", onSuccess, onFail });
	}
}

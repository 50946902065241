<template>
	<v-main class="background pb-16 pt-12">
		<v-col align="center">
			<h1 class="text-h4 pb-8">{{ $t("faq") }}</h1>
			<v-container>
				<v-expansion-panels popout>
					<v-expansion-panel v-for="(item, i) in items" :key="i">
						<v-expansion-panel-header class="font-weight-bold">
							{{ $t(`$landing.$question.$title.${i}`) }}
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="$t(`$landing.$question.$text.${i}`)"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</v-col>
	</v-main>
</template>

<script>
export default {
	data() {
		return {
			stuff: "<span><strong>hello</strong> <span>world</span></span>",
			items: [
				{
					title: "Antrego Nedir?",
					text: "<p>Sevkiyat yapan firmaların ve müşterilerinin ortak takip yapmasına yardımcı olurken takip aşamasında gecikme kaynaklı yaşanabilecek zararların önüne geçen bir sevkiyat takip programıdır.</p>",
				},
				{
					title: "Antrego sevkiyat sürecinde firmama ne katkı sağlayacak?",
					text: `<p>- Tüm anlık güncellemelerini müşterinle paylaş. Hizmet kaliteni artır.<br>
					- Sevkiyat ile ilgili tüm evraklarını tek bir sistemde gör, müşterinle paylaş.<br>
					- Tüm sevkiyatlarını aynı yerden takip et. Dinamik raporlamalarla finansını kontrol altında tut.</p>`,
				},
				{
					title: "Antrego’nun desteklediği lojistik türler?",
					text: `<p>- Deniz<br>
- Kara<br>
- Hava<br>
- Tren</p>`,
				},
				{
					title: "Antrego’yu neden kullanmalıyım?",
					text: `<p>Sunduğu hizmetler ile sevkiyat sürecini kolaylaştırmak için her an elinin altında.<br>
1. Kolay Erişim<br>
Web tarayıcı üzerinden tüm cihazlardan ve her yerden erişilebilir.<br>
2. Veri Güvenliği<br>
Bütün veriler SHA-256 şifreleme teknolojisiyle saklanır.<br>
3. Ortak Takip<br>
İş sürecindeki bütün paydaşlara takip imkanı sunar.</p>`,
				},
				{
					title: "Antrego ücretli bir uygulama mıdır?",
					text: "<p>Evet ücretli bir uygulamadır. Sevkiyatlarınızın tüm süreçlerini dijitale taşıyarak, hem işlerinizi kolaylaştırmak, hem de size faydalı geliştirmeler yapmaya devam etmek istiyoruz. Bu geliştirmelerin devam edebilmesi ve sunucu üzerinde kullandığınız alan için seçtiğiniz plana göre bir ücret talep ediyoruz.</p>",
				},
				{
					title: "Antrego için müşterilerimizin de ücret ödemesi gerekiyor mu?",
					text: "<p>Hayır. Müşterileriniz sizin yaptığınız sevkiyatların takibini yaparken herhangi bir ücret ödemesine gerek yoktur.</p>",
				},
			],
		};
	},
	computed: {
		carouselHeight() {
			return (screen.height * 2) / 3;
		},
	},
	methods: {
		//
	},
	created() {
		//
	},
};
</script>

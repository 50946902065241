import Model from "../_model";

export default class ShipmentAttachmentModel extends Model {
	constructor(provider) {
		super(provider, "shipments/{shipmentId}/attachments");
	}

	_getUrl(shipmentId, { id }) {
		let url = this.apiMethod.replace("{shipmentId}", shipmentId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(shipmentId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(shipmentId, {}),
			onSuccess,
			onFail,
		});
	}

	find(id, shipmentId, { onSuccess, onFail }) {
		return super.find({
			url: this._getUrl(shipmentId, { id }),
			onSuccess,
			onFail,
		});
	}

	serialize(instance) {
		return {
			file: {
				key: instance.file.key,
				type: instance.file.type,
			},
			name: instance.name,
			note: instance.note,
			accessModifier: instance.accessModifier,
		};
	}

	serializeToUpdate(instance) {
		return {
			name: instance.name,
			note: instance.note,
			accessModifier: instance.accessModifier,
		};
	}

	create(instance, shipmentId, { onSuccess, onFail }) {
		const body = this.serialize(instance);
		return super.create(body, {
			url: this._getUrl(shipmentId, {}),
			onSuccess,
			onFail,
		});
	}

	update(instance, shipmentId, { onSuccess, onFail }) {
		const body = this.serializeToUpdate(instance);
		return super.update(body, {
			url: this._getUrl(shipmentId, { id: instance.id }),
			onSuccess,
			onFail,
		});
	}

	delete(id, shipmentId, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(shipmentId, { id }),
			onSuccess,
			onFail,
		});
	}
}

import HttpModule from "./_http_module";

export default class Model extends HttpModule {
	constructor(provider, apiMethod) {
		super(provider);
		this.apiMethod = apiMethod;
	}

	_parse(object) {
		if (object.createdAt) object.createdAt = super.parseDateTime(object.createdAt);
		if (object.updatedAt) object.updatedAt = super.parseDateTime(object.updatedAt);
		return super._parse(object);
	}

	_parseList(list) {
		if (list.items) {
			list.items = super._parseList(list.items);
			return list;
		}
		return super._parseList(list);
	}

	_getUrl(id) {
		if (this.apiMethod) {
			let url = this.apiMethod;
			if (id) url = url.concat(`/${id}`);
			return url;
		} else throw `Default "getUrl" method is not available for ${this.constructor.name}.`;
	}

	/**
	 * @param { number, size } pager
	 * @param { property, method?: 'asc' | 'desc' } sorter
	 * @returns
	 */
	filter({ url, params, pager, sorter, requiresToken, parse, onSuccess, onFail }) {
		if (this.apiMethod) {
			if (pager && pager.number && pager.size && pager.size > 0)
				params.pager = `${pager.number},${pager.size}`;
			if (sorter && sorter.property) {
				params.sorter = sorter.property;
				if (sorter.method) params.sorter += `,${sorter.method}`;
			}
			return super._get(url || this._getUrl(), {
				params,
				requiresToken,
				parse,
				onSuccess,
				onFail,
			});
		} else throw `"filter" method is not available for ${this.constructor.name}.`;
	}

	find({ id, url, requiresToken, parse, onSuccess, onFail }) {
		if (this.apiMethod && (id || url)) {
			return super._get(url || this._getUrl(id), {
				requiresToken,
				parse,
				onSuccess,
				onFail,
			});
		} else throw `find" method is not available for ${this.constructor.name}.`;
	}

	create(body, { url, parse, onSuccess, onFail }) {
		if (this.apiMethod) {
			return this._post(url || this._getUrl(), body, {
				parse,
				onSuccess,
				onFail,
			});
		} else throw `"create" method is not available for ${this.constructor.name}.`;
	}

	update(body, { id, url, parse, onSuccess, onFail }) {
		if (this.apiMethod && (id || url)) {
			return this._put(url || this._getUrl(id), body, {
				parse,
				onSuccess,
				onFail,
			});
		} else throw `"update" method is not available for ${this.constructor.name}.`;
	}

	delete({ id, url, parse, onSuccess, onFail }) {
		if (this.apiMethod) {
			return super._delete(url || this._getUrl(id), {
				parse,
				onSuccess,
				onFail,
			});
		} else throw `"delete" method is not available for ${this.constructor.name}.`;
	}
}

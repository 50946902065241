<template>
	<v-container class="pa-2">
		<v-row v-if="dashboard.currencyReports" class="ma-0">
			<v-col cols="12" sm="6" md="6" lg="3" class="pa-2">
				<container-count-report
					:data="dashboard.exportContainerCountReport"
					:title="$t('exported_containers')"
					chart-color="white"
					backgroundColor="info"
					dark
				/>
			</v-col>
			<v-col cols="12" sm="6" md="6" lg="3" class="pa-2" elevation="64">
				<container-count-report
					:data="dashboard.importContainerCountReport"
					:title="$t('imported_containers')"
					chart-color="white"
					backgroundColor="primary"
					dark
				/>
			</v-col>
			<v-col
				v-for="(item, i) in dashboard.currencyReports"
				:key="i"
				cols="12"
				md="12"
				lg="6"
				class="pa-2"
			>
				<trade-report
					:data="item"
					:title="`${$t('total_amount')} (${item.currency.code})`"
					:dark="$vuetify.theme.isDark"
				/>
			</v-col>
		</v-row>
		<v-row class="ma-0">
			<v-col class="pa-2" cols="12" xl="6" v-if="outgoingShipmentsVisibility">
				<v-card elevation="1">
					<v-card-title>{{ $t("outgoing_shipments") }}</v-card-title>
					<shipment-status-table role="sender" />
				</v-card>
			</v-col>
			<v-col class="pa-2" cols="12" xl="6" v-if="incomingShipmentsVisibility">
				<v-card elevation="1">
					<v-card-title>{{ $t("incoming_shipments") }}</v-card-title>
					<shipment-status-table role="receiver" />
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ShipmentStatusTable from "../shipment/partial/ShipmentStatusTable";
import ContainerCountReport from "./partial/ContainerCountReport.vue";
import TradeReport from "./partial/TradeReport";
import { mapGetters, mapState } from "vuex";
import remote from "../../../data/remote";

export default {
	components: { ShipmentStatusTable, ContainerCountReport, TradeReport },
	data() {
		return {
			dashboard: {
				currencyReports: undefined,
				exportContainerCountReport: undefined,
				importContainerCountReport: undefined,
			},
		};
	},
	computed: {
		...mapGetters(["company", "customsAgency"]),
		...mapState(["employee"]),
		outgoingShipmentsVisibility() {
			return this.employee.type != "importer";
		},
		incomingShipmentsVisibility() {
			return this.employee.type != "exporter";
		},
	},
	created() {
		if (this.company) this.loadDashboard();
	},
	methods: {
		loadDashboard() {
			remote.utilities.getDashboard({
				onSuccess: (result) => {
					this.dashboard = result;
					let currencyReports = result.currencyReports;
					this.dashboard.currencyReports = [];
					for (const currencyReport of currencyReports) {
						let add = false;
						for (const key in currencyReport.salesReport.monthBasedTotals) {
							if (currencyReport.salesReport.monthBasedTotals[key] > 0) {
								add = true;
								break;
							}
						}
						if (!add) {
							for (const key in currencyReport.purchaseReport
								.monthBasedTotals) {
								if (currencyReport.purchaseReport.monthBasedTotals[key] > 0) {
									add = true;
									break;
								}
							}
						}
						if (add) this.dashboard.currencyReports.push(currencyReport);
					}
				},
				onFail: () => (this.loading = false),
			});
		},
	},
};
</script>

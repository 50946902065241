export default {
	$accessibility: {
		present: "En cours",
		archived: "Archivé",
	},
	$accessModifier: {
		$description: {
			private: "Privé",
			hidden_from_receiver: "Caché du destinataire",
			hidden_from_sender: "Caché de l'expéditeur",
			shared_with_everyone: "Partagé avec tout le monde",
			shared_with_custom: "Partagé avec des utilisateurs spécifiques",
			shared_with_receiver: "Partagé avec le destinataire",
			shared_with_sender: "Partagé avec l'expéditeur",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "Andorre",
		united_arab_emirates: "Émirats arabes unis",
		afghanistan: "Afghanistan",
		antigua_and_barbuda: "Antigua-et-Barbuda",
		anguilla: "Anguilla",
		albania: "Albanie",
		armenia: "Arménie",
		angola: "Angola",
		antarctica: "Antarctique",
		argentina: "Argentine",
		american_samoa: "Samoa américaines",
		austria: "Autriche",
		australia: "Australie",
		aruba: "Aruba",
		aland: "Îles Åland",
		azerbaijan: "Azerbaïdjan",
		bosnia_and_herzegovina: "Bosnie-Herzégovine",
		barbados: "Barbade",
		bangladesh: "Bangladesh",
		belgium: "Belgique",
		burkina_faso: "Burkina Faso",
		bulgaria: "Bulgarie",
		bahrain: "Bahreïn",
		burundi: "Burundi",
		benin: "Bénin",
		saint_barthelemy: "Saint-Barthélemy",
		bermuda: "Bermudes",
		brunei: "Brunéi",
		bolivia: "Bolivie",
		bonaire_sint_eustatius_and_saba: "Bonaire, Saint-Eustache et Saba",
		brazil: "Brésil",
		bahamas: "Bahamas",
		bhutan: "Bhoutan",
		bouvet_island: "Île Bouvet",
		botswana: "Botswana",
		belarus: "Biélorussie",
		belize: "Belize",
		canada: "Canada",
		cocos_islands: "Îles Cocos",
		dr_congo: "République démocratique du Congo",
		central_african_republic: "République centrafricaine",
		congo_republic: "Congo-Brazzaville",
		switzerland: "Suisse",
		ivory_coast: "Côte d'Ivoire",
		cook_islands: "Îles Cook",
		chile: "Chili",
		cameroon: "Cameroun",
		china: "Chine",
		colombia: "Colombie",
		costa_rica: "Costa Rica",
		cuba: "Cuba",
		cabo_verde: "Cap-Vert",
		curacao: "Curaçao",
		christmas_island: "Île Christmas",
		cyprus: "Chypre",
		czechia: "Tchéquie",
		germany: "Allemagne",
		djibouti: "Djibouti",
		denmark: "Danemark",
		dominica: "Dominique",
		dominican_republic: "République dominicaine",
		algeria: "Algérie",
		ecuador: "Équateur",
		estonia: "Estonie",
		egypt: "Égypte",
		western_sahara: "Sahara occidental",
		eritrea: "Érythrée",
		spain: "Espagne",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		fiji: "Fidji",
		falkland_islands: "Îles Malouines",
		micronesia: "États fédérés de Micronésie",
		faroe_islands: "Îles Féroé",
		france: "France",
		gabon: "Gabon",
		united_kingdom: "Royaume-Uni",
		grenada: "Grenade",
		georgia: "Géorgie",
		french_guiana: "Guyane française",
		guernsey: "Guernesey",
		ghana: "Ghana",
		gibraltar: "Gibraltar",
		greenland: "Groenland",
		gambia: "Gambie",
		guinea: "Guinée",
		guadeloupe: "Guadeloupe",
		equatorial_guinea: "Guinée équatoriale",
		greece: "Grèce",
		south_georgia_and_south_sandwich_islands:
			"Géorgie du Sud et îles Sandwich du Sud",
		guatemala: "Guatemala",
		guam: "Guam",
		guinea_bissau: "Guinée-Bissau",
		guyana: "Guyana",
		hong_kong: "Hong Kong",
		heard_island_and_mcdonald_islands: "Îles Heard et McDonald",
		honduras: "Honduras",
		croatia: "Croatie",
		haiti: "Haïti",
		hungary: "Hongrie",
		indonesia: "Indonésie",
		ireland: "Irlande",
		israel: "Israël",
		isle_of_man: "Île de Man",
		india: "Inde",
		british_indian_ocean_territory: "Territoire britannique de l'océan Indien",
		iraq: "Irak",
		iran: "Iran",
		iceland: "Islande",
		italy: "Italie",
		jersey: "Jersey",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		japan: "Japon",
		kenya: "Kenya",
		kyrgyzstan: "Kirghizistan",
		cambodia: "Cambodge",
		kiribati: "Kiribati",
		comoros: "Comores",
		st_kitts_and_nevis: "Saint-Christophe-et-Niévès",
		north_korea: "Corée du Nord",
		south_korea: "Corée du Sud",
		kuwait: "Koweït",
		cayman_islands: "Îles Caïmans",
		kazakhstan: "Kazakhstan",
		laos: "Laos",
		lebanon: "Liban",
		saint_lucia: "Sainte-Lucie",
		liechtenstein: "Liechtenstein",
		sri_lanka: "Sri Lanka",
		liberia: "Libéria",
		lesotho: "Lesotho",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		latvia: "Lettonie",
		libya: "Libye",
		morocco: "Maroc",
		monaco: "Monaco",
		moldova: "Moldavie",
		montenegro: "Monténégro",
		saint_martin: "Saint-Martin",
		madagascar: "Madagascar",
		marshall_islands: "Îles Marshall",
		north_macedonia: "Macédoine du Nord",
		mali: "Mali",
		myanmar: "Myanmar",
		mongolia: "Mongolie",
		macao: "Macao",
		northern_mariana_islands: "Îles Mariannes du Nord",
		martinique: "Martinique",
		mauritania: "Mauritanie",
		montserrat: "Montserrat",
		malta: "Malte",
		mauritius: "Maurice",
		maldives: "Maldives",
		malawi: "Malawi",
		mexico: "Mexique",
		malaysia: "Malaisie",
		mozambique: "Mozambique",
		namibia: "Namibie",
		new_caledonia: "Nouvelle-Calédonie",
		niger: "Niger",
		norfolk_island: "Île Norfolk",
		nigeria: "Nigéria",
		nicaragua: "Nicaragua",
		netherlands: "Pays-Bas",
		norway: "Norvège",
		nepal: "Népal",
		nauru: "Nauru",
		niue: "Niue",
		new_zealand: "Nouvelle-Zélande",
		oman: "Oman",
		panama: "Panama",
		peru: "Pérou",
		french_polynesia: "Polynésie française",
		papua_new_guinea: "Papouasie-Nouvelle-Guinée",
		philippines: "Philippines",
		pakistan: "Pakistan",
		poland: "Pologne",
		saint_pierre_and_miquelon: "Saint-Pierre-et-Miquelon",
		pitcairn_islands: "Îles Pitcairn",
		puerto_rico: "Porto Rico",
		palestine: "Territoire palestinien",
		portugal: "Portugal",
		palau: "Palau",
		paraguay: "Paraguay",
		qatar: "Qatar",
		reunion: "Réunion",
		romania: "Roumanie",
		serbia: "Serbie",
		russia: "Russie",
		rwanda: "Rwanda",
		saudi_arabia: "Arabie saoudite",
		solomon_islands: "Îles Salomon",
		seychelles: "Seychelles",
		sudan: "Soudan",
		sweden: "Suède",
		singapore: "Singapour",
		saint_helena: "Sainte-Hélène",
		slovenia: "Slovénie",
		svalbard_and_jan_mayen: "Svalbard et Jan Mayen",
		slovakia: "Slovaquie",
		sierra_leone: "Sierra Leone",
		san_marino: "Saint-Marin",
		senegal: "Sénégal",
		somalia: "Somalie",
		suriname: "Suriname",
		south_sudan: "Soudan du Sud",
		sao_tome_and_principe: "Sao Tomé-et-Principe",
		el_salvador: "Salvador",
		sint_maarten: "Saint-Martin (partie néerlandaise)",
		syria: "Syrie",
		eswatini: "Eswatini",
		turks_and_caicos_islands: "Îles Turques-et-Caïques",
		chad: "Tchad",
		french_southern_territories: "Terres australes françaises",
		togo: "Togo",
		thailand: "Thaïlande",
		tajikistan: "Tadjikistan",
		tokelau: "Tokelau",
		timor_leste: "Timor-Leste",
		turkmenistan: "Turkménistan",
		tunisia: "Tunisie",
		tonga: "Tonga",
		turkiye: "Turquie",
		trinidad_and_tobago: "Trinité-et-Tobago",
		tuvalu: "Tuvalu",
		taiwan: "Taïwan",
		tanzania: "Tanzanie",
		ukraine: "Ukraine",
		uganda: "Ouganda",
		us_minor_outlying_islands: "Îles mineures éloignées des États-Unis",
		united_states: "États-Unis",
		uruguay: "Uruguay",
		uzbekistan: "Ouzbékistan",
		vatican_city: "Cité du Vatican",
		st_vincent_and_grenadines: "Saint-Vincent-et-les-Grenadines",
		venezuela: "Venezuela",
		british_virgin_islands: "Îles Vierges britanniques",
		us_virgin_islands: "Îles Vierges américaines",
		vietnam: "Viêt Nam",
		vanuatu: "Vanuatu",
		wallis_and_futuna: "Wallis-et-Futuna",
		samoa: "Samoa",
		kosovo: "Kosovo",
		yemen: "Yémen",
		mayotte: "Mayotte",
		south_africa: "Afrique du Sud",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
	},
	$currency: {
		us_dollar: "Dollar américain",
		euro: "Euro",
		turkish_lira: "Livre turque",
	},
	$employeeType: {
		admin: "Administrateur",
		default: "Normal",
		exporter: "Exportateur",
		importer: "Importateur",
	},
	$format: {
		date: "DD.MM.YYYY",
		date_time: "DD.MM.YYYY HH:mm:SS",
		date_time_without_second: "DD.MM.YYYY HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "Bienvenue sur Antrego",
			description:
				"Contrôlez vos processus d'importation et d'exportation avec Antrego!",
			sign_in_title: "Vous avez un compte?",
			sign_in_subtitle: "Connectez-vous ici",
			sign_up_title: "Vous voulez créer un compte?",
			sign_up_subtitle: "Inscrivez-vous ici",
		},
		$noCompany: {
			title: "Bienvenue {name}",
			description:
				"Vous vous êtes connecté avec succès. Vous devez maintenant rejoindre une entreprise existante ou créer la vôtre.",
			join_company_title: "Vous voulez rejoindre une entreprise existante?",
			join_company_subtitle:
				"Entrez votre code d'invitation ici et rejoignez l'entreprise.",
			create_company_title: "Vous voulez créer votre entreprise?",
			create_company_subtitle: "Créez une nouvelle entreprise ici.",
		},
	},
	$invitationType: {
		employee: "Invitation du personnel",
		company_partner: "Invitation à la collaboration commerciale",
		customs_partner: "Invitation des douanes",
	},
	$landing: {
		$question: {
			$title: {
				0: "Qu'est-ce qu'Antrego?",
				1: "En quoi Antrego contribuera-t-il à mon processus d'expédition?",
				2: "Quels types de logistique Antrego prend en charge?",
				3: "Pourquoi devrais-je utiliser Antrego?",
				4: "Antrego est-il une application payante?",
				5: "Les clients d'Antrego doivent-ils payer des frais?",
			},
			$text: {
				0: "<p>Un programme de suivi des expéditions qui aide les entreprises expéditrices et leurs clients à suivre conjointement tout en prévenant les pertes dues à des retards lors du suivi.</p>",
				1: `<p>- Partagez toutes vos mises à jour en temps réel avec votre client. Améliorez la qualité de service.<br>
                - Consultez tous vos documents d'expédition dans un seul système et partagez-les avec votre client.<br>
                - Suivez toutes vos expéditions depuis un seul endroit. Contrôlez vos finances avec des rapports dynamiques.</p>`,
				2: `<p>- Maritime<br>
                - Aérien<br>
                - Routier<br>
                - Ferroviaire</p>`,
				3: `<p>Facilite le processus d'expédition avec ses services à portée de main.<br>
                <strong>1. Facile d'accès</strong><br>
                Accessible via un navigateur web depuis tous les appareils et partout.<br>
                <strong>2. Sécurité des données</strong><br>
                Toutes les données sont stockées avec la technologie de cryptage SHA-256.<br>
                <strong>3. Suivi partagé</strong><br>
                Offre la possibilité de suivre à tous les intervenants du processus d'affaires.</strong>`,
				4: "<p>Oui, c'est une application payante. Nous voulons faciliter vos affaires en numérisant tous les processus de vos expéditions et continuer à vous apporter des améliorations utiles. Pour permettre la continuation de ces améliorations et pour l'espace que vous utilisez sur notre serveur, nous vous demandons un paiement mensuel.</p>",
				5: "<p>Non, vos clients n'ont pas besoin de payer de frais pour suivre vos expéditions avec Antrego.</p>",
			},
		},
		$lifecycle: {
			headline: "TOUS LES PROCESSUS À PORTÉE DE MAIN",
			$title: {
				0: "Inscrivez-vous",
				1: "Envoyez une facture Proforma",
				2: "Créez une expédition",
				3: "Suivez les activités financières",
			},
			$text: {
				0: "Saisissez les informations de votre entreprise et de vos produits, créez vos clients.",
				1: "Créez une facture proforma et partagez-la avec vos clients pour approbation.",
				2: "Saisissez les informations sur votre expédition et ajoutez les documents. Gérez toutes les étapes de votre expédition avec vos clients via un canal unique.",
				3: "Suivez en temps réel toutes les situations financières liées à l'importation et à l'exportation.",
			},
		},
		$content: {
			headline: "TYPES DE LOGISTIQUE PRIS EN CHARGE",
			$title: {
				0: "Maritime",
				1: "Aérien",
				2: "Routier",
				3: "Ferroviaire",
			},
		},
		$pricing: {
			0: {
				title: "GRATUIT",
				subtitle: "Individuel",
				features: [
					"1 Utilisateur",
					"3 Partenaires",
					"10 Expéditions",
					"10 Factures",
				],
			},
			1: {
				title: "ANNUEL",
				subtitle: "Entreprises",
				features: [
					"20 Utilisateurs",
					"100 Partenaires",
					"1000 Expéditions",
					"1000 Factures",
				],
			},
			2: {
				title: "MENSUEL",
				subtitle: "Petites entreprises",
				features: [
					"10 Utilisateurs",
					"30 Partenaires",
					"250 Expéditions",
					"250 Factures",
				],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "Êtes-vous sûr de vouloir {function}?",
		created: "{item} créé.",
		deleted: "{item} supprimé.",
		file_oversize: "La taille du fichier doit être au maximum {size}.",
		invalid: "{item} invalide.",
		sent_email: "E-mail envoyé. ({value})",
		sign_in_failed: "Adresse e-mail ou mot de passe incorrect",
		updated: "{item} mis à jour.",
		migrated: "{item} migré. ({value})",
	},
	$productGroup: {
		add_child: "Ajouter un sous-groupe",
		add_to_root: "Ajouter à la racine",
		has_children_fail_message:
			"Le groupe de produits avec un sous-groupe ne peut pas être supprimé.",
		initial_message:
			"Appuyez sur le bouton {0} pour ajouter votre premier groupe de produits.",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name} a créé.",
			update: "{name} a mis à jour.",
			send: "{name} a envoyé au destinataire pour approbation.",
			revoke: "{name} a révoqué.",
			approve: "{name} a approuvé.",
			demand_revise: "{name} a demandé une révision.",
			reject: "{name} a rejeté.",
			mark_as_processed: "{name} a marqué comme traité.",
			create_attachment: "{name} a créé une pièce jointe.",
			update_attachment: "{name} a mis à jour une pièce jointe.",
			delete_attachment: "{name} a supprimé une pièce jointe.",
		},
		$actionDescription2: {
			send: '{name} a été marqué comme "Envoyé".',
			revoke: '{name} a été marqué comme "Révoqué".',
			approve: '{name} a été marqué comme "Approuvé".',
			demand_revise: '{name} a été marqué comme "Révision en attente".',
			reject: "{name} a été rejeté.",
			mark_as_processed: `{name} a été marqué comme "Traité".`,
		},
		$actions: {
			approve: "Approuver",
			demand_revise: "Demander une révision",
			mark_as_approved: "Approuvé",
			mark_as_processed: "Traité",
			mark_as_rejected: "Rejeté",
			mark_as_revise_demanded: "Révision demandée",
			mark_as_revoked: "Révoqué",
			mark_as_sent: "Envoyé",
			reject: "Rejeter",
			revoke: "Révoquer",
			send: "Envoyer",
		},
		$status: {
			approved: "Approuvé",
			pending_approval: "En attente d'approbation",
			pending_revise: "Révision en attente",
			processed: "Traité",
			rejected: "Rejeté",
			revoked: "Révoqué",
			template: "Modèle",
		},
		send_confirm_message:
			"Confirmez-vous l'envoi de la facture proforma au destinataire?",
		send_success_message: "La facture proforma a été envoyée au destinataire.",
		approve_confirm_message:
			"Êtes-vous sûr de vouloir approuver la facture proforma?",
		approve_success_message: "La facture proforma a été approuvée.",
		demand_revise_success_message:
			"La facture proforma a été renvoyée pour révision.",
		reject_confirm_message:
			"Êtes-vous sûr de vouloir rejeter la facture proforma?",
		reject_success_message: "La facture proforma a été rejetée.",
		mark_as_processed_confirm_message:
			"Confirmez-vous le marquage de la facture proforma comme traitée?",
		mark_as_processed_success_message:
			"La facture proforma a été marquée comme traitée.",
		revoke_confirm_message:
			"Êtes-vous sûr de vouloir révoquer la facture proforma?",
		revoke_success_message: "La facture proforma a été révoquée.",
		demand_revise_description_message:
			"Veuillez expliquer quelle révision vous attendez:",
		no_attachment_text: "Aucune pièce jointe n'a été ajoutée.",
	},
	$serviceMessage: {
		already_subs_module: "Ce module est déjà abonné.",
		bad_request: "Mauvaise requête",
		code_already_used: "Le code est déjà utilisé.",
		container_status_not_available:
			"Le statut du conteneur n'est pas disponible.",
		existing_email_warning: "L'e-mail existe déjà.",
		existing_employee_error:
			"Cet utilisateur est déjà ajouté à votre entreprise.",
		existing_employee_for_email_warning:
			"Le code d'invitation a déjà été envoyé à cet e-mail.",
		existing_invitation_warning:
			"Le code d'invitation a déjà été envoyé à cet e-mail.",
		existing_token_already_have_time:
			"Le jeton actuel a déjà suffisamment de temps d'utilisation.",
		existing_user: "L'utilisateur existe déjà.",
		five_min_reset_password_mail:
			"Attendez au moins 5 minutes après avoir demandé un e-mail de réinitialisation de mot de passe.",
		forbidden: "Interdit",
		internal_server_error: "Erreur interne du serveur.",
		instance_can_no_delete_existing_booking_code:
			"L'élément ne peut plus être supprimé en raison du code de réservation existant.",
		instance_cannot_delete: "L'élément ne peut plus être supprimé.",
		instance_not_found: "L'élément est introuvable.",
		invitation_accepting_is_due_to_the_admin:
			"L'acceptation de l'invitation dépend de l'administrateur.",
		invitation_already_discarded: "L'invitation a déjà été annulée.",
		invitation_already_used: "L'invitation a déjà été utilisée.",
		invitation_has_been_used: "L'invitation a été utilisée.",
		invitation_not_exist_email:
			"L'adresse e-mail de l'invitation n'existe pas.",
		invitation_not_for_custom_agency:
			"Cette invitation n'est pas pour une agence en douane.",
		invitation_not_for_company:
			"Cette invitation n'est pas pour une entreprise.",
		invitation_not_found: "L'invitation est introuvable.",
		invitation_not_valid: "L'invitation n'est plus valide.",
		invalid_current_password: "Votre mot de passe actuel est incorrect.",
		invalid_identifier_or_password: "Identifiant ou mot de passe invalide.",
		invalid_invitation_code: "Code d'invitation invalide.",
		invalid_moving_request: "Demande de déplacement invalide.",
		invalid_refresh_token: "Jetons de rafraîchissement invalide.",
		invalid_operation: "Opération invalide.",
		invalid_unit: "Unité invalide.",
		have_no_corporation: "Votre entreprise n'existe pas.",
		have_no_custom_agency: "Votre agence en douane n'existe pas.",
		have_no_employee: "Vous n'avez aucun employé.",
		link_not_be_used_anymore: "Ce lien ne peut plus être utilisé.",
		link_has_been_expired: "Le lien a expiré.",
		missing_required_fields: "Les champs obligatoires doivent être renseignés.",
		no_employee_found: "Aucun employé trouvé.",
		no_proper_partner_found: "Aucun partenaire approprié trouvé.",
		not_acceptable: "Inacceptable.",
		not_allowed_action: "Vous n'êtes pas autorisé à effectuer cette action.",
		not_allowed_use_api: "Vous n'êtes pas autorisé à utiliser cette API.",
		not_found: "Introuvable.",
		not_permitted_use_api: "Vous n'êtes pas autorisé à utiliser cette API.",
		own_invitation_cannot_accept:
			"Vous ne pouvez pas envoyer une invitation à vous-même.",
		parent_group_not_found: "Groupe de produits parent introuvable.",
		partnerId_required: "L'ID du partenaire est requis.",
		partnership_already_exists: "Le partenariat existe déjà.",
		password_cant_involve_space_char:
			"Le mot de passe ne peut pas contenir de caractères d'espace.",
		password_min_chars_least:
			"Le mot de passe doit comporter au moins 6 caractères.",
		ports_are_required: "Les ports sont requis.",
		product_group_tree_not_found:
			"L'arbre du groupe de produits est introuvable.",
		proforma_invoice_action_not_available:
			"L'état de la facture proforma n'est pas disponible pour cette action.",
		proforma_invoice_template_update:
			"La mise à jour de la facture proforma nécessite un modèle.",
		shipment_method_is_required: "La méthode d'expédition est requise.",
		shipping_term_is_required: "Le terme d'expédition est requis.",
		shipment_email_not_exist: "Veuillez saisir une adresse e-mail valide.",
		someone_else_uses_this_email:
			"Quelqu'un d'autre utilise déjà cette adresse e-mail.",
		someone_else_uses_this_username:
			"Quelqu'un d'autre utilise déjà ce nom d'utilisateur.",
		something_went_wrong: "Quelque chose s'est mal passé.",
		type_can_be_abstract_company: '"type" ne peut être que "abstract_company".',
		unauthorized: "Non autorisé",
		undefined_result: "Résultat non défini.",
		unexpected_error_occurred: "Une erreur inattendue s'est produite.",
		unit_already_added: "L'unité a déjà été ajoutée.",
		username_min_chars_error:
			'"Nom d\'utilisateur" doit comporter au moins 6 caractères.',
		user_already_employee: "L'utilisateur est déjà un employé.",
		user_already_exists: "L'utilisateur est déjà un employé de l'entreprise.",
		user_does_not_exist: "L'utilisateur n'existe pas.",
		user_not_found: "Utilisateur introuvable.",
	},
	$shipment: {
		$actionDescription: {
			create: "Créé par {name}.",
			update: "Mis à jour par {name}.",
			create_container: "{name} a ajouté un conteneur.",
			update_container: "{name} a mis à jour un conteneur.",
			delete_container: "{name} a supprimé un conteneur.",
			create_attachment: "{name} a ajouté une pièce jointe.",
			update_attachment: "{name} a mis à jour une pièce jointe.",
			delete_attachment: "{name} a supprimé une pièce jointe.",
			$land: {
				create_container: "{name} a ajouté un véhicule.",
				update_container: "{name} a mis à jour un véhicule.",
				delete_container: "{name} a supprimé un véhicule.",
			},
		},
		$containerStatus: {
			reserved: "Réservé",
			on_load: "Chargé",
			on_way: "En route",
			arrived: "Arrivé",
			off_load: "Déchargé",
		},
		$method: {
			sea: "Mer",
			aerial: "Aérien",
			land: "Terrestre",
			railway: "Ferroviaire",
		},
		$vehicle: {
			license_number: "Numéro de plaque",
		},
		archiving_success_message: "L'expédition a été archivée.",
		leave_message: "Laissez votre message ou commentaire",
		no_booking_info:
			"Aucune information de réservation n'est encore définie. Cliquez sur le bouton {0} pour ajouter.",
		no_transportation_info:
			"Aucun détail d'expédition pour la réservation n'est encore défini. Cliquez sur le bouton {0} pour définir.",
		un_archiving_success_message: "L'expédition a été retirée des archives.",
	},
	$shortening: {
		number: "No",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "Millimètre",
		meter: "Mètre",
		kilometer: "Kilomètre",
		milligram: "Milligramme",
		gram: "Gramme",
		kilogram: "Kilogramme",
		square_meter: "Mètre carré",
		hectare: "Hectare",
		square_kilometer: "Kilomètre carré",
		milliliter: "Millilitre",
		liter: "Litre",
		cubic_meter: "Mètre cube",
		acres: "Acres",
		feet: "Pieds",
		fluid_ounces: "Onces liquides",
		gallons: "Gallons",
		inches: "Pouces",
		miles: "Milles",
		ounces: "Onces",
		pounds: "Livres",
		quarts: "Quarts",
		square_feet: "Pieds carrés",
		square_miles: "Milles carrés",
		square_yards: "Yards carrés",
		square_inches: "Pouces carrés",
		yards: "Yards",
		pieces: "Pièces",
		boxes: "Boîtes",
		pallets: "Palettes",
		dozen: "Douzaine",
		ton: "Tonne",
	},
	$validation: {
		required: "{item} ne peut pas être vide",
		required_for_default_language:
			"{item} doit être défini pour la langue par défaut de l'entreprise.",
		invalid: "{item} est invalide",
		email: "Veuillez entrer une adresse e-mail valide",
		min_length: "{item} doit comporter au moins {length} caractères",
		max_length: "{item} doit comporter au maximum {length} caractères",
		password_repeat_warning:
			"La répétition du mot de passe doit être identique au nouveau mot de passe.",
	},

	accept_invitation_success_message:
		"Vous avez accepté l'invitation avec succès.",
	accessibility: "Accessibilité",
	actions: "Actions",
	active: "Actif",
	add: "Ajouter",
	add_item: "Ajouter {item}",
	address: "Adresse",
	again: "Encore",
	alert: "Alerte",
	already_have_employee_message: "Vous êtes déjà un employé de l'entreprise.",
	amount: "Montant",
	archive: "Archiver",
	attachment: "Pièce jointe",
	attachments: "Pièces jointes",

	booking: "Réservation",
	booking_code: "Code de réservation",
	business_partner: "Partenaire commercial",
	business_partners: "Partenaires commerciaux",

	cancel: "Annuler",
	change_password: "Changer le mot de passe",
	checking_invitation_code_message:
		"Vérification du code d'invitation en cours",
	choose_image: "Choisir une image",
	clear: "Effacer",
	close: "Fermer",
	code: "Code",
	companies: "Entreprises",
	company: "Entreprise",
	company_profile: "Profil de l'entreprise",
	commercial: "Commercial",
	commercial_partners: "Partenaires commerciaux",
	content: "Contenu",
	configuration: "Configuration",
	confirm: "Confirmer",
	contact: "Contact",
	contact_info: "Coordonnées",
	contact_to_us: "Contactez-nous",
	container: "Conteneur",
	containers: "Conteneurs",
	content: "Contenu",
	countries: "Pays",
	country: "Pays",
	create: "Créer",
	create_company: "Créer une entreprise",
	created_at: "Créé à",
	currencies: "Devises",
	currency: "Devise",
	current_password: "Mot de passe actuel",
	customs_agency: "Agence en douane",
	customs_agencies: "Agences en douane",

	date: "Date",
	dashboard: "Tableau de bord",
	default: "Par défaut",
	default_language: "Langue par défaut",
	delete: "Supprimer",
	delete_confirm_message: "Confirmez-vous la suppression?",
	description: "Description",
	detail: "Détail",
	discard: "Rejeter",
	document_number: "Numéro de document",
	download: "Télécharger",

	edit: "Modifier",
	edit_item: "Modifier {item}",
	email: "E-mail",
	employee: "Employé",
	employee_type: "Type d'employé",
	employees: "Employés",
	estimated_arrival_day: "Jour d'arrivée estimé",
	expense: "Frais",
	expenses: "Frais",
	exported_containers: "Conteneurs exportés",

	faq: "FAQ",
	file: "Fichier",
	filter: "Filtre",
	first_name: "Prénom",
	forget_password: "Mot de passe oublié",
	forget_password_message: "Mot de passe oublié?",
	from: "De",

	hide: "Masquer",
	home_page: "Page d'accueil",

	imported_containers: "Conteneurs importés",
	incoming: "Entrant",
	incoming_shipments: "Expéditions entrantes",
	initial: "Initial",
	invitation: "Invitation",
	invitation_code: "Code d'invitation",
	invitation_mail_sent: "E-mail d'invitation envoyé",
	invitations: "Invitations",

	join: "Rejoindre",

	language_options: "Options de langue",
	last_name: "Nom de famille",
	last_one_year: "Dernière année",
	latitude: "Latitude",
	loading_country: "Pays de chargement",
	loading_point: "Point de chargement",
	loading_port: "Port de chargement",
	logo: "Logo",
	longitude: "Longitude",

	go_to_panel: "Aller au panneau",

	mail_sent: "E-mail envoyé",
	migrate: "Migrer",
	migrate_partner: "Migrer le partenaire",
	message: "Message",
	message_company_create: "Entreprise créée.",
	message_company_join: "Rejoignez l'entreprise",
	message_company_joined: "Vous avez rejoint l'entreprise",
	message_employee_delete: "Employé supprimé.",
	message_employee_save: "Employé enregistré.",
	message_join_company:
		"Veuillez saisir votre code d'invitation pour vous inscrire à une entreprise.",
	method: "Méthode",
	mobile_number: "Numéro de portable",
	my_role: "Mon rôle",

	name: "Nom",
	next: "Suivant",
	new: "Nouveau",
	new_password: "Nouveau mot de passe",
	no: "Non",
	no_content: "Aucun contenu",
	no_text: "Pas de texte",
	not_found_page_message: "Désolé, cette page est introuvable :(",
	note: "Note",

	ok: "OK",
	outgoing: "Sortant",
	outgoing_shipments: "Expéditions sortantes",

	parent_group: "Groupe parent",
	partner: "Partenaire",
	partners: "Partenaires",
	passive: "Passif",
	password: "Mot de passe",
	phone_number: "Numéro de téléphone",
	previous: "Précédent",
	pricing: "Tarification",
	product: "Produit",
	product_group: "Groupe de produits",
	product_groups: "Groupes de produits",
	products: "Produits",
	profile: "Profil",
	proforma_invoice: "Facture proforma",
	proforma_invoices: "Factures proforma",
	proforma_number: "Numéro de proforma",
	purchase: "Achat",

	quantity: "Quantité",

	receiver: "Destinataire",
	receiver_company: "Entreprise destinataire",
	receiver_customs_agency: "Agence en douane destinataire",
	redirection_to_home_link: "cliquez ici",
	redirection_to_home_message: "pour revenir à la page d'accueil, veuillez ",
	redirection_to_sign_in_message: "Vous avez déjà un compte?",
	redirection_to_sign_up_message: "Vous n'avez pas encore de compte?",
	reload: "Recharger",
	reset_password: "Réinitialiser le mot de passe",

	sales: "Ventes",
	save: "Enregistrer",
	search: "Rechercher",
	select_file: "Sélectionner un fichier",
	select_file_place_holder: "Veuillez sélectionner une image",
	selected_partner: "Partenaire sélectionné",
	send: "Envoyer",
	send_again: "Renvoyer",
	send_email: "Envoyer un e-mail",
	send_invitation: "Envoyer une invitation",
	sender: "Expéditeur",
	sender_company: "Entreprise expéditrice",
	sender_customs_agency: "Agence en douane expéditrice",
	show: "Afficher",
	shipment: "Expédition",
	shipment_method: "Méthode d'expédition",
	shipments: "Expéditions",
	shipments_filter: "Filtre d'expédition",
	shipping_term: "Conditions d'expédition",
	shipping_terms: "Termes d'expédition",
	sign_in: "Se connecter",
	sign_out: "Déconnexion",
	sign_out_confirm_message: "Confirmez-vous la déconnexion?",
	sign_out_success_message: "Au revoir.",
	sign_up: "S'inscrire",
	social_media: "Médias sociaux",
	social_media_account: "Compte de médias sociaux",
	status: "Statut",
	subscribe: "S'abonner",
	super: "Super",
	switch_language: "Changer de langue",
	switch_theme: "Changer de thème",

	target: "Cible",
	target_partner: "Partenaire cible",
	theme: "Thème",
	this_month: "Ce mois-ci",
	this_year: "Cette année",
	title: "Titre",
	to: "À",
	toDelete: "Supprimer",
	total: "Total",
	total_amount: "Montant total",
	type: "Type",
	types: "Types",

	un_archive: "Désarchiver",
	unit: "Unité",
	units: "Unités",
	unloading_country: "Pays de déchargement",
	unloading_point: "Point de déchargement",
	unloading_port: "Port de déchargement",
	use_code: "Utiliser le code",
	use_invitation_code: "Utiliser le code d'invitation",
	use_invitation_code_description:
		"Veuillez saisir le code d'invitation que vous avez reçu dans votre boîte de réception.",
	use_invitation_code_success_message:
		"Le code d'invitation a été utilisé avec succès.",
	user_profile: "Mon profil",

	vehicle: "Véhicule",
	vehicles: "Véhicules",
	visibility: "Visibilité",

	warehouse: "Entrepôt",
	warehouses: "Entrepôts",
	website: "Site web",
	welcome: "Bienvenue",

	yes: "Oui",
};

export default {
	$accessibility: {
		present: "进行中",
		archived: "已存档",
	},
	$accessModifier: {
		$description: {
			private: "私有的",
			hidden_from_receiver: "对接收者隐藏的",
			hidden_from_sender: "对发件人隐藏的",
			shared_with_everyone: "与所有人共享的",
			shared_with_custom: "与特定人共享的",
			shared_with_receiver: "与接收者共享的",
			shared_with_sender: "与发件人共享的",
		},
	},
	$company: {
		name: "Slush Yazılım A.Ş.",
		address:
			"Kötekli mah. Denizli Yolu bul. Teknopark Sitesi B Blok No:4B/14 MENTESE/MUĞLA/TÜRKİYE",
		phoneNumber: "+90 (555) 444-33-22",
		googleMapSrc:
			"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2878.302258037826!2d28.375765134248574!3d37.17762700221327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bf732687fd3eb1%3A0x2815d6eefcce8910!2sMu%C4%9Fla%20Teknopark!5e0!3m2!1str!2str!4v1701804355508!5m2!1str!2str",
	},
	$country: {
		andorra: "安道尔",
		united_arab_emirates: "阿拉伯联合酋长国",
		afghanistan: "阿富汗",
		antigua_and_barbuda: "安提瓜和巴布达",
		anguilla: "安圭拉",
		albania: "阿尔巴尼亚",
		armenia: "亚美尼亚",
		angola: "安哥拉",
		antarctica: "南极洲",
		argentina: "阿根廷",
		american_samoa: "美属萨摩亚",
		austria: "奥地利",
		australia: "澳大利亚",
		aruba: "阿鲁巴",
		aland: "奥兰群岛",
		azerbaijan: "阿塞拜疆",
		bosnia_and_herzegovina: "波斯尼亚和黑塞哥维那",
		barbados: "巴巴多斯",
		bangladesh: "孟加拉国",
		belgium: "比利时",
		burkina_faso: "布基纳法索",
		bulgaria: "保加利亚",
		bahrain: "巴林",
		burundi: "布隆迪",
		benin: "贝宁",
		saint_barthelemy: "圣巴泰勒米",
		bermuda: "百慕大",
		brunei: "文莱",
		bolivia: "玻利维亚",
		bonaire_sint_eustatius_and_saba: "博内尔，圣尤斯特歇斯和萨巴",
		brazil: "巴西",
		bahamas: "巴哈马",
		bhutan: "不丹",
		bouvet_island: "布韦岛",
		botswana: "博茨瓦纳",
		belarus: "白俄罗斯",
		belize: "伯利兹",
		canada: "加拿大",
		cocos_islands: "科科斯群岛",
		dr_congo: "刚果民主共和国",
		central_african_republic: "中非共和国",
		congo_republic: "刚果共和国",
		switzerland: "瑞士",
		ivory_coast: "科特迪瓦",
		cook_islands: "库克群岛",
		chile: "智利",
		cameroon: "喀麦隆",
		china: "中国",
		colombia: "哥伦比亚",
		costa_rica: "哥斯达黎加",
		cuba: "古巴",
		cabo_verde: "佛得角",
		curacao: "库拉索",
		christmas_island: "圣诞岛",
		cyprus: "塞浦路斯",
		czechia: "捷克",
		germany: "德国",
		djibouti: "吉布提",
		denmark: "丹麦",
		dominica: "多米尼克",
		dominican_republic: "多米尼加共和国",
		algeria: "阿尔及利亚",
		ecuador: "厄瓜多尔",
		estonia: "爱沙尼亚",
		egypt: "埃及",
		western_sahara: "西撒哈拉",
		eritrea: "厄立特里亚",
		spain: "西班牙",
		ethiopia: "埃塞俄比亚",
		finland: "芬兰",
		fiji: "斐济",
		falkland_islands: "福克兰群岛",
		micronesia: "密克罗尼西亚联邦",
		faroe_islands: "法罗群岛",
		france: "法国",
		gabon: "加蓬",
		united_kingdom: "英国",
		grenada: "格林纳达",
		georgia: "格鲁吉亚",
		french_guiana: "法属圭亚那",
		guernsey: "根西岛",
		ghana: "加纳",
		gibraltar: "直布罗陀",
		greenland: "格陵兰",
		gambia: "冈比亚",
		guinea: "几内亚",
		guadeloupe: "瓜德罗普",
		equatorial_guinea: "赤道几内亚",
		greece: "希腊",
		south_georgia_and_south_sandwich_islands: "南乔治亚岛和南桑威奇群岛",
		guatemala: "危地马拉",
		guam: "关岛",
		guinea_bissau: "几内亚比绍",
		guyana: "圭亚那",
		hong_kong: "香港",
		heard_island_and_mcdonald_islands: "赫德岛和麦克唐纳群岛",
		honduras: "洪都拉斯",
		croatia: "克罗地亚",
		haiti: "海地",
		hungary: "匈牙利",
		indonesia: "印度尼西亚",
		ireland: "爱尔兰",
		israel: "以色列",
		isle_of_man: "曼岛",
		india: "印度",
		british_indian_ocean_territory: "英属印度洋领地",
		iraq: "伊拉克",
		iran: "伊朗",
		iceland: "冰岛",
		italy: "意大利",
		jersey: "泽西岛",
		jamaica: "牙买加",
		jordan: "约旦",
		japan: "日本",
		kenya: "肯尼亚",
		kyrgyzstan: "吉尔吉斯斯坦",
		cambodia: "柬埔寨",
		kiribati: "基里巴斯",
		comoros: "科摩罗",
		st_kitts_and_nevis: "圣基茨和尼维斯",
		north_korea: "朝鲜",
		south_korea: "韩国",
		kuwait: "科威特",
		cayman_islands: "开曼群岛",
		kazakhstan: "哈萨克斯坦",
		laos: "老挝",
		lebanon: "黎巴嫩",
		saint_lucia: "圣卢西亚",
		liechtenstein: "列支敦士登",
		sri_lanka: "斯里兰卡",
		liberia: "利比里亚",
		lesotho: "莱索托",
		lithuania: "立陶宛",
		luxembourg: "卢森堡",
		latvia: "拉脱维亚",
		libya: "利比亚",
		morocco: "摩洛哥",
		monaco: "摩纳哥",
		moldova: "摩尔多瓦",
		montenegro: "黑山",
		saint_martin: "圣马丁",
		madagascar: "马达加斯加",
		marshall_islands: "马绍尔群岛",
		north_macedonia: "北马其顿",
		mali: "马里",
		myanmar: "缅甸",
		mongolia: "蒙古",
		macao: "澳门",
		northern_mariana_islands: "北马里亚纳群岛",
		martinique: "马提尼克",
		mauritania: "毛里塔尼亚",
		montserrat: "蒙特塞拉特",
		malta: "马耳他",
		mauritius: "毛里求斯",
		maldives: "马尔代夫",
		malawi: "马拉维",
		mexico: "墨西哥",
		malaysia: "马来西亚",
		mozambique: "莫桑比克",
		namibia: "纳米比亚",
		new_caledonia: "新喀里多尼亚",
		niger: "尼日尔",
		norfolk_island: "诺福克岛",
		nigeria: "尼日利亚",
		nicaragua: "尼加拉瓜",
		netherlands: "荷兰",
		norway: "挪威",
		nepal: "尼泊尔",
		nauru: "瑙鲁",
		niue: "纽埃",
		new_zealand: "新西兰",
		oman: "阿曼",
		panama: "巴拿马",
		peru: "秘鲁",
		french_polynesia: "法属波利尼西亚",
		papua_new_guinea: "巴布亚新几内亚",
		philippines: "菲律宾",
		pakistan: "巴基斯坦",
		poland: "波兰",
		saint_pierre_and_miquelon: "圣皮埃尔和密克隆",
		pitcairn_islands: "皮特凯恩群岛",
		puerto_rico: "波多黎各",
		palestine: "巴勒斯坦",
		portugal: "葡萄牙",
		palau: "帕劳",
		paraguay: "巴拉圭",
		qatar: "卡塔尔",
		reunion: "留尼汪",
		romania: "罗马尼亚",
		serbia: "塞尔维亚",
		russia: "俄罗斯",
		rwanda: "卢旺达",
		saudi_arabia: "沙特阿拉伯",
		solomon_islands: "所罗门群岛",
		seychelles: "塞舌尔",
		sudan: "苏丹",
		sweden: "瑞典",
		singapore: "新加坡",
		saint_helena: "圣赫勒拿",
		slovenia: "斯洛文尼亚",
		svalbard_and_jan_mayen: "斯瓦尔巴和扬马延群岛",
		slovakia: "斯洛伐克",
		sierra_leone: "塞拉利昂",
		san_marino: "圣马力诺",
		senegal: "塞内加尔",
		somalia: "索马里",
		suriname: "苏里南",
		south_sudan: "南苏丹",
		sao_tome_and_principe: "圣多美和普林西比",
		el_salvador: "萨尔瓦多",
		sint_maarten: "圣马丁",
		syria: "叙利亚",
		eswatini: "埃斯瓦蒂尼",
		turks_and_caicos_islands: "特克斯和凯科斯群岛",
		chad: "乍得",
		french_southern_territories: "法属南部领地",
		togo: "多哥",
		thailand: "泰国",
		tajikistan: "塔吉克斯坦",
		tokelau: "托克劳",
		timor_leste: "东帝汶",
		turkmenistan: "土库曼斯坦",
		tunisia: "突尼斯",
		tonga: "汤加",
		turkiye: "土耳其",
		trinidad_and_tobago: "特立尼达和多巴哥",
		tuvalu: "图瓦卢",
		taiwan: "台湾",
		tanzania: "坦桑尼亚",
		ukraine: "乌克兰",
		uganda: "乌干达",
		us_minor_outlying_islands: "美国小离岛",
		united_states: "美国",
		uruguay: "乌拉圭",
		uzbekistan: "乌兹别克斯坦",
		vatican_city: "梵蒂冈",
		st_vincent_and_grenadines: "圣文森特和格林纳丁斯",
		venezuela: "委内瑞拉",
		british_virgin_islands: "英属维尔京群岛",
		us_virgin_islands: "美属维尔京群岛",
		vietnam: "越南",
		vanuatu: "瓦努阿图",
		wallis_and_futuna: "瓦利斯和富图纳",
		samoa: "萨摩亚",
		kosovo: "科索沃",
		yemen: "也门",
		mayotte: "马约特",
		south_africa: "南非",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
	},
	$currency: {
		us_dollar: "美元",
		euro: "欧元",
		turkish_lira: "土耳其里拉",
	},
	$employeeType: {
		admin: "管理员",
		default: "普通",
		exporter: "出口商",
		importer: "进口商",
	},
	$format: {
		date: "YYYY年MM月DD日",
		date_time: "YYYY年MM月DD日 HH:mm:SS",
		date_time_without_second: "YYYY年MM月DD日 HH:mm",
	},
	$home: {
		$unauthorized: {
			title: "欢迎来到Antrego",
			description: "通过Antrego掌控您的进出口流程！",
			sign_in_title: "您已经有账户了吗？",
			sign_in_subtitle: "从这里登录",
			sign_up_title: "想要创建一个账户吗？",
			sign_up_subtitle: "从这里注册",
		},
		$noCompany: {
			title: "欢迎，{name}",
			description: "您已成功登录。现在，您可以加入一个公司或创建您自己的公司。",
			join_company_title: "想要加入一个现有的公司吗？",
			join_company_subtitle: "在此处输入邀请码并加入公司。",
			create_company_title: "想要创建您自己的公司吗？",
			create_company_subtitle: "在此处创建一个新公司。",
		},
	},
	$invitationType: {
		employee: "员工邀请",
		company_partner: "商业合作邀请",
		customs_partner: "报关行邀请",
	},
	$landing: {
		$question: {
			$title: {
				0: "Antrego是什么？",
				1: "Antrego将为我的运输过程提供什么帮助？",
				2: "Antrego支持哪些物流类型？",
				3: "为什么我应该使用Antrego？",
				4: "Antrego是一个付费应用吗？",
				5: "Antrego的客户需要支付费用吗？",
			},
			$text: {
				0: "<p>Antrego是一个协助运输公司和客户进行共同跟踪的运输跟踪程序，以防止由于跟踪阶段的延误而可能发生的损失。</p>",
				1: `<p>- 与客户分享所有实时更新，提高服务质量。<br>
				- 在一个系统中查看与运输相关的所有文件，并与客户共享。<br>
				- 从同一位置跟踪所有运输。通过动态报告掌握财务状况。</p>`,
				2: `<p>- 海运<br>
				- 空运<br>
				- 陆运<br>
				- 火车运输</p>`,
				3: `<p>通过提供的服务轻松处理运输流程，随时随地都能方便地获得。<br>
				<strong>1. 方便访问</strong><br>
				可以通过Web浏览器从任何设备和任何地方访问。<br>
				<strong>2. 数据安全性</strong><br>
				所有数据都使用SHA-256加密技术存储。<br>
				<strong>3. 共同跟踪</strong><br>
				为业务过程中的所有利益相关者提供跟踪机会。</strong>`,
				4: "<p>是的，这是一个付费应用程序。通过将您的运输所有过程数字化，我们既希望简化您的工作，又希望继续为您提供有益的改进。为了确保这些改进的持续发展，并为您在服务器上使用的空间收取每月费用。</p>",
				5: "<p>不需要。您的客户在跟踪您的运输时无需支付任何费用。</p>",
			},
		},
		$lifecycle: {
			headline: "所有过程都在您的掌握之中",
			$title: {
				0: "注册",
				1: "发送形式发票",
				2: "创建运输",
				3: "跟踪财务活动",
			},
			$text: {
				0: "输入公司信息和产品，创建并添加客户。",
				1: "创建形式发票并与客户分享以进行批准。",
				2: "输入与您的运输相关的信息并添加文档。通过一个渠道管理与客户的所有运输过程。",
				3: "即时跟踪与进口和出口相关的所有财务状况。",
			},
		},
		$content: {
			headline: "支持的物流类型",
			$title: {
				0: "海运",
				1: "空运",
				2: "陆运",
				3: "火车运输",
			},
		},
		$pricing: {
			0: {
				title: "免费",
				subtitle: "个人",
				features: ["1用户", "3合作伙伴", "10运输", "10发票"],
			},
			1: {
				title: "年费",
				subtitle: "公司",
				features: ["20用户", "100合作伙伴", "1000运输", "1000发票"],
			},
			2: {
				title: "月费",
				subtitle: "小型企业",
				features: ["10用户", "30合作伙伴", "250运输", "250发票"],
			},
		},
	},
	$language: {
		en: "English",
		tr: "Türkçe",
		es: "Español",
		it: "Italiano",
		fr: "Français",
		de: "Deutsch",
		pt: "Português",
		ru: "Русский",
		ar: "العربية",
		ja: "日本語",
		ko: "한국어",
		zh: "中文",
		hi: "हिन्दी",
		vi: "Tiếng Việt",
		in: "Bahasa Indonesia",
	},
	$message: {
		confirm: "{function}确定要执行吗？",
		created: "{item}已创建。",
		deleted: "{item}已删除。",
		file_oversize: "文件大小最多为{size}。",
		invalid: "{item}无效",
		sent_email: "电子邮件已发送。 ({value})",
		sign_in_failed: "电子邮件或密码错误",
		updated: "{item}已更新。",
		migrated: "{item}已迁移。 ({value})",
	},
	$productGroup: {
		add_child: "添加子组",
		add_to_root: "添加到根目录",
		has_children_fail_message: "包含子组的产品组不能被删除",
		initial_message: "按下{0}按钮以添加您的第一个产品组。",
	},
	$proformaInvoice: {
		$actionDescription: {
			create: "{name}创建。",
			update: "{name}更新。",
			send: "{name}已发送给收件人以进行确认。",
			revoke: "{name}已撤销。",
			approve: "{name}已批准。",
			demand_revise: "{name}要求修订。",
			reject: "{name}已拒绝。",
			mark_as_processed: "{name}标记为已处理。",
			create_attachment: "{name}创建附件。",
			update_attachment: "{name}更新附件。",
			delete_attachment: "{name}删除附件。",
		},
		$actionDescription2: {
			send: '{name}标记为"已发送"。',
			revoke: '{name}标记为"已撤销"。',
			approve: '{name}标记为"已批准"。',
			demand_revise: '{name}标记为"等待修订"。',
			reject: "{name}已被拒绝。",
			mark_as_processed: `{name}标记为"已处理"。`,
		},
		$actions: {
			approve: "批准",
			demand_revise: "要求修订",
			mark_as_approved: "已批准",
			mark_as_processed: "已处理",
			mark_as_rejected: "已拒绝",
			mark_as_revise_demanded: "已要求修订",
			mark_as_revoked: "已撤销",
			mark_as_sent: "已发送",
			reject: "拒绝",
			revoke: "撤销",
			send: "发送",
		},
		$status: {
			approved: "已批准",
			pending_approval: "等待批准",
			pending_revise: "等待修订",
			processed: "已处理",
			rejected: "已拒绝",
			revoked: "已撤销",
			template: "模板",
		},
		send_confirm_message: "确认要将形式发票发送给收件人吗？",
		send_success_message: "形式发票已发送给收件人。",
		approve_confirm_message: "确认要批准形式发票吗？",
		approve_success_message: "形式发票已获批准。",
		demand_revise_success_message: "形式发票已退回进行修订。",
		reject_confirm_message: "确认要拒绝形式发票吗？",
		reject_success_message: "形式发票已被拒绝。",
		mark_as_processed_confirm_message: "确认要标记形式发票为已处理吗？",
		mark_as_processed_success_message: "形式发票已标记为已处理。",
		revoke_confirm_message: "确认要撤销形式发票吗？",
		revoke_success_message: "形式发票已撤销。",
		demand_revise_description_message: "请解释您期望的修订。",
		no_attachment_text: "尚未上传附件。",
	},
	$serviceMessage: {
		already_subs_module: "该模块已经订阅。",
		bad_request: "错误的请求",
		code_already_used: "代码已经被使用。",
		container_status_not_available: "容器状态无效。",
		existing_email_warning: "电子邮件已存在。",
		existing_employee_error: "此用户已经添加到您的公司。",
		existing_employee_for_email_warning: "邀请码已发送至此电子邮件。",
		existing_invitation_warning: "邀请码已发送至此电子邮件。",
		existing_token_already_have_time: "当前令牌已有足够的时间使用。",
		existing_user: "用户已存在。",
		five_min_reset_password_mail: "请求密码重置邮件后，请等待至少5分钟。",
		forbidden: "禁止",
		internal_server_error: "内部服务器错误。",
		instance_can_no_delete_existing_booking_code:
			"由于现有预订代码，该项目无法再删除。",
		instance_cannot_delete: "该项目无法再删除。",
		instance_not_found: "找不到该项目。",
		invitation_accepting_is_due_to_the_admin: "邀请接受取决于管理员。",
		invitation_already_discarded: "邀请已被取消。",
		invitation_already_used: "邀请已经被使用。",
		invitation_has_been_used: "邀请已经被使用。",
		invitation_not_exist_email: "邀请电子邮件地址不存在。",
		invitation_not_for_custom_agency: "此邀请不适用于海关代理。",
		invitation_not_for_company: "此邀请不适用于公司。",
		invitation_not_found: "找不到邀请。",
		invitation_not_valid: "邀请已不再有效。",
		invalid_current_password: "当前密码无效。",
		invalid_identifier_or_password: "无效的电子邮件或密码",
		invalid_invitation_code: "邀请码无效。",
		invalid_moving_request: "无效的移动请求。",
		invalid_refresh_token: "无效的刷新令牌。",
		invalid_operation: "无效的操作。",
		invalid_unit: "无效的单位。",
		have_no_corporation: "您没有公司。",
		have_no_custom_agency: "您没有海关代理。",
		have_no_employee: "您没有雇员。",
		link_not_be_used_anymore: "此链接可能不再可用。",
		link_has_been_expired: "链接已过期。",
		missing_required_fields: "必填字段不能为空。",
		no_employee_found: "找不到雇员。",
		no_proper_partner_found: "找不到合适的合作伙伴。",
		not_acceptable: "不可接受。",
		not_allowed_action: "您无权执行此操作。",
		not_allowed_use_api: "您无权使用此 API。",
		not_found: "找不到。",
		not_permitted_use_api: "您无权使用此 API。",
		own_invitation_cannot_accept: "您不能向自己发送邀请。",
		parent_group_not_found: "找不到产品组。",
		partnerId_required: "合作伙伴 ID 为必填项。",
		partnership_already_exists: "合作伙伴已经存在。",
		password_cant_involve_space_char: "密码不能包含空格字符。",
		password_min_chars_least: "密码至少应为 6 个字符。",
		ports_are_required: "端口是必需的。",
		product_group_tree_not_found: "找不到产品组树。",
		proforma_invoice_action_not_available: "形式发票的状态不适用于此操作。",
		proforma_invoice_template_update: "形式发票必须是模板才能更新。",
		shipment_method_is_required: "发货方式是必需的。",
		shipping_term_is_required: "运输条件是必需的。",
		shipment_email_not_exist: "请输入有效的电子邮件",
		someone_else_uses_this_email: "其他人正在使用此电子邮件地址。",
		someone_else_uses_this_username: "其他人正在使用此用户名。",
		something_went_wrong: "出了点问题",
		type_can_be_abstract_company: '"type" 属性只能是 "abstract_company"。',
		unauthorized: "未经授权",
		undefined_result: "未定义的结果。",
		unexpected_error_occurred: "发生意外错误。",
		unit_already_added: "单位已经添加。",
		username_min_chars_error: '"用户名" 至少应为 6 个字符。',
		user_already_employee: "用户已经是雇员。",
		user_already_exists: "用户已经是公司的雇员。",
		user_does_not_exist: "用户不存在。",
		user_not_found: "找不到用户。",
	},
	$shipment: {
		$actionDescription: {
			create: "{name}创建。",
			update: "{name}更新。",
			create_container: "{name}添加了一个集装箱。",
			update_container: "{name}更新了一个集装箱。",
			delete_container: "{name}删除了一个集装箱。",
			create_attachment: "{name}创建了一个附件。",
			update_attachment: "{name}更新了一个附件。",
			delete_attachment: "{name}删除了一个附件。",
			$land: {
				create_container: "{name}添加了一辆运输车。",
				update_container: "{name}更新了一辆运输车。",
				delete_container: "{name}删除了一辆运输车。",
			},
		},
		$containerStatus: {
			reserved: "已预订",
			on_load: "已装货",
			on_way: "在途中",
			arrived: "已到达",
			off_load: "已卸货",
		},
		$method: {
			sea: "海运",
			aerial: "空运",
			land: "陆运",
			railway: "铁路",
		},
		$vehicle: {
			license_number: "车牌号",
		},
		archiving_success_message: "运输已存档。",
		leave_message: "留下您的消息或评论",
		no_booking_info: "尚未定义booking信息。点击{0}按钮进行添加。",
		no_transportation_info: "尚未定义booking运输细节。点击{0}按钮进行定义。",
		un_archiving_success_message: "运输已从存档中取消。",
	},
	$shortening: {
		number: "编号",
	},
	$socialMedia: {
		facebook: "Facebook",
		instagram: "Instagram",
		linked_in: "Linked-in",
		twitter: "Twitter",
	},
	$unit: {
		millimeter: "毫米",
		meter: "米",
		kilometer: "千米",
		milligram: "毫克",
		gram: "克",
		kilogram: "千克",
		square_meter: "平方米",
		hectare: "公顷",
		square_kilometer: "平方千米",
		milliliter: "毫升",
		liter: "升",
		cubic_meter: "立方米",
		acres: "英亩",
		feet: "英尺",
		fluid_ounces: "液盎司",
		gallons: "加仑",
		inches: "英寸",
		miles: "英里",
		ounces: "盎司",
		pounds: "磅",
		quarts: "夸脱",
		square_feet: "平方英尺",
		square_miles: "平方英里",
		square_yards: "平方码",
		square_inches: "平方英寸",
		yards: "码",
		pieces: "件",
		boxes: "盒",
		pallets: "托盘",
		dozen: "打",
		ton: "吨",
	},
	$validation: {
		required: "{item}不能为空",
		required_for_default_language: "{item}信息应该对于公司的默认语言进行定义。",
		invalid: "{item}无效",
		email: "请输入有效的电子邮件",
		min_length: "{item}至少应为{length}个字符",
		max_length: "{item}最多应为{length}个字符",
		password_repeat_warning: "密码重复应与新密码相同。",
	},

	accept_invitation_success_message: "邀请已成功接受。",
	accessibility: "可访问性",
	actions: "操作",
	active: "活动的",
	add: "添加",
	add_item: "添加 {item}",
	address: "地址",
	again: "再次",
	alert: "警告",
	already_have_employee_message: "您已经是某个公司的员工。",
	amount: "金额",
	archive: "存档",
	attachment: "附件",
	attachments: "附件",

	booking: "Booking",
	booking_code: "预订代码",
	business_partner: "商业合作伙伴",
	business_partners: "商业合作伙伴",

	cancel: "取消",
	change_password: "修改密码",
	checking_invitation_code_message: "正在检查邀请码",
	choose_image: "选择图像",
	clear: "清除",
	close: "关闭",
	code: "代码",
	companies: "公司",
	company: "公司",
	company_profile: "公司概况",
	commercial: "商业",
	commercial_partners: "商业合作伙伴",
	content: "内容",
	configuration: "配置",
	confirm: "确认",
	contact: "联系",
	contact_info: "联系信息",
	contact_to_us: "联系我们",
	container: "集装箱",
	containers: "集装箱",
	content: "内容",
	countries: "国家",
	country: "国家",
	create: "创建",
	create_company: "创建公司",
	created_at: "创建于",
	currencies: "货币",
	currency: "货币",
	current_password: "当前密码",
	customs_agency: "海关代理",
	customs_agencies: "海关代理",

	date: "日期",
	dashboard: "仪表板",
	default: "默认",
	default_language: "默认语言",
	delete: "删除",
	delete_confirm_message: "确定要删除吗？",
	description: "描述",
	detail: "详情",
	discard: "放弃",
	document_number: "文件编号",
	download: "下载",

	edit: "编辑",
	edit_item: "编辑 {item}",
	email: "电子邮件",
	employee: "员工",
	employee_type: "员工类型",
	employees: "员工",
	estimated_arrival_day: "预计到达日",
	expense: "费用",
	expenses: "费用",
	exported_containers: "出口的集装箱",

	faq: "常见问题",
	file: "文件",
	filter: "过滤",
	first_name: "名字",
	forget_password: "忘记密码",
	forget_password_message: "忘记密码了吗？",
	from: "从",

	hide: "隐藏",
	home_page: "首页",

	imported_containers: "进口的集装箱",
	incoming: "收到的",
	incoming_shipments: "收到的装运",
	initial: "初始",
	invitation: "邀请",
	invitation_code: "邀请码",
	invitation_mail_sent: "邀请邮件已发送",
	invitations: "邀请",

	join: "加入",

	language_options: "语言选项",
	last_name: "姓氏",
	last_one_year: "过去一年",
	latitude: "纬度",
	loading_country: "装货国家",
	loading_point: "装货点",
	loading_port: "装货港口",
	logo: "标志",
	longitude: "经度",

	go_to_panel: "转到面板",

	mail_sent: "邮件已发送",
	migrate: "迁移",
	migrate_partner: "迁移合作伙伴",
	message: "消息",
	message_company_create: "公司已创建。",
	message_company_join: "加入公司",
	message_company_joined: "您已加入公司。",
	message_employee_delete: "员工已删除。",
	message_employee_save: "员工已保存。",
	message_join_company: "输入邀请码以注册公司。",
	method: "方法",
	mobile_number: "手机号码",
	my_role: "我的角色",

	name: "名称",
	next: "下一步",
	new: "新",
	new_password: "新密码",
	no: "否",
	no_content: "没有内容",
	no_text: "没有文本",
	not_found_page_message: "抱歉，找不到此页面 :(",
	note: "备注",

	ok: "确定",
	outgoing: "传出的",
	outgoing_shipments: "传出的装运",

	parent_group: "上级组",
	partner: "合作伙伴",
	partners: "合作伙伴",
	passive: "被动",
	password: "密码",
	phone_number: "电话号码",
	previous: "上一页",
	pricing: "定价",
	product: "产品",
	product_group: "产品组",
	product_groups: "产品组",
	products: "产品",
	profile: "个人资料",
	proforma_invoice: "形式发票",
	proforma_invoices: "形式发票",
	proforma_number: "形式号码",
	purchase: "购买",

	quantity: "数量",

	receiver: "接收者",
	receiver_company: "接收公司",
	receiver_customs_agency: "接收海关代理",
	redirection_to_home_link: "点击这里",
	redirection_to_home_message: "返回主页，请",
	redirection_to_sign_in_message: "已有账户吗？",
	redirection_to_sign_up_message: "还没有账户吗？",
	reload: "重新加载",
	reset_password: "重置密码",

	sales: "销售",
	save: "保存",
	search: "搜索",
	select_file: "选择文件",
	select_file_place_holder: "请选择图像",
	selected_partner: "已选择合作伙伴",
	send: "发送",
	send_again: "重新发送",
	send_email: "发送电子邮件",
	send_invitation: "发送邀请",
	sender: "发件人",
	sender_company: "发件公司",
	sender_customs_agency: "发件海关代理",
	show: "显示",
	shipment: "发货",
	shipment_method: "发货方法",
	shipments: "发货",
	shipments_filter: "发货过滤",
	shipping_term: "发货条件",
	shipping_terms: "发货条件",
	sign_in: "登录",
	sign_out: "退出",
	sign_out_confirm_message: "您确定要退出吗？",
	sign_out_success_message: "再见。",
	sign_up: "注册",
	social_media: "社交媒体",
	social_media_account: "社交媒体账户",
	status: "状态",
	subscribe: "订阅",
	super: "超级",
	switch_language: "切换语言",
	switch_theme: "切换主题",

	target: "目标",
	target_partner: "目标合作伙伴",
	theme: "主题",
	this_month: "本月",
	this_year: "今年",
	title: "标题",
	to: "到",
	toDelete: "删除",
	total: "总计",
	total_amount: "总金额",
	type: "类型",
	types: "类型",

	un_archive: "解除存档",
	unit: "单位",
	units: "单位",
	unloading_country: "卸货国家",
	unloading_point: "卸货点",
	unloading_port: "卸货港口",
	use_code: "使用代码",
	use_invitation_code: "使用邀请码",
	use_invitation_code_description: "输入您收到的邀请码。",
	use_invitation_code_success_message: "邀请码已成功使用。",
	user_profile: "用户资料",

	vehicle: "车辆",
	vehicles: "车辆",
	visibility: "可见性",

	warehouse: "仓库",
	warehouses: "仓库",
	website: "网站",
	welcome: "欢迎",

	yes: "是",
};

<template>
	<v-container>
		<v-col align="center">
			<h1 class="text-h4">{{ $t("pricing").toLocaleUpperCase() }}</h1>
			<v-row v-if="items" justify="center">
				<v-col v-for="(item, i) in items" :key="i" cols="auto">
					<v-card
						:class="cardStyle"
						elevation="8"
						:color="item.primary ? item.color : 'colors.white'"
					>
						<v-card-title :class="getTitleStyle(item.primary)" align="left">
							{{ $t(`$landing.$pricing.${i}.title`) }}
						</v-card-title>
						<!-- <v-card-subtitle
							:class="getSubtitleStyle(item.primary)"
							align="left"
						>
							{{ $t(`$landing.$pricing.${i}.subtitle`) }}
						</v-card-subtitle> -->
						<v-card-text :class="getPriceStyle(item.primary)" align="left">
							{{ item.price }}
						</v-card-text>
						<v-card-text
							v-for="(feature, j) in $t(`$landing.$pricing.${i}.features`)"
							:key="j"
							:class="getFeatureStyle(item.primary)"
							align="left"
						>
							<v-icon :class="getIconStyle(item.primary)">
								mdi-check-decagram
							</v-icon>
							{{ feature }}
						</v-card-text>
						<v-card-actions>
							<v-spacer />
							<v-btn class="mt-8">
								{{ $t("subscribe") }}
							</v-btn>
							<v-spacer />
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-container>
</template>

<script>
import { getSetting, Setting } from "../../../../data/local/ls";

export default {
	props: { isMobile: Boolean, isTablet: Boolean },
	data() {
		return {
			loading: false,
			items: [
				{
					price: "$0",
					color: "#003B73",
				},
				{
					price: "$999",
					primary: true,
					color: "#60A3D9",
				},
				{
					price: "$99",
					color: "#003B73",
				},
			],
		};
	},
	methods: {
		getTitleStyle(isPrimary = false) {
			return isPrimary ? "white--text text-h5" : "text-h5";
		},
		getSubtitleStyle(isPrimary = false) {
			return isPrimary ? "white--text text-subtitle-12" : "text-subtitle-12";
		},
		getPriceStyle(isPrimary = false) {
			return isPrimary ? "white--text text-h4" : "text-h4";
		},
		getFeatureStyle(isPrimary = false) {
			return isPrimary ? "white--text py-1 text-body-1" : "py-1 text-body-1";
		},
		getIconStyle(isPrimary = false) {
			return this.isDark
				? "white--text"
				: isPrimary
				? "white--text"
				: "black--text";
		},
	},
	computed: {
		isDark() {
			return getSetting(Setting.dark_mode);
		},
		cardStyle() {
			return this.isMobile
				? "my-4 px-4 py-4 pb-8 mx-1 mx-0"
				: this.isTablet
				? "my-8 px-8 py-6 pb-8 mx-1 mx-2"
				: "my-8 px-12 py-8 pb-12 mx-12";
		},
	},
	created() {
		//
	},
};
</script>

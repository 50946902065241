<template>
	<v-main>
		<v-container class="my-8">
			<v-col align="center">
				<h1 class="text-h4">{{ $t("$landing.$lifecycle.headline") }}</h1>
				<v-row>
					<v-col
						v-for="(item, i) in items"
						:key="i"
						cols="12"
						sm="3"
						md="3"
						lg="3"
					>
						<v-card
							class="mx-auto mt-16"
							max-width="300"
							color="transparent"
							elevation="0"
						>
							<v-icon size="132"> {{ item.icon }} </v-icon>
							<v-card-title class="justify-center px-2">
								{{ $t(`$landing.$lifecycle.$title.${i}`) }}
							</v-card-title>
							<v-card-text class="py-2">
								{{ $t(`$landing.$lifecycle.$text.${i}`) }}
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-container>
	</v-main>
</template>

<script>
export default {
	data() {
		return {
			items: [
				{ icon: "mdi-clipboard-account-outline" },
				{ icon: "mdi-receipt-text-arrow-right-outline" },
				{ icon: "mdi-truck-fast-outline" },
				{ icon: "mdi-finance" },
			],
		};
	},
	computed: {
		//
	},
	methods: {},
	created() {},
};
</script>

import Model from "../_model";

export default class ContainerProductModel extends Model {
	constructor(provider) {
		super(provider, "shipments/{shipmentId}/containers/{containerId}/products");
	}

	_getUrl(shipmentId, containerId, id) {
		let url = this.apiMethod
			.replace("{shipmentId}", shipmentId)
			.replace("{containerId}", containerId);
		if (id) url = url.concat(`/${id}`);
		return url;
	}

	filter(shipmentId, containerId, { onSuccess, onFail }) {
		return super.filter({
			url: this._getUrl(shipmentId, containerId),
			onSuccess,
			onFail,
		});
	}

	find() {
		throw `Find method is not available for ${this.constructor.name}`;
	}

	create(shipmentId, containerId, instance, { onSuccess, onFail }) {
		const body = {
			productId: instance.productId,
			name: instance.name,
			unitCode: instance.unitCode,
			quantity: instance.quantity,
			amount: instance.amount,
		};
		return super.create(body, {
			url: this._getUrl(shipmentId, containerId),
			onSuccess,
			onFail,
		});
	}

	update(shipmentId, containerId, instance, { onSuccess, onFail }) {
		const body = {
			productId: instance.productId,
			name: instance.name,
			unitCode: instance.unitCode,
			quantity: instance.quantity,
			amount: instance.amount,
		};
		return super.update(body, {
			url: this._getUrl(shipmentId, containerId, instance.id),
			onSuccess,
			onFail,
		});
	}

	delete(shipmentId, containerId, id, { onSuccess, onFail }) {
		return super.delete({
			url: this._getUrl(shipmentId, containerId, id),
			onSuccess,
			onFail,
		});
	}
}

<template>
	<page-template>
		<template slot="title">{{ $t("product_group") }}</template>
		<template slot="content">
			<v-container>
				<v-treeview
					:items="productGroupTree.children"
					v-if="productGroupTree && productGroupTree.children.length"
					hoverable
					open-all
				>
					<template slot="label" slot-scope="{ item }">
						{{ item.name[$lang] || item.name["_default"] }}
					</template>
					<template slot="append" slot-scope="{ item }">
						<v-btn
							v-if="item.level < maxParentCount"
							rounded
							x-small
							elevation="1"
							@click="dialog.show(item.id)"
							class="d-none d-sm-inline-flex"
						>
							{{ $t("$productGroup.add_child") }}
							<v-icon right small>mdi-plus-circle</v-icon>
						</v-btn>

						<v-btn class="d-sm-none" rounded icon small elevation="1" @click="dialog.show(item.id)">
							<v-icon>mdi-plus-circle</v-icon>
						</v-btn>

						<v-btn
							rounded
							x-small
							elevation="1"
							@click="dialog.show(item)"
							class="d-none d-sm-inline-flex ml-2"
						>
							{{ $t("edit") }}
							<v-icon right small>mdi-pencil-circle</v-icon>
						</v-btn>

						<v-btn
							class="d-sm-none ml-2"
							rounded
							icon
							small
							elevation="1"
							@click="dialog.show(item)"
						>
							<v-icon>mdi-pencil-circle</v-icon>
						</v-btn>
					</template>
				</v-treeview>
				<v-btn
					v-if="productGroupTree && productGroupTree.children.length"
					class="mt-2"
					rounded
					elevation="1"
					@click="dialog.show()"
				>
					<v-icon left>mdi-plus-circle</v-icon>
					{{ $t("$productGroup.add_to_root") }}
				</v-btn>

				<i18n v-else-if="productGroupTree" path="$productGroup.initial_message" tag="p" for="add">
					<v-btn class="mx-2" elevation="1" @click="dialog.show()">
						<v-icon left>mdi-plus</v-icon>
						{{ $t("add") }}
					</v-btn>
				</i18n>
			</v-container>

			<product-group-dialog
				v-model="dialog.visibility"
				:item="dialog.item"
				:productGroupTree="productGroupTree"
				:maxParentCount="maxParentCount"
				@saved="dialog.saved"
				@closed="dialog.closed"
				@deleted="dialog.deleted"
			/>
		</template>
	</page-template>
</template>

<script>
import uiMixin from "../../store/interfaces/ui.mixin";
import PageTemplate from "../templates/PageTemplate";
import ProductGroupDialog from "../partials/dialogs/ProductGroupDialog.vue";
import remote from "../../data/remote";

export default {
	mixins: [uiMixin],

	components: {
		PageTemplate,
		ProductGroupDialog,
	},

	data() {
		return {
			maxParentCount: 5,
			productGroupTree: null,

			dialog: {
				visibility: false,
				item: null,
				show(item) {
					this.item = item;
					this.visibility = true;
				},
				hide() {
					this.item = null;
					this.visibility = false;
				},
				saved: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
				closed: () => {
					this.dialog.hide();
				},
				deleted: () => {
					this.loadProductGroupTree();
					this.dialog.hide();
				},
			},
		};
	},

	created() {
		this.$trackPageView(this.$route.path);
		this.loadProductGroupTree();
	},

	methods: {
		loadProductGroupTree() {
			remote.productGroups.getMyProductGroupTree({
				onSuccess: (result) => {
					this.productGroupTree = result;
					this.handleParentCount(this.productGroupTree, 0);
				},
				onFail: (error) => {
					if (error) console.error(error);
				},
			});
		},
		handleParentCount(productGroupTree, level) {
			productGroupTree.level = level;
			if (level === 5) productGroupTree.id = productGroupTree._id;
			if (productGroupTree.children) level++;
			for (const child of productGroupTree.children) this.handleParentCount(child, level);
		},
	},
};
</script>

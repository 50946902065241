import Model from "../_model";

export default class EmployeeModel extends Model {
	constructor(provider) {
		super(provider, "employees");
	}

	filter({ pager, sorter }, { onSuccess, onFail }) {
		return super.filter({ params: {}, pager, sorter, onSuccess, onFail });
	}

	find(id, { onSuccess, onFail }) {
		return super.find({ id, onSuccess, onFail });
	}

	create(employee, { onSuccess, onFail }) {
		const body = {
			user: employee.user,
			corporationId: employee.corporationId,
			type: employee.type,
		};
		return super.create(body, { onSuccess, onFail });
	}

	updateType(id, type, { onSuccess, onFail }) {
		return super._put(`${this._getUrl(id)}/set_type/${type}`, null, {
			onSuccess,
			onFail,
		});
	}

	delete(id, { onSuccess, onFail }) {
		return super.delete({ id, onSuccess, onFail });
	}

	getMyEmployee({ onSuccess, onFail }) {
		super._get("employee", { onSuccess, onFail });
	}

	updateMyEmployee(employee, { onSuccess, onFail }) {
		const body = {
			user: {
				email: employee.user.email,
				firstName: employee.user.firstName,
				lastName: employee.user.lastName,
			},
		};
		return super._put("employee", body, { onSuccess, onFail });
	}

	// TODO: Company ile filtreleme filter fonksiyonunun altına taşınacak
	getMyCompanyEmployees({ onSuccess, onFail }) {
		super.filter({
			url: "employees",
			onSuccess,
			onFail,
		});
	}
}

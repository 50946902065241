<template>
	<v-dialog v-model="value" persistent max-width="480">
		<v-card v-if="currentItem">
			<v-card-title class="headline">
				{{ $t("warehouse") }}
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field
						v-model="currentItem.name"
						:label="$t('name')"
						:rules="rules.name"
					/>
					<v-text-field
						v-model="currentItem.address"
						:label="$t('address')"
						:rules="rules.address"
					/>
					<v-row class="mx-1 my-0 pa-0">
						<v-col cols="6" class="pa-0 pr-2">
							<v-text-field
								v-model="currentItem.latitude"
								:label="$t('latitude')"
								:rules="rules.latitude"
							/>
						</v-col>
						<v-col cols="6" class="pa-0 pl-2">
							<v-text-field
								v-model="currentItem.longitude"
								:label="$t('longitude')"
								:rules="rules.longitude"
							/>
						</v-col>
					</v-row>
					<v-text-field
						v-model="currentItem.email"
						:label="$t('email')"
						:rules="rules.email"
					/>
					<v-text-field
						v-model="currentItem.phoneNumber"
						:label="$t('phone_number')"
						:rules="rules.phoneNumber"
					/>
					<v-text-field
						v-model="currentItem.mobileNumber"
						:label="$t('mobile_number')"
						:rules="rules.mobileNumber"
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-btn
					v-if="currentItem.id"
					:disabled="deleting || saving"
					:loading="deleting"
					color="error"
					@click="deleteAction"
				>
					{{ $t("delete") }}
				</v-btn>
				<v-spacer />
				<v-btn :disabled="deleting || saving" @click="cancelAction">
					{{ $t("cancel") }}
				</v-btn>
				<v-btn
					:disabled="deleting || saving"
					:loading="saving"
					color="accent"
					@click="saveAction"
				>
					{{ $t("save") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import uiMixin from "../../../../store/interfaces/ui.mixin";
import remote from "../../../../data/remote";
import LocalizedTextField from "../../../controls/LocalizedTextField";
import { mapGetters } from "vuex";

export default {
	mixins: [uiMixin],
	props: ["value", "item"],
	components: { LocalizedTextField },
	data() {
		return {
			currentItem: null,
			valid: null,
			saving: false,
			deleting: false,
			rules: {
				name: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", { item: this.$t("name") });
						else if (v && v.length < 3)
							return this.$t("$validation.min_length", {
								item: this.$t("name"),
								length: "3",
							});
						else if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("name"),
								length: "50",
							});
						return true;
					},
				],
				address: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("address"),
							});
						if (v && v.length > 100)
							return this.$t("$validation.max_length", {
								item: this.$t("address"),
								length: "100",
							});
						return true;
					},
				],
				latitude: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("latitude"),
							});
						else if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("latitude"),
							});
						return true;
					},
				],
				longitude: [
					(v) => {
						if (!v)
							return this.$t("$validation.required", {
								item: this.$t("longitude"),
							});
						else if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("longitude"),
							});
						return true;
					},
				],
				phoneNumber: [
					(v) => {
						if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("phone_number"),
							});
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("phone_number"),
								length: "20",
							});
						return true;
					},
				],
				mobileNumber: [
					(v) => {
						if (v && isNaN(v))
							return this.$t("$validation.invalid", {
								item: this.$t("mobile_number"),
							});
						else if (v && v.length > 20)
							return this.$t("$validation.max_length", {
								item: this.$t("mobile_number"),
								length: "20",
							});
						return true;
					},
				],
				email: [
					(v) => {
						if (!/.+@.+\..+/.test(v))
							return this.$t("$validation.invalid", { item: this.$t("email") });
						return true;
					},
				],
			},
		};
	},
	computed: {
		...mapGetters(["company"]),
	},
	watch: {
		value(val) {
			if (val) this.handleProps();
			else this.reset();
		},
	},
	methods: {
		handleProps() {
			if (this.item) this.currentItem = Object.assign({}, this.item);
			else
				this.currentItem = {
					name: "",
					address: "",
					latitude: 0,
					longitude: 0,
					phoneNumber: "",
					mobileNumber: "",
					email: "",
				};
		},
		reset() {
			this.$refs.form.reset();
			this.currentItem = null;
		},
		saveAction() {
			if (this.valid) {
				const onSuccess = (result) => {
					this.saving = false;
					this.$emit("saved", result);
					this.showSnackBar({
						message: this.$t(
							this.currentItem.id ? "$message.updated" : "$message.created",
							{
								item: this.$t("warehouse"),
							}
						),
					});
				};
				const onFail = () => (this.saving = false);
				const warehouse = {
					id: this.currentItem.id,
					name: this.currentItem.name,
					address: this.currentItem.address,
					latitude: this.currentItem.latitude,
					longitude: this.currentItem.longitude,
					phoneNumber: this.currentItem.phoneNumber,
					mobileNumber: this.currentItem.mobileNumber,
					email: this.currentItem.email,
				};
				this.saving = true;
				if (this.currentItem.id) {
					remote.warehouses.update(this.company.id, warehouse, {
						onSuccess,
						onFail,
					});
				} else {
					remote.warehouses.create(this.company.id, warehouse, {
						onSuccess,
						onFail,
					});
				}
			} else this.$refs.form.validate();
		},
		deleteAction() {
			this.showConfirmDialog({
				message: this.$t("delete_confirm_message"),
				onConfirm: () => {
					this.deleting = true;
					remote.warehouses.delete(this.company.id, this.currentItem.id, {
						onSuccess: (result) => {
							this.deleting = false;
							this.$emit("deleted", result);
							this.showSnackBar({
								message: this.$t("$message.deleted", {
									item: this.$t("warehouse"),
								}),
							});
						},
						onFail: () => (this.deleting = false),
					});
				},
			});
		},
		cancelAction() {
			this.$emit("input", false);
			this.$emit("closed");
		},
	},
};
</script>

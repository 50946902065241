<template>
	<v-carousel
		cycle
		:show-arrows="false"
		hide-delimiter-background
		delimiter-icon="mdi-minus"
		interval="5000"
		:hide-delimiters="items.length <= 1"
		:height="carouselHeight"
	>
		<v-carousel-item v-for="(item, i) in items" :key="i" :src="item.imageUrl" />
	</v-carousel>
</template>

<script>
import remote from "../../../../data/remote";

export default {
	data() {
		return {
			isBusy: false,
			items: [
				{
					filePath: "landing/landing-headline-1.jpg",
					imageUrl: "",
				},
			],
		};
	},
	computed: {
		carouselHeight() {
			return (screen.height * 2) / 3;
		},
	},
	methods: {
		loadImages() {
			this.items.forEach((i) => {
				remote.files.get(i.filePath, {
					onSuccess: (result) => {
						i.imageUrl = window.URL.createObjectURL(new Blob([result]));
					},
				});
			});
			// this.isBusy = false;
		},
	},
	created() {
		this.isBusy = true;
		this.loadImages();
	},
};
</script>

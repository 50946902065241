<template>
	<page-template :hideAppBar="!isAuthenticated">
		<template slot="title" v-if="corporation">{{ $t("dashboard") }}</template>
		<template slot="title" v-else>
			<v-img
				max-height="48"
				max-width="196"
				cover
				src="@/assets/antrego_logo.png"
				class="mx-auto"
			/>
		</template>
		<template slot="buttons" v-if="isAuthenticated && !corporation">
			<v-tooltip top open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="changeLanguageDialog.show" v-on="on">
						<v-icon>mdi-translate</v-icon>
					</v-btn>
					<v-btn icon @click="feedbackDialog.show">
						<v-icon>mdi-comment-quote-outline</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("switch_language") }}</span>
			</v-tooltip>
			<v-btn icon @click="userProfileAction">
				<v-icon>mdi-account</v-icon>
			</v-btn>
			<v-btn icon @click="signOutAction">
				<v-icon>mdi-exit-run</v-icon>
			</v-btn>
		</template>
		<template slot="buttons" v-else-if="isAuthenticated && corporation">
			<v-tooltip top open-delay="500">
				<template v-slot:activator="{ on }">
					<v-btn icon @click="changeLanguageDialog.show" v-on="on">
						<v-icon>mdi-translate</v-icon>
					</v-btn>
					<v-btn icon @click="feedbackDialog.show">
						<v-icon>mdi-comment-quote-outline</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("switch_language") }}</span>
			</v-tooltip>
		</template>
		<template slot="content">
			<v-container fluid class="fill-height pa-0 d-block">
				<Landing v-if="!isAuthenticated" />
				<no-corporation-view v-else-if="!corporation" />
				<company-dashboard v-else />
			</v-container>

			<change-language-dialog
				v-model="changeLanguageDialog.visibility"
				@closed="changeLanguageDialog.hide"
			/>
			<feedback-dialog v-model="feedbackDialog.visibility" @closed="feedbackDialog.hide" />
		</template>
	</page-template>
</template>

<script>
import PageTemplate from "../../templates/PageTemplate";
import ChangeLanguageDialog from "../../partials/dialogs/ChangeLanguageDialog.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import uiMixin from "../../../store/interfaces/ui.mixin";
import Landing from "../landing/Landing.vue";
import NoCorporationView from "./NoCorporationView.vue";
import FeedbackDialog from "../../partials/FeedbackDialog.vue";
import CompanyDashboard from "./CompanyDashboard";

export default {
	mixins: [uiMixin],
	components: {
		PageTemplate,
		Landing,
		NoCorporationView,
		CompanyDashboard,
		ChangeLanguageDialog,
		FeedbackDialog,
	},
	computed: {
		...mapGetters("auth", ["isAuthenticated"]),
		...mapState(["corporation"]),
	},
	data() {
		return {
			feedbackDialog: {
				visibility: false,
				show: () => {
					this.feedbackDialog.visibility = true;
				},
				hide: () => {
					this.feedbackDialog.visibility = false;
				},
			},
			changeLanguageDialog: {
				visibility: false,
				show: () => {
					this.changeLanguageDialog.visibility = true;
				},
				hide: () => {
					this.changeLanguageDialog.visibility = false;
				},
			},
		};
	},
	methods: {
		...mapActions("auth", ["signOut"]),

		userProfileAction() {
			this.$router.push({ name: "userProfile" });
		},
		signOutAction() {
			this.showConfirmDialog({
				message: this.$t("sign_out_confirm_message"),
				onConfirm: () => {
					this.signOut(() => {
						this.showSnackBar({
							message: this.$t("sign_out_success_message"),
						});
						this.$router.push({ name: "signin" });
					});
				},
			});
		},
	},
};
</script>
